import React from 'react';
import PropTypes from 'prop-types';

class CleanerNameWithCategory extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        category: PropTypes.string,
        deaf: PropTypes.bool
    };

    render() {
        return (
            <div className={"maid-name-with-category " + this.props.category} >
                {this.props.name}{this.props.deaf && <span className="deaf-img-blue"></span>}
            </div>
        );
    }
}

export default CleanerNameWithCategory;
