import React from 'react';
import PropTypes from "prop-types";

import _ from 'underscore'

import InputWithErrors from '@components/common/input_with_errors';
import TermsOfUse from '@components/common/authenticator/terms_of_use';
import $ from 'jquery';
import Validator from '@services/validator';

class RegistrationForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        errors: PropTypes.object,

        onSubmitClick: PropTypes.func,
    };

    state = { errors: {} };

    errors = () => {
        return _.extend({}, this.state.errors, this.props.errors);
    };

    validateName = (value) => {
        if(!Validator.isPresent(value)) {
            return i18n.t('activerecord.errors.models.client.attributes.name.blank');
        }

        return null;
    };

    validateEmail = (value) => {
        if(!Validator.isPresent(value)) {
            return i18n.t('activerecord.errors.models.client.attributes.email.blank');
        }

        if(!Validator.isEmail(value)) {
            return i18n.t('activerecord.errors.models.client.attributes.email.invalid');
        }

        return null;
    };

    validatePhone = (value) => {
        if(!Validator.isPresent(value)) {
            return i18n.t('activerecord.errors.models.client.attributes.phone.blank');
        }

        if(!Validator.isPhone(value)) {
            return i18n.t('activerecord.errors.models.client.attributes.phone.invalid');
        }

        return null;
    };

    validatePassword = (value) => {
        if(!Validator.isPresent(value)) {
            return i18n.t('activerecord.errors.models.user.attributes.password.blank');
        }

        return null;
    };

    validatePasswordConfirmation = (password, passwordConfirmation) => {
        if(password !== passwordConfirmation) {
            return i18n.t('activerecord.errors.models.user.attributes.password_confirmation.confirmation');
        }

        return null;
    };

    validateSubmission = () => {
        var result = {},
            valid = true,
            errors = {
                name: this.validateName(this.refs.name.value),
                email: this.validateEmail(this.refs.email.value),
                phone: this.validatePhone(this.refs.phone.value),
                password: this.validatePassword(this.refs.password.value),
                passwordConfirmation: this.validatePasswordConfirmation(this.refs.password.value,
                                                                        this.refs.passwordConfrimation.value)
            };

        _.each(_.pairs(errors), function(pair) {
            var key = pair[0], error = pair[1];

            if(error) {
                result[key] = error;
                valid = false;
            }
        });

        this.setState({ errors: result });
        return valid;
    };

    validateAndSubmit = (event) => {
        event.preventDefault();

        var name = this.refs.name.value,
            email = this.refs.email.value,
            phone = this.refs.phone.value,
            password = this.refs.password.value,
            passwordConfirmation = this.refs.passwordConfrimation.value;

        if(this.validateSubmission()) {
            this.props.onSubmitClick();
            this.props.onSubmit(name, email, phone, password, passwordConfirmation);
        }
    };

    render() {
        return (
            <form onSubmit={this.validateAndSubmit}>
              <div className="pretty-form-heading">
                {i18n.t('funnel.user-form.registration.description')}
              </div>

              <InputWithErrors errors={this.errors().name}>
                <input name="name" ref="name" type="text" placeholder={i18n.t('helpers.label.funnel_form.name')} className="form-control input-lg"/>
              </InputWithErrors>

              <InputWithErrors errors={this.errors().email}>
                <input name="email" ref="email" type="email" placeholder={i18n.t('helpers.label.funnel_form.email')} className="form-control input-lg" />
              </InputWithErrors>

              <InputWithErrors errors={this.errors().phone}>
                <input name="phone" ref="phone" type="text" placeholder={i18n.t('helpers.label.funnel_form.phone')} className="form-control input-lg" />
              </InputWithErrors>

              <InputWithErrors errors={this.errors().password}>
                <input name="password" ref="password" type="password" placeholder={i18n.t('helpers.label.user.password')} className="form-control input-lg" />
              </InputWithErrors>

              <InputWithErrors errors={this.errors().passwordConfirmation}>
                <input name="password_confirmation" ref="passwordConfrimation" type="password" placeholder={i18n.t('helpers.label.user.password_confirmation')}  className="form-control input-lg" />
              </InputWithErrors>

              <div>
                <button type="submit" className="pretty-submit">{i18n.t('funnel.user-form.registration.button')}</button>
              </div>

              <TermsOfUse />
            </form>
        );
    }
};

export default RegistrationForm;
