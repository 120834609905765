import React from 'react';
import PropTypes from 'prop-types';


class FunnelProgressListLocationProductInfo extends React.Component {
    static propTypes = {
        cityAndDistrict: PropTypes.string,
        address: PropTypes.object,
        serviceTitle: PropTypes.string,
    };

    cityAndDistrict = () => {
        if(this.props.address)
            return this.props.address.city_name + ', ' + this.props.address.district_name;
        else if(this.props.cityAndDistrict)
            return this.props.cityAndDistrict;
        else
            return i18n.t("funnel.breadcrumbs.location-product.content");
    }

    render() {
        return (
            <div>
                <div className="little-darkblue-title">
                    {this.props.serviceTitle} - {this.cityAndDistrict()}
                </div>
            </div>
        )
    }
};

export default FunnelProgressListLocationProductInfo;
