import React from 'react';


class FunnelBreadcrumbsConfirmationInfo extends React.Component {
    content = () => {
        return i18n.t('funnel.breadcrumbs.confirmation.content');
    };

    title = () => {
        return i18n.t('funnel.breadcrumbs.confirmation.title');
    }

    render() {
        return (
            <div>
                <div className="little-darkblue-title">
                    {this.title()}
                </div>
                <p>
                    {this.content()}
                </p>
            </div>
        );
    }
};

export default FunnelBreadcrumbsConfirmationInfo;
