import React from 'react';
import PropTypes from 'prop-types';

import AbstractFunnel from "@components/abstract_funnel";
import withLoader from '@components/common/with_loader_decorator';
import withHistory from '@components/common/with_history_decorator'
import withPhoneCollector from '@components/common/with_phone_collector_decorator'
import Authenticator from '@components/common/authenticator'

import FunnelStatePersistance from '@services/funnel_state_persistance'

class LightCleaningFunnel extends AbstractFunnel {
    static childContextTypes = {
        locationStrategy: PropTypes.string
    };

    componentDidMount() {
        if (this.props.initializeStateAndHistory) {
            this.props.initializeStateAndHistory({componentName: 'LightCleaningFunnel', currentState: this.state});
        }

        FunnelStatePersistance.loadPersistedStateIfNeeded(this);
    }

    serviceType = () => {
        return 'light_cleaning';
    };

    availabilityPathString = () => {
        return 'light_cleaning_availability_path';
    };


    companiesForDatePath = () => {
        return PathHelper('companies_for_date_light_cleaning_requests_path');
    };

    attributesPath = () => {
        return PathHelper('attributes_light_cleaning_requests_path');
    };

    extraServiceTypes = () => {
        return ['carpet_and_upholstery_cleaning']
    };
}

LightCleaningFunnel = withLoader(LightCleaningFunnel);
LightCleaningFunnel = withPhoneCollector(LightCleaningFunnel);
LightCleaningFunnel = withHistory(LightCleaningFunnel);

class AuthenticatingLightCleaningFunnel extends React.Component {
    render() {
        return (
            <Authenticator status={this.props.componentProps.authenticationStatus}
                           omniauthError={this.props.componentProps.omniauthError}
                           component={LightCleaningFunnel}
                           {...this.props} />
        );
    }
}

export default AuthenticatingLightCleaningFunnel;
