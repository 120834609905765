import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import CompanyBox from '@components/common/company_box'

class CompanyList extends React.Component {
    static propTypes = {
        priorityCompanies: PropTypes.arrayOf(PropTypes.object),
        regularCompanies: PropTypes.arrayOf(PropTypes.object),
        allowNull: PropTypes.bool,
        showRecommendedBadge: PropTypes.bool,
        showPromotedProfile: PropTypes.bool,

        highlightedCompanyId: PropTypes.number,
        highlightedCompanyError: PropTypes.string,

        onSelect: PropTypes.func,
        selectBtnText: PropTypes.string,
        profileSelectBtnText: PropTypes.string,
        promotedCompany: PropTypes.object,

        membershipPercentDiscount: PropTypes.number,
        buyAddressMembership: PropTypes.bool,
        isCurrentAddressMember: PropTypes.bool,
        toggleBuyMembership: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            showAllCompanies: false,
            companiesShowCount: 6,
        };
    }

    increaseCompaniesShowCount = () => {
      this.setState({companiesShowCount: this.state.companiesShowCount + 6})
    };

    hasMoreCompanies = () => {
      return this.props.regularCompanies.length > this.state.companiesShowCount;
    };

    hasPriorityCompanies = () => {
        return this.props.priorityCompanies.length > 0;
    };

    showAllCompanies = () => {
        this.setState({showAllCompanies: true});
    };

    collectionTopRank = () => {
        return  _.min(_.map(_.flatten([this.props.regularCompanies, this.props.priorityCompanies]),
                                function(company){ return company.ranking.domestina_rank;}));
    };

    showPromoted = (company) => {
        return this.props.showPromotedProfile && this.props.promotedCompany && this.props.promotedCompany.id === company.id;
    }

    renderHighlightedError = () => {
        return (
            <div className={'missing-housemaid-error-container'}>
                {this.props.highlightedCompanyError}
            </div>
        )
    }

    renderCompanyBox = (company, i) => {
        return (
            <CompanyBox companyData={company}
                        highlighted={company.id === this.props.highlightedCompanyId}
                        onSelect={this.props.onSelect}
                        selectBtnText={this.props.selectBtnText}
                        showRecommendedBadge={this.props.showRecommendedBadge}
                        showPromotedProfile={this.showPromoted(company)}
                        profileSelectBtnText={this.props.profileSelectBtnText}
                        collectionTopRank={this.collectionTopRank()}
                        isCurrentAddressMember={this.props.isCurrentAddressMember}
                        membershipPercentDiscount={this.props.membershipPercentDiscount}
                        buyAddressMembership={this.props.buyAddressMembership}
                        toggleBuyMembership={this.props.toggleBuyMembership}
                        key={i}/>
        )
    };

    renderRegularCompaniеs = () => {
      return (
          <div>
              {_.map(this.props.regularCompanies.slice(0,this.state.companiesShowCount), function (company, i) {
                  return (
                      this.renderCompanyBox(company, i)
                  );
              }.bind(this))}
              {this.hasMoreCompanies() &&
              <div  className={'show-all-companies-container'}>
                  <a onClick={this.increaseCompaniesShowCount} style={{cursor: "pointer"}}> {i18n.t('funnel.companies.show-all-companies')} </a>
              </div>}
          </div>
      );
    };

    renderList = () => {
        return (
            <div className="company-list">
                {this.props.highlightedCompanyError && this.renderHighlightedError()}

                {_.map(this.props.priorityCompanies, function (company, i) {
                    return (
                        this.renderCompanyBox(company, i)
                    );
                }.bind(this))}

                {this.hasPriorityCompanies() && !this.state.showAllCompanies &&
                <div  className={'show-all-companies-container'}>
                    <a onClick={this.showAllCompanies} style={{cursor: "pointer"}}> {i18n.t('funnel.companies.show-all-companies')} </a>
                </div>}

                {(!this.hasPriorityCompanies() || this.state.showAllCompanies) &&
                    this.renderRegularCompaniеs()
                }
            </div>
        );
    };

    renderEmptyList = () => {
        return (
            <div className="company-list">
                <h2>{i18n.t('funnel.companies.no-providers')}</h2>
            </div>
        );
    };

    render() {
        if (this.props.regularCompanies.length + this.props.priorityCompanies.length >= 1) {
            return this.renderList()
        } else {
            return this.renderEmptyList()
        }
    }
}

export default CompanyList;
