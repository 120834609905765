import React from 'react';

import _ from 'underscore';
import LabelService from '@services/label_service';
import NumberField from '@components/common/number_field'
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import BookingRequest from '@services/booking_request'
import classNames from "classnames";
import AnimateHeight from "react-animate-height";


class CarUpholsteryCleaning extends AbstractProductCustomizer {
    static selectorType = 'car_upholstery_cleaning';
    static zeroSelectedProducts = (new BookingRequest()).zeroSelectedProductsForService(CarUpholsteryCleaning.selectorType);

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = this.zeroSelectedProducts();
        this.initializeProductOptions(props);
    };

    selectorType = () => {
        return CarUpholsteryCleaning.selectorType;
    };

    zeroSelectedProducts = () => {
        return _.clone(CarUpholsteryCleaning.zeroSelectedProducts);
    };

    samplePriceOptions = () => {
        return ['car_seats', 'car_floors']
    };

    renderFields = () => {
        return (
            <div className={'service-customizer-input-fields-container'}>
                <div className="row">
                    {this.props.showExplanation && this.showInputFields() &&
                    <div className="funnel-subtitle"
                         dangerouslySetInnerHTML={{__html: i18n.t('funnel.car-upholstery.subtitle_html')}}/>
                    }

                    {_.map(['car_seats', 'car_floors', 'car_trunks'], function (option) {
                        return (
                            <div className="col-xs-12 col-sm-4" key={option}>
                                <NumberField value={this.state.selectedProductOptions[option]}
                                             label={LabelService.labelOption(option, this.state.selectedProductOptions[option])}
                                             onChange={_.partial(this.updateOption, option)}
                                             disabled={!this.fieldSupported(option)}
                                             price={this.extraInformation(option)}
                                             min={0}
                                />
                            </div>
                        );
                    }.bind(this))}
                </div>
            </div>
        )
    }



    render() {
        return (
            <div>
                {this.props.isOptional &&
                <div className="add-service-checkbox">
                    <div className="headline-container">
                        <div className={'add-service-label'} onClick={this.toggleCheckbox}>
                            {i18n.t('services.car_upholstery_cleaning.title')}
                            <br/>
                            {this.priceHookText()}
                        </div>
                        {this.showExpandable() &&
                        <div className="arrow-headline-container">
                            <div className={classNames({'arrow': true, 'down': !this.state.checkbox, 'up': this.state.checkbox})} onClick={this.toggleCheckbox}>
                            </div>
                        </div> }
                    </div>
                    {this.showExpandable() &&
                    <AnimateHeight id={'bathroom-cleaning-expanded'}
                                   duration={500}
                                   height={(this.showInputFields()) ? 'auto' : 0}>
                        <hr className="customization-separator"/>
                        {this.renderFields()}
                    </AnimateHeight>}
                </div>}

                {!this.props.isOptional && this.renderFields()}
            </div>
        )
    }
}


export default CarUpholsteryCleaning;