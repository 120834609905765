import React from 'react';
import PropTypes from 'prop-types';


class FunnelProgressListAddressInfo extends React.Component {
    static propTypes = {
        address: PropTypes.string
    };

    renderAddress = () => {
        return (
            <div>
              <div className="little-darkblue-title">
                {this.props.address}
              </div>
            </div>
        );
    };

    renderRequiredAction = () => {
        return (
            <div>
              <div>
                {i18n.t('funnel.breadcrumbs.address.content')}
              </div>
            </div>
        );
    };

    hasAddress = () => {
        return !!this.props.address;
    };

    render() {
        if(this.hasAddress()) {
            return this.renderAddress();
        }
        else {
            return this.renderRequiredAction();
        }
    }
};

export default FunnelProgressListAddressInfo;
