import React from 'react';
import _ from 'underscore';

function withHistory(Component) {
    return class extends React.Component {
        state = {
            componentName: '',
            componentState: {},
            updatingFromHistory: false,
            onHistoryStateChange: null
        };

        componentWillUnmount() {
            if(this.state.onHistoryStateChange) {
                window.removeEventListener('popstate', this.state.onHistoryStateChange);
            }
        }

        // Call at componentDidMount hook of the wrapped component
        initializeStateAndHistory = (options) => { // componentName, currentState
            if(window.history.state && window.history.state[options.componentName]) {
                // console.log('Loading history state: ', window.history.state);

                var newState = window.history.state[options.componentName];
                this.setState({ componentName: options.componentName, updatingFromHistory: true, componentState: newState });
            }
            else {
                var currentState = _.extend({}, window.history.state || {});
                currentState[options.componentName] = _.extend({}, options.currentState);

                // console.log('Setting initial history state: ', currentState);

                window.history.replaceState(currentState, '', window.location);
                this.setState({ componentName: options.componentName });
            }

            var onHistoryStateChange = function(e) {
                // console.log('Propagate history state to component: ', e.state);

                if(e.state && e.state[options.componentName]){
                    this.setState({componentState: _.extend(e.state[options.componentName]), updatingFromHistory: true });
                }
            }.bind(this);

            this.setState({ onHistoryStateChange: onHistoryStateChange });
            window.addEventListener('popstate', onHistoryStateChange);
        };

        // Probably should be called in componentWillUpdate hook of the wrapped component
        captureHistoryState = (componentNextState) => {
            var newState = _.extend({}, window.history.state || {});
            newState[this.state.componentName] = _.extend({}, componentNextState);

            // console.log('Saving step to history: ', newState);
            window.history.pushState(newState, '', window.location);
        };

        markStateAsSynced = () => {
            this.setState({ updatingFromHistory: false });
        };

        render() {
            return (
                <Component {...this.props}
                           stateFromHistory={this.state.componentState}
                           updatingFromHistory={this.state.updatingFromHistory}
                           initializeStateAndHistory={this.initializeStateAndHistory}
                           captureHistoryState={this.captureHistoryState}
                           updateFromHistoryFinished={this.markStateAsSynced} />
            );
        }
    };
}

export default withHistory;