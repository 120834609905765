import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import {BreadcrumbsItem, Breadcrumbs} from '@components/common/breadcrumbs'
import FunnelBreadcrumbsCityAndDistrictInfo from '@components/funnel/breadcrumbs/city_and_district_info'
import FunnelBreadcrumbsAddressInfo from '@components/funnel/breadcrumbs/address_info'
import FunnelBreadcrumbsDetailsInfo from '@components/funnel/breadcrumbs/details_info'
import FunnelBreadcrumbsPaymentInfo from '@components/funnel/breadcrumbs/payment_info'
import FunnelBreadcrumbsProductInfo from '@components/funnel/breadcrumbs/product_info'
import FunnelBreadcrumbsPestControlProductInfo from '@components/funnel/breadcrumbs/pest_control_product_info'
import FunnelBreadcrumbsLocationProductInfo from '@components/funnel/breadcrumbs/location_product_info'
import FunnelBreadcrumbsCompanyPriceInfo from '@components/funnel/breadcrumbs/company_price_info'
import FunnelBreadcrumbsCleanerPriceInfo from '@components/funnel/breadcrumbs/cleaner_price_info'
import FunnelBreadcrumbsDateslotInfo from '@components/funnel/breadcrumbs/dateslot_info'
import FunnelBreadcrumbsExtrasInfo from '@components/funnel/breadcrumbs/extras_info'
import FunnelBreadcrumbsConfirmationInfo from '@components/funnel/breadcrumbs/confirmation_info'

class FunnelBreadcrumbs extends React.Component {
    static propTypes = {
        step: PropTypes.string,
        stepUpTo: PropTypes.string,
        product: PropTypes.object,
        address: PropTypes.object,
        onStepChange: PropTypes.func,
        slotsInfo: PropTypes.object,
        cleaner: PropTypes.object,
        dateslots: PropTypes.arrayOf(PropTypes.object),
        totalPrice: PropTypes.number,
        priceType: PropTypes.string,
        discounts: PropTypes.arrayOf(PropTypes.object),
        prepaidEventsCount: PropTypes.number,
        steps: PropTypes.arrayOf(PropTypes.string),
        cityAndDistrict: PropTypes.string,
        serviceTitle: PropTypes.string,
        extraServicePrice: PropTypes.number,
        membershipPercentDiscount: PropTypes.number,
        buyAddressMembership: PropTypes.bool,
        onlyPaymentAvailable: PropTypes.bool,
    };

    propsForStep = (step) => {
        var upToStepIndex = this.props.steps.indexOf(this.props.stepUpTo),
            givenStepIndex = this.props.steps.indexOf(step),
            disabled = upToStepIndex < givenStepIndex;


        if(this.props.onlyPaymentAvailable)
            disabled = this.props.steps.indexOf(step) !== (this.props.steps.length - 1)

        var onClick = !disabled ? _.partial(this.props.onStepChange, step) : null;

        return {
            onClick: onClick,
            disabled: disabled,
            active: this.props.step === step,
            style: { width: this.breadcrumbsItemWidthPercent() },
            key: givenStepIndex
        };
    };

    breadcrumbsItemWidthPercent = () => {
        return (100 / this.props.steps.length) + "%";
    };

    breadcrumbItem = (step) => {
        var content;

        if(step === 'locationProductCustomization') {
            content = <FunnelBreadcrumbsLocationProductInfo serviceTitle={this.props.serviceTitle}
                                                            address={this.props.address}
                                                            cityAndDistrict={this.props.cityAndDistrict}
            />;
        }
        else if(step === 'companySelection') {
            content = <FunnelBreadcrumbsCompanyPriceInfo extraServicePrice={this.props.extraServicePrice}
                                                         totalPrice={this.props.totalPrice}
                                                         company={this.props.company}
                                                         membershipPercentDiscount={this.props.membershipPercentDiscount}
                                                         buyAddressMembership={this.props.buyAddressMembership}
            />;
        }
        else if(step === 'cleanerSelection') {
            content = <FunnelBreadcrumbsCleanerPriceInfo totalPrice={this.props.totalPrice}
                                                         cleaner={this.props.cleaner}
            />;
        }
        else if(step === 'dateslotSelection') {
            content = <FunnelBreadcrumbsDateslotInfo dateslots={this.props.dateslots}/>;
        }
        else if(step === 'extraInfoCustomization' || step === 'commentEntry') {
            content = <FunnelBreadcrumbsExtrasInfo/>;
        }
        else if(step === 'bookingConfirmation') {
            content = <FunnelBreadcrumbsConfirmationInfo/>;
        }
        else if(step === 'locationSelection') {
            content = <FunnelBreadcrumbsCityAndDistrictInfo cityAndDistrict={this.props.cityAndDistrict}/>;
        }
        else if(step === 'productSelection') {
            content = <FunnelBreadcrumbsProductInfo product={this.props.product}/>;
        }
        else if(step === 'cleanerAndSlotSelection') {
            content = <FunnelBreadcrumbsDetailsInfo product={this.props.product}
                                                      slotsInfo={this.props.slotsInfo}
                                                      dateslots={this.props.dateslots}
                                                      cleaner={this.props.cleaner}
                                                      service={'housemaid'} />;
        }
        else if(step === 'addressSelection') {
            content = <FunnelBreadcrumbsAddressInfo product={this.props.product}
                                                      address={this.props.address}/>;
        }
        else if(step === 'payment') {
            content = <FunnelBreadcrumbsPaymentInfo totalPrice={this.props.totalPrice}
                                                      priceType={this.props.priceType}
                                                      product={this.props.product}
                                                      prepaidEventsCount={this.props.prepaidEventsCount} />;
        }
        else if(step === 'pestControlCustomization') {
            content = <FunnelBreadcrumbsPestControlProductInfo product={this.props.product} company={this.props.company}/>;
        }
        else if(step === 'pestControlDateSlotSelection') {
            content = <FunnelBreadcrumbsDetailsInfo product={this.props.product}
                                                      slotsInfo={this.props.slotsInfo}
                                                      dateslots={this.props.dateslots}
                                                      cleaner={this.props.cleaner} />;
        }
        else if(step === 'pestControlPayment') {
            content = <FunnelBreadcrumbsPaymentInfo totalPrice={this.props.totalPrice}
                                                    priceType={this.props.priceType}
                                                    defaultText={i18n.t('funnel.breadcrumbs.payment.confirm-order')}
                                                    defaultTitle={i18n.t('funnel.breadcrumbs.payment.confirm-title')}
            />;
        }
        if(content) {
            return (
                <BreadcrumbsItem {...this.propsForStep(step)}>
                  {content}
                </BreadcrumbsItem>
            );
        }
        else {
            return '';
        }
    };

    // ==============================================
    render() {
        return (
            <Breadcrumbs>
              {_.map(this.props.steps, function(step) {
                  return this.breadcrumbItem(step);
              }.bind(this))}
            </Breadcrumbs>
        );
    }
};

export default FunnelBreadcrumbs;
