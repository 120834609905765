import React from 'react';
import PropTypes from 'prop-types';
import MoneyUtils from '@services/money_utils'

class PriceTag extends React.Component {
    static propTypes = {
        amount: PropTypes.any,
        amountWithCurrency: PropTypes.string,
        priceType: PropTypes.string,
        className: PropTypes.string,
        prefix: PropTypes.string,
        flat: PropTypes.bool,
        postfix: PropTypes.string
    };

    currency = () => {
        return MoneyUtils.currency();
    };

    amount = () => {
        if(this.props.amount) {
            return this.props.amount;
        }
        else if(this.props.amountWithCurrency) {
            return this.props.amountWithCurrency.match(/[0-9.]+/)[0];
        }
        else {
            return null;
        }
    };

    renderFlat = () => {
        return (
            <div className={this.props.className}>
              {this.props.prefix && <div className="prefix">
                    {this.props.prefix}
              </div>}
              {COUNTRY == 'uk' && this.currency()}{this.amount()}{COUNTRY != 'uk' && this.currency()}
            </div>
        );
    };

    renderVaried = () => {
        return (
            <div className={this.props.className}>
              {this.props.prefix && <div className="prefix">
                {this.props.prefix}
              </div>}

              <div className="amount">
                {COUNTRY == 'uk' && this.currency()}
                {this.amount()}
              </div>

              {COUNTRY != 'uk' && <div className="currency">
                    {this.currency()}
              </div>}

              {this.props.postfix && <div className="postfix">
                    {this.props.postfix}
              </div>}
            </div>
        );
    };

    render() {
        if(this.props.flat) {
            return this.renderFlat();
        }
        else {
            return this.renderVaried();
        }
    }
}

export default PriceTag;
