import React from 'react';

import _ from 'underscore';
import UnitsUtils from '@services/units_utils';
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import classNames from "classnames";


class LightCleaning extends AbstractProductCustomizer {
    static zeroSelectedProducts = {
        light_cleaning_area: 0,
    };
    static selectorType = 'light_cleaning';

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = _.clone(LightCleaning.zeroSelectedProducts);
        this.initializeProductOptions(props);
    };

    selectorType = () => {
        return LightCleaning.selectorType;
    };

    zeroSelectedProducts = () => {
        return _.clone(LightCleaning.zeroSelectedProducts);
    };

    samplePriceOptions = () => {
        return ['light_cleaning_area']
    };

    updateArea = (event) => {
        var area = parseInt(event.target.value),
            selectedProductOptions = this.state.selectedProductOptions;
        area = _.isNaN(area) ? 0 : area;

        selectedProductOptions['light_cleaning_area'] = (area < 2000) ? area : 2000;

        this.setState({selectedProductOptions: selectedProductOptions}, function(){
            this.sendSelection(this.state.selectedProductOptions)
        }.bind(this));
    };

    hasInput = () => {
        var area = this.state.selectedProductOptions['light_cleaning_area'];
        return !!area && area !== '' && area > 0;
    }


    render() {
        var area = this.state.selectedProductOptions['light_cleaning_area'];

        if (isNaN(area) || area === 0) {
            area = '';
        }
        return (
            <div>
                {this.props.isOptional && this.state.pricelist.doesSupportService(this.selectorType()) &&
                <div className="add-service-checkbox">
                    <label className={'add-service-label'}>
                        <input type="checkbox" checked={this.state.checkbox}
                               onChange={this.toggleCheckbox}/>
                        <span className="cr with-price"><i className="cr-icon glyphicon glyphicon-ok"></i></span>
                        {I18n.t('services.light_cleaning.title')}
                        <br/>
                        {this.priceHookText()}
                    </label>
                </div>}
                {this.showInputFields() &&
                <div className="service-customizer-input-fields-container">
                    <div className="row">
                        {this.props.showExplanation &&
                        <div className='secondary-service-explanation col-md-8 col-md-offset-2 '>
                            {I18n.t('funnel.light_cleaning.subtitle_html')}
                        </div>}
                        <div className="form-group col-md-6 col-md-offset-3 free-input-field-container">
                            <div className={classNames({"input-group": this.hasInput()})}>
                                <input type="number" name="area"
                                       value={area}
                                       onChange={this.updateArea}
                                       onKeyPress={this.areaInputFieldKeyPressed}
                                       placeholder={I18n.t('components.customizers.enter-area-placeholder', {metric: UnitsUtils.areaUnit()})}
                                       className={classNames({"without-right-border": this.hasInput(), "form-control": true})}/>
                                {this.hasInput() &&
                                <div className="input-group-addon">{UnitsUtils.areaUnit()}</div>}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}


export default LightCleaning;