import React from 'react';
import PropTypes from 'prop-types';
import {BreadcrumbsItem, Breadcrumbs} from '@components/common/breadcrumbs'
import FunnelBreadcrumbsAddressInfo from '@components/funnel/breadcrumbs/address_info'
import FunnelBreadcrumbsProductInfo from '@components/funnel/breadcrumbs/product_info'
import FunnelBreadcrumbsPaymentInfo from '@components/funnel/breadcrumbs/payment_info'
import SubscriptionEditFormSubscriptionDetails from '@components/subscription_edit_form/subscription_details'

class SubscriptionRenewalFormBreadcrumbs extends React.Component {
    static propTypes = {
        product: PropTypes.object,
        address: PropTypes.string,
        cleaner: PropTypes.object,
        totalPrice: PropTypes.string,
        priceType: PropTypes.string
    };

    // ==============================================
    render() {
        return (
            <Breadcrumbs>
              <BreadcrumbsItem disabled={false}>
                <FunnelBreadcrumbsProductInfo product={this.props.product}/>
              </BreadcrumbsItem>

              <BreadcrumbsItem disabled={false}>
                <FunnelBreadcrumbsAddressInfo
                  product={this.props.product}
                  address={this.props.address}/>
              </BreadcrumbsItem>

              <BreadcrumbsItem disabled={false}>
                <SubscriptionEditFormSubscriptionDetails
                  product={this.props.product}
                  cleaner={this.props.cleaner}
                  weekdaysDescription={this.props.weekdaysDescription}
                  dateslots={[]}/>
              </BreadcrumbsItem>

              <BreadcrumbsItem disabled={false} active={true}>
                <FunnelBreadcrumbsPaymentInfo
                  totalPrice={this.props.totalPrice}
                  priceType={this.props.priceType}
                  product={this.props.product} />
              </BreadcrumbsItem>
            </Breadcrumbs>
        );
    }
};

export default SubscriptionRenewalFormBreadcrumbs;
