import React from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapModal from 'react-bootstrap/lib/Modal'

class Modal extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        onHide: PropTypes.func,
        size: PropTypes.string,
        dialogClassName: PropTypes.string,
        keyboard: PropTypes.bool,
        title: PropTypes.string
    };

    static defaultProps = {
        keyboard: false,
    };

    render() {
        return (
            <ReactBootstrapModal dialogClassName={this.props.dialogClassName} bsSize={this.props.size}
                                  show={this.props.isOpen} onHide={this.props.onHide} keyboard={this.props.keyboard}>
                {this.props.title &&
                <ReactBootstrapModal.Header closeButton>
                    <ReactBootstrapModal.Title>{this.props.title}</ReactBootstrapModal.Title>
                </ReactBootstrapModal.Header>
                }
                {this.props.children}
            </ReactBootstrapModal>
        );
    }
}

export default Modal;
