import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import InputWithErrors from '@components/common/input_with_errors';
import Modal from '@components/vendor/modal'
import $ from 'jquery';
import Validator from '@services/validator';

class PhoneCollector extends React.Component {
    static propTypes = {
        updatePhoneNumber: PropTypes.func
    };

    state = {
        modalIsOpen: true,
        validationErrors: {}
    };

    validatePhone = (value) => {
        if (!Validator.isPresent(value)) {
            return i18n.t('activerecord.errors.models.client.attributes.phone.blank');
        }

        if (!Validator.isPhone(value)) {
            return i18n.t('activerecord.errors.models.client.attributes.phone.invalid');
        }

        return null;
    };

    submit = (event) => {
        event.preventDefault();
        var phone = this.refs.phone.value,
            validationErrors = {},
            phoneErrorMessage = null;

        phoneErrorMessage = this.validatePhone(phone);

        if (phoneErrorMessage) {
            validationErrors['phone'] = [phoneErrorMessage];
        }

        if (_.isEmpty(validationErrors)) {
            $.ajax({
                type: "PATCH",
                url: PathHelper('client_update_phone_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify({
                    phone: phone
                }),
                statusCode: {
                    500: function () {
                        window.location.href = '/500';
                    }
                }
            }).done(function (result) {
                if (result['success']) {
                    this.setState({modalIsOpen: false});

                    if (this.props.updatePhoneNumber) {
                        this.props.updatePhoneNumber(phone);
                    }
                } else {
                    this.setState({validationErrors: result['errors']});
                }
            }.bind(this));
        } else {
            this.setState({validationErrors: validationErrors});
        }
    };

    phoneErrorMessage = () => {
        var messages = this.state.validationErrors['phone'];

        if (messages) {
            return messages[0];
        } else {
            return null;
        }
    };

    render() {
        return (
            <Modal isOpen={this.state.modalIsOpen}>
                <div className="custom-modal-content phone-collector">
                    <form className="form-horizontal" onSubmit={this.submit}>
                        <div className="form-group">
                            <h1>{i18n.t('funnel.phone-collector.heading')}</h1>
                        </div>
                        <div className="form-group phone-input-container">
                            <InputWithErrors errors={this.phoneErrorMessage()}>
                                <input name="phone" ref="phone" type="text" className="form-control input-lg phone-input-field"
                                       placeholder={i18n.t('helpers.label.client.phone')}/>
                            </InputWithErrors>
                        </div>
                        <div className='explanation'>
                            <i>*{i18n.t('funnel.phone-collector.explanation')}</i>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-lg btn-primary">
                                {i18n.t('form.buttons.save')}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}

export default PhoneCollector;
