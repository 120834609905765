import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import {ProgressListItem, ProgressList} from '@components/common/progress_list';
import FunnelProgressListMaidInfo from '@components/funnel/progress_list/maid_info';
import FunnelProgressListPaymentInfo from '@components/funnel/progress_list/payment_info';
import FunnelProgressListDetailsInfo from '@components/funnel/progress_list/details_info';
import FunnelProgressListProductInfo from '@components/funnel/progress_list/product_info';
import FunnelProgressListAddressInfo from '@components/funnel/progress_list/address_info';
import FunnelProgressListCityAndDistrictInfo from '@components/funnel/progress_list/city_and_district_info';
import FunnelProgressListPestControlProductInfo from '@components/funnel/progress_list/pest_control_product_info';

import FunnelProgressListLocationProductInfo from '@components/funnel/progress_list/location_product_info';
import FunnelProgressListCompanyPriceInfo from '@components/funnel/progress_list/company_price_info';
import FunnelProgressListCleanerPriceInfo from '@components/funnel/progress_list/cleaner_price_info';
import FunnelProgressListExtrasInfo from '@components/funnel/progress_list/extras_info';
import FunnelProgressListDateslotInfo from '@components/funnel/progress_list/dateslot_info';
import FunnelProgressListConfirmationInfo from '@components/funnel/progress_list/confirmation_info';

import DateSlotService from '@services/date_slot_service';


class FunnelProgressList extends React.Component {
    static propTypes = {
        step: PropTypes.string,
        stepUpTo: PropTypes.string,
        product: PropTypes.object,
        address: PropTypes.object,
        onStepChange: PropTypes.func,
        slotsInfo: PropTypes.object,
        cleaner: PropTypes.object,
        dateslots: PropTypes.arrayOf(PropTypes.object),
        totalPrice: PropTypes.number,
        price: PropTypes.object,
        discounts: PropTypes.arrayOf(PropTypes.object),
        prepaidEventsCount: PropTypes.number,
        steps: PropTypes.arrayOf(PropTypes.string),
        cityAndDistrict: PropTypes.string,
        serviceTitle: PropTypes.string,
        extraServicePrice: PropTypes.number,
        membershipPercentDiscount: PropTypes.number,
        buyAddressMembership: PropTypes.bool,
    };

    additionalStepVisible = () => {
        var selectionDone = DateSlotService.selectionDone(this.props.product, this.props.dateslots),
            noCleaner = !this.props.cleaner || this.props.cleaner.id == undefined;

        return this.isCleanerAndSlotSelectionStep() && selectionDone && noCleaner;
    };

    isCleanerAndSlotSelectionStep = () => {
        return this.props.step == 'cleanerAndSlotSelection';
    };

    propsForStep = (step) => {
        var upToStepIndex = this.props.steps.indexOf(this.props.stepUpTo),
            givenStepIndex = this.props.steps.indexOf(step),
            disabled = upToStepIndex < givenStepIndex,
            onClick = !disabled ? _.partial(this.props.onStepChange, step) : null,
            active = this.props.step === step;

        if(this.additionalStepVisible()) {
            active = false;
        }

        return {
            disabled: disabled,
            onClick: onClick,
            active: active,
            key: givenStepIndex
        };
    };

    showStep = (step) => {
        var currentStepIndex = this.props.steps.indexOf(this.props.step),
            givenStepIndex = this.props.steps.indexOf(step);

        return givenStepIndex <= currentStepIndex;
    };

    currentStepIsAfterCleanerAndSlotSelection = () => {
        var currentStepIndex = this.props.steps.indexOf(this.props.step),
            cleanerAndSlotSelectionStepIndex = this.props.steps.indexOf('cleanerAndSlotSelection');

        return cleanerAndSlotSelectionStepIndex < currentStepIndex;
    };

    progressListItem = (step) => {
        if(!this.showStep(step)) {
            return '';
        }

        var content;
        if(step === 'locationProductCustomization') {
            content = <FunnelProgressListLocationProductInfo serviceTitle={this.props.serviceTitle}
                                                            address={this.props.address}
                                                            cityAndDistrict={this.props.cityAndDistrict}
            />;
        }
        else if(step === 'companySelection') {
            content = <FunnelProgressListCompanyPriceInfo company={this.props.company}
                                                          totalPrice={this.props.totalPrice}
                                                          extraServicePrice={this.props.extraServicePrice}
                                                          membershipPercentDiscount={this.props.membershipPercentDiscount}
                                                          buyAddressMembership={this.props.buyAddressMembership}
            />;
        }
        else if(step === 'cleanerSelection') {
            content = <FunnelProgressListCleanerPriceInfo totalPrice={this.props.totalPrice}
                                                         cleaner={this.props.cleaner}
            />;
        }
        else if(step === 'dateslotSelection') {
            content = <FunnelProgressListDateslotInfo dateslots={this.props.dateslots}/>;
        }
        else if(step === 'extraInfoCustomization') {
            content = <FunnelProgressListExtrasInfo/>;
        }
        else if(step === 'bookingConfirmation') {
            content = <FunnelProgressListConfirmationInfo />;
        }

        if(step === 'locationSelection') {
            content = <FunnelProgressListCityAndDistrictInfo cityAndDistrict={this.props.cityAndDistrict} />;
        }
        else if(step === 'productSelection') {
            content = <FunnelProgressListProductInfo product={this.props.product}/>;
        }
        else if(step === 'cleanerAndSlotSelection')
            content = <FunnelProgressListDetailsInfo product={this.props.product}
                                                       slotsInfo={this.props.slotsInfo}
                                                       dateslots={this.props.dateslots}
                                                       cleaner={this.props.cleaner}
                                                       showDetails={this.additionalStepVisible() || this.currentStepIsAfterCleanerAndSlotSelection()} />;
        else if(step === 'addressSelection') {
            content = <FunnelProgressListAddressInfo product={this.props.product}
                                                       address={this.props.address}/>;
        }
        else if(step === 'payment') {
            content = <FunnelProgressListPaymentInfo product={this.props.product}
                                                       prepaidEventsCount={this.props.prepaidEventsCount}
                                                       price={this.props.price} />;
        }
        else if(step === 'pestControlCustomization') {
            content = <FunnelProgressListPestControlProductInfo product={this.props.product} company={this.props.company}/>;
        }
        else if(step === 'pestControlDateSlotSelection') {
            content = <FunnelProgressListDetailsInfo product={this.props.product}
                                                       slotsInfo={this.props.slotsInfo}
                                                       dateslots={this.props.dateslots}
                                                       cleaner={this.props.cleaner}
                                                       showDetails={true}
                                                       noCleaner={true} />;
        }
        else if(step === 'pestControlPayment') {
            content = <FunnelProgressListPaymentInfo totalPrice={this.props.totalPrice}
                                                       priceType={this.props.priceType}
                                                       defaultText={i18n.t('funnel.breadcrumbs.payment.confirm-order')} />;
        }

        return (
            <ProgressListItem {...this.propsForStep(step)}>
              {content}
            </ProgressListItem>
        );
    };

    render() {
        return (
              <ProgressList>
                {_.map(this.props.steps, function(step) {
                    return this.progressListItem(step);
                }.bind(this))}

                {this.additionalStepVisible() &&
                    <ProgressListItem disabled={false} active={true} key={this.props.steps.length}>
                          <FunnelProgressListMaidInfo/>
                    </ProgressListItem>}
              </ProgressList>
        );
    }
};


export default FunnelProgressList;
