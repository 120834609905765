import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import Modal from '@components/vendor/modal';
import {CleanerCarouselBox, LanguageFilterCarouselBox, AllCleanersCarouselBox} from '@components/common/cleaner_and_slot_selector/cleaner_carousel'


class PreferredCleanerModal extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        onOpen: PropTypes.func,
        onSelect: PropTypes.func.isRequired,
        cleaners: PropTypes.arrayOf(PropTypes.object),
        availableLanguages: PropTypes.array,
        selectedLanguage: PropTypes.string,
        languageFilterActive: PropTypes.bool
    };

    languageBoxShouldBeVisible = () => {
        return this.props.availableLanguages && this.props.availableLanguages.length > 0 && i18n.locale != NATIVE_LOCALE;
    };

    componentDidMount() {
        this.props.onOpen && this.props.onOpen();
    }

    render() {
        var allCleanersBox = (
                <div key={this.props.cleaners.length}>
                  <AllCleanersCarouselBox active={this.props.cleanerIdFilter === null && !this.props.languageFilterActive}
                                          onSelect={this.props.onSelect}/>
                </div>
            );

        var languageFilterCarouselBox = (
            <div className={this.languageBoxShouldBeVisible() ? '' : 'hidden'}>
              <LanguageFilterCarouselBox active={this.props.languageFilterActive}
                                         onSelect={this.props.onSelect}
                                         languages={this.props.availableLanguages}
                                         selectedLanguage={this.props.selectedLanguage}
                                         mobile={true}/>
            </div>
        );

        return (
            <Modal isOpen={this.props.isOpen} onHide={(e) => { if(e) { e.stopPropagation(); } this.props.onSelect({})} }>

              <div className="custom-modal-header">
                <div className="close-button" onClick={(e) => { if(e) { e.stopPropagation(); } this.props.onSelect({})} }></div>
                <h1>{i18n.t('funnel.cleaners-options.suggest-cleaner')}</h1>
              </div>

              <div className="custom-modal-content">
                <div className="cleaner-list">
                  {allCleanersBox}
                  {languageFilterCarouselBox}
                  {_.map(this.props.cleaners, function(cleaner, i) {
                      return (
                          <div key={i}>
                            <CleanerCarouselBox {...cleaner}
                                                onSelect={this.props.onSelect} />
                          </div>
                      );
                  }.bind(this))}
                </div>
              </div>
            </Modal>
        );
    }
}

export default PreferredCleanerModal;
