import React from 'react';
import PropTypes from 'prop-types';

import DateService from '@services/date_service';

class FunnelProgressListDateslotInfo extends React.Component {
    static propTypes = {
        dateslots: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            slot: PropTypes.number
        })),
    };

    content = () => {
        var dateslot = this.props.dateslots[0];
        return (
            <span>
                {DateService.formatDateAndDay(dateslot.date)}, {this.firstEventTimeslot(dateslot)}
            </span>
        );
    };

    firstEventTimeslot = (dateslot) => {
        return (
            <span>
                {i18n.t("funnel.breadcrumbs.dateslot-selection.visit-starts", {time: dateslot.starts_at})}, &nbsp;
                {i18n.t("funnel.breadcrumbs.dateslot-selection.visit-ends", {time: dateslot.ends_at})}
            </span>
        );
    }


    renderDetailsInfo = () => {
        return (
            <div>
              <div className="little-darkblue-title">
                {this.content()}
              </div>
            </div>
        );
    };

    renderRequiredAction = () => {
        return (
            <div>
              <div>
                {i18n.t('funnel.time-form.hints.single-event')}
              </div>
            </div>
        );
    };


    render() {
        if(this.props.dateslots && this.props.dateslots.length > 0) {
            return this.renderDetailsInfo();
        }
        else {
            return this.renderRequiredAction();
        }
    }
};

export default FunnelProgressListDateslotInfo;
