import React from 'react';
import PropTypes from 'prop-types';

import AbstractFunnel from "@components/abstract_funnel";
import withLoader from '@components/common/with_loader_decorator';
import withHistory from '@components/common/with_history_decorator'
import withPhoneCollector from '@components/common/with_phone_collector_decorator'
import Authenticator from '@components/common/authenticator'

import FunnelStatePersistance from '@services/funnel_state_persistance'

class EndOfTenancyCleaningFunnel extends AbstractFunnel {
    static childContextTypes = {
        locationStrategy: PropTypes.string
    };

    componentDidMount() {
        if (this.props.initializeStateAndHistory) {
            this.props.initializeStateAndHistory({componentName: 'EndOfTenancyCleaningFunnel', currentState: this.state});
        }
        FunnelStatePersistance.loadPersistedStateIfNeeded(this);
    }

    serviceType = () => {
        return 'end_of_tenancy_cleaning';
    };

    availabilityPathString = () => {
        return 'end_of_tenancy_cleaning_availability_path';
    };


    companiesForDatePath = () => {
        return PathHelper('companies_for_date_end_of_tenancy_cleaning_requests_path');
    };

    attributesPath = () => {
        return PathHelper('attributes_end_of_tenancy_cleaning_requests_path');
    };

    areExtrasOptional = () => {
        return false;
    }

    extraServicesSubheading = () => {
        return  "";
    }

    extraServicesHeading = () => {
        return i18n.t('services.carpet_and_upholstery_cleaning.title');
    }

}


EndOfTenancyCleaningFunnel = withLoader(EndOfTenancyCleaningFunnel);
EndOfTenancyCleaningFunnel = withPhoneCollector(EndOfTenancyCleaningFunnel);
EndOfTenancyCleaningFunnel = withHistory(EndOfTenancyCleaningFunnel);

class AuthenticatingEndOfTenancyCleaningFunnel extends React.Component {
    render() {
        return (
            <Authenticator status={this.props.componentProps.authenticationStatus}
                           omniauthError={this.props.componentProps.omniauthError}
                           component={EndOfTenancyCleaningFunnel}
                           {...this.props} />
        );
    }
}

export default AuthenticatingEndOfTenancyCleaningFunnel;
