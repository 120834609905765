import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import Modal from '@components/vendor/modal';
import AddressSelectorForm from '@components/common/address_selector/form';
import AddressSelectorResponsiveOptions from '@components/common/address_selector/responsive_options';
import withLoader from '@components/common/with_loader_decorator';
import $ from 'jquery';

class AddressIndex extends React.Component {
    static propTypes = {
        addresses: PropTypes.arrayOf(PropTypes.object),
        cities: PropTypes.arrayOf(PropTypes.object),
        productType: PropTypes.number,
        locationStrategy: PropTypes.string
    };

    static childContextTypes = {
        locationStrategy: PropTypes.string
    };

    state = {
        addresses: this.props.addresses,
        newAddressDialogIsOpen: false
    };

    getChildContext() {
        return { locationStrategy: this.props.locationStrategy };
    }

    addAddress = (address) => {
        return this.props.executeSlowOperation(function() {
            var promise = $.ajax({
                type: "POST",
                url: PathHelper('addresses_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(address),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });

            promise.done(function(response) {
                if(response.success) {
                    this.setState({ addresses: this.state.addresses.concat(response.address), newAddressDialogIsOpen: false });
                }
            }.bind(this));

            return promise;
        }.bind(this));
    };

    removeAddress = (address) => {
        if(address.has_upcoming_event) {
            alert(i18n.t('funnel.address-form.remove-error'));
            return;
        }

        if(confirm(i18n.t('funnel.address-form.remove-confirmation'))) {
            if(address.id) {
                $.ajax({
                    type: "DELETE",
                    url: PathHelper('address_path', {id: address.id}),
                    statusCode: {
                        500: function() {
                            window.location.href = '/500';
                        }
                    }
                });
            }

            var addresses = _.reject(this.state.addresses, function(addr) {
                return addr.id === address.id;
            });

            this.setState({addresses: addresses});
        }
    };

    openAddressDialog = () => {
        this.setState({newAddressDialogIsOpen: true});
    };

    closeAddressDialog = () => {
        this.setState({newAddressDialogIsOpen: false});
    };

    districtsForProductType = () => {
        return AddressUtils.districtsForProductType(this.props.districts, this.props.productType);
    };

    renderForm = () => {
        return (
            <AddressSelectorForm cities={this.props.cities}
                                  onSubmit={this.addAddress}
                                  productType={this.props.productType}
                                  collectPhone={false}/>
        );
    };

    render() {
        return (
            <div>
              <AddressSelectorResponsiveOptions addresses={this.state.addresses} onDestroy={this.removeAddress} onAdd={this.openAddressDialog} />

              <Modal size="large" isOpen={this.state.newAddressDialogIsOpen} onHide={this.closeAddressDialog}>
                <div className="custom-modal-header">
                  <div className="close-button" onClick={this.closeAddressDialog}></div>
                  <h1>New address</h1>
                </div>
                <div className="custom-modal-content">
                  {this.renderForm()}
                </div>
              </Modal>
            </div>
        );
    }
}

export default withLoader(AddressIndex);
