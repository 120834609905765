var ScheduleUtils = {
    extractCleanerOptionsFromSchedule: function(schedule, dateslot) {
        var day = _.find(schedule, function(day) {
                return day.date === dateslot.date;
            }),

            slot = _.find(day.slots, function(slot) {
                return slot.starts_at == dateslot.starts_at &&
                       slot.ends_at == dateslot.ends_at;
            });

        return slot.cleaners;
    },

    extractCleanerOptionsFromDateslots: function(dateslots) {
        if(dateslots.length > 0) {
            return _.last(dateslots).cleaners;
        }
        else {
            return [];
        }
    },

    dateslotsEqual: function(dateslot1, dateslot2) {
        return dateslot1.date === dateslot2.date &&
            dateslot1.starts_at === dateslot2.starts_at &&
            dateslot1.ends_at === dateslot2.ends_at;
    },

    dateslotToUniqString: function(dateslot) {
        return dateslot.date + dateslot.starts_at + dateslot.ends_at;
    },

    sanitizeDateslot: function(dateslot) {
        return { date: dateslot.date,
                 starts_at: dateslot.starts_at,
                 ends_at: dateslot.ends_at };
    },

    getDateslotsFromSchedule: function(schedule, dateslots) {
        return _.map(dateslots, function(dateslot) {
            var day = _.find(schedule, function(day) {
                return day.date === dateslot.date;
            }),

                slot = _.find(day.slots, function(slot) {
                    return slot.starts_at == dateslot.starts_at &&
                           slot.ends_at == dateslot.ends_at;
                });

            return { date: day.date, starts_at: slot.starts_at,
                     ends_at: slot.ends_at, cleaners: slot.cleaners };
        }.bind(this));
    }
};

export default ScheduleUtils;