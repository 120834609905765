import React from 'react';
import PropTypes from 'prop-types';


class FunnelProgressListCityAndDistrictInfo extends React.Component {
    static propTypes = {
        cityAndDistrict: PropTypes.string
    };

    content = () => {
        return this.props.cityAndDistrict || i18n.t('funnel.breadcrumbs.district.content');
    };

    render() {
        return (
            <div>
              <div className="little-darkblue-title">
                {this.content()}
              </div>
            </div>
        );
    }
};

export default FunnelProgressListCityAndDistrictInfo;
