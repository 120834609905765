import React from 'react';
import MediaQuery from 'react-responsive';
import FunnelBreadcrumbs from '@components/funnel/breadcrumbs';
import FunnelProgressList from '@components/funnel/progress_list';
import BreakPoints from '@services/breakpoints';

class ResponsiveBreadcrumbs extends React.Component {
  render() {
      return (
          <div>
            <MediaQuery minWidth={BreakPoints.xsMax}>
              <FunnelBreadcrumbs {...this.props} />
            </MediaQuery>

            <MediaQuery maxWidth={BreakPoints.xsMax}>
              <FunnelProgressList {...this.props} />
            </MediaQuery>
          </div>
      );
  }
};

export default ResponsiveBreadcrumbs;
