import React from 'react';
import PropTypes from "prop-types";


class Box extends React.Component {
    static propTypes = {
        address: PropTypes.object,
        destroyable: PropTypes.bool,
        onDestroy: PropTypes.func,
        onSelect: PropTypes.func,
        selected: PropTypes.bool,
        displaySpecialBadge: PropTypes.bool,
    };

    static defaultProps = {
        displaySpecialBadge: true,
    };

    destroyButton = () => {
        if (this.props.destroyable) {
            return (
                <div className="close-button" onClick={this.props.onDestroy}></div>
            );
        } else {
            return "";
        }
    };

    handleSelect = () => {
        this.props.onSelect(this.props.address);
    };

    renderButton = () => {
        var text, classes = "btn btn-primary";

        if (this.props.selected) {
            text = i18n.t('form.buttons.selected');
            classes += ' active';
        } else {
            text = i18n.t('form.buttons.choose');
        }

        return (
            <button className={classes} onClick={this.handleSelect}>
                {text}
            </button>
        );
    };

    renderFooter = () => {
        return (
            <div className="footer">
                <hr/>

                {this.renderButton()}
            </div>
        );
    };

    render() {
        return (
            <div className="address-box">
                {this.destroyButton()}

                <div className="little-darkblue-title">{i18n.t('funnel.address-form.address')}</div>
                <p>{this.props.address.text_description}</p>

                {this.props.address.description &&
                <div>
                    <div className="little-darkblue-title">{i18n.t('funnel.address-form.note')}</div>
                    <p>{this.props.address.description}</p>
                </div>}

                {(this.props.address.cat || this.props.address.dog) &&
                <p className="pets">{this.props.address.cat &&
                <span className="pet-icon pet-icon-cat"></span>} {this.props.address.dog &&
                <span className="pet-icon pet-icon-dog"></span>}</p>}

                {this.props.onSelect && this.renderFooter()}
            </div>
        );
    }
};

export default Box;
