import React from 'react';
import PropTypes from "prop-types";

import classNames from 'classnames';

class LoginForm extends React.Component {
  static propTypes = {
      onSubmit: PropTypes.func,
      onForgottenPasswordClick: PropTypes.func,
      errors: PropTypes.object
  };

  handleSubmit = (event) => {
      event.preventDefault();

      this.props.onSubmit(this.refs.email.value,
                          this.refs.password.value);
  };

  render() {
      return (
          <form onSubmit={this.handleSubmit}>
                <div className="pretty-form-heading">
                  {i18n.t('funnel.user-form.login.description')}
                </div>

                <div className={classNames({"has-error": this.props.errors.general})}>
                  <div className="help-block">
                    {this.props.errors.general}
                  </div>

                  <div className="pretty-input">
                    <input name="email" ref="email" type="email" placeholder={i18n.t('helpers.label.funnel_form.login_email')} className="form-control input-lg"/>
                  </div>

                  <div className="pretty-input">
                    <input name="password" ref="password" type="password" placeholder={i18n.t('helpers.label.funnel_form.login_password')} className="form-control input-lg"/>
                  </div>
                </div>

              <div className="forgotten-password-link-container">
                  <span className={'oh-hover-underline cursor-pointer'} onClick={this.props.onForgottenPasswordClick}> {i18n.t("login.forgotten-password")} </span>
              </div>
                <button type="submit" className="pretty-submit">{i18n.t('form.buttons.proceed')}</button>
          </form>
      );
  }
};

export default LoginForm;
