import React from 'react';

import _ from 'underscore';
import $ from 'jquery';
import NumberField from '@components/common/number_field'
import BreakPoints from '@services/breakpoints';
import ModalSelect from '@components/common/modal_select';
import UnitsUtils from '@services/units_utils';
import PageUtils from '@services/page_utils';
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import classNames from "classnames";


class PestControl extends AbstractProductCustomizer {
    static zeroSelectedProducts = {
        pest_type: null,
        property_type: null,
        pest_quantity: 0,
    };
    static selectorType = 'pest_control';

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = {...this.zeroSelectedProducts(), ...props.initiallySelectedProductOptions};
        this.initializeProductOptions(props);
        this.state.dropdownsSeen = false;
    };

    selectorType = () => {
        return PestControl.selectorType;
    };

    zeroSelectedProducts = () => {
        return _.clone(PestControl.zeroSelectedProducts);
    };

    selectionValid = () => {
        return (this.state.selectedProductOptions.pest_type != null &&
            this.state.selectedProductOptions.property_type != null &&
            this.state.selectedProductOptions.pest_quantity != null &&
            parseInt(this.state.selectedProductOptions.pest_quantity) > 0);
    };

    selectPropertyType = (property_type) => {
        let newSelectedProductOptions = {property_type: property_type, pest_quantity: 0 , pest_type: null};
        this.setState({selectedProductOptions: newSelectedProductOptions}, function(){
            this.sendSelection(this.state.selectedProductOptions)
        });
    };

    selectPestType = (pest_type) => {
        let newSelectedProductOptions = {
            property_type: this.state.selectedProductOptions.property_type,
            pest_type: pest_type,
            pest_quantity: 0
        };

        this.setState({selectedProductOptions: newSelectedProductOptions}, function(){
            if(this.unitType() === 'single'){
                let newSelectedProductOptions = {
                    property_type: this.state.selectedProductOptions.property_type,
                    pest_type: this.state.selectedProductOptions.pest_type,
                    pest_quantity: 1
                };
                this.setState({selectedProductOptions: newSelectedProductOptions}, function(){
                    this.sendSelection(this.state.selectedProductOptions)
                });
            }

            if(window.innerWidth < BreakPoints.xsMax) {
                if($('.service-description').length != 0) {
                    $('html, body').animate({
                        scrollTop: $('.service-description').offset().top - PageUtils.navbarHeight() - 20
                    }, 1000);
                }
            }


        }.bind(this));
    };

    setPestQuantityValue = (value) => {
        let newSelectedProductOptions = {
            property_type: this.state.selectedProductOptions.property_type,
            pest_type: this.state.selectedProductOptions.pest_type,
            pest_quantity: value
        };

        this.setState({selectedProductOptions: newSelectedProductOptions}, function(){
            this.sendSelection(this.state.selectedProductOptions)
        });

    } ;

    product = () => {
        return  _.find(this.props.supportedProductOptions, function(item){
            return item.pest_property.property_type === this.state.selectedProductOptions.property_type && item.pest.pest_type === this.state.selectedProductOptions.pest_type;
        }.bind(this));
    };

    unitType = () => {
        return this.product().unit_type;
    };

    description = () => {
        /* return this.product().description;*/
        if(this.unitType() === 'single') {
            return ''
        }
        else {
            return I18n.t('services.pest_control.inputs.' + this.unitType())
        }
    };

    pests = () => {
        return _.filter(this.props.supportedProductOptions, function(item){
            return item.pest_property.property_type === this.state.selectedProductOptions.property_type;
        }.bind(this));
    };

    setQuantity = (event) => {
        var value = parseInt(event.target.value)
        if(!isNaN(value)) {
            let newSelectedProductOptions = {
                property_type: this.state.selectedProductOptions.property_type,
                pest_type: this.state.selectedProductOptions.pest_type,
                pest_quantity: value
            };
            this.setState({selectedProductOptions: newSelectedProductOptions}, function(){
                this.sendSelection(this.state.selectedProductOptions)
            });
        }
    };

    propertyTypeIsSelected = () => {
        return this.state.selectedProductOptions.property_type;
    };

    propertyLabel = (property_type) => {
        return I18n.t('services.pest_control.properties.' + property_type);
    };

    renderPropertyDropdown = () => {
        return (
            <ModalSelect modalTitle={I18n.t('services.pest_control.pest_property_question')} label={this.propertyLabel(this.state.selectedProductOptions.property_type)} modalContentClasses="pest-control-property-selection">
                {_.map(["residential_private", "residential_common", "commercial", "outdoor"], function(property_type) {
                    return (
                        <div key={property_type} className="modal-select-option" onClick={this.selectPropertyType.bind(this, property_type)}>
                            {this.propertyLabel(property_type)}
                        </div>
                    );
                }.bind(this))}
            </ModalSelect>
        );
    };

    renderPropertyTypeSelector = () => {
        if(!this.state.selectedProductOptions.property_type){
            return (
                <div className="property-selection-options row">
                    <div className="col-xs-12 col-sm-6">
                        <button className="btn btn-property-selection" onClick={this.selectPropertyType.bind(this, 'residential_private')}>
                            <span className="home-icon-having icon-having">{this.propertyLabel('residential_private')}</span>
                        </button>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <button className="btn btn-property-selection" onClick={this.selectPropertyType.bind(this, 'residential_common')}>
                            <span className="stair-icon-having icon-having">{this.propertyLabel('residential_common')}</span>
                        </button>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <button className="btn btn-property-selection btn_multiline" onClick={this.selectPropertyType.bind(this, 'commercial')}>
                            <span className="office-icon-having icon-having">{this.propertyLabel('commercial')}</span>
                        </button>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <button className="btn btn-property-selection btn_multiline" onClick={this.selectPropertyType.bind(this, 'outdoor')}>
                            <span className="outdoor-icon-having icon-having">{this.propertyLabel('outdoor')}</span>
                        </button>
                    </div>
                </div>
            );
        }
    };

    renderPestSelector = () => {
        if(this.propertyTypeIsSelected()){
            return (
                <div>
                    <h3>{I18n.t('services.pest_control.pest_question')}</h3>
                    <div className="visits-weekly pest-options row">
                        { _.map(this.pests(), function(item) {
                            return (
                                <div className="col-xs-12 col-sm-4 col-md-3" key={item.pest.pest_type}>
                                    <div className={classNames({option: true, active: this.state.selectedProductOptions.pest_type === item.pest.pest_type })}
                                         onClick={this.selectPestType.bind(this, item.pest.pest_type)}>
                                        <span>{ I18n.t('pests.pest-types.' + item.pest.pest_type)  }</span>
                                    </div>
                                </div>
                            );
                        }.bind(this))}
                    </div>
                </div>
            );
        }
    };

    renderInput = () => {
        if(this.product()){
            let inputField;
            switch(this.unitType()){
                case 'rooms_count':
                    inputField = (
                        <NumberField value={this.state.selectedProductOptions.pest_quantity}
                                     label={I18n.t("measurement.room", { count: this.state.selectedProductOptions.pest_quantity })}
                                     onChange={function(value){ this.setPestQuantityValue(value); }.bind(this)}
                                     min={0}/>
                    );
                    break;
                case 'apartments_count':
                    inputField = (
                        <div className="input-group apartments-count">
                            <div className="input-group-addon">{I18n.t('services.pest_control.apartments_count')}</div>
                            <input name="pest_quantity"
                                   value={this.state.selectedProductOptions.pest_quantity}
                                   onChange={this.setQuantity}
                                   className="form-control input-lg"/>
                        </div>
                    );
                    break;
                case 'area':
                    inputField = (
                        <div className="input-group area">
                            <div className="input-group-addon">{I18n.t('funnel.after-renovation-cleaning.area')}</div>
                            <input name="pest_quantity"
                                   value={this.state.selectedProductOptions.pest_quantity}
                                   onChange={this.setQuantity}
                                   className="form-control input-lg"/>
                            <div className="input-group-addon">{UnitsUtils.areaUnit()}</div>
                        </div>
                    );
                    break;
                case 'single':
                    inputField = null;
                    break;
            }
            return (
                <div>
                    {this.renderDescription()}
                    {inputField}
                </div>
            );
        }
    };

    renderDescription = () => {
        if(this.product()){
            return (
                <div className="service-description row">
                    <div className="col-md-8 col-md-offset-2">
                        {this.description()}
                    </div>
                </div>
            );
        }
    };


    render() {
        return (
            <div className="pest-control-customizer">
                {this.renderPropertyTypeSelector()}
                {this.renderPestSelector()}
                {this.renderInput()}
            </div>
        );
    }
}


export default PestControl;