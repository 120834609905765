import moment from 'moment';


var DateService = function(){
    var format = "YYYY-MM-DD",

        getMoment = function(date) {
            return moment(date, format);
        },

        formatDate = function(date) {
            return getMoment(date).format("DD.MM");
        },

        weekdayString = function(date) {
            return i18n.t("date.abbr_day_names")[getMoment(date).weekday()];
        };

    return {
        getMoment: getMoment,

        formatDate: formatDate,

        weekdayString: weekdayString,

        formatDateAndDay: function(date) {
            return formatDate(date) + ', ' + weekdayString(date);
        },

        isTomorrow: function(date) {
            var dateMoment = getMoment(date),
                tomorrowMoment = moment().add(1, 'day');

            return dateMoment.isSame(tomorrowMoment, 'day');
        }
    };
}();

export default DateService;
