import React from 'react';
import PropTypes from 'prop-types';

import ProductOptionsSelector from '@components/common/product_selector/product_options_selector';
import MembershipOptionSelector from '@components/common/product_selector/membership_option_selector';
import AtHomeDescription from '@components/common/product_selector/at_home_description';
import ProductUtils from '@services/product_utils';

class ProductSelector extends React.Component {
    static propTypes = {
        productOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
        selectedOption: PropTypes.object,

        onSelect: PropTypes.func,

        prepaidEventsCount: PropTypes.number,
        showOptionDescriptions: PropTypes.bool,
        onTabChange: PropTypes.func,
        subscriptionOptionsTip: PropTypes.string,
        discountMessage: PropTypes.string,
        showDescriptions: PropTypes.bool,

        isAddressMember: PropTypes.bool,

        triggerMembershipModal: PropTypes.func,
    };

    static defaultProps = {
        showDescriptions: true
    };

    state = {
    };

    componentDidMount() {
        Analytics.sendProductSelectionPageview();
    }

    atHomeBoxCount = () => {
        var count = 0;

        if(this.singleVisitOptions().length > 0) {
            count += 1;
        }

        if(this.subscriptionOptions().length > 0) {
            count += 1;
        }

        if(this.membershipOptions().length > 0) {
            count += 1;
        }

        return count;
    };

    atHomeBoxSizeClass = () => {
        switch(this.atHomeBoxCount()) {
        case 1:
        case 2:
            return "wide";
        default:
            return "thin";
        }
    };

    singleVisitOptions = () => {
        return ProductUtils.selectOptionsByType(this.props.productOptions, "single_visit");
    };

    subscriptionOptions = () => {
        return ProductUtils.selectOptionsByType(this.props.productOptions, "subscription");
    };

    membershipOptions = () => {
        return ProductUtils.selectOptionsByType(this.props.productOptions, "membership");
    };

    productSelectorItemClasses = () => {
        return "product-selector-item " + this.atHomeBoxSizeClass();
    };



    render() {
        var singleVisitOptions = this.singleVisitOptions(),
            subscriptionOptions = this.subscriptionOptions(),
            membershipOptions = this.membershipOptions();

        return (
            <div>
              <div>
                <div className="product-selector-row">

                  {singleVisitOptions.length > 0 &&
                      <div className={this.productSelectorItemClasses()  + " single-visit-options"}>
                            <ProductOptionsSelector productName={singleVisitOptions[0].productName}
                                                    typeName={singleVisitOptions[0].typeName}
                                                    options={singleVisitOptions}
                                                    onSelect={this.props.onSelect}
                                                    priceClass="orange-text"
                                                    selectedOption={this.props.selectedOption}
                                                    selectName={"single-visit-duration"}
                                                    prepaidEventsCount={this.props.prepaidEventsCount}
                                                    triggerMembershipModal={this.props.triggerMembershipModal}
                                                    isAddressMember={this.props.isAddressMember}
                                                    showOptionDescriptions={this.props.showOptionDescriptions} />
                      </div>}

                  {subscriptionOptions.length > 0 && (singleVisitOptions.length > 0) &&
                          <div className={this.productSelectorItemClasses() + " subscription-options"}>
                            <ProductOptionsSelector productName={subscriptionOptions[0].productName}
                                                    typeName={subscriptionOptions[0].typeName}
                                                    options={subscriptionOptions}
                                                    onSelect={this.props.onSelect}
                                                    highlighted={true}
                                                    priceClass="green-text"
                                                    selectedOption={this.props.selectedOption}
                                                    selectName={"subscription-visit-duration"}
                                                    triggerMembershipModal={this.props.triggerMembershipModal}
                                                    isAddressMember={this.props.isAddressMember}
                                                    showOptionDescriptions={this.props.showOptionDescriptions} />
                          </div>}


                  {membershipOptions.length > 0 &&
                      <div className={this.productSelectorItemClasses() + " membership-options"}>
                            <MembershipOptionSelector productName={membershipOptions[0].productName}
                                                      typeName={membershipOptions[0].typeName}
                                                      options={membershipOptions}
                                                      onSelect={this.props.onSelect}
                                                      priceClass="orange-text"
                                                      selectedOption={this.props.selectedOption}
                                                      prepaidEventsCount={this.props.prepaidEventsCount}
                                                      triggerMembershipModal={this.props.triggerMembershipModal}
                                                      isAddressMember={this.props.isAddressMember}
                                                      showOptionDescriptions={this.props.showOptionDescriptions} />
                      </div>}
                </div>
              </div>

              {this.props.discountMessage &&
                  <div className="yellow-box" style={{width: '80%', margin: '0 auto'}}>
                        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                        { this.props.discountMessage }
                  </div>}

              {this.props.showDescriptions && <AtHomeDescription/> }
            </div>
        );
    }
}

export default ProductSelector;
