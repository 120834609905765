import React from 'react';

class PestControl extends React.Component {
    static zeroSelectedProducts = {
        pest_type: null,
        property_type: null,
        pest_quantity: 0,
    };
    static selectorType = 'pest_control';


    render() {
        return (
            <div className="pest-control-customizer">
                <span style={{'fontSize': '24px', fontWeight: 600}}>
                    <a href="tel:0894448683">0894 448 683</a>
                </span>
            </div>
        );
    }
}


export default PestControl;