import React from "react";
import PropTypes from "prop-types";
import Braintree from 'braintree-web';
import $ from "jquery";


class BraintreeHostedFields extends React.Component {
    static propTypes = {
        clientToken: PropTypes.string.isRequired,
        onInstance: PropTypes.func,
        className: PropTypes.string,
    };

    CARD_TYPES = ["unknown", "visa", "master-card", "maestro", "visaelectron", "american-express", "discover"];
    SUPPORTED_CARD_TYPES = ["visa", "master-card", "maestro", "visaelectron"];

    hostedFieldsInstance;

    async componentDidMount() {
        Braintree.client.create({authorization: this.props.clientToken}, function (err, clientInstance) {
            if (err) {
                console.error(err);
                return;
            }
            Braintree.threeDSecure.create({
                client: clientInstance,
                version: 2
            }, function (threeDSecureErr, threeDSecureInstance) {
                if (threeDSecureErr) {
                    console.error(threeDSecureErr);
                    return;
                }
                this.createHostedFields(clientInstance, threeDSecureInstance);
            }.bind(this));
        }.bind(this));
    }

    async componentWillUnmount() {
        if (this.hostedFieldsInstance) {
            await this.hostedFieldsInstance.teardown();
        }
    }

    shouldComponentUpdate() {
        // Static
        return false;
    }

    removeCardIcon = () => {
        for (var i in this.CARD_TYPES) {
            $("#card-number").removeClass(this.CARD_TYPES[i]);
        }
    }

    setCardIcon = (cardType, niceType) => {
        this.removeCardIcon();
        if (cardType !== null) {
            var icon = $.inArray(cardType, this.CARD_TYPES) > -1 ? cardType : "unknown";
            $("#card-number").addClass(icon);
            if ($.inArray(cardType, this.SUPPORTED_CARD_TYPES) === -1) {
                $("#braintree-errors").text(i18n.t('funnel.pay.braintree.credit-card-type-not-supported', {card_type: niceType}));
            } else {
                $("#braintree-errors").text("");
            }
        } else {
            $("#card-number").addClass("unknown");
        }
    }

    setupCardIconUpdate = () => {
        this.hostedFieldsInstance.on('cardTypeChange', function (event) {
            if (event.cards.length === 1) {
                this.setCardIcon(event.cards[0].type, event.cards[0].niceType)
            } else {
                this.setCardIcon(null, null)
            }
        }.bind(this));
    }

    createHostedFields(clientInstance, threeDSecureInstance) {
        Braintree.hostedFields.create({
            client: clientInstance,
            styles: {
                'input': {
                    'font-size': '18px',
                    'color': '#555555'
                },
                'input.invalid': {
                    'color': 'red'
                },
            },
            fields: {
                number: {
                    selector: '#card-number',
                    placeholder: 'XXXX XXXX XXXX XXXX',
                    rejectUnsupportedCards: true
                },
                cvv: {
                    selector: '#card-cvc',
                    placeholder: 'XXX'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    placeholder: 'XX / XX',
                }
            }
        }, (err, hostedFieldsInstance) => {
            if (err)
                console.error(err)
            else {
                this.hostedFieldsInstance = hostedFieldsInstance
                this.setupCardIconUpdate();
                if (this.props.onInstance) {
                    this.props.onInstance(clientInstance, hostedFieldsInstance, threeDSecureInstance);
                }
            }
        })
    }

    render() {
        return (
            <div className={'braintree-hosted-fields-container ' + this.props.className}>
                <form id="braintree-payment-form" className='form-horizontal payment-form'>
                    <div className={'braintree-hosted-fields-internal-container'}>
                        <div className="hosted-fields-row">
                            <label htmlFor="card_number" className="control-label hosted-fields-label">
                                {i18n.t("funnel.pay.braintree.card-number")}</label>
                            <div className="unknown hosted-field-container" id="card-number"/>
                        </div>

                        <div className="hosted-fields-row card-input-second-row">
                            <div className={'small-hosted-field-container'}>
                                <label htmlFor="expiration-date" className="control-label">
                                    {i18n.t("funnel.pay.braintree.form-expires-on")}</label>
                                <div className="hosted-field-container" id="expiration-date"/>
                            </div>
                            <div className={'small-hosted-field-container'}>
                                <label htmlFor="card-cvc" className="control-label">
                                    CVV &nbsp;
                                    <span className="glyphicon glyphicon-question-sign" data-toggle="tooltip"
                                          data-placement="top" data-html="true"
                                          id="braintree-cvv-label" title="<div class='cvv-tooltip-image'></div>"/>
                                </label>
                                <div className=" hosted-field-container" id="card-cvc"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}


export default BraintreeHostedFields;