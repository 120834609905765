import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import withLoader from '@components/common/with_loader_decorator';
import $ from 'jquery';

class FeedbacksList extends React.Component {
    static propTypes = {
        requestPath: PropTypes.string.isRequired,
        recipientName: PropTypes.string.isRequired,
        requestParams: PropTypes.object.isRequired,
        initialPage: PropTypes.number,
        initialFeedbacks: PropTypes.arrayOf(PropTypes.shape({
            author: PropTypes.string.isRequired,
            rating: PropTypes.number.isRequired,
            content: PropTypes.string,
            comment_reply: PropTypes.string,
        })),

        allFeedbacksShowed: PropTypes.bool
    };

    static defaultProps = {
        feedbacksPerPage: 3,
        allFeedbacksShowed: false
    };

    state = {
        feedbacks: this.props.initialFeedbacks || [],
        page: this.props.initialPage || 1,
        lastPageShowed: this.props.allFeedbacksShowed
    };

    componentDidMount() {
        if(this.state.feedbacks.length == 0 ){
            this.fetchFeedbacks(1);
        }
    }

    requestParams = (pageNumber) => {
        return _.extend({
            page: pageNumber,
            per_page: this.props.feedbacksPerPage
        }, this.props.requestParams);
    };

    requestPath = (pageNumber) => {
        // This logic is because of a bug in jsroutes:
        //
        // This works correctly:
        // Routes.public_feedbacks_cleaners_path({code: 1, omg: 5}) => "/cleaners/public_feedbacks/1?omg=5"
        //
        // This line call omits the omg parameter
        // Routes.feedbacks_api_company_path({id: 1, omg: 5}) => "/api/companies/1/feedbacks"

        if(this.props.requestParams.id && this.props.requestParams.id != '') {
            return PathHelper(this.props.requestPath, { id: this.props.requestParams.id }) + '?' + $.param(_.omit(this.requestParams(pageNumber), 'id'));
        }
        else {
            return PathHelper(this.props.requestPath, this.requestParams(pageNumber));
        }
    };

    fetchNextPageFeedbacks = (e) => {
        e.preventDefault();
        this.fetchFeedbacks(this.state.page + 1);
    };

    fetchFeedbacks = (pageNumber) => {
        this.props.executeSlowOperation(function () {
            return $.get(this.requestPath(pageNumber),
                function (data) {
                    var lastPage = data.feedbacks.length < this.props.feedbacksPerPage;
                    this.setState({
                        feedbacks: this.state.feedbacks.concat(data.feedbacks),
                        page: pageNumber,
                        lastPageShowed: lastPage
                    });
                }.bind(this),
                "json");
        }.bind(this));
    };

    render() {
        var feedbackItems = _.map(this.state.feedbacks, function (feedback, i) {
            return (
                <div className="feedback-item" key={i}>
                    <div className="rating-and-date">
                        <i className="glyphicon glyphicon-star"></i>
                        <span className="rating-text">{ i18n.t('cleaner-profile.rating') }:</span>&nbsp;
                        <b> {feedback.rating} / {feedback.maxRating}</b>
                        &nbsp; | &nbsp;
                        {feedback.submitted_at}
                    </div>

                    {feedback.content && feedback.content != "" &&
                        <div className="comment">
                            "{feedback.content}"
                        </div>}

                    <div className="author">
                        <span className="hyphen">-</span>&nbsp;
                        {feedback.author}
                    </div>

                    {feedback.comment_reply && feedback.comment_reply != "" &&
                    <div className='reply-container'>
                        <div className="content">
                            {feedback.comment_reply}
                        </div>

                        <div className="reply-author">
                            - {this.props.recipientName}
                        </div>
                    </div> }

                    <div className="clearer"></div>

                </div>
            );
        }.bind(this));

        return (
            <div className="feedbacks-list">
                {feedbackItems}
                <div className="show-more-container">
                    {!this.state.lastPageShowed && <a className="show-more btn btn-default" href="" onClick={this.fetchNextPageFeedbacks}>
                        {i18n.t('cleaner-profile.show-more')}
                    </a>}
                </div>
            </div>
        );
    }
}

export default withLoader(FeedbacksList);
