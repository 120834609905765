import React from 'react';

import _ from 'underscore';
import UnitsUtils from '@services/units_utils';
import AbstractProductCustomizer from '@components/product_customizers/abstract'


class Disinfection extends AbstractProductCustomizer {
    static zeroSelectedProducts = {
        disinfection_area: 0,
    };
    static selectorType = 'disinfection';

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = _.clone(Disinfection.zeroSelectedProducts);
        this.initializeProductOptions(props);
    };

    selectorType = () => {
        return Disinfection.selectorType;
    };

    zeroSelectedProducts = () => {
        return _.clone(Disinfection.zeroSelectedProducts);
    };

    samplePriceOptions = () => {
        return ['disinfection_area']
    };

    updateArea = (event) => {
        var area = parseInt(event.target.value),
            selectedProductOptions = this.state.selectedProductOptions;
        area = _.isNaN(area) ? 0 : area;

        selectedProductOptions['disinfection_area'] = (area < 2000) ? area : 2000;

        this.setState({selectedProductOptions: selectedProductOptions}, function(){
            this.sendSelection(this.state.selectedProductOptions)
        }.bind(this));
    };


    render() {
        var area = this.state.selectedProductOptions['disinfection_area'];

        if (isNaN(area) || area === 0) {
            area = '';
        }
        return (
            <div>
                {this.props.isOptional && this.state.pricelist.doesSupportService(this.selectorType()) &&
                <div className="add-service-checkbox">
                    <label className={'add-service-label'}>
                        <input type="checkbox" checked={this.state.checkbox}
                               onChange={this.toggleCheckbox}/>
                        <span className="cr with-price"><i className="cr-icon glyphicon glyphicon-ok"></i></span>
                        {I18n.t('services.disinfection.title')}
                        <br/>
                        {this.priceHookText()}
                    </label>
                </div>}
                {this.showInputFields() &&
                <div className="service-customizer-input-fields-container">
                    <div className="row">
                        {this.props.showExplanation &&
                        <div className='secondary-service-explanation col-md-8 col-md-offset-2'>
                            {I18n.t('funnel.disinfection.subtitle_html')}
                        </div>}
                        <div className="form-group col-md-6 col-md-offset-3">
                            <div className="input-group">
                                <div className="input-group-addon">{I18n.t('funnel.deep-cleaning.area')}</div>
                                <input type="number" name="area"
                                       value={area}
                                       onChange={this.updateArea}
                                       onKeyPress={this.areaInputFieldKeyPressed}
                                       className="form-control input-lg"/>
                                <div className="input-group-addon">{UnitsUtils.areaUnit()}</div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}


export default Disinfection;