import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/vendor/modal';
import FunnelCleanerProfile from '@components/funnel/cleaner_profile';

class FunnelCleanerProfileModal extends React.Component {
    static propTypes = {
        cleaner: PropTypes.object,
        isCurrentAddressMember: PropTypes.bool,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onAddToFavoritesClicked: PropTypes.func,
        isLoggedin: PropTypes.bool,

    };

    render() {
        return (
            <Modal size="large" isOpen={this.props.isOpen} onHide={this.props.onClose} dialogClassName="cleaner-profile-modal">
              <FunnelCleanerProfile cleaner={this.props.cleaner}
                                    isCurrentAddressMember={this.props.isCurrentAddressMember}
                                    onAddToFavoritesClicked={this.props.onAddToFavoritesClicked}
                                    isLoggedin={this.props.isLoggedin}

              />

              <div className="close-button" onClick={this.props.onClose}/>
            </Modal>
        );
    }
}


export default FunnelCleanerProfileModal;
