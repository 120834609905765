import $ from 'jquery';

var SelectedCityStore = function() {
    var eventEmitter = $({});
    var id = {};
    var name = {};

    return {
        setCity: function(newId, newName) {
            id = newId;
            name = newName;
            eventEmitter.trigger('cityChanged');
        },

        getCityId: function() {
            return id;
        },

        onChange: function(callback) {
            eventEmitter.on('cityChanged', function() {
                callback(id, name);
            });
        }
    };
}();

export default SelectedCityStore;
