import React from 'react';
import PropTypes from 'prop-types';

import PriceTag from '@components/common/price_tag'


class FunnelBreadcrumbsCompanyPriceInfo extends React.Component {

    static propTypes = {
        extraServicePrice: PropTypes.number,
        company: PropTypes.object,
        totalPrice: PropTypes.number,

        buyAddressMembership: PropTypes.bool,
    };

    hasRating = () => {
        return this.props.company.rating !== null && this.props.company.rating > 0;
    };

    content = () => {
        if (this.props.company)
            return (
                <div className={'company-selection-info'}>
                    {this.hasRating() && <span className='rating-info green-text'>
                        <span className="glyphicon glyphicon-star" aria-hidden="true"/>
                        {this.props.company.rating}
                    </span>}
                    {this.props.company &&
                    <PriceTag amount={this.price()} className="price-tag"/>}
                </div>
            );
        else
            return i18n.t("funnel.breadcrumbs.company-selection.content");
    };

    title = () => {
        if (this.props.company)
            return this.props.company.name;
        else
            return i18n.t("funnel.breadcrumbs.company-selection.title")
    }

    // assume company is present
    price = () => {
        if (this.props.company)
            return this.props.totalPrice.toFixed(2);
        else
            return '';
    }

    render() {
        return (
            <div>
                <div className="little-darkblue-title">
                    {this.title()}
                </div>
                {this.content()}
            </div>
        );
    }
};

export default FunnelBreadcrumbsCompanyPriceInfo;
