import React from "react";
import PropTypes from "prop-types";
import ClientPreferencesCollector from '@components/common/client_preferences_collector'

class ClientPreferencesForm extends React.Component {
    static propTypes = {
        clientPreferences: PropTypes.object
    };

    reloadPage = () => {
        window.location.reload();
    };

    render() {
        return (
            <ClientPreferencesCollector afterCollect={this.reloadPage}
                                        submitButtonName={i18n.t('settings.save')}
                                        initialWantsSubstitute={this.props.clientPreferences.wantsSubstitute}
                                        initialDeclinesTrainings={this.props.clientPreferences.declinesTrainings} />
        );
    }
}

export default  ClientPreferencesForm;