import React from 'react';
import BreakPoints from '@services/breakpoints';
import $ from 'jquery';

// This is a simple wrapper around regular DOM generated from template
// partial, containing jquery dom modifications

class OrderPayment extends React.Component {
    componentDidMount() {
        $("#payment_options").show();

        var screenIsSmall = window.innerWidth < BreakPoints.xsMax;

        if(screenIsSmall) {
            $(function() {
                window.scrollTo(0,0);
            });
        }
    }

    componentWillUnmount() {
        $("#payment_options").hide();
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return null;
    }
}

export default OrderPayment;
