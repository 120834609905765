import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import CompanyList from '@components/common/company_list'
import CompanyFilter from '@components/funnel/company_filter'

const SORTING_CRITERIA = {
    no_criteria: 0,
    domestina_rank: 1,
    price: 2,
    rating: 3,
};

class CompanySelector extends React.Component {
    static propTypes = {
        companies: PropTypes.arrayOf(PropTypes.object),
        onSelect: PropTypes.func,
        allowNull: PropTypes.bool,

        highlightedCompanyId: PropTypes.number,
        highlightedCompanyError: PropTypes.string,

        ctaButtonText: PropTypes.string,

        selectedFilter: PropTypes.string,
        selectedFilterDate: PropTypes.instanceOf(Date),
        handleFilterSelect: PropTypes.func,

        promotionSeed: PropTypes.number,

        membershipPercentDiscount: PropTypes.number,
        buyAddressMembership: PropTypes.bool,
        isCurrentAddressMember: PropTypes.bool,
        toggleBuyMembership: PropTypes.func,

    };

    constructor(props) {
        super(props);
        this.state = {
            sortingCriteria: this.initialSortingCriteria(),
        };
    };

    priorityCompanies = () => {
        return _.compact([this.highlightedCompany()]);
    };

    initialSortingCriteria = () => {
        return SORTING_CRITERIA['domestina_rank'];
    };

    highlightedCompany = () => {
        return _.find(this.props.companies, function (company) {
            return company.id === this.props.highlightedCompanyId;
        }.bind(this));
    };

    hasPriorityCompanies = () => {
        return this.priorityCompanies().length > 0;
    };

    regularCompanies = () => {
        var result;
        if(this.hasPriorityCompanies()) {
            result = this.companiesWithoutPriority();
        } else if(this.state.sortingCriteria === SORTING_CRITERIA['domestina_rank']){
            result = this.companiesWithPromotedSecond();
            result = this.pushPreviousChoiceFirst(result);
        } else {
            result = this.sortedCompanies();
        }

        return result;
    };

    sortedCompanies = () => {
        var sortedCompanies = this.props.companies;
        if(this.state.sortingCriteria === SORTING_CRITERIA['domestina_rank']){
            sortedCompanies = this.sortedByDomestina()
        }else if(this.state.sortingCriteria === SORTING_CRITERIA['price']){
            sortedCompanies = _.sortBy(this.sortedByDomestina(), function(company) {
                return parseFloat(company.ranking.price);
            });
        }
        return sortedCompanies;
    }


    companiesWithoutPriority = () => {
        var priorityCompanyIds = _.map(this.priorityCompanies(), function(company){ return company.id});
        return _.reject(this.props.companies, function(company){
            return _.contains(priorityCompanyIds, company.id);
        }.bind(this));
    };

    companiesWithPromotedSecond = () => {
        var promotedCompany = this.findPromotedCompany();
        if(promotedCompany && this.props.companies.length > 2 && promotedCompany.ranking.domestina_rank > 2) {
            // remove the promoted company
            var companies = _.without(this.props.companies, promotedCompany);
            // put the promoted company at second place
            companies.splice(1, 0, promotedCompany);
            return companies;
        } else {
            return this.props.companies;
        }
    };

    pushPreviousChoiceFirst = (companies) => {
        var prevChoice = _.find(companies, function(company){ return company.previous_choice; });
        if(prevChoice) {
            // remove the promoted company
            var companiesRearranged = _.without(companies, prevChoice);
            // add the promoted company up front
            companiesRearranged.unshift(prevChoice);
            return companiesRearranged;
        } else
            return companies;
    };

    findPromotedCompany = () => {
        let promotedCompanies = _.filter(this.props.companies, function(company){ return company.promoted && !company.previous_choice; });
        if(promotedCompanies.length > 0) {
            return promotedCompanies[this.props.promotionSeed % promotedCompanies.length];
        } else {
            return null;
        }
    };

    hasPromotedCompany = () => {
      return !!this.findPromotedCompany();
    };

    showRecommendedBadge = () => {
        return this.state.sortingCriteria === SORTING_CRITERIA['domestina_rank'];
    };

    showPromotedProfile = () => {
        return this.state.sortingCriteria === SORTING_CRITERIA['domestina_rank'];
    };

    selectAll = (e) => {
        e.stopPropagation();
        this.props.onSelect(null);
    };

    buttonName = () => {
        return this.props.ctaButtonText || i18n.t('funnel.companies.schedule');
    };

    onSelect = (companyId) => {
        this.props.onSelect(companyId, this.findCompanyRank(companyId), this.state.sortingCriteria, this.showPromotedProfile());
    };

    findCompanyRank = (companyId) => {
        return _.findIndex(_.flatten([this.priorityCompanies(), this.regularCompanies()]), function (company) {
            return company.id === companyId
        }) + 1;
    };

    sortingOptions = () => {
        var result = _.map(_.omit(SORTING_CRITERIA, 'no_criteria', 'rating'), function (v, k) {
            return {value: v, label: i18n.t('funnel.companies.sorting-criteria.' + k)};
            // isDisabled: !this.addressSupportsService(address, this.selectedService().id)}
        }.bind(this));
        return result;
    };

    selectSortingCriteria = (sortingCriteria) => {
        this.setState({sortingCriteria: sortingCriteria});
    };

    sortedByDomestina = () => {
        return _.sortBy(this.props.companies, function(company) {
            return company.ranking.domestina_rank;
        });
    };

    render() {
        return (
            <div className="company-selector">
                <div>
                    {this.props.children}

                    {this.props.allowNull && <div className="select-all-cta-wrapper">
                        <button className="btn btn-company-outlined"
                                onClick={this.selectAll}>{i18n.t('funnel.companies.select-all-companies')}</button>
                    </div>}
                </div>


                <div className="row">
                    <div className="col-xs-12">
                        <div className='company-selector-filter-container'>
                            <CompanyFilter selectedFilter={this.props.selectedFilter}
                                           selectedFilterDate={this.props.selectedFilterDate}
                                           handleFilterSelect={this.props.handleFilterSelect}
                                           sortingOptions={this.sortingOptions()}
                                           selectedSortingCriteria={this.state.sortingCriteria}
                                           handleSortingSelect={this.selectSortingCriteria}
                            />
                        </div>
                    </div>
                </div>



                <div className="company-list-container">
                    <CompanyList priorityCompanies={this.priorityCompanies()}
                                 regularCompanies={this.regularCompanies()}
                                 isCurrentAddressMember={this.props.isCurrentAddressMember}
                                 highlightedCompanyId={this.props.highlightedCompanyId}
                                 highlightedCompanyError={this.props.highlightedCompanyError}
                                 onSelect={this.onSelect}
                                 selectBtnText={this.buttonName()}
                                 profileSelectBtnText={i18n.t('form.buttons.choose')}
                                 showRecommendedBadge={this.showRecommendedBadge()}
                                 showPromotedProfile={this.showPromotedProfile()}
                                 promotedCompany={this.findPromotedCompany()}
                                 membershipPercentDiscount={this.props.membershipPercentDiscount}
                                 buyAddressMembership={this.props.buyAddressMembership}
                                 toggleBuyMembership={this.props.toggleBuyMembership}
                    />
                </div>
            </div>
        );
    }
}

export default CompanySelector;
