import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classNames from 'classnames';

import MoneyUtils from '@services/money_utils'
import BraintreeFormService from '@services/braintree_form_service'

import BraintreeForm from "@components/braintree/braintree_form";
import InvoiceModalTrigger from '@components/common/invoice_modal_trigger'
import PriceTag from '@components/common/price_tag'


class FunnelHousemaidPayment extends React.Component {
    static propTypes = {
        serviceType: PropTypes.string.isRequired,
        isTeamEntity: PropTypes.bool,
        orderCode: PropTypes.string,
        invoiceRequested: PropTypes.bool,
        activePromoCodeExists: PropTypes.bool,

        clientToken: PropTypes.string.isRequired,
        verifyCardAmount: PropTypes.number.isRequired,
        braintreeCards: PropTypes.array,
        payWithCard: PropTypes.bool,
        buyMembership: PropTypes.bool,

        membershipCurrentlyValid: PropTypes.bool,


        orderPrice: PropTypes.shape({
            amount: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired,

            productAmount: PropTypes.string.isRequired,
            productDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),

            fees: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
            productAndFeeDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
        })
    };

    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: false,
            orderPrice: this.props.orderPrice,
            membershipLoading: false,
            selectedCardId: null,
            nonce: null,
            errorMessage: null,
        };

        if (props.braintreeCards.length > 0)
            this.state.selectedCardId = _.last(props.braintreeCards).id;

    };

    componentDidMount() {
        amplitude.track('see page - housemaid funnel payment',
            { with_membership: this.props.buyMembership,
              payment_type: this.props.payWithCard ? "card" : "cash",
                entity_type: this.props.isTeamEntity? "team_entity" : "person_entity"

            });
    }

    onInstanceCreated = (clientInstance, hostedFieldsInstance, threeDSecureInstance) => {
        this.setState({
            clientInstance: clientInstance,
            hostedFieldsInstance: hostedFieldsInstance,
            threeDSecureInstance: threeDSecureInstance
        })
    };

    onCardSelect = (cardId) => {
        this.setState({selectedCardId: cardId})
    }

    finalServicePrice = () => {
        return this.props.orderPrice.amount;
    }

    partialCardPayment = () => {
        return !this.props.payWithCard && this.props.buyMembership;
    }

    btnClasses = () => {
        var mandatory = "btn btn-primary next-step";
        if (this.state.buttonDisabled) {
            mandatory += " loading disabled";
        }
        return mandatory
    };

    lineItemsForService = (serviceType) => {
        return _.filter(this.props.lineItems, function (lineItem) {
            return lineItem.service_type === serviceType;
        })
    }

    priceForService = (serviceType) => {
        return _.reduce(this.lineItemsForService(serviceType), function (memo, lineItem) {
            return parseFloat(lineItem.price) + memo;
        }, 0);
    }

    serviceTypes = () => {
        return _.uniq(_.map(this.props.lineItems, function (li) {
            return li.service_type;
        }));
    }

    orderedServiceTypes = () => {
        // we want to keep the primary service type first
        return [this.props.serviceType].concat(_.without(this.serviceTypes(), this.props.serviceType));
    }

    handleSubmit = (event) => {
        this.setState({buttonDisabled: true, errorMessage: null}, () => {
            if(this.shouldSubmitCard()){
                if (this.hasSavedCardSelected()) {
                    BraintreeFormService.savedCardSubmit(this.state.selectedCardId, this.state.threeDSecureInstance, this.props.verifyCardAmount)
                        .then((nonce) => {
                            this.setState({nonce: nonce}, () => {
                                this.submitForm();
                            });
                        }).catch((error) => {
                        this.setState({buttonDisabled: false, errorMessage: error.message});
                    });
                } else {
                    if (!BraintreeFormService.isFormValid(this.state.hostedFieldsInstance)) {
                        this.setState({buttonDisabled: false, errorMessage: i18n.t('funnel.pay.braintree.card-invalid')});
                        return false;
                    }
                    BraintreeFormService.newCardSubmit(this.state.hostedFieldsInstance, this.state.threeDSecureInstance, this.props.verifyCardAmount)
                        .then((nonce) => {
                            this.setState({nonce: nonce}, () => {
                                this.submitForm();
                            });

                        }).catch((error) => {
                        this.setState({buttonDisabled: false, errorMessage: error.message})
                    });
                }
            } else {
                this.submitForm();
            }
        });
        Analytics.sendInitiateCheckout(this.finalServicePrice(),
            this.props.serviceType + (this.props.buyMembership ? "_with_club" : ""));
        event.preventDefault();
        return false;
    };

    submitForm = () => {
        document.getElementById('main-form').submit();
    };


    shouldSubmitCard = () => {
        return this.props.payWithCard || this.props.buyMembership;
    };

    hasSavedCardSelected = () => {
        return this.state.selectedCardId !== null
    };

    formAction = () => {
        if (this.shouldSubmitCard())
            if (this.hasSavedCardSelected())
                return "/braintree/pay_with_saved_card"
            else
                return "/braintree/pay"
        else
            return "/pay_with_cash";
    }

    formHiddenFields = () => {
        if (this.shouldSubmitCard()) {
            let result = {};
            if (this.hasSavedCardSelected()){
                if(this.state.nonce) result['saved_card_3d_nonce'] = this.state.nonce;
                if(this.state.selectedCardId) result['card_id'] = this.state.selectedCardId;
            } else {
                if(this.state.nonce) result['payment_method_nonce'] = this.state.nonce;
            }
            return result;
        } else
            return {};
    }

    allDiscounts = () => {
        return _.flatten([this.props.orderPrice.productDiscounts, this.props.orderPrice.productAndFeeDiscounts]);
    }

    serviceName = (serviceType) => {
        if(serviceType == 'housemaid'){
            if(this.props.isTeamEntity)
                return i18n.t('services.housemaid.team-funnel-service-name');
            else
                return i18n.t('services.housemaid.funnel-service-name');
        }else
            return i18n.t('services.' + serviceType + '.title');
        }

    render() {
        return (
            <div id="payment">
                <div>
                    <div className="funnel-heading hidden-xs hidden-sm">{i18n.t('funnel.cash-payment.title')}</div>
                </div>

                <div className="row">
                    <div className={'col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2'}>
                        <div className={classNames({'order-summary-container': true, 'with-ddc-price': true})}>
                            <div className="payment-section-header">
                                {i18n.t('funnel.cash-payment.order')}
                            </div>
                            <div className="line-items-container">
                                {_.map(this.orderedServiceTypes(), function (serviceType) {
                                    return (
                                        <div className={'service-row'} key={'service-row-' + serviceType}>
                                            <div className="main-service-row">
                                                <div
                                                    className={'service-name'}> {this.serviceName(serviceType)} </div>
                                                <div className={'line-item-price'}>
                                                    {MoneyUtils.price(this.priceForService(serviceType), 2, false)}
                                                </div>
                                            </div>
                                            <div className={'service-line-items-container'}>
                                                {_.map(this.lineItemsForService(serviceType), function (lineItem) {
                                                    return <div className={'line-item-row'}
                                                                key={serviceType + lineItem.name}>{lineItem.name}</div>;
                                                }.bind(this))}
                                            </div>
                                        </div>
                                    )
                                }.bind(this))}


                                {this.props.buyMembership &&
                                        <div className={'service-row'} key={'service-row-domestina-club'}>
                                            <div className="main-service-row">
                                                <div className={'service-name'}> {i18n.t('buy-club.activation-fee')} </div>
                                                <div className={'line-item-price'}>
                                                    {MoneyUtils.price(MEMBERSHIP_ACTIVATION_FEE, 2, false)}
                                                </div>
                                            </div>
                                            <div className={'service-line-items-container'}>
                                                <div className={'line-item-row'}>{i18n.t('buy-club.membership-clarification', {membership_fee: MoneyUtils.price(FUTURE_MEMBERSHIP_PRICE, 2, false)})}</div>
                                            </div>
                                        </div>
                                }

                                {_.map(this.allDiscounts(), function (discount) {
                                    return (
                                        <div className={'service-row'} key={'service-row-domestina-club'}>
                                            <div className="main-service-row">
                                                <div className={'service-name'}> {discount.description} </div>
                                                <div className={'line-item-price'}>
                                                    -{MoneyUtils.price(discount.amount, 2, false)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }.bind(this))}

                                {!this.partialCardPayment() &&
                                <div className="service-row final-price-line-item">
                                    <div className={'payment-method-container'}>
                                        {!this.shouldSubmitCard() &&
                                        <span>{i18n.t('funnel.cash-payment.subtitle')}</span>}
                                    </div>
                                    <div className="funnel-payment-total-price-container">
                                        <div className={'text-container'}>{i18n.t('funnel.pay.total_amount')}</div>
                                        <PriceTag amount={this.finalServicePrice()} className="final-price-tag"/>
                                    </div>
                                </div>}
                            </div>
                        </div>

                        {(this.shouldSubmitCard() && this.props.membershipCurrentlyValid) &&
                        <div className={'funnel-card-form-section'}>
                            <div className="payment-section-header">
                                {i18n.t('funnel.cash-payment.payment')}
                            </div>
                            <div className="card-list-container">
                                {this.partialCardPayment() &&
                                <div className="payment-explanation-container">
                                    <div className="explanation-item">
                                        <div className="text-container">{i18n.t('funnel.pay.club-card')}</div>
                                        <div className="price-container">{MoneyUtils.price(parseFloat(this.props.verifyCardAmount), 2, false)}</div>
                                    </div>
                                    <div className="explanation-item">
                                        <div className="text-container">{i18n.t('funnel.cash-payment.additional-payment')}</div>
                                        <div className="price-container">{MoneyUtils.price((this.props.orderPrice.amount - this.props.verifyCardAmount), 2, false)}</div>
                                    </div>
                                </div>}

                                <BraintreeForm clientToken={this.props.clientToken}
                                               braintreeCards={this.props.braintreeCards}

                                               selectedCardId={this.state.selectedCardId}
                                               onCardSelect={this.onCardSelect}

                                               onInstanceCreated={this.onInstanceCreated}

                                />
                            </div>
                        </div>}

                        {this.shouldSubmitCard() &&
                        <div className="funnel-payment-invoice-container">
                            <InvoiceModalTrigger orderCode={this.props.orderCode}
                                                 invoiceRequested={this.props.invoiceRequested}/>
                        </div>}

                        <div className="hr-before-final-price"></div>

                        {this.props.membershipCurrentlyValid &&
                        <div className="funnel-submit-container">
                            <div
                                className={classNames({hidden: this.state.errorMessage === null}) + " braintree-form-payment-errors"}>
                                {this.state.errorMessage}
                            </div>
                            <form action={this.formAction()} method="post" id={'main-form'}>
                                <input name="order_code" type="hidden" value={this.props.orderCode}/>
                                {_.map(this.formHiddenFields(), (v, k) => {
                                    return <input name={k} type="hidden" value={v} key={'hidden-input-' + k}/>
                                })}
                                <input type="submit" value={i18n.t('form.buttons.confirm')}
                                       className={this.btnClasses()}
                                       onClick={this.handleSubmit}/>
                            </form>
                        </div>}

                        {!this.props.membershipCurrentlyValid &&
                        <div className="overdue-funnel-error-container">
                            <div className="error-text">
                                {i18n.t('funnel.pay.overdue-error')}
                            </div>
                            <div className="cta-container">
                                <a href={PathHelper('new_clients_dashboard_membership_braintree_card_path', {pay: 1})} className="btn btn-primary cta-button">
                                    {i18n.t('funnel.pay.overdue-pay-cta')}
                                </a>
                            </div>
                        </div>}
                    </div>
                </div>


            </div>
        );
    }
}

export default FunnelHousemaidPayment;
