import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";

class AddressDistrictSelector extends React.Component {
    static propTypes = {
        selectedCityId: PropTypes.number,
        selectedDistrictId: PropTypes.number,
        selectedAddressId: PropTypes.number,

        addresses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            city_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })),
        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            districts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                alternative_name: PropTypes.string.isRequired,
            })),
        })),

        onSelectDistrict: PropTypes.func,
        onSelectCity: PropTypes.func,
        onSelectAddress: PropTypes.func,

        renderAddresses: PropTypes.bool,
        //Hide the district select if city is not selected
        hideDistrict: PropTypes.bool,
    };

    static defaultProps = {
        hideDistrict: false,
    };

    addressOptions = () => {
        var result = this.props.addresses.map(function (address, i) {
            return {value: address.id, label: address.name};
        }.bind(this));
        result.push({
            value: -1,
            label: i18n.t('components.service-address-selector.add-address'),
            color: '#49933c'
        });
        return result;
    };

    selectedAddressOption = () => {
        if(this.props.selectedAddressId)
            return this.addressOptions().find(address_option => address_option.value === this.props.selectedAddressId);
        else
            return null;
    };

    cityOptions = () => {
        return this.props.cities.map(function (city, i) {
            return {value: city.id, label: city.name, alternativeLabel: city.alternative_name}
        });
    };

    selectedCityOption = () => {
        if(this.props.selectedCityId)
            return this.cityOptions().find(city_option => city_option.value === this.props.selectedCityId);
        else
            return null;
    };

    districtOptions = () => {
        if (this.selectedCity()) {
            return this.selectedCity().districts.map(function (district, i) {
                return {value: district.id, label: district.name, alternativeLabel: district.alternative_name}
            });
        } else {
            return [];
        }
    };

    selectedDistrictOption = () => {
        var districtOption = this.districtOptions().find(district_option => district_option.value === this.props.selectedDistrictId);
        if(districtOption !== undefined)
            return districtOption;
        else
            return null;
    };

    selectedCity = () => {
        if (this.props.selectedCityId) {
            return this.findCity(this.props.selectedCityId);
        } else {
            return null;
        }
    };

    findCity = (cityId) => {
        return this.props.cities.find(city => city.id === cityId);
    };

    optionFilter = (option, filterString) => {
        var label = option.label.toLowerCase(),
            alternativeLabel = option.data.alternativeLabel.toLowerCase(),
            filterValue = filterString.toLowerCase();

        return label.indexOf(filterValue) >= 0 || alternativeLabel.indexOf(filterValue) >= 0;
    };

    districtError = () => {
        if(this.selectedCityOption()) {
            return i18n.t("components.service-district-selector.no-district-found");
        } else {
            return i18n.t("components.service-district-selector.no-city-message");
        }
    };

    showDistrict = () => {
        return true;
    }

    citySelectorClasses = () => {
        if(this.showDistrict())
            return ' city-and-district';
        else
            return ' city-alone';
    }

    renderAddressSelector = () => {
        return (
            <div className={'row'}>
                <div className={'col-xs-12'}>
                    <Select value={this.selectedAddressOption()}
                            onChange={this.props.onSelectAddress}
                            options={this.addressOptions()}
                            placeholder={i18n.t("components.service-address-selector.address")}
                            isSearchable={false}
                            className='service-customization-select address-select'
                            classNamePrefix='sc-select'
                            isLoading={false}
                            noOptionsMessage={() => {
                                return i18n.t("components.service-district-selector.no-service-message");
                            }}
                            menuPortalTarget={document.body}
                            styles={colourStyles}/>
                </div>
            </div>
        );
    };

    renderCityDistrictSelector = () => {
        return (
            <div className={'row'}>
                <div className={'col-xs-12 col-md-6 city-col'}>
                    <Select value={this.selectedCityOption()}
                            onChange={this.props.onSelectCity}
                            options={this.cityOptions()}
                            filterOption={this.optionFilter}
                            placeholder={i18n.t("components.service-district-selector.city")}
                            isSearchable={true}
                            className={'service-customization-select city-select city-and-district'}
                            classNamePrefix='sc-select'
                            menuPortalTarget={document.body}
                            noOptionsMessage={() => {
                                return '';
                            }}
                    />
                </div>
                {this.showDistrict() &&
                <div className={'col-xs-12 col-md-6 district-col'}>
                    <Select value={this.selectedDistrictOption()}
                            onChange={this.props.onSelectDistrict}
                            options={this.districtOptions()}
                            filterOption={this.optionFilter}
                            placeholder={i18n.t("components.service-district-selector.district")}
                            isSearchable={true}
                            className='service-customization-select district-select'
                            classNamePrefix='sc-select'
                            menuPortalTarget={document.body}
                            noOptionsMessage={this.districtError.bind(this)}
                    />
                </div>}
            </div>
        );
    };

    renderLocationSelector = () => {
        if (this.props.renderAddresses) {
            return this.renderAddressSelector();
        } else {
            return this.renderCityDistrictSelector();
        }
    };

    render() {
        return (
            <div>
                {this.renderLocationSelector()}
            </div>
        );
    }
}

const colourStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        if (typeof data.color === "undefined") {
            return {
                ...styles
            };
        } else {
            return {
                ...styles,
                color: isSelected ? "white" : data.color,
                fontWeight: "bold",
                textAlign: 'center'
            };
        }
    }
};

export default AddressDistrictSelector;
