import React from 'react';
import PropTypes from 'prop-types';

import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import CleanerUtils from '@services/cleaner_utils';
import Tooltip from 'react-bootstrap/lib/Tooltip';

class CleanerCategory extends React.Component {
    static propTypes = {
        category: PropTypes.string,
        deaf: PropTypes.bool,
        displayDeafTooltip: PropTypes.bool,
        name: PropTypes.string
    };

    cleanerFirstName = () => {
        return this.props.name.split(' ')[0];
    };

    renderDeafMaidTooltip = () => {
        var tooltip = (
            <Tooltip id="deaf-maid-explanation">
              {i18n.t('funnel.cleaners-options.all-cleaners.deaf-cleaner-explanation', { name: this.cleanerFirstName()})}
              <br/>
              {i18n.t('funnel.cleaners-options.all-cleaners.deaf-cleaner-tooltip')}
            </Tooltip>
        );

        return (
            <OverlayTrigger placement="right" overlay={tooltip}>
              <span className="info-button-wrapper">
                <span className="glyphicon glyphicon-info-sign" aria-hidden="true"></span>
              </span>
            </OverlayTrigger>
        );
    };

    render() {
        if(this.props.category || this.props.deaf)
            return (
                <div>
                  <span className={"cleaner-category" + " " + CleanerUtils.categoryClasses(this.props.category, this.props.deaf)}>
                    {CleanerUtils.humanCategory(this.props.category, this.props.deaf)}
                  </span>
                  {' '}
                  {this.props.displayDeafTooltip && this.props.deaf && <span>{this.renderDeafMaidTooltip()}</span>}
                </div>
            );
        else {
            return null;
        }
    }
}

export default CleanerCategory;
