import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import $ from "jquery";

import BraintreeFormService from '@services/braintree_form_service'
import BraintreeForm from "@components/braintree/braintree_form";
import PriceTag from '@components/common/price_tag'
import MoneyUtils from '@services/money_utils'
import _ from "underscore";


class OrderPaymentForm extends React.Component {
    static propTypes = {
        clientToken: PropTypes.string.isRequired,
        verifyCardAmount: PropTypes.string.isRequired,
        braintreeCards: PropTypes.array,
        orderCode: PropTypes.string,
        productName: PropTypes.string,
        orderId: PropTypes.number,
        initialOrderPrice: PropTypes.shape({
            currency: PropTypes.string.isRequired,

            productAmount: PropTypes.string.isRequired,
            productDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),

            fees: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
            productAndFeeDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
        }),
    };


    BUYING_STATES = ['initial', 'processing_payment', 'bought']

    constructor(props) {
        super(props);
        this.state = {
            orderPrice: this.props.initialOrderPrice,
            buyingState: this.BUYING_STATES[0],
            selectedCardId: null,
            buttonDisabled: false,
            errorMessage: null,
        };

        if (props.braintreeCards.length > 0)
            this.state.selectedCardId = _.last(props.braintreeCards).id;
    };

    onInstanceCreated = (clientInstance, hostedFieldsInstance, threeDSecureInstance) => {
        this.setState({
            clientInstance: clientInstance,
            hostedFieldsInstance: hostedFieldsInstance,
            threeDSecureInstance: threeDSecureInstance
        })
    };


    encodeQueryData = (data) => {
        const ret = [];
        for (let d in data)
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
    }

    submitPayment = (nonce) => {
        this.setState({buyingState: this.BUYING_STATES[1]}, function(){
            let requestData = null;
            if (this.state.selectedCardId) {
                requestData = {
                    saved_card_3d_nonce: nonce,
                    card_id: this.state.selectedCardId
                }
            } else {
                requestData = {
                    payment_method_nonce: nonce
                }
            }
            requestData["order_id"] = this.props.orderId;

            $.ajax({
                type: "POST",
                url: this.props.submitPath,
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(requestData),
            }).done(function (data) {
                if(data['success']) {
                    this.setState({buyingState: this.BUYING_STATES[2]});
                } else {
                    this.setState({buyingState: this.BUYING_STATES[0], errorMessage: data['error'], buttonDisabled: false});
                }
            }.bind(this));
        }.bind(this));//end set state

    }

    handleSubmit = (event) => {
        this.setState({buttonDisabled: true, errorMessage: null}, () => {
            if (this.hasSavedCardSelected()) {
                BraintreeFormService.savedCardSubmit(this.state.selectedCardId, this.state.threeDSecureInstance, this.props.verifyCardAmount)
                    .then((nonce) => {
                        this.submitPayment(nonce);
                    }).catch((error) => {
                    this.setState({buttonDisabled: false, errorMessage: error.message});
                });
            } else {
                if (!BraintreeFormService.isFormValid(this.state.hostedFieldsInstance)) {
                    this.setState({buttonDisabled: false, errorMessage: i18n.t('funnel.pay.braintree.card-invalid')});
                    return false;
                }
                BraintreeFormService.newCardSubmit(this.state.hostedFieldsInstance, this.state.threeDSecureInstance, this.props.verifyCardAmount)
                    .then((nonce) => {
                        this.submitPayment(nonce);
                    }).catch((error) => {
                        this.setState({buttonDisabled: false, errorMessage: error.message})
                    });
            }
        });
        event.preventDefault();
        return false;
    };

    btnClasses = () => {
        if (this.state.buttonDisabled) {
            return " loading disabled";
        }
        return '';
    };

    hasSavedCardSelected = () => {
        return this.state.selectedCardId !== null
    };

    amount = () => {
        return parseFloat(this.props.verifyCardAmount);
    }

    updatePrice = (newPrice) => {
        this.setState({orderPrice: newPrice});
    }

    membershipPrice = () => {
        var amount = parseFloat(this.props.verifyCardAmount);
        if (amount.toFixed(2) % 1 === 0) {
            return amount.toFixed(0).toString();
        } else {
            return amount.toFixed(2).toString();
        }
    }

    allDiscounts = () => {
        return _.flatten([this.state.orderPrice.productDiscounts, this.state.orderPrice.productAndFeeDiscounts]);
    }

    onCardSelect = (cardId) => {
        this.setState({selectedCardId: cardId})
    }

    isOnInitialState = () => {
        return this.state.buyingState === this.BUYING_STATES[0];
    }

    isOnProcessingState = () => {
        return this.state.buyingState === this.BUYING_STATES[1];
    }

    isOnBoughtState = () => {
        return this.state.buyingState === this.BUYING_STATES[2];
    }

    render() {
        return (
            <div className='buy-membership-box-container'>
                <div className={classNames({hidden: !this.isOnBoughtState()}) + " progress-container payment-success"}>
                    <div className="circle-check">
                    </div>
                    <div className="header-text">
                        <h4>{i18n.t('payments.order-payments.payment-success')}</h4>
                        <div>
                            <a href={PathHelper("dashboard_path")} className={"btn btn-primary"}>{i18n.t('payments.order-payments.back-to-bookings')}</a>
                        </div>
                    </div>
                </div>


                <div className={classNames({hidden: !this.isOnProcessingState()}) + " progress-container"}>
                    <div className="css-loader">
                    </div>
                    {i18n.t('buy-club.processing-payment')}
                </div>

                <div className={classNames({hidden: !this.isOnInitialState()})}>
                    <div className='box-content-container'>
                        <div id="payment">
                            <div className={classNames({'order-summary-container': true, 'with-ddc-price': true})}>
                                <div className="payment-section-header">
                                    {i18n.t('funnel.cash-payment.order')}
                                </div>
                                <div className="line-items-container">
                                    <div className={'service-row'} key={'service-row-club'}>
                                        <div className="main-service-row">
                                            <div className={'service-name'}> {this.props.productName} </div>
                                            <div className={'line-item-price'}>
                                                {MoneyUtils.price(this.state.orderPrice.productAmount, 2, false)}
                                            </div>
                                        </div>
                                    </div>
                                    {_.map(this.allDiscounts(), function (discount) {
                                        return (
                                            <div className={'service-row'} key={'service-row-domestina-club'}>
                                                <div className="main-service-row">
                                                    <div className={'service-name'}> {discount.description} </div>
                                                    <div className={'line-item-price'}>
                                                        -{MoneyUtils.price(discount.amount, 2, false)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }.bind(this))}

                                    <div className="service-row final-price-line-item">
                                        <div className={'payment-method-container'}>
                                        </div>
                                        <div className="funnel-payment-total-price-container">
                                            <div className={'text-container'}>{i18n.t('funnel.pay.total_amount')}</div>
                                            <PriceTag amount={this.state.orderPrice.amount} className="final-price-tag"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'funnel-card-form-section'}>
                                <div className="payment-section-header">
                                    {i18n.t('funnel.cash-payment.payment')}
                                </div>
                                <div className="card-list-container">
                                    <BraintreeForm clientToken={this.props.clientToken}
                                                   braintreeCards={this.props.braintreeCards}

                                                   selectedCardId={this.state.selectedCardId}
                                                   onCardSelect={this.onCardSelect}

                                                   onInstanceCreated={this.onInstanceCreated}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.errorMessage &&
                    <div className={"errors-container "}>
                        {this.state.errorMessage}
                    </div>}

                    <div className="price-and-cta-container">
                        <div className="cta-container">
                            <button className={"btn btn-primary cta-button " + this.btnClasses()} onClick={this.handleSubmit}>
                                {i18n.t('buy-club.cta')}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default OrderPaymentForm;
