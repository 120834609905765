import React from 'react';

import _ from 'underscore';
import UnitsUtils from '@services/units_utils';
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import classNames from "classnames";


class DeepCleaning extends AbstractProductCustomizer {
    static zeroSelectedProducts = {
        deep_cleaning_area: 0,
    };
    static selectorType = 'deep_cleaning';

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = {...this.zeroSelectedProducts(), ...props.initiallySelectedProductOptions};
        this.initializeProductOptions(props);
    };

    selectorType = () => {
        return DeepCleaning.selectorType;
    };

    zeroSelectedProducts = () => {
        return _.clone(DeepCleaning.zeroSelectedProducts);
    };

    updateArea = (event) => {
        var area = parseInt(event.target.value),
            selectedProductOptions = this.state.selectedProductOptions;

        selectedProductOptions['deep_cleaning_area'] = (isNaN(area) || area < 2000) ? area : 2000;

        this.setState({selectedProductOptions: selectedProductOptions}, function(){
            this.sendSelection(this.state.selectedProductOptions)
        }.bind(this));
    };

    hasInput = () => {
        var area = this.state.selectedProductOptions['deep_cleaning_area'];
        return !!area && area !== '' && area > 0;
    }


    render() {
        var area = this.state.selectedProductOptions['deep_cleaning_area'];

        if (isNaN(area) || area === 0) {
            area = '';
        }
        return (
            <div className="service-customizer-input-fields-container">
                {!this.props.alternativeDesign &&
                    <div className="row">
                        <div className="form-group col-md-6 col-md-offset-3 free-input-field-container">
                            <div className={classNames({"input-group": this.hasInput()})}>
                                <input type="number" name="area"
                                       value={area}
                                       onChange={this.updateArea}
                                       onKeyPress={this.areaInputFieldKeyPressed}
                                       placeholder={i18n.t('components.customizers.enter-area-placeholder', {metric: UnitsUtils.areaUnit()})}
                                       className={classNames({"without-right-border": this.hasInput(), "form-control": true})}/>
                                {this.hasInput() &&
                                <div className="input-group-addon">{UnitsUtils.areaUnit()}</div> }
                            </div>
                        </div>
                    </div>
                }
                {this.props.alternativeDesign &&
                    <div className="row">
                        <div className="col-xs-12 col-sm-4">
                            <div className="customization-explanation">
                                {i18n.t('components.customizers.enter-area-label', {metric: UnitsUtils.areaUnit()})}
                            </div>
                        </div>
                        <div className="form-group col-xs-12 col-sm-8 free-input-field-container">
                            <div className={classNames({"input-group": this.hasInput()})}>
                                <input type="number" name="area"
                                       value={area}
                                       onChange={this.updateArea}
                                       onKeyPress={this.areaInputFieldKeyPressed}
                                       placeholder={UnitsUtils.areaUnit()}
                                       className={classNames({"without-right-border": this.hasInput(), "form-control": true})}/>
                                {this.hasInput() &&
                                <div className="input-group-addon">{UnitsUtils.areaUnit()}</div> }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}


export default DeepCleaning;