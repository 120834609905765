import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import PrettyLittleSelect from '@components/common/pretty_little_select'

class WideSubscriptionOptionsSelector extends React.Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            visitsWeekly: PropTypes.number.isRequired,
            visitsWeeklyDescription: PropTypes.string.isRequired,
            visitsWeekdaysDescription: PropTypes.string.isRequired,

            visitDuration: PropTypes.number.isRequired,
            visitDurationDescription: PropTypes.string.isRequired,

            visitPrice: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired
        })).isRequired,

        onSelect: PropTypes.func.isRequired,
        selectedOption: PropTypes.object
    };

    visitsWeeklyOptions = () => {
        var options = _.uniq(this.props.options, function(option) {
            return option.visitsWeekly;
        });

        return _.map(options, function(option) {
            return {
                visitsWeekly: option.visitsWeekly,
                visitsWeeklyDescription: option.visitsWeeklyDescription,
                visitsWeekdaysDescription: option.visitsWeekdaysDescription
            };
        });
    };

    visitDurationOptions = () => {
        var options = _.uniq(this.props.options, function(option) {
            return option.visitDuration;
        });

        return _.map(options, function(option) {
            return {
                visitDuration: option.visitDuration,
                visitDurationDescription: option.visitDurationDescription
            };
        });
    };

    getSelectedVisitsWeekly = () => {
        var option = this.getPreselectedOption();

        if(option) {
            return option.visitsWeekly;
        }
        else {
            return null;
        }
    };

    getSelectedVisitDuration = () => {
        var option = this.getPreselectedOption();

        if(option) {
            return option.visitDuration;
        }
        else {
            return null;
        }
    };

    getPreselectedOption = () => {
        if(!this.props.selectedOption) return null;
        var preselected = this.props.selectedOption;

        return _.find(this.props.options, function(option) {
            return option.productType === preselected.productType &&
                   option.visitsWeekly === preselected.visitsWeekly &&
                   option.visitDuration === preselected.visitDuration;
        });
    };

    getVisitDurationSelectOptions = () => {
        return _.map(this.visitDurationOptions(), function(option) {
            return { value: option.visitDuration, label: option.visitDurationDescription };
        });
    };

    updateVisitsWeekly = (visitsWeekly) => {
        this.setState({ visitsWeekly: visitsWeekly });
    };

    updateVisitDuration = (event) => {
        this.setState({ visitDuration: parseInt(event.target.value) });
    };

    handleSubmit = () => {
        this.props.onSelect(this.getSelectedOption());
    };

    getSelectedOption = () => {
        return _.find(this.props.options, function(option) {
            return option.visitsWeekly === this.state.visitsWeekly &&
            option.visitDuration === this.state.visitDuration;
        }.bind(this));
    };

    numberIsInt = (number) => {
        return number % 1 === 0;
    };

    formatNumber = (number) => {
        if(this.numberIsInt(number)) {
            return number;
        }
        else {
            return number.toFixed(2);
        }
    };

    renderOptionPrice = (selectedOption) => {
        return (
            <div className="option-price">
              <div className="price-tag">
                <span className="price-per-visit">{ selectedOption.visitPrice }</span>
                <span className="currency">{MoneyUtils.currency()}</span>
                {selectedOption.priceType &&
                    <div className="vat-note">
                          {selectedOption.priceType}
                    </div>}
              </div>

              <div className="total-price">
                {i18n.t('funnel.order-type-form.total-price-for-visits',
                        { price: selectedOption.totalVisits * parseInt(selectedOption.visitPrice) + ' ' + MoneyUtils.currency(),
                count: selectedOption.totalVisits })}
              </div>
            </div>
        );
    };

    renderProductDescription = (selectedOption) => {
        return (
            <ul className="checklist checklist-pushed-left checklist-blue checklist-spaced">
              {_.map(selectedOption.description.split('\n'), function(line, i) {
                  if(line === "") {
                      return null;
                  }
                  else {
                      return <li key={i} dangerouslySetInnerHTML={{__html: line}}></li>;
                  }
              })}
            </ul>
        );
    };

    state = {
        visitsWeekly: this.getSelectedVisitsWeekly() || 1,
        visitDuration: this.getSelectedVisitDuration() || 4
    };

    render() {
        var selectedOption = this.getSelectedOption();

        return (
            <div className="at-work-options">
                <div className="wrapper clearfix">
                    <div className="at-work-info">
                        <h4>{ i18n.t('funnel.subscription-title') }</h4>
                        {this.renderProductDescription(selectedOption)}
                    </div>

                    <div className="visits-weekly green-radio">
                      { _.map(this.visitsWeeklyOptions(), function(option, i) {
                            return (
                                <div className={classNames({option: true, active: option.visitsWeekly === this.state.visitsWeekly })} key={i}
                                     onClick={_.partial(this.updateVisitsWeekly, option.visitsWeekly)} >
                                  <div className="count">{ option.visitsWeekly }</div>

                                  <div>
                                    <div className="times-a-week">
                                      { i18n.t('funnel.order-type-form.times-a-week', { count: option.visitsWeekly })}
                                    </div>

                                    <div className="weekdays">
                                      { option.visitsWeekdaysDescription }
                                    </div>
                                  </div>
                                </div>
                             );
                         }.bind(this))}
                    </div>

                    <div className="visit-duration">
                        <PrettyLittleSelect options={this.getVisitDurationSelectOptions()}
                                            value={this.state.visitDuration}
                                            onChange={this.updateVisitDuration} />
                         <div>
                           {this.renderOptionPrice(selectedOption)}
                         </div>

                         <button className="btn btn-lg btn-primary" onClick={this.handleSubmit} >{i18n.t('home.order-button')}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default WideSubscriptionOptionsSelector;
