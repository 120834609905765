var MoneyUtils = {
    currency: function() {
        return i18n.t('number.currency.format.unit-' + CURRENCY_CODE);
    },

    round: function(value, decimals) {
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    },

    price: function(amount, precision = 2, stripZerosIfInteger = true) {
      var finalAmount;
      if( stripZerosIfInteger && amount % 1 === 0) {
          finalAmount = MoneyUtils.round(amount, 0).toFixed(0);
      } else {
          finalAmount = MoneyUtils.round(amount, precision).toFixed(precision);
      }
      return finalAmount + " " + MoneyUtils.currency();
    },
}

export default MoneyUtils;
