import React from 'react';
import PropTypes from 'prop-types';
import Select from '@components/vendor/select.js';
import AvailabilitySchedule from '@components/availability_schedule';
import $ from 'jquery';

class WaitingListAvailabilitySchedule extends React.Component {
    static propTypes = {
        waitingListRecordId: PropTypes.number,

        initialSelectedCleanerId: PropTypes.number,

        initialSelectedProductType: PropTypes.string,

        productTypes: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })),

        initialSelectedVisitDuration: PropTypes.number,

        visitDurationOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number
        })),

        partialAddress: PropTypes.bool
    };

    state = {
        availableCleaners: [],
        schedule: [],
        selectedCleanerId: this.props.initialSelectedCleanerId || '',
        selectedProductType: this.props.initialSelectedProductType || '',
        selectedVisitDuration: this.props.initialSelectedVisitDuration || '',
        selectedDateSlot: null
    };

    componentWillMount() {
        this.fetchAvailableCleaners();
        this.fetchSchedule();
    }

    componentDidMount() {
        $('.tooltipped').tooltip();
    }

    componentDidUpdate() {
        $('.tooltipped').tooltip();
    }

    fetchSchedule = () => {
        $.get(PathHelper('cohorts_workforce_availabilities_path', { locale: 'bg' }),
              this.queryParams(),
              function(data) {
                  this.setState({ schedule: data, selectedDateSlot: null });
              }.bind(this));
    };

    fetchAvailableCleaners = () => {
        $.get(PathHelper('available_cleaners_cohorts_workforce_availabilities_path', { locale: 'bg' }),
              this.queryParams(),
              function(data) {
                  this.setState({ availableCleaners: data });
              }.bind(this));
    };

    queryParams = () => {
        return ({ waiting_list_record_id: this.props.waitingListRecordId,
                  cleaner_id: this.state.selectedCleanerId,
                  product_type: this.state.selectedProductType,
                  visit_duration: this.state.selectedVisitDuration,
                  visits_weekly: this.visitsWeekly() });
    };

    visitsWeekly = () => {
        if (this.state.selectedProductType == 'subscription') {
            return 1;
        }
        else {
            return null;
        }
    };

    selectDateSlot = (dateSlot) => {
        this.setState({ selectedDateSlot: dateSlot });
    };

    renderOptionForSelectedDateslot = () => {
        if(this.state.selectedDateSlot) {
            var cleaner = this.state.selectedDateSlot.cleaners[0],
                actions;

            if(this.props.partialAddress) {
                actions = (
                    <div className="card-action right-align">
                      <a className="waves-effect waves-light btn" href={Routes.edit_cohort_waiting_list_record_path(current_cohort_id(), this.props.waitingListRecordId)}>Fill address</a>
                      {' '}
                      <span style={{ height: '36px', display: 'inline-block'}}className="tooltipped" data-position="bottom" data-tooltip="Cannot create order because the address is not full">
                        <button disabled={true} className="waves-effect waves-light btn">Create Order</button>
                        </span>
                    </div>
                );
            }
            else {
                actions = (
                    <div className="card-action right-align">
                      <button onClick={this.createOrder} className="waves-effect waves-light btn">Create Order</button>
                    </div>
                );
            }

            return (
                <div className="row">
                  <div style={ {marginTop: '15px' } } className="col s12">
                    <div className="card horizontal">
                      <div className="card-image">
                        <img src={cleaner.image_path} alt={"Photo of " + cleaner.name} style={ {maxHeight: '250px'} }/>
                      </div>
                      <div className="card-stacked">
                        <div className="card-content">
                          <span className="card-title">{cleaner.name}</span>
                          <p>
                                <blockquote>
                                  {cleaner.comment.content}
                                  <footer>{cleaner.comment.client_name}</footer>
                                </blockquote>
                          </p>
                        </div>
                        {actions}
                      </div>
                    </div>
                  </div>
                </div>
            );
        }
        else {
            return null;
        }
    };

    createOrder = () => {
        $.ajax({
            type: "POST",
            url: Routes.create_order_cohort_waiting_list_record_path(current_cohort_id(), this.props.waitingListRecordId),
            data: JSON.stringify({order: {cleaner_id: this.state.selectedDateSlot.cleaners[0].id,
                                          product_type: this.state.selectedProductType,
                                          visit_duration: this.state.selectedVisitDuration,
                                          visits_weekly: this.visitsWeekly(),
                                          dateslots_data: [{date: this.state.selectedDateSlot['date'],
                                                            starts_at: this.state.selectedDateSlot['starts_at'],
                                                            ends_at: this.state.selectedDateSlot['ends_at']}]}}),
            dataType: 'json',
            contentType: 'application/json',
            success: function(data) {
                window.location.href = Routes.cohort_order_path(current_cohort_id(), data['id']);
            },
            statusCode: {
                422: function() {
                    Materialize.toast('There was an error during the attempt to create an order.', 4000);
                },
                500: function() {
                    Materialize.toast('There was a server error during the attempt to create an order.', 4000);
                }
            }
        });
    };

    selectCleaner = (selectedOption) => {
        this.setState({ selectedCleanerId: (selectedOption && selectedOption.value) || '' }, function() {
            this.fetchSchedule();
        }.bind(this));
    };

    renderCleanerSelect = () => {
        var optionFilter = function(option, filterString) {
            var label = option.label.toLowerCase(),
                alternative_label = option.alternative_label.toLowerCase(),
                filterValue = filterString.toLowerCase();

            return label.indexOf(filterValue) >= 0 || alternative_label.indexOf(filterValue) >= 0;
        };

        return (
            <div style={ {marginBottom: '10px'} }>
            <Select name="cleaner_id"
                    value={this.state.selectedCleanerId}
                    options={this.state.availableCleaners}
                    onChange={this.selectCleaner}
                    filterOption={optionFilter}
                    clearable={true}
                    searchable={true}
                    placeholder=""
                    scrollMenuIntoView={false}
                    autoBlur={true}
                    />
            </div>
        );
    };

    selectProductType = (selectedOption) => {
        this.setState({ selectedProductType: (selectedOption && selectedOption.value) || '' }, function() {
            this.fetchAvailableCleaners();
            this.selectCleaner('');
        }.bind(this));
    };

    renderProductTypeSelect = () => {
        return (
            <div style={ {marginBottom: '5px'} }>
              <Select name="product_type"
                      value={this.state.selectedProductType}
                      options={this.props.productTypes}
                      onChange={this.selectProductType}
                      scrollMenuIntoView={false}
                      autoBlur={true}
                      clearable={false}
                      searchable={false}
                      />
            </div>
        );
    };

    selectVisitDuration = (selectedOption) => {
        this.setState({ selectedVisitDuration: (selectedOption && selectedOption.value) || '' }, function() {
            this.fetchAvailableCleaners();
            this.selectCleaner('');
        }.bind(this));
    };

    renderVisitDurationSelect = () => {
        return (
            <div style={ {marginBottom: '5px'} }>
              <Select name="visit_duration"
                      value={this.state.selectedVisitDuration}
                      options={this.props.visitDurationOptions}
                      onChange={this.selectVisitDuration}
                      scrollMenuIntoView={false}
                      autoBlur={true}
                      clearable={false}
                      searchable={false}
                      />
            </div>
        );
    };

    render() {
        return (
            <div>
              <div>Product type: </div>
              {this.renderProductTypeSelect()}

              <div>Visit duration: </div>
              {this.renderVisitDurationSelect()}

              <div>Filter by maid: </div>
              {this.renderCleanerSelect()}

               <AvailabilitySchedule
                  schedule={this.state.schedule}
                  onSlotSelect={this.selectDateSlot}
                  selectedDateSlot={this.state.selectedDateSlot}/>

              {this.renderOptionForSelectedDateslot()}
            </div>
        );
    }
};

export default WaitingListAvailabilitySchedule;
