import React from 'react';
import PropTypes from 'prop-types';

import ProductUtils from '@services/product_utils';
import DateService from '@services/date_service';
import DateSlotService from '@services/date_slot_service';
import CleanerNameWithCategory from '@components/common/cleaner_name_with_category';

class FunnelProgressListDetailsInfo extends React.Component {
    static propTypes = {
        product: PropTypes.object,

        dateslots: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            starts_at: PropTypes.string,
            ends_at: PropTypes.string
        })),

        cleaner: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string
        }),

        slotsInfo: PropTypes.object,
        showDetails: PropTypes.bool
    };

    content = () => {
        if(ProductUtils.isWeekly(this.props.product)) {
            return this.startsOn(this.props.dateslots[0]);
        }
        else {
            return this.firstEventDescription(this.props.dateslots[0]);
        }
    };

    firstEventDescription = (dateslot) => {
        var timeStart = dateslot.starts_at,
            dateslotDescription = null, calendarDate = null;

        if(dateslot) {
            dateslotDescription = (
                <div>
                  {DateService.formatDateAndDay(dateslot.date) + ', ' + timeStart}
                </div>
            );
        }

        return (
            <div>
              <div className="cleaner-and-event">
                {dateslotDescription}
                {this.props.cleaner && this.props.cleaner.id && <CleanerNameWithCategory {...this.props.cleaner}/>}
              </div>
            </div>
        );
    };

    startsOn = (dateslot) => {
        var dateslotDescription = null,
            weekdaysDescription = null;

        if(dateslot) {
            dateslotDescription = i18n.t('funnel.breadcrumbs.details.starts-on', { date: DateService.formatDate(dateslot.date)} );
        }

        if(ProductUtils.isWeekly(this.props.product)) { // Subscription
            weekdaysDescription = this.subscriptionDescription();
        }

        return (
            <div>
              {weekdaysDescription}, {dateslotDescription}
              <div>
                {this.props.cleaner && this.props.cleaner.id && <CleanerNameWithCategory {...this.props.cleaner}/>}
              </div>
            </div>
        );
    };

    subscriptionDescription = () => {
        return DateSlotService.formatSubscriptionDateslots(this.props.dateslots, this.props.slotsInfo);
    };

    renderDetailsInfo = () => {
        return (
            <div>
              <div className="little-darkblue-title">
                {this.content()}
              </div>
            </div>
        );
    };

    actionText = () => {
        var type = this.props.product.productType,
            mapping = {
                0: 'single-event',
                1: 'subscription',
                4: 'at-work',
                7: 'single-event'
            };

        return i18n.t('funnel.time-form.hints.' + (mapping[type] || 'single-event'), { count: this.props.product.visitsWeekly });
    };

    renderRequiredAction = () => {
        return (
            <div>
              <div>
                {this.actionText()}
              </div>
            </div>
        );
    };

    hasDetails = () => {
        return (this.props.noCleaner || !!this.props.cleaner.id) && !!this.props.dateslots && this.props.dateslots.length > 0;
    };

    isSlotsSelectionDone = () => {
        return DateSlotService.selectionDone(this.props.product, this.props.dateslots);
    };

    render() {
        if(this.props.showDetails &&
           (this.hasDetails() || this.isSlotsSelectionDone())) {
            return this.renderDetailsInfo();
        }
        else {
            return this.renderRequiredAction();
        }
    }
};

export default FunnelProgressListDetailsInfo;
