

var UnitsUtils = function(){
    var areaUnit = function() {
        return  i18n.t("number.area.unit-" + LENGTH_UNIT)
    };

    var area = function(number) {
        return number + areaUnit();
    };

    var rooms = function(count) {
        return  i18n.t("measurement.room", { count: count })
    };

    var apartments = function(count) {
        return  i18n.t("measurement.apartment", { count: count })
    };

    return {
        areaUnit: areaUnit,
        area: area,
        rooms: rooms,
        apartments: apartments,

        quantityWithUnit: function(quantity, unit) {
            switch(unit) {
            case 'area':
                return area(quantity)
                break;
            case 'apartments_count':
                return apartments(quantity);
                break;
            case 'rooms_count':
                return rooms(quantity);
                break
            default:
                return quantity;
            }
        }
    }
}();

export default UnitsUtils;
