import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import PriceTag from '@components/common/price_tag';
import PrettyLittleSelect from '@components/common/pretty_little_select'

class ProductOptionsSelector extends React.Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            // Presentation
            discounted: PropTypes.bool,

            optionLabel: PropTypes.string,

            visitPrice: PropTypes.string,
            regularVisitPrice: PropTypes.string,
            discountedVisitPrice: PropTypes.string,

            totalPrice: PropTypes.string,
            regularTotalPrice: PropTypes.string,
            discountedTotalPrice: PropTypes.string,

            description: PropTypes.string,

            tooltipMessage: PropTypes.string,

            addMembership: PropTypes.bool,

            // Data
            productType: PropTypes.number,
            visitDuration: PropTypes.number,
            visitsWeekly: PropTypes.number
        })),

        selectName: PropTypes.string,
        priceClass: PropTypes.string,
        onSelect: PropTypes.func,
        highlighted: PropTypes.bool,

        selectedOption: PropTypes.object,
        prepaidEventsCount: PropTypes.number,
        priceType: PropTypes.string,
        triggerMembershipModal: PropTypes.func,

        isAddressMember: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);
        var selectedOption = this.fetchSelectedOption(props.selectedOption.visitDuration,
                                                      props.selectedOption.visitsWeekly,
                                                      props.selectedOption.addMembership

        );

        if(selectedOption) {
            this.state = { visitDuration: selectedOption.visitDuration,
                     visitsWeekly: selectedOption.visitsWeekly,
                    addMembership: selectedOption.addMembership,
                     selected: true };
        }
        else {
            var defaultVisitDuration = (_.contains(_.map(props.options, function(option) { return option.visitDuration; }), 4) && 4) ||
                                       props.options[0].visitDuration,
                defaultVisitsWeekly = (_.contains(_.map(props.options, function(option) { return option.visitsWeekly; }), 1) && 1) ||
                                      props.options[0].visitWeekly;

            this.state = { visitDuration: defaultVisitDuration,
                          visitsWeekly: defaultVisitsWeekly,
                          selected: false };
        }

        if(this.shouldDisplayMembershipCheckbox() && this.state.addMembership === undefined ) {
            this.state.addMembership = false;

        }
    }

    shouldDisplayMembershipCheckbox = () => {
      return !this.props.isAddressMember && _.any(this.props.options, (productOption) => {
          return productOption.addMembership;
      })
    };

    handleVisitDurationChange = (event) => {
        this.setState({ visitDuration: parseInt(event.target.value), selected: false });
    };

    handleVisitsWeeklyChange = (event) => {
        this.setState({ visitsWeekly: parseInt(event.target.value), selected: false });
    };

    handleSelect = () => {
        this.props.onSelect(this.selectedOption());
    };

    selectedOption = () => {
        return this.fetchSelectedOption(this.state.visitDuration, this.state.visitsWeekly, this.state.addMembership);
    };

    toggleAddMembership = () => {
        this.setState({addMembership: !this.state.addMembership});

        if(!this.state.addMembership)
            Analytics.sendEvent('Housemaids', 'Club checkbox click', window.location.href);
    }

    fetchSelectedOption = (visitDuration, visitsWeekly, addMembership) => {
        if(this.shouldDisplayMembershipCheckbox()) {
            return _.find(this.props.options, function(option) {
                return visitDuration === option.visitDuration &&
                       visitsWeekly === option.visitsWeekly &&
                       addMembership === !!option.addMembership;
            });
        } else {
            return _.find(this.props.options, function(option) {
                return visitDuration === option.visitDuration &&
                    visitsWeekly === option.visitsWeekly;
            });
        }
    };

    getVisitDurationOptions = () => {
        return _.chain(this.props.options)
            .map(function(option) {
                return option.visitDuration;
            }).uniq().map(function(visitDuration) {
                return { value: visitDuration,
                         label: i18n.t('datetime.distance_in_words.x_hours', { count: visitDuration })};
            }).sortBy('value').value();
    };

    getVisitsWeeklyOptions = () => {
        return _.chain(this.props.options)
            .map(function(option) {
                return option.visitsWeekly;
            }).uniq().reject(function(visitsWeekly){
                return visitsWeekly == undefined;
            }).map(function(visitsWeekly) {
                var visitsTimesAWeek = i18n.t('time.frequency', { count: visitsWeekly });

                return { value: visitsWeekly,
                         label: i18n.t('funnel.order-type-form.events-weekly', { visits: visitsTimesAWeek })};
            }).sortBy('value').value();
    };

    renderPrepaidEventsCount = () => {
        return (
            <div className="prepaid-events-count">
              <div>{i18n.t('funnel.order-type-form.remaining')}</div>
              <div className="count">{this.props.prepaidEventsCount}</div>
              <div className="visits">{i18n.t('funnel.order-type-form.visits', { count: this.props.prepaidEventsCount})}</div>
            </div>
        );
    };

    renderPriceTag = () => {
        var selectedOption = this.selectedOption();

        if(selectedOption.discounted) {
            return (
                <div>
                  <PriceTag amount={selectedOption.regularVisitPrice} className={"crossed-price"} prefix={selectedOption.pricePrefix} flat={true}/>
                  <PriceTag amount={selectedOption.discountedVisitPrice} className={"price-tag " + this.props.priceClass} prefix={selectedOption.pricePrefix}/>
                </div>
            );
        }
        else {
            return (
                <PriceTag amount={selectedOption.visitPrice} className={"price-tag " + this.props.priceClass} prefix={selectedOption.pricePrefix}/>
            );
        }
    };

    renderButton = () => {
        var classes = 'btn btn-primary', text, handler;

        if(this.state.selected) {
            classes += ' active';
            text = i18n.t('form.buttons.selected');
        }
        else {
            text = i18n.t('form.buttons.choose');
        }

        return (
            <button className={classes} onClick={this.handleSelect}>
              {text}
            </button>
        );
    };

    openMembershipModal = (event) => {
        event.preventDefault();
        this.props.triggerMembershipModal(false);
    }

    renderDescription = () => {
        var selectedOption = this.selectedOption();
        var tooltip, overlayTrigger;

        if(selectedOption.tooltipMessage) {
            tooltip = (
                <Tooltip id={selectedOption.productType}>
                  <div dangerouslySetInnerHTML={{__html: selectedOption.tooltipMessage}}></div>
                </Tooltip>
            );

            overlayTrigger = (
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <span className="glyphicon glyphicon-info-sign" aria-hidden="true"></span>
                </OverlayTrigger>
            );
        }

        return (
            <div>
                <ul>
                    {_.map(selectedOption.description.split('\n'), function(line, i) {
                        if(line === "") {
                            return null;
                        }
                        else {
                            return (
                                <li key={i}>
                                    <span dangerouslySetInnerHTML={{__html: line}}></span>
                                    {i == 0 && overlayTrigger}
                                </li>
                            );
                        }
                    }.bind(this))}
                </ul>
                {this.shouldDisplayMembershipCheckbox() &&
                <div className={'funnel-add-membership-checkbox-container'}>
                    <label><input type="checkbox" name="addMembership" onChange={this.toggleAddMembership}
                                  checked={this.state.addMembership} value={this.state.addMembership}/>
                        {i18n.t('funnel.add-services.add-club')}
                    </label> &nbsp;
                    <a href="#" onClick={this.openMembershipModal}>(виж повече)</a>
                </div>}
            </div>

        );
    };

    renderLabel = () => {
        var option = this.selectedOption(),
            ribbon = null;

        if(option.corner_ribbon) {
            if(option.corner_ribbon.type == 'text') {
                ribbon = (
                    <div className="corner-ribbon corner-ribbon-orange-big">
                      <div className="content" dangerouslySetInnerHTML={{__html: option.corner_ribbon.content}}></div>
                    </div>
                );
            }
            else if(option.corner_ribbon.type == 'discount') {
                ribbon = (
                    <div className="corner-ribbon corner-ribbon-orange-big corner-ribbon-discount">
                      <div className="content">
                        <div className="amount">{option.corner_ribbon.amount}</div>
                        <div className="description">{option.corner_ribbon.description}</div>
                      </div>
                    </div>
                );
            }
        }


        if(this.props.typeName && this.props.productName) {
            return (
                <div className="box-label">
                  {ribbon}
                  <div className="product-name">{this.props.productName}</div>
                </div>
            );
        }
        else {
            return (
                <div className="box-label">
                  {ribbon}
                  <div className="product-name small" dangerouslySetInnerHTML={{__html: this.props.productName}}></div>
                </div>
            );
        }
    };

    render() {
        return (
            <div className={classNames({'product-selector': true, highlighted: this.props.highlighted})}>
              {this.renderLabel()}
              <div className="box-content">
                {!_.isEmpty(this.getVisitDurationOptions()) &&
                    <PrettyLittleSelect
                           className={!_.isEmpty(this.getVisitsWeeklyOptions()) ? "visit-duration" : ""}
                           name={this.props.selectName}
                           options={this.getVisitDurationOptions()}
                           value={this.state.visitDuration}
                           onChange={this.handleVisitDurationChange}/>}

                {!_.isEmpty(this.getVisitsWeeklyOptions()) &&
                    <PrettyLittleSelect
                           className={!_.isEmpty(this.getVisitDurationOptions()) ? "visits-weekly" : ""}
                           name={this.props.selectName}
                           options={this.getVisitsWeeklyOptions()}
                           value={this.state.visitsWeekly}
                           onChange={this.handleVisitsWeeklyChange}/>}

                {this.renderPriceTag()}
                {this.renderButton()}
                {this.renderDescription()}
              </div>
            </div>
        );
    }
}


export default ProductOptionsSelector;
