import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import storage from 'local-storage-fallback'
import $ from "jquery";
import moment from 'moment';

import withLoader from '@components/common/with_loader_decorator';
import withHistory from '@components/common/with_history_decorator'
import withPhoneCollector from '@components/common/with_phone_collector_decorator'
import Authenticator from '@components/common/authenticator'



import CleanerSelector from '@components/funnel/cleaner_selector'
import MicroWaitingListModal from '@components/funnel/micro_waiting_list_modal'

import FunnelBookingAddressSelector from '@components/funnel/booking_address_selector'
import FunnelHousemaidPayment from '@components/funnel/housemaid_payment'
import FunnelBookingDateSlotSelector from '@components/funnel/booking_date_slot_selector'
import CommentInputStep from '@components/funnel/comment_input_step'

import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';
import ResponsiveBreadcrumbs from '@components/responsive_breadcrumbs'


import FunnelStatePersistance from '@services/funnel_state_persistance'
import FunnelLocationProductCustomizer from '@components/funnel/location_product_customizer'

import FunnelCleanerProfileModal from '@components/funnel/cleaner_profile_modal'
import EarlyAdopterCollector from '@components/funnel/early_adopter_collector'



class HousemaidFunnel extends React.Component {
    static propTypes = {
        serviceType: PropTypes.string,
        serviceTitle: PropTypes.string,

        submitPath: PropTypes.string,
        httpVerb: PropTypes.string,

        initialStep: PropTypes.string,

        // Authentication
        authenticationPrompt: PropTypes.func,
        authenticationStatus: PropTypes.string,

        // Location
        locationStrategy: PropTypes.string,
        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            alternative_name: PropTypes.string,
            districts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                alternative_name: PropTypes.string.isRequired,
            })),
            supports_service: PropTypes.bool,
        })),

        initialCityId: PropTypes.number,
        initialDistrictId: PropTypes.number,

        initialAddress: PropTypes.object,
        initialAddresses: PropTypes.array,
        initialPostCode: PropTypes.string,

        highlightedCleanerId: PropTypes.number,
        highlightedCleanerName: PropTypes.string,
        highlightedCleanerDistrictIds: PropTypes.arrayOf(PropTypes.number),
        highlightedCleanerSupportsFix: PropTypes.bool,

        // Product
        initiallySelectedProductOptions: PropTypes.shape({
            product_type: PropTypes.string,
            visits_weekly: PropTypes.number,
        }),

        // Companies
        initialCleaners: PropTypes.arrayOf(PropTypes.object),
        initialCleanerId: PropTypes.number,


        // Schedule
        initialWeek: PropTypes.number,
        initialDateslots: PropTypes.arrayOf(PropTypes.object),
        weeksAheadLimit: PropTypes.number,

        // Payment
        activePromoCodeExists: PropTypes.bool,

        orderCode: PropTypes.string,

        invoiceRequested: PropTypes.bool,
        orderPrice: PropTypes.shape({
            currency: PropTypes.string.isRequired,

            productAmount: PropTypes.string.isRequired,
            productDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
            productAndFeeDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
        }),


        // Filter
        initialFilter: PropTypes.string,
        initialFilterDate: PropTypes.string,

        collectPhone: PropTypes.bool,
        filterDateCache: PropTypes.object,

        promotionSeed: PropTypes.number,

        isMember: PropTypes.bool,
        membershipCurrentlyValid: PropTypes.bool,

        initialBuyMembership: PropTypes.bool,

        onlyPaymentAvailable: PropTypes.bool,

        initialHasWorkingCleaners: PropTypes.bool,

        isLoggedin: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            location: {
                city: this.findCity(props.initialCityId),
                district: null,
            },

            address: this.props.initialAddress,
            addresses: this.props.initialAddresses,
            hasDog: false,
            hasCat: false,

            selectedProductOptions: this.props.initiallySelectedProductOptions || {},

            cleaners: this.props.initialCleaners,
            selectedCleanerId: null,
            // availabilitiesForSelectedUnavailableDateslot: null,

            selectedDateslots: this.props.initialDateslots || [],
            week: this.props.initialWeek || 0,
            weeksAheadLimit: this.props.weeksAheadLimit,

            commentStepSeen: false,

            step: this.props.initialStep || this.steps()[0],
            stepUpTo: this.props.initialStep || this.steps()[0],

            companyListSelectionData: {
                sortingCriteria: 2,
                selectedRank: null,
                possibleChoices: 0,
                promoted: false
            },

            filter: this.props.initialFilter || 'all',
            filterDate: null,
            filterDateCache: this.props.filterDateCache || {},
            buyAddressMembership: this.props.initialBuyMembership || false,

            unlockDialogCleanerName: null,

            cleanerProfileOpen: false,

            microWaitingListOpen: false,
            microWaitingListLoading: false,
            microWaitingListSuccess: false,

            cleanerProfileCleanerId: null,
            hasWorkingCleaners: this.props.initialHasWorkingCleaners || false,
        }

        _.each(this.state.cleaners, function (cleaner) {
            cleaner.initialFavorite = cleaner.favorite;
        });

        if (this.props.isMember) {
            this.state.buyAddressMembership = false;
        }

        if (this.state.filter === 'date') {
            if (Date.parse(this.props.initialFilterDate)) {
                this.state.filterDate = new Date(this.props.initialFilterDate);
            } else {
                this.state.filter = 'all';
            }

        }

        if (this.state.location.city) {
            this.state.location.district = this.findCity(props.initialCityId).districts.find(district => district.id === props.initialDistrictId);
        }

        if (this.props.initialCleanerId) {
            if (this.props.initialStep === 'dateslotSelection')
                this.state = {...this.state, ...this.newStateAfterCleanerSelect(this.props.initialCleanerId, null, null, false)}
            else
                this.state['selectedCleanerId'] = this.props.initialCleanerId
        }
    }

    static childContextTypes = {
        locationStrategy: PropTypes.string,
    }

    getChildContext() {
        return {locationStrategy: this.props.locationStrategy};
    }

    componentDidMount() {
        if (this.props.initializeStateAndHistory) {
            this.props.initializeStateAndHistory({componentName: 'HousemaidFunnel', currentState: this.state});
        }
        FunnelStatePersistance.loadPersistedStateIfNeeded(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.updatingFromHistory) {
            let newState = _.clone(props.stateFromHistory);
            props.updateFromHistoryFinished();
            return newState;
        } else
            return null;
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.updatingFromHistory) {
    //         this.setState(nextProps.stateFromHistory);
    //         nextProps.updateFromHistoryFinished();
    //     }
    // }

    // componentWillUpdate(nextProps, nextState) {
    //     var stepChanged = (this.state.step !== nextState.step ||
    //         this.state.extraServicesStepSeen !== nextState.extraServicesStepSeen);
    //
    //     if (stepChanged) {
    //         window.scrollTo(0, 0);
    //
    //         if (!nextProps.updatingFromHistory) {
    //             nextProps.captureHistoryState(nextState);
    //         }
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var stepChanged = (this.state.step !== prevState.step ||
            this.state.extraServicesStepSeen !== prevState.extraServicesStepSeen);

        if (stepChanged) {
            window.scrollTo(0, 0);

            if (!this.props.updatingFromHistory) {
                this.props.captureHistoryState(this.state);
            }
        }
    }


    serviceType = () => {
        return 'housemaid';
    };

    availabilityPathString = () => {
        return 'housemaid_availability_path';
    };

    cleanersForDatePath = () => {
        return PathHelper('cleaners_for_date_housemaid_requests_path');
    };

    attributesPath = () => {
        return PathHelper('attributes_housemaid_requests_path');
    };


    selectedCleaner = () => {
        return this.findCleaner(this.state.selectedCleanerId);
    };

    findCleaner = (cleanerId) => {
        return _.find(this.state.cleaners, function (cleaner) {
            return cleaner.id === cleanerId;
        }.bind(this));
    };

    profileModalAddToFavoritesClicked = () => {
        let cleaner = this.findCleaner(this.state.cleanerProfileCleanerId);
        this.changeFavorites(cleaner.id, !cleaner.favorite);
    };

    changeFavorites = (cleanerId, favorite) => {
        let cleaners = _.clone(this.state.cleaners);
        let cleaner = _.find(cleaners, function (cleaner) {
            return cleaner.id === cleanerId;
        });
        cleaner.favorite = favorite;

        this.props.executeSlowOperation(function () {
            return this.updateServerFavorite(cleanerId, favorite)
                .done(function (attributes) {
                    this.setState({cleaners: cleaners})
                }.bind(this));
        }.bind(this));
    };

    updateServerFavorite = (cleanerId, favorite) => {
        let requestType = favorite ? "POST" : "DELETE";
        let url = favorite ? PathHelper("favorite_cleaners_path") : PathHelper("favorite_cleaners_path") + '/' + cleanerId;

        return $.ajax({
            type: requestType,
            url: url,
            data: { cleaner_id: cleanerId},
            statusCode: {
                500: function() {
                    window.location.href = '/500';
                }
            }
        });
    }

    selectedProductsPrice = () => {
        let selectedCleaner = this.selectedCleaner();
        if (selectedCleaner) {
            return parseFloat(selectedCleaner.ranking.price);
        } else {
            return null;
        }
    }

    selectedCleanerVisitDuration = () => {
        let selectedCleaner = this.selectedCleaner();
        if(selectedCleaner == null)
            return null;
        else if (this.isTeamEntity())
            return '2.0';
        else
            return '4';
    }

    onDurationChange = (newDuration) => {
        let nextState = {};
        nextState['selectedProductOptions'] = {...this.state.selectedProductOptions, ...{visit_duration: newDuration.value}};
        this.setState({...this.state, ...nextState})
    }

    durationOptions = () => {
        if(this.isTeamEntity())
            return [
                {label: i18n.t('services.housemaid.customizer.number-of-hours', {hours: '2'} ), value: '2.0'},
                {label: i18n.t('services.housemaid.customizer.number-of-hours', {hours: '4'} ), value: '4.0'},
                {label: i18n.t('services.housemaid.customizer.number-of-hours', {hours: '6'} ), value: '6.0'},
                {label: i18n.t('services.housemaid.customizer.number-of-hours', {hours: '8'} ), value: '8.0'},
            ]
        else
            return [
                {label: i18n.t('services.housemaid.customizer.number-of-hours', {hours: '4'} ), value: '4.0'},
                {label: i18n.t('services.housemaid.customizer.number-of-hours', {hours: '8'} ), value: '8.0'}
            ]
    }



    isTeamEntity = () => {
        let selectedCleaner = this.selectedCleaner();
        if(selectedCleaner == null)
            return false;
        else
            return selectedCleaner.entityType === 'team_entity'
    }

    productTitle = () => {
        let duration_hours = this.selectedCleanerVisitDuration();
        let duration_string = duration_hours ? i18n.t('services.housemaid.customizer.number-of-hours', { hours: duration_hours } ) : ''
        if(this.state.selectedProductOptions.product_type === 'single_visit')
            return i18n.t('services.housemaid.customizer.single_visit') + ' ' + duration_string;
        else if(this.state.selectedProductOptions.product_type === 'subscription')
            return i18n.t('services.housemaid.customizer.once_a_week') + ' ' +  duration_string;
        else
            return this.props.serviceTitle;
    };

    totalPrice = () => {
        return this.selectedProductsPrice();
    };

    waitingListClicked = () => {
        this.setState({microWaitingListOpen: true})
    }

    closeMicroWaitingListModal = () => {
        this.setState({microWaitingListOpen: false})
    }

    switchToFlexClicked = () => {
        let nextState = {};
        nextState['selectedProductOptions'] = {...this.state.selectedProductOptions, ...{product_type: "single_visit", visits_weekly: 0}};

        if (!this.selectedAddressId() && !this.selectedDistrictId()) {
            alert("No Location Selected!")
            return;
        }

        this.props.executeSlowOperation(function () {
            return this.fetchAttributes(nextState['selectedProductOptions'], this.selectedAddressId(), this.selectedDistrictId())
                .done(function (attributes) {
                    this.setAttributesToState(nextState, attributes);

                    this.setState(nextState);
                }.bind(this));
        }.bind(this));
    }

    highlightedCleanerError = () => {
        if(this.props.highlightedCleanerId === null)
            return null;
        else if(!_.contains(this.props.highlightedCleanerDistrictIds, this.activeDistrictId()))
            return i18n.t('funnel.cleaners.highlight-errors.not-support-district', {cleaner_name: this.props.highlightedCleanerName})
        else if(this.state.selectedProductOptions.product_type === 'subscription' && !this.props.highlightedCleanerSupportsFix)
            return i18n.t('funnel.cleaners.highlight-errors.does-not-support-fix', {cleaner_name: this.props.highlightedCleanerName})
        else if(!_.contains(this.cleanerIds(), this.props.highlightedCleanerId))
            return i18n.t('funnel.cleaners.highlight-errors.not-available', {cleaner_name: this.props.highlightedCleanerName})
        else
            return null;
    }

    // Stepping logic
    steps = () => {
        return ['locationProductCustomization', 'cleanerSelection', 'dateslotSelection', 'commentEntry',
            'bookingConfirmation']
    };

    dateslotSelectionStepName = () => {
        return 'dateslotSelection';
    }

    showWaitingListButton = () => {
        return this.state.filter === 'all' && this.filteredCleaners().length === 0;
    }

    showSwitchToFlexButton = () => {
        return this.showWaitingListButton() && this.state.selectedProductOptions.product_type === 'subscription' && this.state.hasWorkingCleaners;
    }


    stepBody = () => {
        switch (this.state.step) {
            case 'locationProductCustomization':
                return this.locationProductCustomizer()
            case 'cleanerSelection':
                if(OPERATIONAL) {
                    Analytics.sendCompanySelectionPageview();
                    let amplitudeProperties =  {
                        date_filter: this.state.filter,
                        sorting_criteria: this.state.companyListSelectionData.sortingCriteria === 1 ? "rank" : "price",
                        product_type: this.state.selectedProductOptions.product_type,
                        cleaners_count: this.state.cleaners.length,
                        team_count: _.filter(this.state.cleaners, function(cleaner){ return cleaner.entityType == 'team_entity' }).length,
                        person_count: _.filter(this.state.cleaners, function(cleaner){ return cleaner.entityType == 'person_entity' }).length,
                    }
                    return (
                        <CleanerSelector cleaners={this.filteredCleaners()}
                                         highlightedCleanerId={this.props.highlightedCleanerId}
                                         highlightedCleanerError={this.highlightedCleanerError()}
                                         onSelect={this.selectCleaner}
                                         selectedFilter={this.state.filter}
                                         handleFilterSelect={this.handleFilterSelect}
                                         selectedFilterDate={this.state.filterDate}
                                         promotionSeed={this.props.promotionSeed}
                                         buyAddressMembership={this.state.buyAddressMembership}
                                         isCurrentAddressMember={this.isCurrentAddressAMember()}
                                         openCleanerProfileDialog={this.openCleanerProfileDialog}
                                         hasSelectedFilterDate={this.hasSelectedFilterDate()}
                                         waitingListClicked={this.showWaitingListButton() ?  this.waitingListClicked : null}
                                         switchToFlexClicked={this.showSwitchToFlexButton() ?  this.switchToFlexClicked : null}
                                         amplitudeContext={'housemaid funnel'}
                                         amplitudeProperties={amplitudeProperties}

                        >
                            <div className="funnel-heading hidden-xs hidden-sm">{i18n.t('funnel.companies.title')}</div>
                            <div className="funnel-subtitle"></div>
                        </CleanerSelector>);
                } else {
                    return(
                        <div>
                            <div className="funnel-heading hidden-xs hidden-sm">{i18n.t('funnel.companies.title')}</div>
                            <EarlyAdopterCollector
                                cityName={this.selectedCity() ? this.selectedCity().name : '' }
                                cityId={this.selectedCityId()}
                                districtId={this.selectedDistrictId()}
                                serviceType={this.serviceType()}
                            />
                        </div>
                    );
                }

            case 'dateslotSelection':
                Analytics.sendDateslotSelectionPageview();
                return this.dateSlotSelector();
            case 'commentEntry':
                if (!this.state.commentStepSeen) {
                    Analytics.sendCommentPageview();
                    return (<CommentInputStep onSubmit={this.onCommentSubmit}
                                              initiallySelectedProductOptions={this.state.selectedProductOptions}
                                              amplitudeContext={'housemaid funnel'}
                        />
                    );
                } else if (!this.hasSelectedAddress()) {
                    if (this.props.authenticationStatus === 'unauthenticated')
                        return (<div
                            className={'creating-order-funnel-step'}>{i18n.t("funnel.step-placeholders.authenticating")}</div>);
                    else{
                        return this.addressSelector();
                    }
                } else {
                    return (<div
                        className={'creating-order-funnel-step'}>{i18n.t("funnel.step-placeholders.creating-order")}</div>);
                }
            case 'bookingConfirmation':
                return (
                    <FunnelHousemaidPayment serviceType={'housemaid'}
                                            lineItems={this.props.lineItems}
                                            orderCode={this.props.orderCode}
                                            invoiceRequested={this.props.invoiceRequested}
                                            orderPrice={this.props.orderPrice}
                                            activePromoCodeExists={this.props.activePromoCodeExists}
                                            isTeamEntity={this.isTeamEntity()}
                                            clientToken={this.props.clientToken}
                                            verifyCardAmount={parseFloat(this.props.verifyCardAmount)}
                                            braintreeCards={this.props.braintreeCards}
                                            payWithCard={this.selectedCleaner().payWithCard}
                                            buyMembership={this.props.initialBuyMembership}
                                            membershipCurrentlyValid={this.props.membershipCurrentlyValid}
                    />
                )
            default:
                return '';
        }
    };

    clearStep = (step, state) => {
        switch (step) {
            case 'locationProductCustomization':
                break;
            case 'cleanerSelection':
                break;
            case 'dateslotSelection':
                break;
            case 'commentEntry':
                state.commentStepSeen = false;
                break;
            case 'bookingConfirmation':
                state.totalPrice = null;
                break;
            default:
                return '';
        }
    };

    dateSlotSelector = () => {

        var scheduleOptions = {
            districtId: this.activeDistrictId(),
            cat: this.state.hasCat,
            dog: this.state.hasDog,
            weeksAheadLimit: this.state.weeksAheadLimit
        };

        return (
            //TODO add weekly visits to dateslot selector
            <FunnelBookingDateSlotSelector serviceType={this.props.serviceType}
                                           availabilityPathString={this.availabilityPathString()}
                                           initialWeek={this.state.week}
                                           scheduleOptions={scheduleOptions}
                                           selectedDateslots={this.state.selectedDateslots}
                                           onSlotsSelect={this.updateDateslots}
                                           companies={this.state.companies}
                                           cleanerId={this.state.selectedCleanerId}
                                           listCompanies={this.props.listCompanies}
                                           executeSlowOperation={this.props.executeSlowOperation}
                                           onSubmit={this.handleDateSlotSelectorSubmit}
                                           filterDate={this.state.filterDate}
                                           extraAvailabilityParams={this.state.selectedProductOptions}
                                           handleBackToList={this.handleBackToList}
                                           showDuration={true}
                                           selectedDuration={this.state.selectedProductOptions.visit_duration}
                                           durationOptions={this.durationOptions()}
                                           onDurationChange={this.onDurationChange}
                                           amplitudeEvent={'see page - housemaid funnel slot selection'}
                                           amplitudeProperties={{entity_type: this.selectedCleaner().entityType}}
            />
        );
    };


    // --------------- STEPS LOGIC --------------------------

    changeStepAndClear = (step, cb) => {
        var newState = this.clearStepAndStepsAfter(step);
        newState.step = step;

        this.setState(newState, cb);
    };

    nextStepState = (step) => {
        var nextStep = this.nextStep(step),
            state = this.clearStepAndStepsAfter(nextStep);

        state.step = nextStep;
        state.stepUpTo = nextStep;

        return state;
    };

    nextStep = (step) => {
        var steps = this.steps(),
            givenStepIndex = steps.indexOf(step);

        return steps[givenStepIndex + 1];
    };

    clearStepAndStepsAfter = (step) => {
        var state = {},
            steps = this.steps(),
            stepIndex = steps.indexOf(step),
            lastStepIndex = steps.length - 1;

        while (stepIndex <= lastStepIndex) {
            this.clearStep(steps[stepIndex], state);
            stepIndex += 1;
        }

        return state;
    };

    isSubmissionStep = (step) => {
        return step === 'commentEntry' && this.hasSelectedAddress();
    };

    hasSelectedAddress = () => {
        return !_.isNull(this.state.address) && !_.isUndefined(this.state.address)
    }

    handleBackToList = (e) => {
        e.preventDefault();
        this.changeStep('cleanerSelection');
    }

    changeStep = (step) => {
        var newState = {step: step};
        if (step === 'dateslotSelection') {
            this.clearStep(step, newState);
        }
        if (step === 'locationProductCustomization' || step === 'cleanerSelection') {
            this.clearStep(step, newState);
        }
        if (step === 'commentEntry') {
            this.clearStep(step, newState);
        }
        this.setState(newState);
    };

    // --------------- LOCATION LOGIC --------------------------

    locationProductCustomizer = () => {
        return (
            <FunnelLocationProductCustomizer service={this.props.serviceType}
                                             onSubmit={this.setLocationAndProduct}
                                             initiallySelectedCityId={this.selectedCityId()}
                                             initiallySelectedDistrictId={this.selectedDistrictId()}
                                             initiallySelectedAddressId={this.state.address ? this.state.address.id : null}
                                             initiallySelectedProductOptions={this.state.selectedProductOptions}
                                             addresses={this.state.addresses}
                                             cities={this.props.cities}
                                             districts={this.props.districts}
                                             isMember={this.props.isMember}
            />
        );
    }

    findCity = (cityId) => {
        return this.props.cities.find(city => city.id === cityId);
    };

    findDistrictInCity = (cityId, districtId) => {
        return this.findCity(cityId).districts.find(district => district.id === districtId);
    };

    selectPostCode = (postCode, city, district) => {
    };

    selectCityAndDistrict = (city, district) => {
        var nextState = this.nextStepState(this.state.step);
        nextState.location = {city: city, district: district};
        this.setState(nextState);

        Cookies.set('district_id', district.id);
        Analytics.sendAddPartialAddress(this.serviceType());
    };

    selectedCity = () => {
        return this.state.location.city;
    };

    selectedCityId = () => {
        if (this.selectedCity()) {
            return this.state.location.city.id;
        } else {
            return null;
        }
    };

    activeDistrict = () => {
        if (this.state.address && this.state.address.district_id) {
            return this.findDistrictInCity(this.state.address.city_id, this.state.address.district_id);
        } else {
            return this.state.location.district;
        }

    };

    activeDistrictId = () => {
        if (this.activeDistrict()) {
            return this.activeDistrict().id;
        } else {
            return null;
        }
    };


    selectedDistrict = () => {
        return this.state.location.district;
    };

    selectedDistrictId = () => {
        if (this.selectedDistrict())
            return this.selectedDistrict().id
        else
            return null;

    };

    selectedAddressId = () => {
        if (this.hasSelectedAddress())
            return this.state.address.id;
        else
            return null;
    }

    // --------------- PRODUCT LOGIC --------------------------

    setLocationAndProduct = (selected) => {
        var nextState = this.nextStepState(this.state.step);
        var selectedDistrictId = null;
        var selectedAddressId = null;

        if (selected['address']) {
            nextState['address'] = selected['address'];
            nextState.location = {city: null, district: null};
            selectedDistrictId = selected['address'].district_id;
            selectedAddressId = selected['address'].id;
        } else if (selected['district'] && selected['city']) {
            nextState.address = null;
            nextState.location = {city: selected['city'], district: selected['district']};
            selectedDistrictId = selected['district'].id
        } else {
            alert("No Location Selected!")
            return;
        }

        // I want to keep selection from extra services, but I want to get prices just for the main service(
        nextState['selectedProductOptions'] = {...this.state.selectedProductOptions, ...selected['productOptions']};

        this.props.executeSlowOperation(function () {
            return this.fetchAttributes(nextState['selectedProductOptions'], selectedAddressId, selectedDistrictId)
                .done(function (attributes) {
                    this.setAttributesToState(nextState, attributes);

                    this.setState(nextState);
                }.bind(this));
        }.bind(this));
    };

    // take the attributes returned from fetch attributes and assigns them to newState
    setAttributesToState(nextState, attributes) {
        nextState.cleaners = attributes.cleaners;
        nextState.weeksAheadLimit = attributes.weeksAheadLimit;
        nextState.hasWorkingCleaners = attributes.hasWorkingCleaners;
        nextState.selectedCleanerId = null;
        nextState.companyListSelectionData = {};
        nextState.companyListSelectionData.sortingCriteria = 0;
        nextState.companyListSelectionData.selectedRank = null;
        nextState.companyListSelectionData.promoted = false;
    }

    fetchAttributes = (product, addressId, districtId) => {
        var params = {};
        _.extend(params, {
            product_data: product,
            loyalty_discount_reference_code: this.props.loyaltyDiscountReferenceCode
        });

        params.address_data = {};
        if (districtId) {
            params.address_data = {district_id: districtId};
        }
        if (addressId) {
            params.address_data = {id: addressId};
        }

        return $.ajax({
            type: "GET",
            url: this.attributesPath(),
            dataType: 'json',
            contentType: 'application/json',
            data: params,
            statusCode: {
                500: function () {
                    window.location.href = '/500';
                }
            }
        });
    };

    // ----------------------- COMPANIES LOGIC ---------------------------

    isCurrentAddressAMember = () => {
        return this.props.isMember;
    }

    showAddressMembershipOption = () => {
        return !this.isCurrentAddressAMember();
    }

    activeDiscountPercent = () => {
        return 0;
    }

    selectCleaner = (cleanerId, rank, sortingCriteria, showedPromoted) => {
        let cleaner = this.findCleaner(cleanerId);
        amplitude.track('click housemaid funnel select cleaner', {
            date_filter: this.state.filter,
            sorting_criteria: sortingCriteria === 1 ? "rank" : "price",
            entity_type: cleaner.entityType,
            has_video: !!cleaner.youtubeVideoId,
            rank: rank,
            selected_price: cleaner.ranking.price,
            team_count: _.filter(this.state.cleaners, function(cleaner){ return cleaner.entityType == 'team_entity' }).length,
            person_count: _.filter(this.state.cleaners, function(cleaner){ return cleaner.entityType == 'person_entity' }).length,
        });

        this.setState({...this.nextStepState(this.state.step), ...this.newStateAfterCleanerSelect(cleanerId, rank, sortingCriteria, showedPromoted)});
        window.scrollTo(0, 0);
    };

    handleFilterSelect = (newFilter, filterDate) => {
        this.setState({filter: newFilter, filterDate: filterDate}, function () {
            var filterDateString = this.filterDateString();
            if (newFilter === 'date' && !this.state.filterDateCache[filterDateString]) {
                this.props.executeSlowOperation(function () {
                    return this.fetchCleanersForDate(this.state.selectedProductOptions, this.activeDistrictId(), filterDateString)
                        .done(function (cleanerIds) {
                            let toMerge = {};
                            toMerge[filterDateString] = cleanerIds;
                            this.setState({filterDateCache: {...this.state.filterDateCache, ...toMerge}});
                        }.bind(this));
                }.bind(this));
            }
        }.bind(this));
    }

    filteredCleaners = () => {
        var filteredIds = this.filteredCleanerIds();
        var result;
        if (filteredIds) {
            result =  _.filter(this.state.cleaners, function (cleaner) {
                return _.contains(filteredIds, cleaner.id);
            });
        } else {
            result = this.state.cleaners;
        }

        return result;
    };

    cleanerIds = () => {
        return _.map(this.state.cleaners, function(cleaner){ return cleaner.id });
    }

    filteredCleanerIds = () => {
        if (this.state.filter && this.state.filter !== 'all') {
            if (this.state.filter === 'recent') {
                return this.cleanerIdsAvailableSoon();
            } else if (this.state.filter === 'date') {
                return this.state.filterDateCache[this.filterDateString()];
            }
        } else {
            return null;
        }
    };

    filterDateString = () => {
        return this.state.filterDate ? moment(this.state.filterDate).format('YYYY-MM-DD') : null;
    };

    hasSelectedFilterDate = () => {
        return !!this.filterDateString();
    }


    fetchCleanersForDate = (product, districtId, date) => {
        var params = {};
        _.extend(params, {
            product_data: product,
            dateslot_data: {date: date, starts_at: '00:00', ends_at: '23:59'}
        });

        if (districtId) {
            params.address_data = {district_id: districtId};
        }

        return $.ajax({
            type: "GET",
            url: this.cleanersForDatePath(),
            dataType: 'json',
            contentType: 'application/json',
            data: params,
            statusCode: {
                500: function () {
                    window.location.href = '/500';
                }
            }
        });
    };


    cleanerIdsAvailableSoon = () => {
        return _.map(_.filter(this.state.cleaners, function (cleaner) {
            return cleaner.available_soon;
        }), function (cleaner) {
            return cleaner.id
        });
    }

    newStateAfterCleanerSelect = (cleanerId, rank, sortingCriteria, showedPromoted) => {
        //filter out all selected product options with no price
        var cleaner = this.findCleaner(cleanerId);
        var newState = {
            selectedCleanerId: cleanerId,
            extraServicesStepSeen: false,
            companyListSelectionData: {
                selectedRank: rank,
                sortingCriteria: sortingCriteria,
                possibleChoices: this.state.cleaners.length,
                promoted: showedPromoted && cleaner.promoted
            },
            selectedProductOptions: {...this.state.selectedProductOptions, ...{visit_duration: cleaner.atomicSlotDuration}}
        };
        return newState;
    };


    // ----------------------- ADDRESS LOGIC ---------------------------

    addressSelector = () => {
        return (
            <FunnelBookingAddressSelector
                location={this.state.location}
                cities={this.props.cities}
                districts={this.props.districts}
                addresses={this.state.addresses}

                selectedCompanyName={this.selectedCleaner().name}

                selectedDistrict={this.selectedDistrict()}
                selectedCity={this.selectedCity()}

                onSelect={this.selectAddress}
                onDestroy={this.removeAddress}

                executeSlowOperation={this.props.executeSlowOperation}
                amplitudeContext={'housemaid funnel'}
            />
        );
    };

    selectAddress = (address) => {
        var newAddress = !_.some(this.state.addresses, function (existingAddress) {
                return existingAddress.id === address.id;
            }),
            newState = {
                address: address,
                hasCat: address.cat,
                hasDog: address.dog
            };

        if (newAddress) {
            newState.addresses = this.state.addresses.concat(address);
        }

        this.setState(newState, function () {
            if (this.isSubmissionStep(this.state.step)) {
                this.submit();
            }
        }.bind(this));
    };

    removeAddress = (address) => {
        var newState = {};

        if (address.id === this.state.address.id) {
            newState = this.clearStepAndStepsAfter(this.state.step);
        }

        newState.addresses = _.reject(this.state.addresses, function (existingAddress) {
            return existingAddress.id === address.id;
        });

        this.setState(newState);
    };

    // ----------------------- DATESLOT LOGIC ---------------------------

    updateDateslots = (dateslots) => {
        var newState = this.clearStepAndStepsAfter(this.nextStep(this.state.step));
        newState.stepUpTo = this.state.step;
        newState.selectedDateslots = dateslots;
        this.setState(newState);
    };

    selectedUnavailableDateslot = () => {
        return this.state.selectedDateslots[0] !== undefined && this.state.selectedDateslots[0].unavailable;
    };

    handleDateSlotSelectorSubmit = () => {
        this.setState(this.nextStepState(this.state.step));
    };

    afterAuthFunnelPath = () => {
        return this.props.afterAuthenticationUrl + "?" + $.param({after_auth: true});
    };

    updateFunnelAfterAuth = () => {
        return this.props.executeSlowOperation(function () {
            return this.fetchAttributes(this.state.selectedProductOptions, this.selectedAddressId(), this.activeDistrictId())
                .done(function (attributes) {
                    this.setState({addresses: attributes.addresses});
                }.bind(this));
        }.bind(this));
    };

    // ----------------------- SUBMISSION LOGIC ---------------------------

    funnelData = () => {
        return {
            product_data: this.state.selectedProductOptions,
            address_data: this.state.address,
            cleaner_id: this.state.selectedCleanerId,
            schedule_data: {
                dateslots: _.map(this.state.selectedDateslots, function (dateslot) {
                    return ScheduleUtils.sanitizeDateslot(dateslot);
                })
            },
            company_list_selection_data: {
                sorting_criteria: this.state.companyListSelectionData.sortingCriteria,
                selected_rank: this.state.companyListSelectionData.selectedRank,
                possible_choices: this.state.companyListSelectionData.possibleChoices,
                promoted: this.state.companyListSelectionData.promoted,
            },
            filter: this.state.filter,
            filter_date: this.filterDateString(),
            buy_address_membership: this.state.buyAddressMembership,
        };
    };

    submit = () => {
        this.props.executeSlowOperation(function () {
            var promise = $.ajax({
                type: this.props.httpVerb,
                url: this.props.submitPath,
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(this.funnelData()),
                statusCode: {
                    500: function () {
                        window.location.href = '/500';
                    }
                }
            });

            var deferred = $.Deferred();
            deferred.promise();

            promise.done(function (result) {
                this.props.showScreenLoader();
                if (result.success) {
                    if (window.location.pathname === result.redirect_path) {
                        // Reloading preserves the scroll and we want
                        // the payment page to be always scrolled to
                        // the top so we scroll it before the reload.
                        window.scrollTo(0, 0);
                        window.location.reload();
                    } else {
                        window.location.assign(result.redirect_path);
                    }
                } else {
                    alert(result.error);
                    this.changeStepAndClear('cleanerSelection', function () {
                        this.setState({cleanerAndSlotSelectorVersion: this.state.cleanerAndSlotSelectorVersion + 1});
                    }.bind(this));
                    deferred.resolve();
                }
            }.bind(this)).fail(function () {
                alert(i18n.t('funnel.time-form.errors.general-order-create'));
                this.changeStepAndClear(this.dateslotSelectionStepName());
                deferred.resolve();
            }.bind(this));

            return deferred;
        }.bind(this));
    };


    // ----------------------- MISC ---------------------------


    openCleanerProfileDialog = (cleanerId) => {
        this.setState({cleanerProfileOpen: true, cleanerProfileCleanerId: cleanerId});
    }

    closeCleanerProfileDialog = () => {
        this.setState({cleanerProfileOpen: false});
    }

    ensureAuthentication = () => {
        if (this.props.authenticationStatus === 'unauthenticated') {
            FunnelStatePersistance.persistState(_.extend({}, this.state));
            this.props.authenticationPrompt(function () {
                this.updateFunnelAfterAuth();
            }.bind(this), this.afterAuthFunnelPath());
        } else {
            if (this.isSubmissionStep(this.state.step)) {
                this.submit();
            }
        }
    };


    cityAndDistrictDescription = () => {
        var district = this.selectedDistrict();
        var city = this.selectedCity();

        if (city && district)
            return city.name + ", " + district.name;
        else
            return null;
    };

    onCommentSubmit = (comment) => {
        var updatedOptions = this.state.selectedProductOptions;
        updatedOptions['comment'] = comment;
        this.setState({selectedProductOptions: updatedOptions, commentStepSeen: true}, function () {
            this.ensureAuthentication();
        }.bind(this));
    };


    breadcrumbsProps = () => {
        return {
            step: this.state.step,
            stepUpTo: this.state.stepUpTo,
            product: this.state.selectedProductOptions,
            cityAndDistrict: this.cityAndDistrictDescription(),
            address: this.state.address,
            // slotsInfo: SLOTS_INFO,
            onStepChange: this.changeStep,
            onStepChangeMobile: this.changeStepAndClear,
            dateslots: this.state.selectedDateslots,
            totalPrice: this.totalPrice(),
            priceType: this.props.priceType,
            prepaidEventsCount: this.props.prepaidEventsCount,
            steps: this.steps(),
            cleaner: this.selectedCleaner(),
            serviceTitle: this.productTitle(),
            extraServicePrice: this.state.extraServicePrice,
            addressMembershipPercentDiscount: this.props.addressMembershipPercentDiscount,
            buyAddressMembership: this.state.buyAddressMembership,
            onlyPaymentAvailable: this.props.onlyPaymentAvailable
        };
    };

    render() {
        return (
            <div>
                <ResponsiveBreadcrumbs {...this.breadcrumbsProps()} />
                <div className="funnel-body">
                    {this.stepBody()}
                </div>
                <FunnelCleanerProfileModal cleaner={this.findCleaner(this.state.cleanerProfileCleanerId)}
                                           onClose={this.closeCleanerProfileDialog}
                                           isOpen={this.state.cleanerProfileOpen}
                                           isCurrentAddressMember={this.isCurrentAddressAMember()}
                                           onAddToFavoritesClicked={this.profileModalAddToFavoritesClicked}
                                           isLoggedin={this.props.isLoggedin}
                />

                <MicroWaitingListModal isLoggedin={this.props.isLoggedin}
                                       selectedAddressId={this.selectedAddressId()}
                                       selectedDistrictId={this.selectedDistrictId()}
                                       selectedProduct={this.state.selectedProductOptions.product_type}
                                       onClose={this.closeMicroWaitingListModal}
                                       isOpen={this.state.microWaitingListOpen}
                                       executeSlowOperation={this.props.executeSlowOperation}
                                       onNotifyClick={this.onWaitingListNotifyClick} />
            </div>
        );
    }

}


HousemaidFunnel = withLoader(HousemaidFunnel);
HousemaidFunnel = withPhoneCollector(HousemaidFunnel);
HousemaidFunnel = withHistory(HousemaidFunnel);

class AuthenticatingHousemaidFunnel extends React.Component {
    render() {
        return (
            <Authenticator status={this.props.componentProps.authenticationStatus}
                           omniauthError={this.props.componentProps.omniauthError}
                           component={HousemaidFunnel}
                           {...this.props} />
        );
    }
}

export default AuthenticatingHousemaidFunnel;
