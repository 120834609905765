import React from 'react';
import PropTypes from 'prop-types';


class FunnelBreadcrumbsCityAndDistrictInfo extends React.Component {
    static contextTypes = {
        locationStrategy: PropTypes.string
    };

    static propTypes = {
        cityAndDistrict: PropTypes.string
    };

    content = () => {
        if(this.props.cityAndDistrict)
            return this.props.cityAndDistrict;
        else if(this.context.locationStrategy == 'district') {
            return i18n.t('funnel.breadcrumbs.district.content');
        }
        else {
            return "Enter postcode";
        }
    };

    render() {
        return (
            <div>
              <div className="little-darkblue-title">
                {i18n.t('funnel.breadcrumbs.district.title')}
              </div>
              <p>
                {this.content()}
              </p>
            </div>
        );
    }
};

export default FunnelBreadcrumbsCityAndDistrictInfo;
