import React from 'react';


class FunnelBreadcrumbsExtrasInfo extends React.Component {
    static propTypes = {
    };

    content = () => {
        return i18n.t("funnel.breadcrumbs.extras-selection.content");
    };

    title = () => {
        return i18n.t("funnel.breadcrumbs.extras-selection.title");
    }

    render() {
        return (
            <div>
                <div className="little-darkblue-title">
                    {this.title()}
                </div>
                <p>
                    {this.content()}
                </p>
            </div>
        );
    }
};

export default FunnelBreadcrumbsExtrasInfo;
