import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import SpinnerComponent from "@components/common/spinner";
import Modal from '@components/vendor/modal';

import AuthenticatorOptions from '@components/common/authenticator/options';
import $ from 'jquery';

class Authenticator extends React.Component {
    static propTypes = {
        status: PropTypes.string,
        omniauthError: PropTypes.bool,
        component: PropTypes.func,
        componentProps: PropTypes.object,
        facebookAuthUrl: PropTypes.string,
        googleAuthUrl: PropTypes.string,
        afterAuthenticationUrl: PropTypes.string
    };

    state = {
        componentProps: this.props.componentProps,

        status: this.props.status || 'unauthenticated',
        afterAuthenticationCallback: null,
        omniauthRedirectPath: "",

        authenticationModalIsOpen: false,
        loginErrors: {},

        registrationErrors: {},
        loading: false,

        resetPasswordErrors: null,
        resetPasswordSent: false

    };

    componentDidMount() {
        var onHistoryStateChange = function(e) {
            this.setState({ authenticationModalIsOpen: false});
        }.bind(this);

        this.setState({ onHistoryStateChange: onHistoryStateChange });
        window.addEventListener('popstate', onHistoryStateChange);
        amplitude.track('see page - authenticator');
    }

    componentWillUnmount() {
        if(this.state.onHistoryStateChange) {
            window.removeEventListener('popstate', this.state.onHistoryStateChange);
        }
    }

    executeSlowOperation = (operation) => {
        this.setState({loading: true});

        operation().done(function() {
            this.setState({loading: false});
        }.bind(this));
    };

    showAuthenticationModal = (callback, omniauthRedirectPath) => {
        this.setState({ authenticationModalIsOpen: true,
                        afterAuthenticationCallback: callback,
                        omniauthRedirectPath: omniauthRedirectPath  });
    };

    handleLogin = (email, password) => {
        this.executeSlowOperation(function() {
            var promise = $.post(PathWithLocale.build('/funnel/login/'), { email: email, password: password });

            promise.done(function(data) {
                if(data.success) {
                    var afterAuthenticationCallback = this.state.afterAuthenticationCallback;
                    // var funnelParams = _.clone(data.funnel_params);
                    // funnelParams.withDistrictStep = this.props.componentProps.withDistrictStep;

                    this.setState(function(prevState, props){
                        return { authenticationModalIsOpen: false,
                                 afterAuthenticationCallback: null,
                                 componentProps: _.extend({}, prevState.componentProps, { user: data.user }),
                                 status: 'authenticated'};
                    }, function() {
                        afterAuthenticationCallback();
                    });
                    Analytics.sendLoginComplete();
                }
                else {
                    this.setState({ loginErrors: data.errors });
                }
            }.bind(this));

            return promise;
        }.bind(this));
    };

    handleRegistration = (name, email, phone, password, passwordConfirmation) => {
        this.executeSlowOperation(function() {
            var promise = $.post(PathWithLocale.build('/funnel/register/'), { name: name, email: email, phone: phone, password: password,
                                                                         password_confirmation: passwordConfirmation });
            promise.done(function(data) {
                if(data.success) {
                    var afterAuthenticationCallback = this.state.afterAuthenticationCallback;
                    // var funnelParams = _.clone(data.funnel_params);
                    // funnelParams.withDistrictStep = this.props.componentProps.withDistrictStep;

                    this.setState(function(prevState, props) {
                        return {
                            authenticationModalIsOpen: false,
                            afterAuthenticationCallback: null,
                            componentProps: _.extend({}, prevState.componentProps, { user: data.user }),
                            status: 'authenticated'
                        };
                    }, function() {
                        afterAuthenticationCallback();
                    });
                }
                else {
                    this.setState({ registrationErrors: data.errors });
                }
                Analytics.sendRegistrationComplete();
            }.bind(this));

            return promise;
        }.bind(this));
    };

    notifyDataLayer = () => {
      if(PRODUCTION){
          Analytics.sendDataLayerSignal('Registration form', 'Registration')
      }
    };

    closeModal = () => {
        this.setState({
            authenticationModalIsOpen: false,
            afterAuthenticationCallback: null
        });
    };

    onResetPasswordSubmit = (email) => {
        this.executeSlowOperation(function() {
            var promise = $.ajax({
                type: "POST",
                url: PathHelper('send_password_recovery_email_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify({ email: email }),
            });

            promise.done(function(data) {
                if(data.success) {
                    this.setState({resetPasswordSent: true})
                }
                else {
                    this.setState({ resetPasswordErrors: data.error });
                }
            }.bind(this));

            return promise;
        }.bind(this));
    }

    render() {
        var Component = this.props.component;
        return (
            <div>
              <SpinnerComponent loading={this.state.loading} />
              <Modal isOpen={this.state.authenticationModalIsOpen}>
                <div className="custom-modal-header blank-header">
                  <div className="close-button" onClick={this.closeModal}></div>
                </div>

                <div className="custom-modal-content">
                  <AuthenticatorOptions onLoginSubmit={this.handleLogin}
                                        onRegisterClick={this.notifyDataLayer}
                                        loginErrors={this.state.loginErrors}

                                        onRegistrationSubmit={this.handleRegistration}
                                        registrationErrors={this.state.registrationErrors}

                                        omniauthRedirectPath={this.state.omniauthRedirectPath}
                                        omniauthError={this.props.omniauthError}

                                        onResetPasswordSubmit={this.onResetPasswordSubmit}
                                        resetPasswordErrors={this.state.resetPasswordErrors}
                                        resetPasswordSent={this.state.resetPasswordSent}

                                        facebookAuthUrl={this.props.facebookAuthUrl}
                                        googleAuthUrl={this.props.googleAuthUrl}/>
                </div>
              </Modal>

              <Component {...this.state.componentProps}
                         authenticationPrompt={this.showAuthenticationModal}
                         authenticationStatus={this.state.status}
                         afterAuthenticationUrl={this.props.afterAuthenticationUrl}
                         />
            </div>
        );
    }
}

export default Authenticator;
