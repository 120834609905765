import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class NumberField extends React.Component {
    static propTypes = {
        value: PropTypes.number,
        label: PropTypes.string,
        onChange: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number,
        disabled: PropTypes.bool,
        price: PropTypes.string,
    };

    static defaultProps = {
        min: 0,
        max: 100,
        disabled: false,
    };

    increment = () => {
        if (this.incrementIsAllowed()) {
            this.props.onChange(this.props.value + 1);
        }
    };

    decrement = () => {
        if (this.decrementIsAllowed()) {
            this.props.onChange(this.props.value - 1);
        }
    };

    incrementIsAllowed = () => {
        return !this.props.disabled && this.props.value < this.props.max;
    };

    decrementIsAllowed = () => {
        return !this.props.disabled && this.props.value > this.props.min;
    };

    render() {
        return (
            <div className={'number-field-container'}>
                <div className={classNames({'number-field': true, disabled: this.props.disabled, active: this.props.value > 0})}>
                    <div className="number-field-row">
                        <span className={classNames({control: true, disabled: !this.decrementIsAllowed()})}
                              onClick={this.decrement}> − </span>
                        <div className="items-count"> {this.props.label} &nbsp;
                            {this.props.price && <span className={'price-span'}>({this.props.price})</span>}
                        </div>
                        <span className={classNames({control: true, disabled: !this.incrementIsAllowed()})}
                              onClick={this.increment}> + </span>
                    </div>
                </div>
                {this.props.extraInformation &&
                <div className="extra-info-string">
                    {this.props.extraInformation}
                </div>}
            </div>
        );
    }
}

export default NumberField;