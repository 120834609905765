import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import CleanerNameWithCategory from '@components/common/cleaner_name_with_category'
import DateService from '@services/date_service';
import DateSlotService from '@services/date_slot_service';

class SubscriptionEditFormSubscriptionDetails extends React.Component {
    static propTypes = {
        product: PropTypes.shape({
            attributesDescription: PropTypes.string.isRequired,
            weekdays: PropTypes.string.isRequired
        }).isRequired,

        cleaner: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string
        }),

        dateslots: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            slot: PropTypes.number
        }))
    };

    dateslotsChanged = () => {
        return this.props.dateslots.length > 0;
    };

    weekdaysDescription = () => {
        if(this.dateslotsChanged()) {
            return DateSlotService.formatSubscriptionDateslots(this.props.dateslots, SLOTS_INFO);
        }
        else {
            return this.props.product.weekdays;
        }
    };

    render() {
        var changeFrom = null;

        if(this.dateslotsChanged()) {
            var dateslot = _.first(this.props.dateslots);

            changeFrom = (
                <div>
                  {i18n.t('funnel.breadcrumbs.details.edit-applies-from', { date: DateService.formatDate(dateslot.date)} )}
                </div>
            );
        }

        return (
            <div className="subscription-details">
              <div className="little-darkblue-title">
                {i18n.t('funnel.order-type-form.subscription')}
              </div>

              <div className="weekdays">
                {this.weekdaysDescription()}
              </div>

              {this.props.cleaner && this.props.cleaner.id && <CleanerNameWithCategory {...this.props.cleaner}/>}

              <div className="change-from">
                {changeFrom}
              </div>
            </div>
        );
    }
};

export default SubscriptionEditFormSubscriptionDetails;
