import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import _ from 'underscore'

import CitySelector from '@components/common/address_selector/city_selector';
import DistrictSelector from '@components/common/address_selector/district_selector';

class FunnelBookingAddressForm extends React.Component {
    static propTypes = {
        selectedCity: PropTypes.object.isRequired,
        selectedDistrict: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    cityReadOnly = () => {
        return true;
    }

    districtReadOnly = () => {
        return true;
    }

    cityOptions = () => {
        return [{value: this.props.selectedCity.id, label: this.props.selectedCity.name,
            alternative_label: this.props.selectedCity.alternative_name}]
    }

    districtOptions = () => {
        return [{value: this.props.selectedDistrict.id, label: this.props.selectedDistrict.name,
                        alternative_label: this.props.selectedDistrict.alternative_name}]
    }

    handleSubmit = (event) => {
        event.preventDefault();
        var address = {
            district_id: this.props.selectedDistrict.id,
            description: this.refs.description.value,
            cat: this.getCatCheckboxState(),
            dog: this.getDogCheckboxState()
        };

        _.extend(address, {
            street: this.refs.street.value,
            number: this.refs.number.value,
        });
        if (ADDRESS_FIELDS['block']['present']) {
            _.extend(address, {
                block: this.refs.block.value,
            });
        }
        if (ADDRESS_FIELDS['entrance']['present']) {
            _.extend(address, {
                entrance: this.refs.entrance.value,
            });
        }
        if (ADDRESS_FIELDS['postcode']['present']) {
            _.extend(address, {
                postcode: this.refs.postcode.value,
            });
        }
        if (ADDRESS_FIELDS['floor']['present']) {
            _.extend(address, {
                floor: this.refs.floor.value,
            });
        }
        if (ADDRESS_FIELDS['apartment']['present']) {
            _.extend(address, {
                apartment: this.refs.apartment.value,
            });
        }
        this.props.onSubmit(address);
    };

    getCatCheckboxState = () => {
        return this.refs.cat ? this.refs.cat.checked : false;
    };

    getDogCheckboxState = () => {
        return this.refs.dog ? this.refs.dog.checked : false;
    };

    renderSubmitButton = () => {
        return (
            <button onClick={this.handleSubmit} type="submit"
                    className={'btn btn-primary'}>{i18n.t('funnel.address-form.save-address')}</button>
        );
    };


    selectedCityId = () => {
        var selectedCity = this.selectedCity();
        if (selectedCity) {
            return selectedCity.id;
        } else {
            return null;
        }
    };

    render() {
        return (
            <div className="address-fields">
                <div className="row">
                    <div className={classNames({
                        "form-group": true,
                        "col-sm-6": true,
                    })}>
                        <label>{i18n.t('helpers.label.funnel_form.city_id')}</label>
                        <CitySelector readOnly={true} cities={this.cityOptions()}
                                      selected={this.props.selectedCity.id}
                                      hasError={false}/>
                    </div>

                    <div className={classNames({
                        "form-group": true,
                        "col-sm-6": true
                    })}>
                        <label>{i18n.t('helpers.label.funnel_form.district_id')}</label>
                        <DistrictSelector readOnly={true} districts={this.districtOptions()}
                                          selected={this.props.selectedDistrict.id}
                                          hasError={false}/>
                    </div>
                </div>

                <div className="address-form row">
                    <div className="form-group col-xs-9  address">
                        <label>{i18n.t('helpers.label.funnel_form.address')}</label>
                        <input name="street" ref="street" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.street')}/>
                    </div>

                    <div className="form-group col-xs-3 col-sm-3 with-offset">
                        <label className={'hidden-xs'}>&nbsp;</label>
                        <input name="number" ref="number" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.number')}/>
                    </div>

                    {ADDRESS_FIELDS['postcode']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['postcode']['css-classes']}>
                        <input name="block" ref="postcode" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.postcode')}/>
                    </div>}

                    {ADDRESS_FIELDS['block']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['block']['css-classes']}>
                        <input name="block" ref="block" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.block')}/>
                    </div>}

                    {ADDRESS_FIELDS['entrance']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['entrance']['css-classes']}>
                        <input name="entrance" ref="entrance" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.entrance')}/>
                    </div>}

                    {ADDRESS_FIELDS['floor']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['floor']['css-classes']}>
                        <input name="floor" ref="floor" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.floor')}/>
                    </div>}

                    {ADDRESS_FIELDS['apartment']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['apartment']['css-classes']}>
                        <input name="apartment" ref="apartment" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.apartment')}/>
                    </div>}
                </div>


                <div className="row">
                    <div className="form-group col-sm-12">
                        <label>{i18n.t('helpers.label.funnel_form.description')}</label>
                        <textarea name="description" ref="description" className="form-control input-lg">

                  </textarea>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-2 form-group">
                        <label>
                            <input type="checkbox" ref="cat"
                                   name="cat"/> {i18n.t('helpers.label.funnel_form.i_have_a_cat')}
                        </label>
                    </div>
                    <div className="col-sm-2 form-group">
                        <label>
                            <input type="checkbox" ref="dog"
                                   name="dog"/> {i18n.t('helpers.label.funnel_form.i_have_a_dog')}
                        </label>
                    </div>
                </div>

                {this.renderSubmitButton()}
            </div>
        );
    }
};

export default FunnelBookingAddressForm;
