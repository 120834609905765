import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'underscore';
import classNames from "classnames";
import SlotSelector from '@components/common/cleaner_and_slot_selector/slot_selector';
import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';
import withLoader from '@components/common/with_loader_decorator';
import SpinnerComponent from "@components/common/spinner";


class EventDateslotEditForm extends React.Component {
    static propTypes = {
        submitPath: PropTypes.string,
        initialWeek: PropTypes.number,
        selectedDateslot: PropTypes.object,
        eventId: PropTypes.number,
        weeksAheadLimit: PropTypes.number,
    };

    state = {
        schedule: [],
        selectedDateslot: this.props.selectedDateslot || null,
        week: this.props.initialWeek || 0,
        showSpinner: false,
    };

    componentDidMount() {
        this.updateSchedule(this.state.week);
    }

    updateSchedule = (week) => {
        return this.props.executeSlowOperation(function () {
            var promise = this.fetchSchedule(week);

            promise.done(function (schedule) {
                this.setState({schedule: schedule});
            }.bind(this));

            return promise;
        }.bind(this));
    };

    queryUrl = () => {
        return PathHelper('clients_dashboard_event_dateslot_edit_availabilities_path');
    };

    fetchSchedule = (week) => {
        var queryParams = {week_offset: week, event_id: this.props.eventId};

        return $.get(this.queryUrl() + "?" + $.param(queryParams));
    };


    selectedDateslots = () => {
        return this.state.selectedDateslot === null ? [] : [this.state.selectedDateslot];
    };

    handleDateSlotToggle = (dateslot) => {
        if (this.state.selectedDateslot === null) {
            this.setState({selectedDateslot: dateslot})
        } else if (ScheduleUtils.dateslotsEqual(dateslot, this.state.selectedDateslot)) {
            this.setState({selectedDateslot: null})
        } else {
            this.setState({selectedDateslot: dateslot})
        }
    };

    getNextWeekHandler = () => {
        if (this.state.week < this.props.weeksAheadLimit) {
            return this.incrementWeek;
        } else {
            return null;
        }
    };

    getPrevWeekHandler = () => {
        if (this.state.week > 0) {
            return this.decrementWeek;
        } else {
            return null;
        }
    };

    incrementWeek = () => {
        var new_week = this.state.week + 1
        this.setState({week: new_week});
        this.updateSchedule(new_week)
    };

    decrementWeek = () => {
        var new_week = this.state.week - 1
        this.setState({week: this.state.week - 1});
        this.updateSchedule(new_week)
    };

    primaryButtonClasses = () => {
        return "btn btn-primary " + classNames({
            active: this.state.selectedDateslot !== null
        });
    };

    handleSubmit = () => {
        if (this.state.selectedDateslot === null) return null
        this.setState({showSpinner: true});
        $('#submit-form').submit();
    };

    handleCancel = () => {
        this.setState({showSpinner: true});
        window.location.href = PathHelper('dashboard_path');
    };

    render() {
        if (this.state.schedule.length === 0) return null;

        return (
            <div>
                <SpinnerComponent loading={this.state.showSpinner}/>
                <div className="clearfix">
                    <SlotSelector
                        schedule={this.state.schedule}
                        selectedDateslots={this.selectedDateslots()}
                        onButtonToggle={this.handleDateSlotToggle}
                        onPrevWeek={this.getPrevWeekHandler()}
                        onNextWeek={this.getNextWeekHandler()}
                    />
                </div>
                <div className="edit_event_dateslot_button_container">
                    <button onClick={this.handleCancel} className='btn btn-link'>
                        {i18n.t('form.buttons.cancel')}
                    </button>
                    <button onClick={this.handleSubmit} className={this.primaryButtonClasses()}>
                        {i18n.t('form.buttons.update')}
                    </button>
                </div>
                {this.state.selectedDateslot &&
                <form action={PathHelper('clients_dashboard_event_dateslot_edits_path')} method='post' id='submit-form'>
                    <input type="hidden" name='event_id' value={this.props.eventId}/>
                    <input type="hidden" name='cleaner_id' value={this.state.selectedDateslot.cleaners[0].id}/>
                    <input type="hidden" name='date' value={this.state.selectedDateslot.date}/>
                    <input type="hidden" name='starts_at' value={this.state.selectedDateslot.starts_at}/>
                    <input type="hidden" name='ends_at' value={this.state.selectedDateslot.ends_at}/>
                    <input type="hidden" name='week_offset' value={this.state.week}/>
                </form>}

            </div>
        );
    }

}

EventDateslotEditForm = withLoader(EventDateslotEditForm);

export default EventDateslotEditForm