import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import storage from 'local-storage-fallback'
import moment from 'moment';
import classNames from 'classnames';

import AddressDistrictSelector from '@components/selectors/address_district_selector';
import ProductCustomizer from '@components/product_customizers/product_customizer';
import DateFilterSelector from '@components/selectors/date_filter_selector';

class ServicePageCustomizer extends React.Component {
    static localStorageKey = 'service-page-product-selection-';

    static propTypes = {
        service: PropTypes.string.isRequired,
        pricelist: PropTypes.PropTypes.object,
        funnelPath: PropTypes.string.isRequired,
        selectedCityId: PropTypes.number,
        selectedDistrictId: PropTypes.number,
        selectedAddressId: PropTypes.number,

        serviceIcon: PropTypes.string,
        serviceHeader: PropTypes.string,
        serviceOneLiner: PropTypes.string,

        addresses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            city_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            supports_service: PropTypes.bool
        })),
        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            districts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                alternative_name: PropTypes.string.isRequired,
            })),
            supports_service: PropTypes.bool,
        })),
        icons: PropTypes.shape({
            glass_doors: PropTypes.string,
            single_windows: PropTypes.string,
            large_windows: PropTypes.string,
            single_french_windows: PropTypes.string,
            large_french_windows: PropTypes.string,
        }),

        clientId: PropTypes.number,
        isMember: PropTypes.bool,

        isExperimental: PropTypes.bool,

        // only pest control
        supportedProductOptions: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {};

    state = {
        selectedProductOptions: {},
        totalPrice: undefined,
        productSelectionValid: false,

        selectedAddressId: null,
        selectedCityId: null,
        selectedDistrictId: null,

        isSubmitted: false,

        renderAddresses: this.props.addresses.length > 0,

        selectedFilter: 'all',
        filterDate: null,
    };

    constructor(props) {
        super(props);
        if(props.selectedCityId && this.findCity(props.selectedCityId)){
            this.state.selectedCityId = props.selectedCityId;
        }

        if(props.selectedAddressId && this.findAddress(props.selectedAddressId)){
            this.state.selectedAddressId = props.selectedAddressId;
        }

        var previouslySelectedCityId = parseInt(storage.getItem('previouslySelectedCityId'));
        var previouslySelectedDistrictId = parseInt(storage.getItem('previouslySelectedDistrictId'));

        if (_.isNull(this.state.selectedCityId) && previouslySelectedCityId) {
            var city = this.findCity(previouslySelectedCityId);
            if (city && city.supports_service) {
                this.state.selectedCityId = previouslySelectedCityId;
                if (previouslySelectedDistrictId && _.contains(_.map(city.districts, (d) => { return d.id;}), previouslySelectedDistrictId)) {
                    this.state.selectedDistrictId = previouslySelectedDistrictId;
                }
            }
        }

        if (_.isNull(this.state.selectedCityId)) {
            var city = this.props.cities[0]
            if (city) {
                this.state.selectedCityId = city.id;
                this.state.selectedDistrictId = city.districts[0] ? city.districts[0].id : null;
            }
        }

        if(this.props.service !== 'housemaid') {
            var previouslySelectedProducts = storage.getItem(ServicePageCustomizer.localStorageKey + this.props.service);
            if (previouslySelectedProducts) {
                this.state.selectedProductOptions = JSON.parse(previouslySelectedProducts);
            }
        }


        if(this.state.renderAddresses && !this.state.selectedAddressId){
            this.state.selectedAddressId = _.last(this.props.addresses).id;
        }
    }

    productOptionsUrlParams = () => {
        return _.map(this.state.selectedProductOptions, function (num, key) {
            return "&product_data[" + key + "]=" + num;
        }).join("");
    };

    selectedCity = () => {
        if (this.state.selectedCityId === null) {
            return null;
        } else {
            return this.findCity(this.state.selectedCityId);
        }
    };

    selectedAddress = () => {
        if (this.state.selectedAddressId) {
            return this.findAddress(this.state.selectedAddressId);
        } else {
            return null;
        }
    };

    selectedLocationCity = () => {
        if (this.state.selectedAddressId) {
            return this.findCity(this.selectedAddress().city_id)
        } else if (this.state.selectedCityId) {
            return this.selectedCity();
        } else {
            return null;
        }
    }

    findCity = (cityId) => {
        return this.props.cities.find(city => city.id === cityId);
    };

    findAddress = (addressId) => {
        return this.props.addresses.find(address => address.id === addressId);
    };

    currentLocationSupportsService = () => {
        var selectedCity = this.selectedLocationCity();
        if (selectedCity) {
            return selectedCity.supports_service;
        } else {
            return true;
        }
    }

    filterParams = () => {
        let params = "&filter=" + this.state.selectedFilter;
        if (this.state.selectedFilter === 'date') {
            params += '&filter_date=' + moment(this.state.filterDate).format('YYYY-MM-DD');
        }
        return params;
    }

    reportToAmplitude = () => {
        if(this.props.service === 'housemaid') {
            amplitude.track('click housemaid funnel see prices', { product_type: this.state.selectedProductOptions['product_type'],
                date_filter: this.state.filterDate || "all"
            });
        }
    }

    handleSubmit = () => {
        if (!this.state.productSelectionValid && this.props.service !== 'housemaid') {
            alert(i18n.t('components.customizers.product-errors.' + this.props.service));
            return;
        }
        if (!this.currentLocationSupportsService()) {
            alert(i18n.t("components.customizers.not-offered-in-city"));
            return;
        }

        if(this.props.service === 'housemaid' && this.state.selectedFilter === 'date'
            && this.state.filterDate > moment().add(14, 'days').toDate() && this.state.selectedProductOptions['product_type'] === 'single_visit') {
            alert(i18n.t("components.customizers.cannot-select-so-far"));
            return;
        }

        if (this.state.selectedAddressId != null) {
            this.setState({isSubmitted: true});
            this.reportToAmplitude();
            window.location.href = this.props.funnelPath + '?address_id=' + this.state.selectedAddressId + this.productOptionsUrlParams() + this.filterParams();;
        } else if (this.state.selectedDistrictId != null) {
            this.setState({isSubmitted: true});
            this.reportToAmplitude();
            window.location.href = this.props.funnelPath + '?district_id=' + this.state.selectedDistrictId + this.productOptionsUrlParams() + this.filterParams();;
        } else if (this.props.addresses.length > 0) {
            alert(i18n.t("components.service-address-selector.no-address-error"));
        }  else {
            alert(i18n.t("components.service-district-selector.no-district-error"));
        }
    };

    // is valid holds if the selection is ok to continue if it's mandatory
    itemsUpdated = (serviceType, selectedProductOptions, isValid, totalPrice) => {
        let updatedItems = {...this.state.selectedProductOptions, ...selectedProductOptions};
        let newState = { selectedProductOptions: updatedItems }
        if(serviceType === this.props.service)
            newState['productSelectionValid'] = isValid;

        this.setState(newState);
        if(this.props.service !== 'housemaid') {
            storage.setItem(ServicePageCustomizer.localStorageKey + serviceType, JSON.stringify(updatedItems))
        }
    };

    handleAddressSelection = (selectedOption) => {
        if (selectedOption.value === -1) {
            this.setState({renderAddresses: false, selectedAddressId: null});
        } else if (this.findAddress(selectedOption.value).supports_service) {
            this.setState({selectedAddressId: selectedOption.value});
            return true;
        } else {
            alert(i18n.t('components.service-address-selector.not-offered-error'));
            return false;
        }
    };

    handleCitySelection = (selectedOption) => {
        this.setState({selectedCityId: selectedOption.value, selectedDistrictId: null});
        storage.setItem('previouslySelectedCityId', selectedOption.value);
    };

    handleDistrictSelection = (selectedOption) => {
        this.setState({selectedDistrictId: selectedOption.value});
        storage.setItem('previouslySelectedDistrictId', selectedOption.value)
        storage.setItem('previouslySelectedCityId', this.state.selectedCityId);
    };

    handleFilterSelect = (newFilter, filterDate) => {
        this.setState({selectedFilter: newFilter, filterDate: filterDate});
    };

    btnClasses = () => {
        var mandatory = "btn btn-primary polyxena-submit-button";
        if (this.state.isSubmitted) {
            mandatory += " loading disabled";
        }
        return mandatory
    };

    render() {
        return (
            <div className="service-page-customizer-container">
                <div className="customizer-with-padding">
                    <div className="customizer-location-date-selector-container">
                        <div className="row">
                            <div className={classNames({"col-sm-12": true, "col-md-8": true})}>
                                <div className="location-header customizer-step-header">
                                    1. {i18n.t('home.hero-widget.select-location')}
                                </div>
                                <AddressDistrictSelector selectedCityId={this.state.selectedCityId}
                                                         selectedDistrictId={this.state.selectedDistrictId}
                                                         selectedAddressId={this.state.selectedAddressId}
                                                         addresses={this.props.addresses}
                                                         cities={this.props.cities}
                                                         onSelectDistrict={this.handleDistrictSelection}
                                                         onSelectCity={this.handleCitySelection}
                                                         onSelectAddress={this.handleAddressSelection}
                                                         renderAddresses={this.state.renderAddresses}
                                                         hideDistrict={true}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 when-filter-col">
                                <div className="location-header customizer-step-header customizer-step-header-second">
                                    2. {i18n.t('home.hero-widget.select-date')}
                                </div>
                                <DateFilterSelector selectedFilter={this.state.selectedFilter}
                                                    handleFilterSelect={this.handleFilterSelect}
                                                    selectedDate={this.state.filterDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="configure-header customizer-step-header customizer-select-service-step">
                        3. {i18n.t('home.hero-widget.configure')}
                    </div>

                    <div className='input-fields-container'>
                        <div className="header-container">
                            <div className="icon-and-header">
                                <div className="icon-container">
                                    <img src={this.props.serviceIcon} />
                                </div>
                                <div className="text-container">
                                    <h1>
                                        {this.props.serviceHeader}
                                    </h1>
                                    <div className="one-liner visible-md visible-lg">
                                        {this.props.serviceOneLiner}
                                    </div>
                                </div>
                            </div>

                            <div className="one-liner visible-xs visible-sm">
                                {this.props.serviceOneLiner}
                            </div>
                        </div>

                        <ProductCustomizer isOptional={false}
                                           isMember={this.props.isMember}
                                           pricelist={this.props.pricelist}
                                           selectedProductOptions={this.state.selectedProductOptions}
                                           onItemSelectionChange={this.itemsUpdated}
                                           showExplanation={false}
                                           serviceType={this.props.service}
                                           onSubmissionRequest={this.handleSubmit}
                                           icons={this.props.icons}
                                           supportedProductOptions={this.props.supportedProductOptions}
                                           clientId={this.props.clientId}
                                           selectedAddressId={this.state.selectedAddressId}
                                           selectedDistrictId={this.state.selectedDistrictId}
                        />

                         {!(this.props.isExperimental || this.props.service === 'pest_control') &&
                        <div className={'cta-container'}>
                            <input type='submit' value={i18n.t('links.see-prices-all-cap')}
                                   className={this.btnClasses()} onClick={this.handleSubmit}/>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default ServicePageCustomizer;