import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import PrettyLittleSelect from '@components/common/pretty_little_select'
import CleanerPhoto from '@components/common/cleaner_photo'
import CleanerCategory from '@components/common/cleaner_category'
import Slider from "react-slick";
import BreakPoints from '@services/breakpoints';

class CleanerCarouselLeftNavButton extends React.Component {
    render() {
        return (
            <div {...this.props} className="arrow-left"></div>
        );
    }
}

class CleanerCarouselRightNavButton extends React.Component {
    render() {
        return (
            <div {...this.props} className="arrow-right"></div>
        );
    }
}

class CleanerCarouselBox extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        name: PropTypes.string,
        image_path: PropTypes.string,
        category: PropTypes.string,
        comment: PropTypes.shape({
            client_name: PropTypes.string,
            content: PropTypes.string
        }),
        active: PropTypes.bool,
        onSelect: PropTypes.func,
        editable: PropTypes.bool
    };

    handleClick = (e) => {
        e.stopPropagation();
        this.props.onSelect({languageFilterActive: false, cleanerId: this.props.active ? null : this.props.id});
    };

    boxClasses = () => {
        return classNames({"cleaner-box": true, active: this.props.active, editable: this.props.editable }) + ' ' + this.props.category;
    };

    render() {
        return (
            <div onClick={this.handleClick} className={this.boxClasses()}>
              <CleanerPhoto {...this.props}/>
              <div className="vertically-centered">
                <div className="cleaner-name">{this.props.name}</div>
                <CleanerCategory {...this.props}/>
                {this.props.editable &&
                  <button className="btn btn-primary">{i18n.t('funnel.cleaners-options.change')}</button>}
              </div>
            </div>
        );
    }
}

class AllCleanersCarouselBox extends React.Component {
    static propTypes = {
        active: PropTypes.bool,
        onSelect: PropTypes.func
    };

    handleClick = (e) => {
        e.stopPropagation();

        if(!this.props.active) {
            this.props.onSelect({cleanerId: null, languageFilterActive: false});
        }
    };

    boxClasses = () => {
        return classNames({"cleaner-box": true, "all-cleaners-box": true, active: this.props.active, editable: this.props.editable });
    };

    render() {
        return (
            <div onClick={this.handleClick} className={this.boxClasses()}>
              <div className="cleaner-photo"></div>
              <div className="vertically-centered">
                <div className="carousel-text">{i18n.t('funnel.cleaners-options.all-cleaners.title')}</div>
                {this.props.editable && <button className="btn btn-primary">{i18n.t('funnel.cleaners-options.change')}</button>}
              </div>
            </div>
        );
    }
}

class LanguageFilterCarouselBox extends React.Component {
    static propTypes = {
        active: PropTypes.bool,
        onSelect: PropTypes.func,
        languages: PropTypes.array,
        selectedLanguage: PropTypes.string,
        mobile: PropTypes.bool
    };

    state = {
        language: this.props.selectedLanguage
    };

    getSelectOptions = () => {
        return _.map(this.props.languages, function(language, i){
            return { value: language, label: i18n.t('languages.' + language) };
        });
    };

    handleLanguageChange = (event) => {
        this.setState({language: event.target.value}, this.handleClick);
    };

    handleClick = (e) => {
        e.stopPropagation();
        this.props.onSelect({cleanerId: null, selectedLanguage: this.state.language, languageFilterActive: true});
    };

    boxClasses = () => {
        return classNames({"cleaner-box": true, "language-filter-box": true, "desktop": !this.props.mobile, active: this.props.active, editable: this.props.editable });
    };

    render() {
        return (
            <div onClick={this.handleClick} className={this.boxClasses()}>
              <div className="cleaner-photo"></div>
              <div className="vertically-centered">
                <div className="carousel-text">
                  {i18n.t('funnel.cleaners-options.maids_who_speak', {language: this.props.editable ? i18n.t('languages.' + this.props.selectedLanguage) : ''})}
                  {!this.props.editable && <PrettyLittleSelect
                                                 value={this.props.selectedLanguage}
                                                 options={this.getSelectOptions()}
                                                 onChange={this.handleLanguageChange}
                                                 onClick={function(e){e.stopPropagation();}}
                                             />}
                </div>
                {this.props.editable && <button className="btn btn-primary">{i18n.t('funnel.cleaners-options.change')}</button>}
              </div>
            </div>
        );
    }
}

class CleanerCarousel extends React.Component {
    static propTypes = {
        cleaners: PropTypes.arrayOf(PropTypes.object),
        onSelect: PropTypes.func,
        cleanerIdFilter: PropTypes.number,
        availableLanguages: PropTypes.array,
        selectedLanguage: PropTypes.string,
        languageFilterActive: PropTypes.bool
    };

    languageBoxShouldBeVisible = () => {
        return this.props.availableLanguages.length > 0 && i18n.locale != NATIVE_LOCALE;
    };

    boxCount = () => {
        var count = 1; // all cleaners box is always there

        if(this.languageBoxShouldBeVisible()) {
            count += 1;
        }

        return count + this.props.cleaners.length;
    };

    render() {
        this.boxCount();
        var settings = {
            draggable: false,
            speed: 200,
            slidesToShow: 4,
            infinite: this.boxCount() > 4,
            arrows: this.boxCount() > 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint:
                    BreakPoints.mdMax,
                    settings: {
                        slidesToShow: 3,
                        infinite: this.boxCount() > 3,
                        arrows: this.boxCount() > 3
                    }
                },
                {
                    breakpoint: BreakPoints.xsMax,
                    settings: {
                        slidesToShow: 2,
                        infinite: this.boxCount() > 2,
                        arrows: this.boxCount() > 2
                    }
                },
                {
                    breakpoint: BreakPoints.addressXsMax,
                    settings: {
                        slidesToShow: 1,
                        infinite: this.boxCount() > 1,
                        arrows: this.boxCount() > 1
                    }
                }

            ],
            nextArrow: CleanerCarouselRightNavButton,
            prevArrow: CleanerCarouselLeftNavButton
        },
            allCleanersBox = (
                <div key={0}>
                  <AllCleanersCarouselBox active={this.props.cleanerIdFilter === null && !this.props.languageFilterActive}
                                          onSelect={this.props.onSelect}/>
                </div>
            ),

            languageFilterCarouselBox = null;

        if(this.languageBoxShouldBeVisible()) {
            languageFilterCarouselBox = (
                <div key={1}>
                  <LanguageFilterCarouselBox
                                             active={this.props.languageFilterActive}
                                             onSelect={this.props.onSelect}
                                             languages={this.props.availableLanguages}
                                             selectedLanguage={this.props.selectedLanguage}/>
                </div>
            );
        }

        var controlSlides = _.compact([allCleanersBox, languageFilterCarouselBox]);

        var cleanersSlides = _.map(this.props.cleaners, function(cleaner, i) {
                                 return (
                                     <div key={i + controlSlides.length}>
                                       <CleanerCarouselBox {...cleaner}
                                                           active={cleaner.id === this.props.cleanerIdFilter}
                                                           onSelect={this.props.onSelect}/>
                                     </div>
                                 );
                             }.bind(this));

        return (
            <div className="cleaner-carousel">
              <Slider {...settings}>
                  {controlSlides}
                  {cleanersSlides}
                </Slider>
            </div>
        );
    }
}

export {CleanerCarouselBox, AllCleanersCarouselBox, LanguageFilterCarouselBox, CleanerCarousel}
