import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import SlotSelector from '@components/common/cleaner_and_slot_selector/slot_selector';
import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';
import $ from 'jquery';
import moment from 'moment';

class FunnelBookingSchedule extends React.Component {
    static propTypes = {
        availabilityPathString: PropTypes.string.isRequired,
        week: PropTypes.number,
        districtId: PropTypes.number,
        cat: PropTypes.bool,
        dog: PropTypes.bool,
        selectedDateslots: PropTypes.arrayOf(PropTypes.object),

        executeSlowOperation: PropTypes.func,

        hideArrows: PropTypes.bool,

        onNextWeek: PropTypes.func,
        onPrevWeek: PropTypes.func,
        onDateslotsChange: PropTypes.func,
        companyId: PropTypes.number,
        renderDescription: PropTypes.func,
        filterDate: PropTypes.instanceOf(Date),
        extraAvailabilityParams: PropTypes.object,

        selectedDuration: PropTypes.string,

        cleanerId: PropTypes.number,

        amplitudeEvent: PropTypes.string,
        amplitudeProperties: PropTypes.object,
    };

    state = {
        schedule: []
    };

    componentDidMount() {
        this.updateSchedule().done(function(){
            if(this.props.amplitudeEvent){
                setTimeout(function() {
                    let all_slots = _.flatten(_.map(this.state.schedule, function(day){return day.slots }));
                    let available_slots = _.filter(all_slots, function(slot){ return slot.available });
                    amplitude.track(this.props.amplitudeEvent, {...this.props.amplitudeProperties, ...{free_slots: available_slots.length}})
                }.bind(this), 200);
            }
        }.bind(this));
    }

    componentDidUpdate(prevProps, _) {
        var weekChanged = this.props.week !== prevProps.week,
            districtIdChanged = this.props.districtId !== prevProps.districtId,
            cleanerIdChanged = this.props.cleanerId !== prevProps.cleanerId,
            languageChanged = this.props.language != prevProps.language,
            versionChanged = this.props.version != prevProps.version,
            companyIdChanged = this.props.companyId != prevProps.companyId,
            productIsChanged = this.props.productType != prevProps.productType,
            durationChanged = this.props.selectedDuration != prevProps.selectedDuration;


        if(weekChanged || districtIdChanged || cleanerIdChanged || languageChanged || versionChanged || companyIdChanged ||
                productIsChanged || durationChanged) {
            this.updateSchedule();
        }
    }

    updateSchedule = () => {
        return this.props.executeSlowOperation(function() {
            var promise = this.fetchSchedule();

            promise.done(function(schedule) {
                this.setState({ schedule: schedule });
            }.bind(this));

            return promise;
        }.bind(this));
    };

    queryUrl = () => {
        return PathHelper(this.props.availabilityPathString);
    };

    filterDateString = () => {
        return moment(this.props.filterDate).format('YYYY-MM-DD');
    }

    fetchSchedule = () => {
        var queryParams = { week: this.props.week, district_id: this.props.districtId, cleaner_id: this.props.cleanerId,
                            cat: this.props.cat, dog: this.props.dog, company_id: this.props.companyId };

        if(this.props.filterDate) queryParams['filter_date'] = this.filterDateString();
        if(this.props.extraAvailabilityParams){
            queryParams = {...queryParams, ...this.props.extraAvailabilityParams}
        }

        return $.get(this.queryUrl() + "?" + $.param(queryParams));
    };

    handleDateSlotToggle = (dateslot) => {
        var newDateslots = this.dateSlotIsSelected(dateslot) ? [] : [dateslot];

        this.props.onDateslotsChange({
            newDateslots: newDateslots,
            dateslotsSelectionDone: this.dateslotsSelectionDone(newDateslots)
        });
    };

    dateslotsSelectionDone = (dateslots) => {
        return dateslots.length > 0;
    };

    // shared =========

    dateSlotIsSelected = (dateslot) => {
        var dateslotFound = _.find(this.props.selectedDateslots, function(ds) {
            return ScheduleUtils.dateslotsEqual(dateslot, ds);
        });

        return dateslotFound != undefined;
    };

    // ================

    render() {
        if(this.state.schedule.length === 0) return null;

        return (
            <div className="clearfix">
              {this.props.renderDescription(_.first(this.state.schedule).date, _.last(this.state.schedule).date)}

              <SlotSelector
                 schedule={this.state.schedule}
                 selectedDateslots={this.props.selectedDateslots}
                 onButtonToggle={this.handleDateSlotToggle}
                 onPrevWeek={this.props.onPrevWeek}
                 onNextWeek={this.props.onNextWeek}
                 categoryPrice={this.props.categoryPrice}
                 allowUnavailable={false}
                 legendType={'booking'}
                 highlightedDateString={this.filterDateString()}
                 hideArrows={this.props.hideArrows}
              />

              {this.props.children}
            </div>
        );
    }
}

export default FunnelBookingSchedule;
