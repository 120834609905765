import React from 'react';
import PropTypes from "prop-types";


import AddressSelectorBox from '@components/common/address_selector/box';

class BoxRow extends React.Component {
    static propTypes = {
        addresses: PropTypes.arrayOf(PropTypes.object),
        selectedAddress: PropTypes.object,
        onAdd: PropTypes.func,
        onDestroy: PropTypes.func,
        onSelect: PropTypes.func,
        displaySpecialBadge: PropTypes.bool,
    };

    renderAddressBoxes = () => {
        var addresses = _.map(this.props.addresses, function(address, i) {
            if(address.blank) {
                return this.renderAddressAdder();
            }

            var selected = this.props.selectedAddress && this.props.selectedAddress.id == address.id;

            return (
                <AddressSelectorBox key={i} destroyable={true} address={address} selected={selected} displaySpecialBadge={this.props.displaySpecialBadge}
                     onDestroy={_.partial(this.props.onDestroy, address)} onSelect={this.props.onSelect} />
            );
        }.bind(this));

        return addresses;
    };

    renderAddressAdder = () => {
        return (
            <div key={this.props.addresses.length} className="blank-address-box" onClick={this.props.onAdd}>
              <div className="content">
                {i18n.t('funnel.address-form.new-address')}
              </div>
            </div>
        );
    };

    render() {
        return (
            <div className="address-options-row">
              {this.renderAddressBoxes()}
            </div>
        );
    }
};

export default BoxRow;
