import moment from 'moment';

import _ from 'underscore';

import DateService from '@services/date_service';

var DateSlotService = {
    formatSubscriptionDateslots: function(dateslots) {
        return _.map(dateslots, function(dateslot) {
            var weekDay = i18n.t("date.day_names")[moment(dateslot.date, "YYYY-MM-DD").weekday()];
            return weekDay + ' ' + dateslot.starts_at;
        }).join(', ');
    },

    selectionDone: function(product, dateslots) {
        if(product && product.productType == 1) {
            return dateslots.length == product.visitsWeekly;
        }
        else {
            return dateslots.length > 0;
        }
    },

    formatDateSlot: function(dateslot) {
        return DateService.formatDate(dateslot.date) + ', ' + dateslot.starts_at;
    }
};

export default DateSlotService;
