import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames'

import Select from '@components/vendor/select.js';

class CitySelector extends React.Component {
    static propTypes = {
        cities: PropTypes.arrayOf(PropTypes.object),
        selected: PropTypes.number,
        select: PropTypes.func,
        hasError: PropTypes.bool,
        readOnly: PropTypes.bool
    };

    optionFilter = (option, filterString) => {
        var label = option.label.toLowerCase(),
            alternative_label = option.alternative_label.toLowerCase(),
            filterValue = filterString.toLowerCase();

        return label.indexOf(filterValue) >= 0 || alternative_label.indexOf(filterValue) >= 0;
    };

    render() {
        return (
            <Select name="city_id"
                    value={this.props.selected}
                    options={this.props.cities}
                    onChange={this.props.select}
                    filterOption={this.optionFilter}
                    placeholder=""
                    className={classNames({"has-error": this.props.hasError, "city-selector": true })}
                    noResultsText={i18n.t('funnel.address-form.city-not-found')}
                    scrollMenuIntoView={false}
                    autoBlur={true}
                    disabled={this.props.readOnly} />
        );
    }
};

export default CitySelector;
