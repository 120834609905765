import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Modal from '@components/vendor/modal';
import withLoader from '@components/common/with_loader_decorator';
import CleanerProfile from '@components/cleaner_profile';
import $ from 'jquery';

class CleanerProfileModal extends React.Component {
    static propTypes = {
        additionalDistrict: PropTypes.string,
        cleanerCode: PropTypes.string,
        onClose: PropTypes.func
    };

    state = {
        cleanerProfileData: {}
    };

    componentDidMount() {
        this.loadCleanerProfileData(this.props.cleanerCode);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cleanerCode && this.props.cleanerCode != nextProps.cleanerCode) {
            this.loadCleanerProfileData(nextProps.cleanerCode);
        }
        else if(nextProps.cleanerCode == null) {
            this.setState({ cleanerProfileData: {} });
        }
    }

    loadCleanerProfileData = (code) => {
        if(code != null && code != "") {
            this.props.executeSlowOperation(function() {
                return $.get(PathHelper('public_profile_cleaners_path', { code: code }),
                             function(data) {
                                 this.setState({ cleanerProfileData: data });
                             }.bind(this),
                             "json");
            }.bind(this));
        }
    };

    render() {
        return (
            <Modal size="large" isOpen={!!(this.props.cleanerCode)} onHide={this.props.onClose} dialogClassName="cleaner-profile-modal">
              {!_.isEmpty(this.state.cleanerProfileData) && <CleanerProfile {...this.state.cleanerProfileData} additionalDistrict={this.props.additionalDistrict} />}
              <div className="close-button" onClick={this.props.onClose}></div>
            </Modal>
        );
    }
}


export default withLoader(CleanerProfileModal);
