import React from 'react';
import PropTypes from 'prop-types';
import MoneyUtils from '@services/money_utils';


class FunnelProgressListCompanyPriceInfo extends React.Component {
    static propTypes = {
        totalPrice: PropTypes.number,
        company: PropTypes.object,
        addressMembershipPercentDiscount: PropTypes.number,
        buyAddressMembership: PropTypes.bool,
    };

    renderDetailsInfo = () => {
        return (
            <div>
              <div className="little-darkblue-title">
                  {this.props.company.name} {this.price()}
              </div>
            </div>
        );
    };

    renderRequiredAction = () => {
        return (
            <div>
              <div>
                {i18n.t('funnel.breadcrumbs.company-selection.title')}
              </div>
            </div>
        );
    };

    // assume company is present
    price = () => {
        if(this.props.totalPrice) {
            return (
                <span>
                    - <span className={'price-in-progress-list'}>{MoneyUtils.price(this.props.totalPrice)}</span>
                </span>
                )
        } else {
            return '';
        }
    }

    render() {
        if(this.props.company) {
            return this.renderDetailsInfo();
        }
        else {
            return this.renderRequiredAction();
        }
    }
};

export default FunnelProgressListCompanyPriceInfo;
