import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import AddressDistrictSelector from '@components/selectors/address_district_selector';
import ProductCustomizer from '@components/product_customizers/product_customizer';

class FunnelLocationProductCustomizer extends React.Component {
    static propTypes = {
        service: PropTypes.string.isRequired,
        onSubmit: PropTypes.func.isRequired,

        initiallySelectedCityId: PropTypes.number,
        initiallySelectedDistrictId: PropTypes.number,
        initiallySelectedAddressId: PropTypes.number,

        initiallySelectedProductOptions: PropTypes.object,

        isMember: PropTypes.bool,

        addresses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            city_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            supports_service: PropTypes.bool
        })),
        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            districts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                alternative_name: PropTypes.string.isRequired,
            })),
            supports_service: PropTypes.bool,
        })),
        icons: PropTypes.shape({
            glass_doors: PropTypes.string,
            single_windows: PropTypes.string,
            large_windows: PropTypes.string,
            single_french_windows: PropTypes.string,
            large_french_windows: PropTypes.string,
        }),

        // only pest control
        supportedProductOptions: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {};

    state = {
        selectedProductOptions: {},
        productSelectionValid: false,

        selectedAddressId: null,
        selectedCityId: null,
        selectedDistrictId: null,

        isSubmitted: false,
    };

    constructor(props) {
        super(props);
        if(props.initiallySelectedCityId && this.findCity(props.initiallySelectedCityId))
            this.state.selectedCityId = props.initiallySelectedCityId;

        if(props.initiallySelectedDistrictId && this.findDistrict(props.initiallySelectedDistrictId))
            this.state.selectedDistrictId = props.initiallySelectedDistrictId;

        if (props.initiallySelectedProductOptions) {
            this.state.selectedProductOptions = props.initiallySelectedProductOptions;
        }

        this.state.renderAddresses = (props.addresses.length > 0) && !this.state.selectedDistrictId;

        if(this.state.renderAddresses && props.addresses.length > 0){
            if(props.initiallySelectedAddressId)
                this.state.selectedAddressId = props.initiallySelectedAddressId;
            else
                this.state.selectedAddressId = _.last(props.addresses).id;
        }
    }

    selectedCity = () => {
        if (this.state.selectedCityId === null) {
            return null;
        } else {
            return this.findCity(this.state.selectedCityId);
        }
    };

    selectedLocationCity = () => {
        if (this.state.selectedAddressId) {
            return this.findCity(this.selectedAddress().city_id)
        } else if (this.state.selectedCityId) {
            return this.selectedCity();
        } else {
            return null;
        }
    };

    selectedAddress = () => {
        if (this.state.selectedAddressId) {
            return this.findAddress(this.state.selectedAddressId);
        } else {
            return null;
        }
    };

    findCity = (cityId) => {
        return this.props.cities.find(city => city.id === cityId);
    };

    findDistrict = (districtId) => {
        return this.selectedCity().districts.find(district => district.id === districtId);
    };

    findAddress = (addressId) => {
        return this.props.addresses.find(address => address.id === addressId);
    };

    currentLocationSupportsService = () => {
        var selectedCity = this.selectedLocationCity();
        if (selectedCity) {
            return selectedCity.supports_service;
        } else {
            return true;
        }
    }

    handleSubmit = () => {
        if (!this.state.productSelectionValid) {
            alert(i18n.t('components.customizers.product-errors.' + this.props.service));
            return;
        }

        if (!this.currentLocationSupportsService()) {
            alert(i18n.t("components.customizers.not-offered-in-city"));
            return;
        }

        if (this.state.selectedAddressId != null) {
            this.setState({isSubmitted: true});
            this.setState({isSubmitted: true}, function(){
                this.props.onSubmit({address: this.findAddress(this.state.selectedAddressId),
                    productOptions: this.state.selectedProductOptions});
            }.bind(this));
        } else if (this.state.selectedDistrictId != null) {
            this.setState({isSubmitted: true}, function(){
                this.props.onSubmit({city: this.findCity(this.state.selectedCityId),
                    address: null,
                    district: this.findDistrict(this.state.selectedDistrictId),
                    productOptions: this.state.selectedProductOptions});
            }.bind(this));
        } else if (this.props.addresses.length > 0) {
            alert(i18n.t("components.service-address-selector.no-address-error"));
        }  else {
            alert(i18n.t("components.service-district-selector.no-district-error"));
        }
    };

    // is valid holds if the selection is ok to continue if it's mandatory
    itemsUpdated = (serviceType, selectedProductOptions, isValid, totalPrice) => {
        var updatedItems = {...this.state.selectedProductOptions, ...selectedProductOptions};
        this.setState({selectedProductOptions: updatedItems, productSelectionValid: isValid});
    };

    handleAddressSelection = (selectedOption) => {
        if (selectedOption.value === -1) {
            this.setState({renderAddresses: false, selectedAddressId: null});
        } else if (this.findCity(this.findAddress(selectedOption.value).city_id).supports_service) {
            this.setState({selectedAddressId: selectedOption.value});
            return true;
        } else {
            alert(i18n.t('components.service-address-selector.not-offered-error'));
            return false;
        }
    };

    handleCitySelection = (selectedOption) => {
        this.setState({selectedCityId: selectedOption.value, selectedDistrictId: null});
    };

    handleDistrictSelection = (selectedOption) => {
        this.setState({selectedDistrictId: selectedOption.value});
    };


    btnClasses = () => {
        var mandatory = "btn btn-primary submit-button";
        if (this.state.isSubmitted) {
            mandatory += " loading disabled";
        }
        return mandatory
    };

    render() {
        return (
            <div className="location-product-customizer-container">
                <div className='input-fields-container'>
                    <div className={'location-product-customization-location-container'}>
                        <AddressDistrictSelector selectedCityId={this.state.selectedCityId}
                                                 selectedDistrictId={this.state.selectedDistrictId}
                                                 selectedAddressId={this.state.selectedAddressId}
                                                 addresses={this.props.addresses}
                                                 cities={this.props.cities}
                                                 districts={this.props.districts}
                                                 onSelectDistrict={this.handleDistrictSelection}
                                                 onSelectCity={this.handleCitySelection}
                                                 onSelectAddress={this.handleAddressSelection}
                                                 renderAddresses={this.state.renderAddresses}
                        />
                    </div>

                    <ProductCustomizer isOptional={false}
                                       selectedProductOptions={this.state.selectedProductOptions}
                                       onItemSelectionChange={this.itemsUpdated}
                                       showExplanation={false}
                                       serviceType={this.props.service}
                                       onSubmissionRequest={this.handleSubmit}
                                       icons={this.props.icons}
                                       supportedProductOptions={this.props.supportedProductOptions}
                                       isMember={this.props.isMember}
                    />

                </div>
                <div className="form-footer">
                    <button className={"btn btn-primary next-step btn-submit "} onClick={this.handleSubmit}>
                        {i18n.t('form.buttons.proceed')}
                    </button>
                </div>
            </div>
        );
    }
}


export default FunnelLocationProductCustomizer;
