import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/lib/Collapse';

class FoldableContainer extends React.Component {
    static propTypes = {
        heading: PropTypes.string,
        contentHTML: PropTypes.string
    };

    state = {
        open: false
    };

    toggleContent = () => {
        this.setState({ open: !this.state.open });
    };

    renderArrow = () => {
        if(this.state.open) {
            return <span className="glyphicon foldable-arrow glyphicon-chevron-up"></span>;
        }
        else {
            return <span className="glyphicon foldable-arrow glyphicon-chevron-down"></span>;
        }
    };

    render() {
        return (
            <div className="foldable-container">
              <div className="heading" onClick={this.toggleContent}>
                {this.props.heading}

                <div className="arrow">
                  {this.renderArrow()}
                </div>
              </div>

              <Collapse in={this.state.open}>
                <div className="content" dangerouslySetInnerHTML={{__html: this.props.contentHTML}}></div>
              </Collapse>
            </div>
        );
    }
}

export default FoldableContainer;
