import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import _ from 'underscore'

import CitySelector from '@components/common/address_selector/city_selector';
import DistrictSelector from '@components/common/address_selector/district_selector';
import Validator from '@services/validator';

class FormWithDistrict extends React.Component {
    static propTypes = {
        cities: PropTypes.arrayOf(PropTypes.object),
        cityReadOnly: PropTypes.bool,
        selectedDistrict: PropTypes.object,
        districtReadOnly: PropTypes.bool,
        onSubmit: PropTypes.func,
        collectPhone: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        var state = {
            cityId: null,
            districtId: null,
            errors: {}
        };

        if (props.selectedDistrict) {
            state.cityId = props.selectedDistrict.city_id;
            state.districtId = props.selectedDistrict.id;
        }

        this.state = state;
    }

    selectedCity = () => {
        return _.find(this.props.cities, function (city) {
            return city.id == this.state.cityId;
        }.bind(this));
    };

    districts = () => {
        if (this.selectedCity()) {
            return this.selectedCity().districts;
        } else {
            return [];
        }
    };

    regularDistricts = () => {
        return _.select(this.districts(), function (district) {
            return !district.gated_complex;
        });
    };

    gatedComplexDistricts = () => {
        return _.select(this.districts(), function (district) {
            return district.gated_complex;
        });
    };

    gatedComplexDistrictSelected = () => {
        var selectedDistrict = this.selectedDistrict();
        return selectedDistrict && selectedDistrict.gated_complex;
    };

    regularDistrictSelected = () => {
        var selectedDistrict = this.selectedDistrict();
        return selectedDistrict && !selectedDistrict.gated_complex;
    };

    gatedComplexesPresent = () => {
        return this.gatedComplexDistricts().length > 0;
    };

    noDistrictSelected = () => {
        return !this.selectedDistrict();
    };

    selectedDistrictWithoutErrors = () => {
        return this.selectedDistrict() && !this.hasDistrictError();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.hasError()) {
            return;
        }

        if (this.validateCity(this.state.cityId) &&
            this.validateDistrict(this.state.districtId) &&
            (!this.props.collectPhone || this.validatePhone(this.refs.phone.value))) {

            var address = {
                district_id: this.state.districtId,
                description: this.refs.description.value,
                cat: this.getCatCheckboxState(),
                dog: this.getDogCheckboxState()
            };

            if (this.regularDistrictSelected()) {
                _.extend(address, {
                    street: this.refs.street.value,
                    number: this.refs.number.value,
                });
                if(ADDRESS_FIELDS['block']['present']){
                    _.extend(address, {
                        block: this.refs.block.value,
                    });
                }
                if(ADDRESS_FIELDS['entrance']['present']){
                    _.extend(address, {
                        entrance: this.refs.entrance.value,
                    });
                }
                if(ADDRESS_FIELDS['postcode']['present']){
                    _.extend(address, {
                        postcode: this.refs.postcode.value,
                    });
                }
                if(ADDRESS_FIELDS['floor']['present']){
                    _.extend(address, {
                        floor: this.refs.floor.value,
                    });
                }
                if(ADDRESS_FIELDS['apartment']['present']){
                    _.extend(address, {
                        apartment: this.refs.apartment.value,
                    });
                }
            }

            if (this.props.collectPhone) {
                address.phone = this.refs.phone.value;
            }
            this.props.onSubmit(address);
        }
    };

    getCatCheckboxState = () => {
        return this.refs.cat ? this.refs.cat.checked : false;
    };

    getDogCheckboxState = () => {
        return this.refs.dog ? this.refs.dog.checked : false;
    };

    validateDistrict = (districtId) => {
        var districtError = null;

        if (districtId === null || districtId === undefined) {
            if (this.gatedComplexesPresent()) {
                districtError = i18n.t('funnel.address-form.missing-district-or-gated-complex-error');
            } else {
                districtError = i18n.t('funnel.address-form.missing-district-error');
            }
        } else {
            var districtAvailable = _.find(this.districts(), function (district) {
                return district.value === districtId;
            }.bind(this)).available;

            if (!districtAvailable) {
                districtError = i18n.t('funnel.address-form.unavailable-disctrict-error');
            }
        }

        if (districtError) {
            this.setState({errors: _.extend({}, this.state.errors, {district: districtError})});
            return false;
        } else {
            this.setState({errors: _.omit(this.state.errors, 'district')});
            return true;
        }
    };

    validateCity = (cityId) => {
        var cityError = null,
            city = _.find(this.props.cities, function (city) {
                return city.id == cityId;
            });

        if (cityId === null || cityId === undefined) {
            cityError = i18n.t('funnel.address-form.missing-city-error');
        } else if (!city.available) {
            cityError = i18n.t('funnel.address-form.unavailable-city-error');
        }

        if (cityError) {
            this.setState({errors: _.extend({}, this.state.errors, {city: cityError})});
            return false;
        } else {
            this.setState({errors: _.omit(this.state.errors, 'city')});
            return true;
        }
    };

    validatePhone = (value) => {
        var phoneError = null;

        if (!Validator.isPresent(value)) {
            phoneError = i18n.t('activerecord.errors.models.client.attributes.phone.blank');
        }

        if (!Validator.isPhone(value)) {
            phoneError = i18n.t('activerecord.errors.models.client.attributes.phone.invalid');
        }

        if (phoneError) {
            this.setState({errors: _.extend({}, this.state.errors, {phone: phoneError})});
            return false;
        } else {
            this.setState({errors: _.omit(this.state.errors, 'phone')});
            return true;
        }
    };

    selectDistrict = (district) => {
        var districtId = district && district.value;
        this.validateDistrict(districtId);
        this.setState({districtId: districtId});
    };

    selectedDistrict = () => {
        return _.find(this.districts(), function (district) {
            return district.id == this.state.districtId;
        }.bind(this));
    };

    renderSubmitButton = (handler) => {
        var hasError = this.hasError(),
            classes = classNames({btn: true, 'btn-primary': true, disabled: hasError});

        return (
            <button onClick={this.handleSubmit} type="submit"
                    className={classes}>{i18n.t('funnel.address-form.save-address')}</button>
        );
    };

    hasError = () => {
        return !_.isEmpty(this.state.errors);
    };

    hasDistrictError = () => {
        return !!this.state.errors.district;
    };

    hasPhoneError = () => {
        return !!this.state.errors.phone;
    };

    shouldShowField = () => {
        return this.selectedDistrictWithoutErrors() || !this.gatedComplexesPresent();
    };

    shouldShowRegularAddressField = () => {
        return (this.regularDistrictSelected() && this.selectedDistrictWithoutErrors()) || !this.gatedComplexesPresent();
    };

    selectedCityId = () => {
        var selectedCity = this.selectedCity();
        if (selectedCity) {
            return selectedCity.id;
        } else {
            return null;
        }
    };

    selectCity = (city) => {
        var cityId = city && city.value;
        this.validateCity(cityId);
        this.setState({cityId: cityId, districtId: null});
    };

    hasCityError = () => {
        return !!this.state.errors.city;
    };

    render() {
        var phoneField = (
            <div className="row">
                <div className={classNames({"form-group": true, "col-sm-12": true, "has-error": this.hasPhoneError()})}>
                    <label>{i18n.t('helpers.label.client.phone')}</label>
                    <input name="phone" ref="phone" type="text" className={"form-control input-lg"}
                           onChange={function (input) {
                               this.validatePhone(input.target.value);
                           }.bind(this)}/>
                </div>
            </div>
        );

        var selectedDistrict = this.selectedDistrict();

        return (
            <div className="address-fields">
                {this.hasError() &&
                <div className="row">
                    <div className="col-xs-12 error-message">
                        {this.state.errors.city}
                        <br/>
                        {this.state.errors.district}
                        <br/>
                        {this.state.errors.phone}
                    </div>
                </div>}

                <div className="row">
                    <div className={classNames({
                        "form-group": true,
                        "col-sm-6": !this.gatedComplexesPresent(),
                        "col-sm-12": this.gatedComplexesPresent()
                    })}>
                        <label>{i18n.t('helpers.label.funnel_form.city_id')}</label>
                        <CitySelector readOnly={this.props.cityReadOnly} cities={this.props.cities}
                                      select={this.selectCity} selected={this.selectedCityId()}
                                      hasError={this.hasCityError()}/>
                    </div>

                    <div className={classNames({
                        "form-group": true,
                        "col-sm-5": this.gatedComplexesPresent(),
                        "col-sm-6": !this.gatedComplexesPresent()
                    })}>
                        <label>{i18n.t('helpers.label.funnel_form.district_id')}</label>
                        <DistrictSelector readOnly={this.props.districtReadOnly} districts={this.regularDistricts()}
                                          select={this.selectDistrict} selected={this.state.districtId}
                                          hasError={this.hasDistrictError() && (this.regularDistrictSelected() || this.noDistrictSelected())}/>
                    </div>

                    {this.gatedComplexesPresent() &&
                    <div className="col-sm-2" style={{height: '70px', textAlign: 'center'}}>
                        <span style={{marginTop: '36px', display: 'inline-block'}}>{i18n.t('support.or')}</span>
                    </div>}

                    {this.gatedComplexesPresent() && <div className="form-group col-sm-5">
                        <label>{i18n.t('helpers.label.funnel_form.gated_complex_id')}</label>
                        <DistrictSelector districts={this.gatedComplexDistricts()} select={this.selectDistrict}
                                          selected={this.state.districtId}
                                          hasError={this.hasDistrictError() && (this.gatedComplexDistrictSelected() || this.noDistrictSelected())}/>
                    </div>}
                </div>

                {this.shouldShowRegularAddressField() && <div className="address-form row">
                    <div className="form-group col-xs-9  address">
                        <label>{i18n.t('helpers.label.funnel_form.address')}</label>
                        <input name="street" ref="street" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.street')}/>
                    </div>

                    <div className="form-group col-xs-3 col-sm-3 with-offset">
                        <label className={'hidden-xs'}>&nbsp;</label>
                        <input name="number" ref="number" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.number')}/>
                    </div>

                    {ADDRESS_FIELDS['postcode']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['postcode']['css-classes']}>
                        <input name="block" ref="postcode" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.postcode')}/>
                    </div>}

                    {ADDRESS_FIELDS['block']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['block']['css-classes']}>
                        <input name="block" ref="block" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.block')}/>
                    </div>}

                    {ADDRESS_FIELDS['entrance']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['entrance']['css-classes']}>
                        <input name="entrance" ref="entrance" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.entrance')}/>
                    </div>}

                    {ADDRESS_FIELDS['floor']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['floor']['css-classes']}>
                        <input name="floor" ref="floor" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.floor')}/>
                    </div>}

                    {ADDRESS_FIELDS['apartment']['present'] &&
                    <div className={"form-group " + ADDRESS_FIELDS['apartment']['css-classes']}>
                        <input name="apartment" ref="apartment" type="text" className="form-control input-lg"
                               placeholder={i18n.t('helpers.label.funnel_form.apartment')}/>
                    </div>}
                </div>}

                {this.shouldShowField() && this.props.collectPhone && phoneField}

                {this.shouldShowField() && <div className="row">
                    <div className="form-group col-sm-12">
                        <label>{i18n.t('helpers.label.funnel_form.description')}</label>
                        <textarea name="description" ref="description" className="form-control input-lg">

                  </textarea>
                    </div>
                </div>}

                {this.shouldShowField() && <div className="row">
                    <div className="col-sm-2 form-group">
                        <label>
                            <input type="checkbox" ref="cat"
                                   name="cat"/> {i18n.t('helpers.label.funnel_form.i_have_a_cat')}
                        </label>
                    </div>
                    <div className="col-sm-2 form-group">
                        <label>
                            <input type="checkbox" ref="dog"
                                   name="dog"/> {i18n.t('helpers.label.funnel_form.i_have_a_dog')}
                        </label>
                    </div>
                </div>}

                {this.shouldShowField() && this.renderSubmitButton()}
            </div>
        );
    }
};

export default FormWithDistrict;
