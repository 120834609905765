import React from 'react';
import PropTypes from 'prop-types';
import {BreadcrumbsItem} from '@components/common/breadcrumbs'
import SubscriptionEditFormSubscriptionDetails from "@components/subscription_edit_form/subscription_details";
import FunnelBreadcrumbsAddressInfo from '@components/funnel/breadcrumbs/address_info'

class SubscriptionEditFormBreadcrumbs extends React.Component {
    static propTypes = {
        step: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        product: PropTypes.object.isRequired,
        dateslots: PropTypes.arrayOf(PropTypes.object),
        cleaner: PropTypes.object.isRequired,
        showAddressSelector: PropTypes.func.isRequired,
        showSlotsSelector: PropTypes.func.isRequired
    };

    render() {
        return (
            <div className="breadcrumbs subscription-edit">
              <BreadcrumbsItem onClick={this.props.showAddressSelector} active={this.props.step === 'addressSelection'}>
                <FunnelBreadcrumbsAddressInfo address={this.props.address}/>
              </BreadcrumbsItem>

              <BreadcrumbsItem onClick={this.props.showSlotsSelector} active={this.props.step === 'slotsSelection'}>
                <SubscriptionEditFormSubscriptionDetails product={this.props.product}
                                                          cleaner={this.props.cleaner}
                                                          dateslots={this.props.dateslots}/>
              </BreadcrumbsItem>
            </div>
        );
    }
};

export default SubscriptionEditFormBreadcrumbs;
