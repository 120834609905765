import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from "underscore";
import DatePicker from "react-datepicker";

import {registerLocale} from "react-datepicker";
import {bg, el, es} from 'date-fns/locale';

registerLocale('bg', bg);
registerLocale('el', el);
registerLocale('es', es);



class DateFilterSelector extends React.Component {
    static propTypes = {
        selectedFilter: PropTypes.string,
        selectedDate: PropTypes.instanceOf(Date),
        handleFilterSelect: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    state = {
        optionsBoxOpen: false,
        calendarVisible: false,
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleSomeClick, false);
        document.addEventListener('touchstart', this.handleSomeClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleSomeClick);
        document.addEventListener('touchstart', this.handleSomeClick);
    }

    calendarMinDate() {
        let d = new Date();
        let hours = d.getHours();
        if (hours >= 19) {
            return d.setDate(d.getDate() + 2);
        } else {
            return d.setDate(d.getDate() + 1);
        }
    }

    calendarMaxDate() {
        let d = new Date();
        return d.setDate(d.getDate() + 28);
    }

    handleSomeClick = (e) => {
        if(!this.node)
            return;

        if (this.node.contains(e.target)) {
            return;
        }

        this.setState({optionsBoxOpen: false, calendarVisible: false})
    }

    handleSelectorBoxClick = () => {
        if(!this.props.disabled)
            this.setState({optionsBoxOpen: !this.state.optionsBoxOpen, calendarVisible: false})
    }

    handleFilterSelect = (filterName) => {
        if (filterName !== 'date') {
            this.setState({optionsBoxOpen: false, calendarVisible: false})
            this.props.handleFilterSelect(filterName, null);
        } else {
            this.setState({calendarVisible: true})
        }
    }

    handleDateSelect = (date) => {
        this.setState({calendarVisible: false, optionsBoxOpen: false,}, function () {
            this.props.handleFilterSelect('date', date)
        }.bind(this));
    }

    filterBoxMaintext = () => {
        if(this.props.disabled){
          return i18n.t('home.when-filter-widget.next-step');
        } else if (this.props.selectedFilter === null || this.props.selectedFilter === 'all') {
            return i18n.t('home.when-filter-widget.all-possibilities');
        } else if (this.props.selectedFilter === 'recent') {
            return i18n.t('home.when-filter-widget.next-4-days');
        } else if (this.props.selectedFilter === 'date' && this.props.selectedDate) {
            return i18n.t('home.when-filter-widget.on-date') + ' ' + this.props.selectedDate.getDate() + ' ' +
                i18n.t('date.month_names').slice(1)[(((this.props.selectedDate.getMonth()) % 12) + 12) % 12] +
                ', ' + i18n.t('date.abbr_day_names')[this.props.selectedDate.getDay()];
        }
    }

    render() {
        return (
            <div className={'date-filter-selector'}>
                <div ref={node => this.node = node}
                     className={classNames({'selector-box': true, 'selector-box-open': this.state.optionsBoxOpen})}>
                    <div className={classNames({'selector-box-header': true, 'selector-box-header-open': this.state.optionsBoxOpen, disabled: this.props.disabled})}
                                        onClick={this.handleSelectorBoxClick}>
                        {this.filterBoxMaintext()}
                        <div className="arrow ">
                            <span className="glyphicon glyphicon-chevron-down"></span>
                        </div>
                    </div>
                    {this.state.optionsBoxOpen &&
                    <div className="filter-options-box">
                        {!this.state.calendarVisible &&
                        <div className="menu-items-container">
                            <div className={classNames({'filter-option': true, 'filter-option-selected': this.props.selectedFilter === 'all'})}
                                 onClick={_.partial(this.handleFilterSelect, "all")}>
                                {i18n.t('home.when-filter-widget.all-possibilities')}
                            </div>
                            <div className={classNames({'filter-option': true, 'filter-option-selected': this.props.selectedFilter === 'recent'})}
                                    onClick={_.partial(this.handleFilterSelect, "recent")}>
                                {i18n.t('home.when-filter-widget.next-4-days')}
                            </div>
                            <div className={classNames({'filter-option': true, 'filter-option-selected': this.props.selectedFilter === 'date'})}
                                    onClick={_.partial(this.handleFilterSelect, "date")}>
                                {i18n.t('home.when-filter-widget.specific-date')}
                            </div>

                        </div>}
                        {this.state.calendarVisible &&
                        <div className="date-filter-calendar-container">
                            <DatePicker
                                onChange={this.handleDateSelect}
                                locale={i18n.locale}
                                minDate={this.calendarMinDate()}
                                maxDate={this.calendarMaxDate()}
                                inline
                            />
                        </div>
                        }
                    </div>}
                </div>
            </div>
        );
    }
}

export default DateFilterSelector;