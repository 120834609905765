import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import $ from "jquery";
import classNames from 'classnames';
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

import MoneyUtils from '@services/money_utils'
import BraintreeFormService from '@services/braintree_form_service'

import BraintreeForm from "@components/braintree/braintree_form";
import InvoiceModalTrigger from '@components/common/invoice_modal_trigger'
import PriceTag from '@components/common/price_tag'


class FunnelBookingPayment extends React.Component {
    static propTypes = {
        serviceType: PropTypes.string.isRequired,
        lineItems: PropTypes.arrayOf(PropTypes.object),
        totalPrice: PropTypes.number,
        selectedProductsPrice: PropTypes.number,
        orderCode: PropTypes.string,
        invoiceRequested: PropTypes.bool,
        activePromoCodeExists: PropTypes.bool,

        isCurrentAddressMember: PropTypes.bool,
        showAddressMembershipOption: PropTypes.bool,
        membershipPercentDiscount: PropTypes.number,
        buyAddressMembership: PropTypes.bool,
        toggleBuyMembership: PropTypes.func,
        addressMembershipAdditionalPrice: PropTypes.number,
        membershipCurrentlyValid: PropTypes.bool,

        clientToken: PropTypes.string.isRequired,
        verifyCardAmount: PropTypes.string.isRequired,
        braintreeCards: PropTypes.array,

        orderPrice: PropTypes.shape({
            amount: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired,

            productAmount: PropTypes.string.isRequired,
            productDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
            productAndFeeDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
        }),

        isAfterRenovationMode: PropTypes.bool,
        afterRenovationModeServices: PropTypes.arrayOf(PropTypes.string)
    };

    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: false,
            orderPrice: this.props.orderPrice,
            boughtMembership: false,
            membershipLoading: false,
            selectedCardId: null,
            nonce: null,
            errorMessage: null,
        };

        if (props.braintreeCards.length > 0)
            this.state.selectedCardId = _.last(props.braintreeCards).id;

    };

    toggleBuyMembership = () => {
        if(confirm(i18n.t('form.confirmations.are-you-sure'))) {
            $.ajax({
                type: "PATCH",
                url: PathHelper('funnel_toggle_order_ddc_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify({
                    order_code: this.props.orderCode
                }),
                statusCode: {
                    500: function () {
                        alert("Error while updating Domestina Club for Order! Please refresh and try again.");
                    }
                }
            });

            if (this.props.buyAddressMembership)
                $("#payment_options").hide();
            else
                $("#payment_options").show();

            this.props.toggleBuyMembership();
        }

        return false;
    }

    componentWillUnmount() {
        $("#payment_options").hide();
    }

    componentDidMount() {
        if (this.props.buyAddressMembership) {
            $("#payment_options").show();
        }

        // OrderPriceStore.onChange(function(newPrice) {
        //     this.setState({orderPrice: newPrice});
        // }.bind(this));
    };

    onInstanceCreated = (clientInstance, hostedFieldsInstance, threeDSecureInstance) => {
        this.setState({
            clientInstance: clientInstance,
            hostedFieldsInstance: hostedFieldsInstance,
            threeDSecureInstance: threeDSecureInstance
        })
    };

    onCardSelect = (cardId) => {
        this.setState({selectedCardId: cardId})
    }

    clubDiscountAmount = () => {
        return this.props.selectedProductsPrice * (this.props.membershipPercentDiscount / 100);
    }

    finalServicePrice = () => {
        let price;
        if (this.props.isCurrentAddressMember) {
            price = this.props.orderPrice.amount;
        } else if (this.state.boughtMembership) {
            price = this.priceWithAddressMembershipDiscount().toFixed(2);
        } else {
            price = this.props.orderPrice.amount;
        }
        return price;
    }

    btnClasses = () => {
        var mandatory = "btn btn-primary next-step";
        if (this.state.buttonDisabled) {
            mandatory += " loading disabled";
        }
        if (this.state.boughtMembership) {
            mandatory += " domestina-club-button";
        }
        return mandatory
    };

    lineItemsForService = (serviceType) => {
        return _.filter(this.props.lineItems, function (lineItem) {
            return lineItem.service_type === serviceType;
        })
    }

    priceForService = (serviceType) => {
        return _.reduce(this.lineItemsForService(serviceType), function (memo, lineItem) {
            return parseFloat(lineItem.price) + memo;
        }, 0);
    }

    serviceTypes = () => {
        return _.uniq(_.map(this.props.lineItems, function (li) {
            return li.service_type;
        }));
    }

    orderedServiceTypes = () => {
        // we want to keep the primary service type first
        return [this.props.serviceType].concat(_.without(this.serviceTypes(), this.props.serviceType));
    }

    priceWithAddressMembershipDiscount = () => {
        return parseFloat(this.state.orderPrice.productAmount) - this.addressMembershipDiscount();
    }

    addressMembershipDiscount = () => {
        return MoneyUtils.round((parseFloat(this.state.orderPrice.productAmount) * (this.props.membershipPercentDiscount) / 100), 2);
    }

    handleSubmit = (event) => {
        this.setState({buttonDisabled: true, errorMessage: null}, () => {
            if(this.shouldSubmitCard()){
                if (this.hasSavedCardSelected()) {
                    BraintreeFormService.savedCardSubmit(this.state.selectedCardId, this.state.threeDSecureInstance, this.props.verifyCardAmount)
                        .then((nonce) => {
                            this.setState({nonce: nonce}, () => {
                                this.submitForm();
                            });
                        }).catch((error) => {
                        this.setState({buttonDisabled: false, errorMessage: error.message});
                    });
                } else {
                    if (!BraintreeFormService.isFormValid(this.state.hostedFieldsInstance)) {
                        this.setState({buttonDisabled: false, errorMessage: i18n.t('funnel.pay.braintree.card-invalid')});
                        return false;
                    }
                    BraintreeFormService.newCardSubmit(this.state.hostedFieldsInstance, this.state.threeDSecureInstance, this.props.verifyCardAmount)
                        .then((nonce) => {
                            this.setState({nonce: nonce}, () => {
                                this.submitForm();
                            });

                        }).catch((error) => {
                        this.setState({buttonDisabled: false, errorMessage: error.message})
                    });
                }
            } else {
                this.submitForm();
            }
        });
        Analytics.sendInitiateCheckout(this.props.totalPrice,
            this.props.serviceType  + (this.props.buyAddressMembership ? "_with_club" : ""));
        event.preventDefault();
        return false;
    };

    submitForm = () => {
        document.getElementById('main-form').submit();
    };

    onMembershipBought = () => {
        this.setState({boughtMembership: true})
    };

    shouldSubmitCard = () => {
        return this.props.buyAddressMembership;
    };

    hasSavedCardSelected = () => {
        return this.state.selectedCardId !== null
    };

    formAction = () => {
        if (this.shouldSubmitCard())
            if (this.hasSavedCardSelected())
                return "/braintree/pay_with_saved_card"
            else
                return "/braintree/pay"
        else
            return "/pay_with_cash";
    }

    formHiddenFields = () => {
        if (this.shouldSubmitCard()) {
            let result = {};
            if (this.hasSavedCardSelected()){
                if(this.state.nonce) result['saved_card_3d_nonce'] = this.state.nonce;
                if(this.state.selectedCardId) result['card_id'] = this.state.selectedCardId;
            } else {
                if(this.state.nonce) result['payment_method_nonce'] = this.state.nonce;
            }
            return result;
        } else
            return {};
    }

    showAfterRenovationMode = (serviceType) => {
        return this.props.isAfterRenovationMode && _.contains(this.props.afterRenovationModeServices, serviceType);
    }

    renderClubInfoIcon = () => {
        var tooltip = (
            <Tooltip id={'discount-club-info-tooltip'} className={'discount-club-info-tooltip'}>
                {i18n.t('funnel.address-membership.tooltip.intro')}
                <ul>
                    <li>{i18n.t('funnel.address-membership.tooltip.point1')}</li>
                    <li>{i18n.t('funnel.address-membership.tooltip.point2', {discount_percent: ADDRESS_MEMBERSHIP_DISCOUNT_PERCENT})}</li>
                    <li>{i18n.t('funnel.address-membership.tooltip.point3')}</li>
                </ul>
                <br/>
                <i>*{i18n.t('funnel.address-membership.tooltip.not-renew')}</i>
            </Tooltip>
        );

        return (
            <OverlayTrigger placement="bottom" overlay={tooltip}>
                <span className="info-icon-wrapper">
                    <span className="glyphicon glyphicon-info-sign" aria-hidden="true"></span>
                </span>
            </OverlayTrigger>
        );
    };

    render() {
        return (
            <div id="payment">
                <div>
                    <div className="funnel-heading hidden-xs hidden-sm">{i18n.t('funnel.cash-payment.title')}</div>
                </div>

                <div className="row">
                    <div className={'col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2'}>
                        <div className={classNames({'order-summary-container': true, 'with-ddc-price': true})}>
                            <div className="payment-section-header">
                                {i18n.t('funnel.cash-payment.order')}
                            </div>
                            <div className="line-items-container">
                                {_.map(this.orderedServiceTypes(), function (serviceType) {
                                    return (
                                        <div className={'service-row'} key={'service-row-' + serviceType}>
                                            <div className="main-service-row">
                                                <div className={'service-name'}>
                                                    {i18n.t('services.' + serviceType + '.title')}
                                                    {this.showAfterRenovationMode(serviceType) &&
                                                        <span> ({i18n.t("funnel.pay.after-renovation-mode")}) </span>
                                                    }
                                                    <div className={'service-line-items-container'}>
                                                        {_.map(this.lineItemsForService(serviceType), function (lineItem) {
                                                            return <div className={'line-item-row'}
                                                                        key={serviceType + lineItem.name}>{lineItem.name}</div>;
                                                        }.bind(this))}
                                                    </div>
                                                </div>

                                                <div className={'line-item-price'}>
                                                    {MoneyUtils.price(this.priceForService(serviceType), 2, false)}
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }.bind(this))}

                                {this.props.buyAddressMembership &&
                                <div className={'service-row'} key={'service-row-domestina-club'}>
                                    <div className="main-service-row">
                                        <div className={'service-name'}>
                                            Domestina Club {this.renderClubInfoIcon()}
                                            <div className={'service-line-items-container'}>
                                                <div className="line-item-row">
                                                    {i18n.t('funnel.buy-club.yearly-membership')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'line-item-price'}>
                                            {MoneyUtils.price(this.props.addressMembershipAdditionalPrice, 2, false)}
                                            <div className="delete-link-holder">
                                                <a href="#" onClick={this.toggleBuyMembership}>
                                                    {i18n.t('links.delete')}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>}

                                {this.props.isCurrentAddressMember &&
                                <div className={'service-row'} key={'service-row-domestina-club'}>
                                    <div className="main-service-row">
                                        <div className={'service-name'}> {i18n.t('funnel.cash-payment.club-discount')}</div>
                                        <div className={'line-item-price'}>
                                            -{MoneyUtils.price(this.clubDiscountAmount(), 2, false)}
                                        </div>
                                    </div>
                                </div>}

                                <div className="service-row final-price-line-item">
                                    <div className={'payment-method-container'}>
                                        {!this.props.buyAddressMembership &&
                                        <span>{i18n.t('funnel.cash-payment.subtitle')}</span>}
                                    </div>
                                    <div className="funnel-payment-total-price-container">
                                        <div className={'text-container'}>{i18n.t('funnel.pay.total_amount')}</div>
                                        <PriceTag amount={this.props.totalPrice.toFixed(2)} currency={this.props.currency}
                                                  className="final-price-tag"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.props.buyAddressMembership &&
                        <div className={'funnel-card-form-section'}>
                            <div className="payment-section-header">
                                {i18n.t('funnel.cash-payment.payment')}
                            </div>
                            <div className="card-list-container">
                                <div className="payment-explanation-container">
                                    <div className="explanation-item">
                                        <div className="text-container">{i18n.t('funnel.cash-payment.partial-payment')}</div>
                                        <div className="price-container">{MoneyUtils.price(parseFloat(ADDRESS_MEMBERSHIP_PRICE), 2, false)}</div>
                                    </div>
                                    <div className="explanation-item">
                                        <div className="text-container">{i18n.t('funnel.cash-payment.additional-payment')}</div>
                                        <div className="price-container">{MoneyUtils.price((this.props.totalPrice - ADDRESS_MEMBERSHIP_PRICE), 2)}</div>
                                    </div>
                                </div>

                                <BraintreeForm clientToken={this.props.clientToken}
                                               braintreeCards={this.props.braintreeCards}

                                               selectedCardId={this.state.selectedCardId}
                                               onCardSelect={this.onCardSelect}

                                               onInstanceCreated={this.onInstanceCreated}

                                />
                            </div>
                        </div>}

                        <div className="funnel-payment-invoice-container">
                            <InvoiceModalTrigger orderCode={this.props.orderCode}
                                                 invoiceRequested={this.props.invoiceRequested}/>
                        </div>

                        <div className="hr-before-final-price"></div>

                        {this.props.membershipCurrentlyValid &&
                        <div className="funnel-submit-container">
                            <div
                                className={classNames({hidden: this.state.errorMessage === null}) + " braintree-form-payment-errors"}>
                                {this.state.errorMessage}
                            </div>
                            <form action={this.formAction()} method="post" id={'main-form'}>
                                <input name="order_code" type="hidden" value={this.props.orderCode}/>
                                {_.map(this.formHiddenFields(), (v, k) => {
                                    return <input name={k} type="hidden" value={v} key={'hidden-input-' + k}/>
                                })}
                                <input type="submit" value={i18n.t('form.buttons.confirm')}
                                       className={this.btnClasses()}
                                       onClick={this.handleSubmit}/>
                            </form>
                        </div>}

                        {!this.props.membershipCurrentlyValid &&
                        <div className="overdue-funnel-error-container">
                            <div className="error-text">
                                {i18n.t('funnel.pay.overdue-error')}
                            </div>
                            <div className="cta-container">
                                <a href={PathHelper('new_clients_dashboard_membership_braintree_card_path', {pay: 1})} className="btn btn-primary cta-button">
                                    {i18n.t('funnel.pay.overdue-pay-cta')}
                                </a>
                            </div>
                        </div>}
                    </div>
                </div>


            </div>
        );
    }
}

export default FunnelBookingPayment;
