import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@components/vendor/modal'
import MoneyUtils from '@services/money_utils'


class DomestinaClubModal extends React.Component {
    static propTypes = {
        addToOrder: PropTypes.func,
        allowAddButton: PropTypes.bool,

        onHide: PropTypes.func,
        isOpen: PropTypes.bool
    };


    render() {
        return (
            <Modal isOpen={this.props.isOpen} onHide={this.props.onHide} dialogClassName="domestina-club-advert">
                <div className="custom-modal-header">
                    <div className="close-button" onClick={this.props.onHide}></div>
                    <h1>{i18n.t('public.club.main-header')}</h1>

                    <div className={'header-subtitle'}>
                        {i18n.t('public.club.main-explanation')}
                    </div>
                </div>

                <div className="custom-modal-content">

                    <div className={'common-benefits-container'}>
                        <div className="common-benefit">
                            <div className="image-container calendar-dates"/>
                            <div className='benefit-header'>
                                {i18n.t('public.club.benefits.order-limit.header')}
                            </div>
                            <div className="explanation-container">
                                {i18n.t('public.club.benefits.order-limit.text')}
                            </div>
                        </div>

                        <div className="common-benefit">
                            <div className="image-container houses"/>
                            <div className='benefit-header'>
                                {i18n.t('public.club.benefits.addresses.header')}
                            </div>
                            <div className="explanation-container">
                                {i18n.t('public.club.benefits.addresses.text')}
                            </div>
                        </div>

                        <div className="common-benefit">
                            <div className="image-container wallet">
                            </div>
                            <div className='benefit-header'>
                                {i18n.t('public.club.benefits.price.text')}
                            </div>
                            <div className="explanation-container">
                                {i18n.t('public.club.benefits.price.header', {price: MoneyUtils.price(ADDRESS_MEMBERSHIP_PRICE)})}
                            </div>
                        </div>
                     </div>

                    <div className="service-benefits-container">
                        <div className={'service-benefit'}>
                            <div className="image-container housemaid"/>
                            <div className={'section-header'}>{i18n.t('public.club.benefits.housemaids')} </div>

                            <div className={'benefit-header'}>{i18n.t('public.club.benefits.lower-price.header')}</div>
                            {i18n.t('public.club.benefits.lower-price.text')}


                            <div className={'benefit-header'}>{i18n.t('public.club.benefits.booking-dates.header')}</div>
                            {i18n.t('public.club.benefits.booking-dates.text')}
                        </div>
                        <div className={'service-benefit'}>
                            <div className="image-container professional_cleaning"/>
                            <div className={'section-header'}>{i18n.t('public.club.benefits.professional-cleaning')}</div>

                            <div className={'benefit-header'}>{i18n.t('public.club.benefits.discount.header', {percent: ADDRESS_MEMBERSHIP_DISCOUNT_PERCENT})}</div>
                            {i18n.t('public.club.benefits.discount.text')}

                            <div className={'benefit-header'}>{i18n.t('public.club.benefits.warranty.header')}</div>
                            {i18n.t('public.club.benefits.warranty.text')}
                        </div>
                    </div>

                    {this.props.addToOrder && this.props.allowAddButton && <div className="text-center">
                        <button className="btn btn-primary" onClick={this.props.addToOrder}>
                            {i18n.t('funnel.order-type-form.membership.modal.for-who.add-to-order')}
                        </button>
                    </div>}

                    {!this.props.allowAddButton && <div className="text-center">
                        <button className="btn btn-primary" onClick={this.props.onHide}>
                            {i18n.t('form.buttons.close')}
                        </button>
                    </div>}
                </div>
            </Modal>
        );
    }
}

export default DomestinaClubModal;
