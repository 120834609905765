import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import MediaQuery from 'react-responsive';
import SlotSelector from '@components/common/cleaner_and_slot_selector/slot_selector';
import CheckList from '@components/common/cleaner_and_slot_selector/check_list';
import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';
import BreakPoints from '@services/breakpoints';

import $ from 'jquery';

class SubscriptionSchedule extends React.Component {
    static propTypes = {
        week: PropTypes.number,
        visitDuration: PropTypes.number,
        visitsWeekly: PropTypes.number,
        districtId: PropTypes.number,
        cat: PropTypes.bool,
        dog: PropTypes.bool,
        hotel: PropTypes.bool,
        language: PropTypes.string,
        cleanerId: PropTypes.string,
        cleanerName: PropTypes.string,
        selectedDateslots: PropTypes.arrayOf(PropTypes.object),

        onDateslotsChange: PropTypes.func,
        onDateslotsLoad: PropTypes.func,
        executeSlowOperation: PropTypes.func,

        onNextWeek: PropTypes.any,
        onPrevWeek: PropTypes.any,
        categoryPrice: PropTypes.object,

        showAllCleaners: PropTypes.func,

        editing: PropTypes.bool,
        subscriptionId: PropTypes.number,
        onSingleSlotSelect: PropTypes.func,
        allowUnavailable: PropTypes.bool,
        companyId: PropTypes.number,
        renderDescription: PropTypes.func,
        weeksAheadLimit: PropTypes.number
    };

    static defaultProps = {
        editing: false
    };

    state = {
        schedule: []
    };

    componentDidMount() {
        this.updateSchedule()
            .done(function() {
                this.props.onDateslotsLoad({
                    newDateslots: ScheduleUtils.getDateslotsFromSchedule(this.state.schedule, this.props.selectedDateslots),
                    dateslotsSelectionDone: this.dateslotsSelectionDone(this.props.selectedDateslots)
                });
            }.bind(this));
    }

    componentDidUpdate(prevProps, _) {
        var weekChanged = this.props.week !== prevProps.week,
            districtIdChanged = this.props.districtId !== prevProps.districtId,
            cleanerIdChanged = this.props.cleanerId !== prevProps.cleanerId,
            visitDurationChanged = this.props.visitDuration !== prevProps.visitDuration,
            visitsWeeklyChanged = this.props.visitsWeekly !== prevProps.visitsWeekly,
            languageChanged = this.props.language != prevProps.language,
            versionChanged = this.props.version != prevProps.version,
            companyIdChanged = this.props.companyId != prevProps.companyId;

        if(weekChanged || districtIdChanged || cleanerIdChanged || visitDurationChanged || versionChanged ||
           visitsWeeklyChanged || this.slotsChanged(prevProps.selectedDateslots) || languageChanged || companyIdChanged) {
            this.updateSchedule();
        }
    }

    slotsChanged = (prevSelectedDateslots) => {
        if(this.props.visitsWeekly === 1) {
            return false;
        }
        var dateslotsCount = this.props.selectedDateslots.length,
            prevDateslotsCount = prevSelectedDateslots.length,
            countChanged = dateslotsCount !== prevDateslotsCount;

        return countChanged && dateslotsCount !== this.props.visitsWeekly;
    };

    updateSchedule = () => {
        return this.props.executeSlowOperation(function() {
            var promise = this.fetchSchedule();

            promise.done(function(schedule) {
                this.setState({ schedule: schedule });
            }.bind(this));

            return promise;
        }.bind(this));
    };

    queryUrl = () => {
        var path;

        if(this.props.subscriptionId) {
            path = "/dashboard/subscription_availability";
        }
        else {
            path = "/subscription_availability";
        }

        return PathWithLocale.build(path);
    };

    queryUrlWithEvents = () => {
        var path;

        if(this.props.subscriptionId) {
            path = "/dashboard/subscription_availability_with_selected_events";
        }
        else {
            path = "/subscription_availability_with_selected_events";
        }

        return PathWithLocale.build(path);
    };

    fetchSchedule = () => {
        var queryParams = { week: this.props.week, district_id: this.props.districtId, cat: this.props.cat,
                            dog: this.props.dog, language: this.props.language, times_a_week: this.props.visitsWeekly,
                            visit_duration: this.props.visitDuration, hotel: this.props.hotel, company_id: this.props.companyId,
                            product_type: this.props.productType },
            queryUrl;

        if(this.props.selectedDateslots.length > 0 && this.props.visitsWeekly > 1) {
            queryUrl = this.queryUrlWithEvents();

            queryParams.events = _.map(this.props.selectedDateslots, function(dateslot) {
                return ScheduleUtils.sanitizeDateslot(dateslot);
            });
        }
        else {
            queryUrl = this.queryUrl();
        }

        if(this.props.cleanerId) {
            queryParams.cleaner_id = this.props.cleanerId;
        }

        if(this.props.subscriptionId) {
            queryParams.subscription_id = this.props.subscriptionId;
        }

        return $.get(queryUrl + "?" + $.param(queryParams));
    };

    dateslotsSelectionDone = (dateslots) => {
        return this.selectedDateslotsAreEnough(dateslots);
    };

    selectedDateslotsAreEnough = (dateslots) => {
        return dateslots.length === this.props.visitsWeekly;
    };

    getNewDateslots = (dateslot) => {
        var wasSelected = _.findIndex(this.props.selectedDateslots, function(ds) {
            return ScheduleUtils.dateslotsEqual(dateslot, ds);
        }) != -1;

        if(this.props.visitsWeekly == 1) {
            if(wasSelected) {
                return [];
            }
            else {
                return [dateslot];
            }
        }

        if(wasSelected){
            return _.reject(this.props.selectedDateslots, function(ds) {
                return ScheduleUtils.dateslotsEqual(dateslot, ds);
            });
        }
        else if (this.props.selectedDateslots.length < this.props.visitsWeekly) {
            return this.props.selectedDateslots.concat([dateslot]);
        }
        else {
            return this.props.selectedDateslots;
        }
    };

    handleDateSlotToggle = (dateslot) => {
        if(this.props.onSingleSlotSelect) this.props.onSingleSlotSelect();
        var newDateslots = this.getNewDateslots(dateslot);

        this.props.onDateslotsChange({
            newDateslots: newDateslots,
            dateslotsSelectionDone: this.dateslotsSelectionDone(newDateslots)
        });
    };

    hints = () => {
        if(!this.props.editing) {
            var count = _.min([this.props.selectedDateslots.length + 1, this.props.visitsWeekly]),
                messages = [i18n.t('funnel.time-form.hints.subscription-1'),
                            i18n.t('funnel.time-form.hints.subscription-2'),
                            i18n.t('funnel.time-form.hints.subscription-3')],
                relevantMessages = _.first(messages, count);

            return _.map(relevantMessages, function(message, index) {
                return {
                    message: message,
                    checked: index < this.props.selectedDateslots.length
                };
            }.bind(this));
        }
        else {
            var message;

            if(this.props.visitsWeekly ===  1) {
                message = i18n.t("dashboard.subscription.edit-hint-once-a-week");

            }
            else {
                message = i18n.t("dashboard.subscription.edit-hint-n-times-a-week");
            }

            return [{
                message: message,
                checked: false
            }];
        }
    };

    visibleNavigation = () => {
        return this.props.selectedDateslots.length === 0;
    };

    weeksAheadLimit = () => {
        var result;
        if(this.props.weeksAheadLimit != undefined) {
            result = this.props.weeksAheadLimit;
        }
        else {
            result = 4;
        }

        return result;
    };

    render() {
        if(this.state.schedule.length === 0) return null;

        return (
            <div className="slot-selector">
              {this.props.renderDescription(_.first(this.state.schedule).date, _.last(this.state.schedule).date)}

              <SlotSelector
                 schedule={this.state.schedule}
                 selectedDateslots={this.props.selectedDateslots}
                 onButtonToggle={this.handleDateSlotToggle}
                 onPrevWeek={this.props.selectedDateslots.length === 0 && this.props.onPrevWeek}
                 onNextWeek={this.props.selectedDateslots.length === 0 && this.props.week < this.weeksAheadLimit() && this.props.onNextWeek}
                 categoryPrice={this.props.categoryPrice}
                 allowUnavailable={this.props.allowUnavailable}
                 legendType={'housemaid'}
              />

                 {this.props.children}

                 {this.props.visitsWeekly > 1 &&
                    <MediaQuery maxWidth={BreakPoints.xsMax}>
                      <CheckList items={this.hints()} responsive={true}/>
                    </MediaQuery>}

                 <MediaQuery minWidth={BreakPoints.xsMax}>
                   <CheckList items={this.hints()} />
                 </MediaQuery>
            </div>
        );
    }
}

export default SubscriptionSchedule;
