import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import $ from 'jquery';

class ClientPreferencesCollector extends React.Component {
    static propTypes = {
        afterCollect: PropTypes.any,
        submitButtonName: PropTypes.string,
        initialWantsSubstitute: PropTypes.string,
        initialDeclinesTrainings: PropTypes.string
    };

    state = {
        wantsSubstitute: this.props.initialWantsSubstitute,
        declinesTrainings: this.props.initialDeclinesTrainings
    };

    submit = (event) => {
        event.preventDefault();

        $.ajax({
            type: "POST",
            url: PathHelper('update_client_preferences_path'),
            dataType: 'json',
            contentType: 'application/json',
            data : JSON.stringify({
                wants_substitute: this.state.wantsSubstitute,
                declines_trainings: this.state.declinesTrainings
            }),
            statusCode: {
                500: function() {
                    window.location.href = '/500';
                }
            }
        }).done(function() {
            this.props.afterCollect();
        }.bind(this));
    };

    onWantsSubstituteChange = (event) => {
        this.setState({wantsSubstitute: event.currentTarget.value});
    };

    onDeclinesTrainingsChange = (event) => {
        this.setState({declinesTrainings: event.currentTarget.value});
    };

    preferencesAreSelected = () => {
        return this.state.wantsSubstitute && this.state.declinesTrainings;
    };

    render() {
        var tooltip = (
            <Tooltip id="at-work-price-discount">
              {i18n.t('settings.postpone-cancel-tooltip')}
            </Tooltip>
        );

        return (
            <div>
              <form className="form-horizontal preferences_form" onSubmit={this.submit}>
              <div className="row" style={{marginBottom: '36px'}}>
                <div className="col-xs-1 icon_holder">
                  <span className="glyphicon glyphicon-refresh" aria-hidden="true"></span>
                </div>

                <div className="col-xs-7 col-sm-9">
                  {i18n.t("settings.substitutes-description")}

                  <OverlayTrigger placement="top" overlay={tooltip}>
                    <span className="glyphicon glyphicon-question-sign"></span>
                  </OverlayTrigger>
                </div>

                <div className="col-xs-2 col-sm-1" style={{textAlign: 'left'}}>
                  <label>
                    <input type="radio"
                           value="true"
                           checked={this.state.wantsSubstitute === "true"}
                           onChange={this.onWantsSubstituteChange}
                           id="client-wants-substitute-true" />
                    {i18n.t("yes")}
                  </label>
                </div>

                <div className="col-xs-2 col-sm-1" style={{textAlign: 'left'}}>
                  <label>
                    <input type="radio"
                           value="false"
                           checked={this.state.wantsSubstitute === "false"}
                           onChange={this.onWantsSubstituteChange}
                           id="client-wants-substitute-false" />
                    {i18n.t("no")}
                  </label>
                </div>
              </div>

              <div className="row" style={{marginBottom: '36px'}}>
                <div className="col-xs-1 icon_holder">
                  <span className="glyphicon glyphicon-user" aria-hidden="true"></span>
                </div>
                <div className="col-xs-7 col-sm-9">
                  {i18n.t("settings.trainings-description")}
                </div>
                <div className="col-xs-2 col-sm-1" style={{textAlign: 'left'}}>
                  <label>
                    <input type="radio"
                           value="false"
                           checked={this.state.declinesTrainings === "false"}
                           onChange={this.onDeclinesTrainingsChange}
                           id="client-declines-tranings-false" />
                    {i18n.t("yes")}
                  </label>
                </div>
                <div className="col-xs-2 col-sm-1" style={{textAlign: 'left'}}>
                  <label>
                    <input type="radio"
                           value="true"
                           checked={this.state.declinesTrainings === "true"}
                           onChange={this.onDeclinesTrainingsChange}
                           id="client-declines-tranings-true" />
                    {i18n.t("no")}
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div className="col-sm-12" style={{textAlign: 'right'}}>
                  <button type="submit"
                          className="btn btn-lg btn-primary"
                          disabled={!this.preferencesAreSelected()} >
                    {this.props.submitButtonName}
                  </button>
                </div>
              </div>
              </form>
            </div>
        );
    }
}


export default ClientPreferencesCollector;
