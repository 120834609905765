import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classNames from 'classnames';

import Modal from '@components/vendor/modal';
import DatePicker from "react-datepicker";

import {registerLocale} from "react-datepicker";
import {bg, el, es} from 'date-fns/locale';

registerLocale('bg', bg);
registerLocale('el', el);
registerLocale('es', es);

import "react-datepicker/dist/react-datepicker.css"

class CompanyFilter extends React.Component {
    static propTypes = {

        selectedFilter: PropTypes.string,
        selectedFilterDate: PropTypes.instanceOf(Date),
        handleFilterSelect: PropTypes.func,

        selectedSortingCriteria: PropTypes.number,
        handleSortingSelect: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            filterDialogOpen: false,
            calendarVisible: false,
            sortingOptions: [{value: 1, label: i18n.t('funnel.companies.sorting-criteria.domestina_rank')},
                {value: 2, label: i18n.t('funnel.companies.sorting-criteria.price')},
            ],
            filterOptions: [{value: 'all', label: i18n.t('home.when-filter-widget.all-possibilities')},
                {value: 'recent', label: i18n.t('home.when-filter-widget.next-4-days')},
                {value: 'date', label: i18n.t('home.when-filter-widget.specific-date')},
            ]
        }

    };

    openDialog = () => {
        this.setState({filterDialogOpen: true});
    };

    closeDialog = () => {
        this.setState({filterDialogOpen: false});
    };


    // Filter logic ---------------------------------
    // Most is duplicated in date filter selector
    // If it goes out of hand changing the same code in two places, create three components, one parent who uses
    // either a select box subcomponent or a radio boxes subcomponent
    handleFilterSelect = (filterName) => {
        if (filterName !== 'date') {
            this.setState({calendarVisible: false})
            this.props.handleFilterSelect(filterName, null);
        } else {
            this.setState({calendarVisible: true})
        }
    }

    handleDateSelect = (date) => {
        this.setState({calendarVisible: false,}, function () {
            this.props.handleFilterSelect('date', date)
        }.bind(this));
    }

    filterLabel = (filterOption) => {
        if(filterOption.value === 'date' && this.props.selectedFilterDate) {
            return i18n.t('home.when-filter-widget.on-date') + ' ' + this.props.selectedFilterDate.getDate() + ' ' +
                i18n.t('date.month_names').slice(1)[(((this.props.selectedFilterDate.getMonth()) % 12) + 12) % 12] +
                ', ' + i18n.t('date.abbr_day_names')[this.props.selectedFilterDate.getDay()];
        } else {
            return filterOption.label;
        }
    }

    calendarMinDate() {
        let d = new Date();
        let hours = d.getHours();
        if (hours >= 19) {
            return d.setDate(d.getDate() + 2);
        } else {
            return d.setDate(d.getDate() + 1);
        }
    }

    calendarMaxDate() {
        let d = new Date();
        return d.setDate(d.getDate() + 28);
    }

    renderFilterOptions = () => {
        return _.map(this.state.filterOptions, function(filterOption) {
            return (
                <div className={classNames({'single-option-container': true, 'single-option-container-selected': this.props.selectedFilter === filterOption.value })}
                     onClick={_.partial(this.handleFilterSelect, filterOption.value)}
                     key={'sorting-value-option-' + filterOption.value}>
                    <div className={classNames({'radio-box': true})}>
                        {this.props.selectedFilter === filterOption.value &&
                        <div className="small-select-ball"></div>
                        }
                    </div>
                    {this.filterLabel(filterOption)}
                </div>
            )
        }.bind(this))
    }
    // Filter logic end -------------

    selectedSortingLabel = () => {
        return _.find(this.state.sortingOptions, function(sortingOption){ return sortingOption.value === this.props.selectedSortingCriteria}.bind(this)).label;
    };

    selectedFilteringLabel = () => {
        return this.filterLabel(_.find(this.state.filterOptions, function(filterOption){ return filterOption.value === this.props.selectedFilter}.bind(this)));
    };

    renderSortingOptions = () => {
        return _.map(this.state.sortingOptions, function(sortingOption) {
                return (
                    <div className={classNames({'single-option-container': true, 'single-option-container-selected': this.props.selectedSortingCriteria === sortingOption.value })}
                         onClick={_.partial(this.props.handleSortingSelect, sortingOption.value)}
                         key={'sorting-value-option-' + sortingOption.value}>
                        <div className={classNames({'radio-box': true})}>
                            {this.props.selectedSortingCriteria === sortingOption.value &&
                                <div className="small-select-ball"></div>
                            }
                        </div>
                        {sortingOption.label}
                    </div>
                )
            }.bind(this))
    }


    renderModal = () => {
        return (
            <Modal size="large" isOpen={this.state.filterDialogOpen} onHide={this.closeDialog}
                   dialogClassName="company-filter-dialog">
                <div className="custom-modal-header">
                    <div className="close-button" onClick={this.closeDialog}></div>

                    <div className="dialog-header-container">
                        {i18n.t('funnel.companies.filter.modal-header')}
                    </div>
                </div>
                <div className="custom-modal-content">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 ">
                            <div className="section">
                                <div className={'section-header'}>{i18n.t('funnel.companies.filter.order-by')}</div>
                                <div className="options-container first-options-container">
                                    {this.renderSortingOptions()}
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6 second-section">
                            <div className="section">
                                <div className={'section-header'}>{i18n.t('funnel.companies.filter.select-when')}</div>
                                <div className="options-container">
                                    {!this.state.calendarVisible && this.renderFilterOptions()}
                                    {this.state.calendarVisible &&
                                        <div className="date-filter-calendar-container">
                                            <DatePicker
                                                onChange={this.handleDateSelect}
                                                locale={i18n.locale}
                                                minDate={this.calendarMinDate()}
                                                maxDate={this.calendarMaxDate()}
                                                inline
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close-button-container">
                        <button onClick={this.closeDialog}
                                className={'btn btn-primary polyxena-submit-button'}>{i18n.t('form.buttons.apply')}</button>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        return (
           <div className='company-filter-container'>
               <div className={'company-filter-cta-with-selection'}>
                   <div className={'selection-bubble'} onClick={this.openDialog}>
                       <span className="glyphicon glyphicon-sort-by-attributes-alt" aria-hidden="true"></span>
                       {this.selectedSortingLabel()}
                   </div>

                   <div className={'selection-bubble'} onClick={this.openDialog}>
                       <span className="glyphicon glyphicon-calendar" aria-hidden="true"></span>
                       {this.selectedFilteringLabel()}
                   </div>
               </div>
               {this.renderModal()}
           </div>
        );
    }
}

export default CompanyFilter;

