var Validator = {
    isPresent: function(value) {
        return value !== null && value !== "";
    },

    isEmail: function(value) {
        // The regex is taken from https://html.spec.whatwg.org/multipage/forms.html#valid-e-mail-address
        var format = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return format.test(value);
    },

    isPhone: function(value) {
        var format = /^[0-9 \-+()]+$/;
        return format.test(value);
    }
};

export default Validator;
