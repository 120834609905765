import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import MoneyUtils from '@services/money_utils'
import Pricelist from '@services/pricelist'
import LabelService from '@services/label_service';

class Abstract extends React.Component {
    static propTypes = {
        initiallySelectedProductOptions: PropTypes.object,
        pricelistData: PropTypes.object,
        onItemSelectionChange: PropTypes.func.isRequired,
        onSubmissionRequest: PropTypes.func,
        showExplanation: PropTypes.bool,
        isOptional: PropTypes.bool,
        icons: PropTypes.shape({
            glass_doors: PropTypes.string,
            single_windows: PropTypes.string,
            large_windows: PropTypes.string,
            single_french_windows: PropTypes.string,
            large_french_windows: PropTypes.string,
        }),

        // only pest control
        supportedProductOptions: PropTypes.arrayOf(PropTypes.object),
        discountPercent: PropTypes.number,
        alternativeDesign: PropTypes.bool,
        alwaysExpanded: PropTypes.bool,
    };

    static defaultProps = {
        showExplanation: false,
        alternativeDesign: false,
        alwaysExpanded: false,
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.state.pricelist = new Pricelist(this.props.pricelistData);
    };

    componentDidMount() {
        this.sendSelection(this.state.selectedProductOptions);
    }

    selectorType = () => {
        throw "define the selector type method";
    };

    // should return an array with 2 strings
    samplePriceOptions = () => {
        throw "define the samplePriceOptions method";
    };

    priceHookText = () => {
        var item1Price = this.priceForItem(this.samplePriceOptions()[0]) || 0;
        var item2Price = this.priceForItem(this.samplePriceOptions()[1]) || 0;
        if(item1Price > 0 || item2Price > 0){
            return (
                <div className={'price-hook'}>
                    {item1Price > 0 && <span>{LabelService.labelOption(this.samplePriceOptions()[0], 1).substring(2)} <b>{MoneyUtils.price(item1Price)}</b>,&nbsp;</span>}
                    {item2Price > 0 && <span>{LabelService.labelOption(this.samplePriceOptions()[1], 1).substring(2)} <b>{MoneyUtils.price(item2Price)}</b> ...</span>}
                </div>
            )
        } else if(this.props.pricelistData && !this.state.pricelist.doesSupportService(this.selectorType())){
            return (
                <div className={'price-hook'}>
                    {i18n.t('components.customizers.not-offered-by-company')}
                </div>
            )
        } else {
            return (
                <div className={'price-hook'}>
                    {i18n.t('funnel.add-services.click-for-prices')}
                </div>
            )
        }
    };

    booleanProductOptions = () => {
      return ['after_renovation_mode']
    };

    initializeProductOptions = (props) => {
        if (props.initiallySelectedProductOptions) {
            Object.keys(this.state.selectedProductOptions).forEach(function (key) {
                if(props.initiallySelectedProductOptions[key] !== undefined) {
                    if(_.contains(this.booleanProductOptions(), key)){
                        this.state['selectedProductOptions'][key] = props.initiallySelectedProductOptions[key];
                    } else {
                        this.state['selectedProductOptions'][key] = parseInt(props.initiallySelectedProductOptions[key]);
                    }
                }

            }.bind(this));

            this.state['checkbox'] = this.hasNonZeroValue(this.nonBooleanSelectedProductOptions());
        }
    };

    // we want to tell the parent if we're valid


    sendSelection = (productHash, overrideTotalPrice) => {
        let totalPrice;

        if(overrideTotalPrice === undefined)
            totalPrice = this.calculateTotalPrice();
        else
            totalPrice = overrideTotalPrice;

        this.props.onItemSelectionChange(this.selectorType(),
            productHash,
            this.selectionValid(),
            totalPrice
        );
    };

    toggleCheckbox = () => {
        if(!this.showExpandable()) return;

        var toggleState = !this.state.checkbox;
        this.setState({checkbox: toggleState}, function () {
            var products = toggleState ? this.state.selectedProductOptions : this.zeroSelectedProducts();
            var totalPrice = toggleState ? undefined : 0; // it will be calculated in send selection
            this.sendSelection(products, totalPrice);
        }.bind(this));
    };

    calculateTotalPrice = () => {
        // every element should have a price in order to calculate total price
        if(_.every(this.nonBooleanSelectedProductOptions(), function(quantity, fieldName){ return quantity === 0 || this.priceForItem(fieldName) !== undefined}.bind(this))) {
            return _.reduce(_.map(this.nonBooleanSelectedProductOptions(), function(count, productName){
                    return count === 0 ? 0 : count * this.priceForItem(productName);
                }.bind(this)),
                function (sum, itemPrice) { return sum + itemPrice;}, 0);
        } else{
            return undefined;
        }
    };

    updateOption = (fieldName, value) => {
        var selectedProductOptions = this.state.selectedProductOptions;
        selectedProductOptions[fieldName] = parseInt(value);
        this.setState({selectedProductOptions: selectedProductOptions}, function () {
            this.sendSelection(this.state.selectedProductOptions);
        }.bind(this));
    };

    renderRenovationModeCheckbox = () => {
        if(this.props.isOptional)
            return '';
        else
            return (
                <div className={'row'} >
                    <div className="col-xs-12" style={{'textAlign': 'center'}}>
                        <div className="checkbox">
                            <label className={'placeholder'}>
                                <input type="checkbox" onChange={this.toggleAfterRenovationMode} checked={this.state.selectedProductOptions['after_renovation_mode']}
                                       value={this.state.selectedProductOptions['after_renovation_mode']}/>
                                {i18n.t('components.customizers.after-renovation-mode')}
                            </label>
                        </div>
                    </div>
                </div>
            )
    }

    toggleAfterRenovationMode = () => {
        var selectedProductOptions = this.state.selectedProductOptions;
        selectedProductOptions['after_renovation_mode'] = !selectedProductOptions['after_renovation_mode'] ;
        this.setState({selectedProductOptions: selectedProductOptions}, function () {
            this.sendSelection(this.state.selectedProductOptions);
        }.bind(this));
    };

    isAfterRenovationMode = () => {
        return !!this.state.selectedProductOptions['after_renovation_mode'];
    }

    priceForItem = (fieldName) => {
        if(this.props.pricelistData)
            return this.state.pricelist.priceFor(fieldName, this.isAfterRenovationMode());
        else
            return undefined;
    };

    fieldSupported = (fieldName) => {
        if (this.props.pricelistData === undefined)
            return true;
        else return this.state.pricelist.isPriceValid(fieldName);
    };

    extraInformation = (fieldName) => {
        if (!this.fieldSupported(fieldName)) {
            return i18n.t('components.customizers.not-offered');
        } else if (this.priceForItem(fieldName)) {
            let count = Math.max(1, this.state.selectedProductOptions[fieldName]);
            let price = this.priceForItem(fieldName);
            if(this.props.discountPercent)
                price = price * ((100 - this.props.discountPercent) / 100);
            return MoneyUtils.price(count * price);
        } else {
            return undefined;
        }
    };

    showInputFields = () => {
        return !this.props.isOptional || this.state.checkbox;
    };


    // if the checkbox is not pressed, then it's valid
    selectionValid = () => {
        return this.hasNonZeroValue(this.nonBooleanSelectedProductOptions());
    };

    nonBooleanSelectedProductOptions = () => {
        return _.omit(this.state.selectedProductOptions, this.booleanProductOptions())
    }

    hasNonZeroValue = (hashMap) => {
        return _.some(Object.values(hashMap), function (value) {
            return value > 0;
        });
    };

    areaInputFieldKeyPressed = (event) => {
        if (event.key === 'Enter' && this.props.onSubmissionRequest) {
            this.props.onSubmissionRequest();
        }
    };

    showExpandable = () => {
        return this.props.isOptional && this.state.pricelist.doesSupportService(this.selectorType());
    }
}


export default Abstract;
