import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import MediaQuery from 'react-responsive';
import CheckList from '@components/common/cleaner_and_slot_selector/check_list'
import PriceTag from '@components/common/price_tag'
import CleanerPhoto from '@components/common/cleaner_photo'
import CleanerCategory from '@components/common/cleaner_category'
import BreakPoints from '@services/breakpoints';
import CleanerUtils from '@services/cleaner_utils';
import $ from 'jquery';
import PageUtils from '@services/page_utils';

class CleanerSelectBox extends React.Component {
    static propTypes = {
        cleaner: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            image_path: PropTypes.string,
            category: PropTypes.string,
            comment: PropTypes.shape({
                client_name: PropTypes.string,
                content: PropTypes.string
            }),
            foreign_languages: PropTypes.arrayOf(PropTypes.string)
        }),

        onSelect: PropTypes.func,
        categoryPrice: PropTypes.object,
        active: PropTypes.bool,
        noButton: PropTypes.bool,
        onMoreInfoAboutCleaner: PropTypes.func,
        displayPrice: PropTypes.bool,
        displayCompany: PropTypes.bool
    };

    state = {
        descriptionVisible: false
    };

    handleClick = () => {
        this.props.onSelect(this.props.cleaner);
    };

    renderCleanerDescription = () => {
        return (
            <div className="cleaner-description">
              <p>{this.props.cleaner.comment.content}</p>
            </div>
        );
    };

    boxClasses = () => {
        return 'cleaner-box' + ' ' +
               (this.props.active ? 'active' : '') + ' ' +
               this.props.cleaner.category + ' ' +
               (this.props.displayPrice ? 'with-price' : '');
    };

    renderButton = () => {
        if(this.props.noButton) {
            return null;
        }
        else if(this.props.active) {
            return <button className="btn btn-primary active"> {i18n.t('form.buttons.selected')} </button>;
        }
        else {
            return <button className="btn btn-primary" onClick={this.handleClick}> {i18n.t('form.buttons.choose')} </button>;
        }
    };

    renderCleanerLanguages = () => {
        return(
            <div className="cleaner-languages">
              {this.props.cleaner.foreign_languages.length > 0 && _.map(this.props.cleaner.foreign_languages, function(language, i) {
                  var classes = "flag-icon flag-icon-" + language;
                  return (
                      <span className={classes} title={i18n.t("languages." + language)} key={i}></span>
                  );
              })}
            </div>
        );
    };

    toggleDescription = () => {
        var descriptionVisible = this.state.descriptionVisible;
        this.setState({descriptionVisible: !descriptionVisible});
    };

    requestMoreInfoAboutCleaner = (e) => {
        e.preventDefault();
        this.props.onMoreInfoAboutCleaner(this.props.cleaner.code);
    };

    renderFullProfileTrigger = () => {
        return (
            <a className="view-full-profile" href="" onClick={this.requestMoreInfoAboutCleaner}>
              {i18n.t('funnel.cleaners-options.all-cleaners.show-full-profile')}
            </a>
        );
    };

    renderNewCleanerLabel = () => {
        // var tooltip = (
        //     <Tooltip>
        //       {i18n.t('funnel.companies.new-provider-tooltip')}
        //     </Tooltip>
        // );

        return (
            <div className="badges-container">
              <div className="new-cleaner-label">
                {/*<OverlayTrigger placement="top" overlay={tooltip}>*/}
                  <span>{CleanerUtils.humanCategory(this.props.cleaner.category)}</span>
                {/*</OverlayTrigger>*/}
              </div>
            </div>
        );
    };

    render() {
        return (
            <div className={classNames({ "cleaner-preview": true,  "profile-preview-select-box": true,  "with-company": this.props.displayCompany})}>
              <div className="row">
                <div className="col-sm-3 logo-container">
                  <CleanerPhoto {...this.props.cleaner}/>
                </div>

                <div className="col-sm-6">
                  <div className="limited-height">
                    <h3>
                      {this.props.cleaner.name}
                    </h3>

                    <CleanerCategory {...this.props.cleaner} displayDeafTooltip={true}/>
                    {this.renderCleanerLanguages()}

                    <div className="visible-xs">
                      {this.props.cleaner.rating &&
                          <div className="rating badges-container">
                            <span className="rating-data label label-default">
                                <span className="label-text">{i18n.t('cleaner-profile.rating')}</span> {this.props.cleaner.rating.value} / {this.props.cleaner.rating.max} <span className="star"/>
                            </span>
                                {' '}
                            <div>
                              <a href="" onClick={this.requestMoreInfoAboutCleaner}>
                                {i18n.t('funnel.companies.out-of-visits', {events_count: this.props.cleaner.visitsDone})}
                              </a>
                            </div>
                          </div>}
                    </div>

                    {this.renderCleanerDescription()}
                  </div>
                  <div className="spacer view-full-profile with-lines">
                    {this.renderFullProfileTrigger()}
                  </div>
                </div>
                <div className="col-sm-3" style={{ position: 'initial' }}>
                  <div className="hidden-xs">
                    {this.props.cleaner.rating &&
                        <div className="rating badges-container">
                          <div className="rating-data label label-default">
                              <span className="label-text">{i18n.t('cleaner-profile.rating')}</span> {this.props.cleaner.rating.value} / {this.props.cleaner.rating.max} <span className="star"/>
                          </div>
                              {' '}
                          <div>
                            <a href="" onClick={this.requestMoreInfoAboutCleaner}>
                              {i18n.t('funnel.companies.out-of-visits', {events_count: this.props.cleaner.visitsDone})}
                            </a>
                          </div>
                        </div>}

                    {!this.props.cleaner.rating && this.renderNewCleanerLabel()}
                  </div>

                  <div className="fixed-to-bottom">
                    {this.props.displayPrice && this.props.cleaner.price &&
                      <div className="rate">
                        <div className="data">
                          <PriceTag amountWithCurrency={this.props.cleaner.price} className="price-tag green-text"/>
                        </div>

                        <div>
                          {i18n.t('funnel.cleaners-options.per-visit')}
                        </div>
                      </div>}

                      {!this.props.displayCompany && this.renderButton()}
                  </div>
                </div>
              </div>


              {this.props.displayCompany && <div className="row company-info">
                <div className={classNames({ 'col-xs-6': !this.props.noButton, 'col-xs-12': this.props.noButton, 'col-sm-3': true, 'company-logo-container': true })}>
                  <img src={this.props.cleaner.company_horizontal_logo_path}/>
                </div>
                <div className="col-xs-6 col-sm-9">
                  {this.renderButton()}
                </div>
              </div>}
            </div>
        );
    }
}

class CleanerSelector extends React.Component {
    static propTypes = {
        cleaners: PropTypes.arrayOf(PropTypes.object),
        cleaner: PropTypes.object,
        onSelect: PropTypes.func,
        categoryPrice: PropTypes.object,
        hintText: PropTypes.string,
        onMoreInfoAboutCleaner: PropTypes.func,
        listCompanies: PropTypes.bool
    };

    componentDidMount() {
        if(window.innerWidth < BreakPoints.xsMax) {
            var thisNode = $(ReactDOM.findDOMNode(this));

            if(thisNode && thisNode.offset() && thisNode.offset().top) {
                $('html, body').animate({
                    scrollTop: thisNode.offset().top - PageUtils.navbarHeight()
                }, 1000);
            }
        }
    }

    hasSingleChoice = () => {
        return this.props.cleaners.length === 1;
    };

    wrapperClasses = () => {
        return 'box-wrapper' + ' ' + 'cleaners' + this.props.cleaners.length;
    };

    hints = () => {
        if(this.hasSingleChoice()) return null;

        return [{
            message: this.props.hintText,
            checked: !!this.props.cleaner.id
        }];
    };

    cleanersByRating = () => {
        return _.sortBy(this.props.cleaners, function(cleaner) {
            return -parseFloat((cleaner.rating && cleaner.rating.value) || 0);
        });
    };

    selectBoxes = () => {
        return _.map(this.cleanersByRating(), function(cleaner) {
            return (
                <CleanerSelectBox
                   active={cleaner.id === this.props.cleaner.id || this.hasSingleChoice()}
                   noButton={this.hasSingleChoice()}
                   categoryPrice={this.props.categoryPrice}
                   cleaner={cleaner}
                   onSelect={this.props.onSelect}
                   onMoreInfoAboutCleaner={this.props.onMoreInfoAboutCleaner}
                   displayPrice={this.props.listCompanies}
                   displayCompany={this.props.listCompanies}
                   />
            );
        }.bind(this));
    };

    render() {
        return (
            <div className="cleaner-selector">
              <MediaQuery minWidth={BreakPoints.xsMax}>
                <CheckList items={this.hints()} />
              </MediaQuery>

              {this.hasSingleChoice() &&
              <p className="message">{i18n.t('funnel.time-form.hints.selected-cleaner')}</p> }

              {_.map(this.selectBoxes(), function(selectBox, i) {
                  return (
                      <div className={this.wrapperClasses()} key={i}>
                        {selectBox}
                      </div>
                  );
              }.bind(this))}
            </div>
        );
    }
}

export default  CleanerSelector;
