import BookingRequest from '@services/booking_request'
import _ from 'underscore';

class Pricelist {
    constructor(pricelistData) {
        this.pricelistData = pricelistData;
        this.bookingRequest = new BookingRequest();
    }

    priceFor = (productOption, after_renovation_mode=false) => {
        if (productOption === 'deep_cleaning_area') {
            return parseFloat(this.pricelistData['deep_cleaning_price']);
        }
        else if (productOption === 'disinfection_area') {
            return parseFloat(this.pricelistData['disinfection_price']);
        }
        else if (productOption === 'light_cleaning_area') {
            return parseFloat(this.pricelistData['light_cleaning_price']);
        }
        else if (_.contains(this.bookingRequest.fieldsForService('carpet_and_upholstery_cleaning'), productOption)) {
            return parseFloat(this.pricelistData[this.removePlural(productOption)]);
        }
        else if (productOption === 'after_renovation_area') {
            return parseFloat(this.pricelistData['after_renovation_cleaning_price']);
        }
        else if (_.contains(this.bookingRequest.fieldsForService('bathroom_cleaning'), productOption)) {
            let renovationSurgePercent = after_renovation_mode ? this.pricelistData['bathroom_renovation_surge_percent'] : 0;
            return parseFloat(this.pricelistData[productOption]) * (100 + renovationSurgePercent)/100;
        }
        else if (_.contains(this.bookingRequest.fieldsForService('oven_cleaning'), productOption)) {
            return parseFloat(this.pricelistData[productOption]);
        }
        else if (_.contains(this.bookingRequest.fieldsForService('car_upholstery_cleaning'), productOption)) {
            return parseFloat(this.pricelistData[this.removePlural(productOption)]);
        }
        else if (_.contains(this.bookingRequest.fieldsForService('window_cleaning'), productOption)) {
            let renovationSurgePercent = after_renovation_mode ? this.pricelistData['window_renovation_surge_percent'] : 0;
            return parseFloat(this.pricelistData[productOption]) * (100 + renovationSurgePercent)/100;
        }
        else {
            return undefined;
        }
    }

    totalPrice = (selectedProductOptions) => {
        let after_renovation_mode = selectedProductOptions['after_renovation_mode'] === true || selectedProductOptions['after_renovation_mode'] === 'true';
        return _.reduce(_.map(selectedProductOptions, function(count, productName){
                            return (productName === 'after_renovation_mode' || count === '' || count === 0 || productName === 'comment') ? 0 : count * this.priceFor(productName, after_renovation_mode);
                        }.bind(this)),
                        function (sum, itemPrice) { return sum + itemPrice;}, 0);
    };

    doesSupportService(service_type) {
        return _.contains(this.pricelistData.supported_services, service_type);
    }

    rejectItemsWithNoPrice(productOptions, serviceTypes) {
        var allFields = _.flatten(_.map(serviceTypes, function (serviceType) {
            return this.bookingRequest.fieldsForService(serviceType);
        }.bind(this)));

        return _.object(_.map(productOptions, function(count, name){
            if(_.contains(allFields, name) && !this.isPriceValid(name)) {
                return [name, 0];
            } else{
                return [name, count ];
            }
        }.bind(this)));
    }

    removePlural(fieldName) {
        if (fieldName.endsWith('es'))
            return fieldName.substring(0, fieldName.length - 2);
        else
            return fieldName.substring(0, fieldName.length - 1);
    }

    isPriceValid(productOption) {
        var price = this.priceFor(productOption);
        return price !== undefined && price > 0;
    }
}

export default Pricelist;