import React from 'react';
import PropTypes from 'prop-types';

import ProductUtils from '@services/product_utils';

class FunnelBreadcrumbsProductInfo extends React.Component {
    static propTypes = {
        product: PropTypes.shape({
            typeName: PropTypes.string,
            attributesDescription: PropTypes.string
        })
    };

    title = () => {
        if(this.props.product.typeName || this.props.product.productName) {
            if(ProductUtils.typeToString(this.props.product.productType) === "membership") {
                return this.props.product.typeName;
            }
            else {
                return this.props.product.typeName + " " + this.props.product.productName;
            }
        }
        else {
            return i18n.t('funnel.breadcrumbs.product.title');
        }
    };

    content = () => {
        return this.props.product.attributesDescription || i18n.t('funnel.breadcrumbs.product.content');
    };

    render() {
        return (
            <div>
              <div className="little-darkblue-title" dangerouslySetInnerHTML={{__html: this.title()}}>
              </div>
              <p>
                {this.content()}
              </p>
            </div>
        );
    }
};

export default FunnelBreadcrumbsProductInfo;
