//    <div style=" ">
//         <%= react_component 'shared/service_combinator',
//                             combinations: ServiceCombinator.combinations,
//                             buttonName: "Комбинирай Услуги",
//                             selectedCityName: @selected_city.transliterated_name,
//                             selectedCityId: @selected_city.id
//
//         %>
//     </div>

import React from 'react';
import PropTypes from "prop-types";
import _ from 'underscore';
import Modal from '@components/vendor/modal'
import classNames from 'classnames'

class ServiceCombinator extends React.Component {
    static propTypes = {
        combinations: PropTypes.object,
        buttonName: PropTypes.string,
        selectedCityName: PropTypes.string,
        selectedCityId: PropTypes.number,
    };

    state = {
        configuratorOpen: false,
        selectedServices: [],
        cityName: this.props.selectedCityName,
        cityId: this.props.selectedCityId
    }

    componentWillMount() {
        SelectedCityStore.onChange(function(id, name) {
            this.setState({cityId: id, cityName: name});
        }.bind(this));
    };

    openModal = () => {
        this.setState({configuratorOpen: true});
        return false;
    };

    closeModal = () => {
        this.setState({configuratorOpen: false});
    };

    services = () => {
        return _.keys(this.props.combinations)
    }

    selectService = (serviceType) => {
        if(this.serviceDisabled(serviceType))
            return;

        if(_.contains(this.state.selectedServices, serviceType)) {
            this.setState({selectedServices: _.without(this.state.selectedServices, serviceType)})
        } else {
            var newServiceSet = _.reject(this.state.selectedServices, function(selectedService){
                return this.serviceIncludes(serviceType, selectedService) ||
                    this.servicesIncompatible(serviceType, selectedService);
            }.bind(this));

            this.setState({selectedServices: _.union(newServiceSet, [serviceType])});
        }
    }

    serviceSelected = (serviceType) => {
        return _.contains(this.state.selectedServices, serviceType);
    }

    serviceDisabled = (serviceType) => {
        return this.serviceIncluded(serviceType) || this.serviceIncompatible(serviceType) || this.serviceNotSupported(serviceType);
    };


    serviceNotSupported = (serviceType) => {
        return !_.contains(this.props.combinations[serviceType].available_in_cities, this.state.cityId);
    }


    serviceIncluded = (serviceType) => {
        return this.findServiceIncluding(serviceType) !== undefined;
    };

    findServiceIncluding = (serviceType) => {
        return _.find(this.state.selectedServices, function (selectedService) {
            return this.serviceIncludes(selectedService, serviceType)
        }.bind(this));
    };

    serviceIncludes = (selectedService, serviceType) => {
        return _.contains(this.props.combinations[selectedService].includes, serviceType);
    };




    serviceIncompatible = (serviceType) => {
        return this.findServiceIncompatibility(serviceType) !== undefined;
    };

    findServiceIncompatibility = (serviceType) => {
        return _.find(this.state.selectedServices, function (selectedService) {
            return this.servicesIncompatible(selectedService, serviceType)
        }.bind(this));
    };

    servicesIncompatible = (selectedService, serviceType) => {
        return _.contains(this.props.combinations[selectedService].incompatible, serviceType);
    };

    explanationClassAndText = (serviceType) => {
        var includedBy = this.findServiceIncluding(serviceType);
        var incompatibleWith = this.findServiceIncompatibility(serviceType);

        if(includedBy !== undefined){
            return ['included', "Услугата се включва в " + I18n.t('services.' + includedBy + '.title')]
        }else if(incompatibleWith !== undefined){
            return ['not-compatible', "Услугата е несъвместима с " + I18n.t('services.' + incompatibleWith + '.title')];
        }else if(this.serviceNotSupported(serviceType)){
            return ['not-supported', "Услугата не се предлага в " + this.state.cityName];
        } else{
            return ['',''];
        }
    };

    explanationText = (serviceType) => {
        return this.explanationClassAndText(serviceType)[1];
    };

    explanationClasses = (serviceType) => {
        var classes = {
            'explanation-text': true,
            'hidden': !this.serviceDisabled(serviceType),
        };
        classes[this.explanationClassAndText(serviceType)[0]] = true;
        return classes;
    };

    combineButtonClick = () => {
        alert("Not implemented yet!")
    }

    render() {
        return (
            <div className={'service-combinator-container'}>
                <div className={'explanation-container'}>
                    Ако желате повече от една услуга, <a href="#" onClick={this.openModal}>комбинирай услуги</a>.
                </div>
                <Modal size="large" isOpen={this.state.configuratorOpen} onHide={this.closeModal}
                       dialogClassName="service-configurator-dialog">
                    <div className="custom-modal-content">
                        <div className="modal-header">
                            Комбинирай услуги в град {this.state.cityName}
                            <div className="modal-subheader">
                                Ако искате да поръчате повече от една услуга можете да го направите по-долу.
                            </div>
                        </div>
                        {_.map(this.services(), function (serviceType) {
                            return (
                                <div className={classNames({'service-container': true})} key={'service-container-' + serviceType}>
                                    <div className={classNames({'service-name': true, 'disabled': this.serviceDisabled(serviceType)})} onClick={_.partial(this.selectService, serviceType)}>
                                        <span className={classNames({'glyphicon': true,
                                            'glyphicon-unchecked': !this.serviceSelected(serviceType),
                                            'glyphicon-check': this.serviceSelected(serviceType),})}/>
                                        {I18n.t('services.' + serviceType + '.title')}
                                        <span className={classNames(this.explanationClasses(serviceType))}>
                                            &nbsp; {this.explanationText(serviceType)}
                                        </span>
                                    </div>
                                </div>
                            );
                        }.bind(this))}
                        <div className="cta-container">
                            <button className={'btn btn-primary combine-cta'}
                                    onClick={this.combineButtonClick}>ПОРЪЧАЙ</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default ServiceCombinator;