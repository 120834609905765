import React from 'react';
import PropTypes from 'prop-types';

class CleanerPhoto extends React.Component {
    static propTypes = {
        image_path: PropTypes.string,
        deaf: PropTypes.bool
    };

    render() {
        return (
            <div className={"cleaner-photo"} style={{'backgroundImage': 'url(' + this.props.image_path + ')'}}>
                {this.props.deaf && <div className="deaf-symbol"><span className="deaf-img-white"></span></div>}
            </div>
        );
    }
}

export default CleanerPhoto;
