import React from 'react';

import _ from 'underscore';
import LabelService from '@services/label_service';
import NumberField from '@components/common/number_field'
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import BookingRequest from '@services/booking_request'
import AnimateHeight from "react-animate-height";
import classNames from 'classnames'


class CarpetAndUpholsteryCleaning extends AbstractProductCustomizer {
    static selectorType = 'carpet_and_upholstery_cleaning';
    static zeroSelectedProducts = (new BookingRequest()).zeroSelectedProductsForService(CarpetAndUpholsteryCleaning.selectorType);

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = this.zeroSelectedProducts();
        this.initializeProductOptions(props);

        this.state.sections = {upholstery: _.some(['sofa_seats', 'armchairs', 'chairs', 'stools'], function(fieldName){
                return (!!this.state.selectedProductOptions[fieldName] && this.state.selectedProductOptions[fieldName] > 0);
            }.bind(this)),
            mattresses:  _.some(['single_mattresses', 'double_mattresses'], function(fieldName){
                return (!!this.state.selectedProductOptions[fieldName] && this.state.selectedProductOptions[fieldName] > 0);
            }.bind(this)),
            carpets:  _.some(['large_carpets', 'small_carpets'], function(fieldName){
            return (!!this.state.selectedProductOptions[fieldName] && this.state.selectedProductOptions[fieldName] > 0);
        }.bind(this)),
        };
    };

    selectorType = () => {
        return CarpetAndUpholsteryCleaning.selectorType;
    };

    zeroSelectedProducts = () => {
        return _.clone(CarpetAndUpholsteryCleaning.zeroSelectedProducts);
    };

    samplePriceOptions = () => {
        return ['single_mattresses', 'chairs']
    };

    toggleOpenSection = (sectionName) => {
        // var newSections = _.clone(this.state.sections);
        this.state.sections[sectionName] = !this.state.sections[sectionName];
        this.setState({sections: this.state.sections})
    }

    isSectionOpen = (sectionName) => {
        return this.props.alwaysExpanded || this.state.sections[sectionName];
    }

    renderFields = () => {
        return (
            <div className={'service-customizer-input-fields-container'}>
                {this.props.showExplanation &&
                <div className="row">
                    <div className="col-xs-12">
                        <div className='secondary-service-explanation col-md-8 col-md-offset-2'>
                            {i18n.t('funnel.additional-service.carpet-and-upholstery.secondary-description')}
                        </div>
                    </div>
                </div>}

                <div className="row">
                    <div className="col-xs-12">
                        <div className="customization-header"
                             onClick={_.partial(this.toggleOpenSection, 'upholstery')}>
                            {i18n.t('components.customizers.carpet-and-upholstery.upholstery')}
                            <div className={classNames({
                                hidden: this.props.alwaysExpanded,
                                arrow: true, "arrow-down": !this.isSectionOpen('upholstery'),
                                "arrow-up": this.isSectionOpen('upholstery')
                            })}>
                            </div>
                        </div>
                    </div>
                </div>
                <AnimateHeight id={'upholstery_expanded'}
                               duration={500}
                               height={(this.isSectionOpen('upholstery')) ? 'auto' : 0}>
                    <div className="row customization-content">
                        <div className="col-xs-12 col-sm-4">
                            <div className="customization-explanation">
                                {i18n.t('components.customizers.carpet-and-upholstery.upholstery-details')}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-8">
                            <div className="row">
                                {_.map(['sofa_seats', 'armchairs'], function (option) {
                                    return (
                                        <div className="col-xs-12 col-sm-6" key={option}>
                                            <NumberField value={this.state.selectedProductOptions[option]}
                                                         label={LabelService.labelOption(option, this.state.selectedProductOptions[option])}
                                                         onChange={_.partial(this.updateOption, option)}
                                                         disabled={!this.fieldSupported(option)}
                                                         price={this.extraInformation(option)}
                                                         min={0}
                                            />
                                        </div>
                                    );
                                }.bind(this))}
                            </div>

                            <div className="row">
                                {_.map(['chairs', 'stools'], function (option) {
                                    return (
                                        <div className="col-xs-12 col-sm-6" key={option}>
                                            <NumberField value={this.state.selectedProductOptions[option]}
                                                         label={LabelService.labelOption(option, this.state.selectedProductOptions[option])}
                                                         onChange={_.partial(this.updateOption, option)}
                                                         disabled={!this.fieldSupported(option)}
                                                         price={this.extraInformation(option)}
                                                         min={0}
                                            />
                                        </div>
                                    );
                                }.bind(this))}
                            </div>

                        </div>
                    </div>
                </AnimateHeight>

                <hr className="customization-separator"/>

                <div className="row">
                    <div className="col-xs-12">
                        <div className="customization-header"
                             onClick={_.partial(this.toggleOpenSection, 'mattresses')}>
                            {i18n.t('components.customizers.carpet-and-upholstery.mattresses')}
                            <div className={classNames({
                                hidden: this.props.alwaysExpanded,
                                arrow: true, "arrow-down": !this.isSectionOpen('mattresses'),
                                "arrow-up": this.isSectionOpen('mattresses')
                            })}>
                            </div>
                        </div>
                    </div>
                </div>

                <AnimateHeight id={'mattresses_expanded'}
                               duration={500}
                               height={(this.isSectionOpen('mattresses')) ? 'auto' : 0}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-4">
                            <div className="customization-explanation">
                                {i18n.t('components.customizers.carpet-and-upholstery.mattresses-details')}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-8">
                            <div className="row">
                                {_.map(['single_mattresses', 'double_mattresses'], function (option) {
                                    return (
                                        <div className="col-xs-12 col-sm-6" key={option}>
                                            <NumberField value={this.state.selectedProductOptions[option]}
                                                         label={LabelService.labelOption(option, this.state.selectedProductOptions[option])}
                                                         onChange={_.partial(this.updateOption, option)}
                                                         disabled={!this.fieldSupported(option)}
                                                         price={this.extraInformation(option)}
                                                         min={0}/>
                                        </div>
                                    );
                                }.bind(this))}
                            </div>
                        </div>
                    </div>
                </AnimateHeight>


                <hr className="customization-separator"/>

                <div className="row">
                    <div className="col-xs-12">
                        <div className="customization-header"
                             onClick={_.partial(this.toggleOpenSection, 'carpets')}>
                            {i18n.t('components.customizers.carpet-and-upholstery.carpets')}
                            <div className={classNames({
                                hidden: this.props.alwaysExpanded,
                                arrow: true, "arrow-down": !this.isSectionOpen('carpets'),
                                "arrow-up": this.isSectionOpen('carpets')
                            })}>
                            </div>
                        </div>
                    </div>
                </div>

                <AnimateHeight id={'carpets_expanded'}
                               duration={500}
                               height={(this.isSectionOpen('carpets')) ? 'auto' : 0}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-4">
                            <div className="customization-explanation">
                                {i18n.t('components.customizers.carpet-and-upholstery.carpets-details')}
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-8">
                            <div className="row">
                                {_.map(['large_carpets', 'small_carpets'], function (option) {
                                    return (
                                        <div className=" col-xs-12 col-sm-6" key={option}>
                                            <NumberField value={this.state.selectedProductOptions[option]}
                                                         label={LabelService.labelOption(option, this.state.selectedProductOptions[option])}
                                                         onChange={_.partial(this.updateOption, option)}
                                                         disabled={!this.fieldSupported(option)}
                                                         price={this.extraInformation(option)}
                                                         min={0}/>
                                        </div>
                                    );
                                }.bind(this))}
                            </div>
                        </div>
                    </div>
                </AnimateHeight>

                <hr className="customization-separator"/>
            </div>
        )
    }


    render() {
        return (
            <div>
                {this.props.isOptional &&
                <div className="add-service-checkbox">
                    <div className="headline-container">
                        <div className={'add-service-label'} onClick={this.toggleCheckbox}>
                            {i18n.t('services.carpet_and_upholstery_cleaning.title')}
                            <br/>
                            {this.priceHookText()}
                        </div>
                        {this.showExpandable() &&
                        <div className="arrow-headline-container">
                            <div className={classNames({'arrow': true, 'down': !this.state.checkbox, 'up': this.state.checkbox})} onClick={this.toggleCheckbox}>
                            </div>
                        </div>}
                    </div>
                    {this.showExpandable() &&
                    <AnimateHeight id={'bathroom-cleaning-expanded'}
                                   duration={500}
                                   height={(this.showInputFields()) ? 'auto' : 0}>
                        <hr className="customization-separator"/>
                        {this.renderFields()}
                    </AnimateHeight>}
                </div> }

                {!this.props.isOptional && this.renderFields()}
            </div>
        );
    }
}


export default CarpetAndUpholsteryCleaning;
