import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import CitySelector from '@components/common/address_selector/city_selector';
import DistrictSelector from '@components/common/address_selector/district_selector';

class PhoneForDistrict extends React.Component {
    static propTypes = {
        cities: PropTypes.arrayOf(PropTypes.shape(
            {districts: PropTypes.arrayOf(PropTypes.shape(
                {
                    alternative_label: PropTypes.string,
                    available: PropTypes.bool,
                    id: PropTypes.number,
                    label: PropTypes.string,
                    value: PropTypes.number,
                    phone: PropTypes.string
                }
            ))},
        ))
    };

    state = {
        city_id: null,
        district_id: null
    };

    phone = () => {
        if (this.state.district_id == null) {
            return "";
        }else {
            var district = this.getSelectedDistrict();
            if(district) {
                if(district.available) {
                    return (
                        <span>
                          <span className='worktime-container'>{I18n.t('footer.phone.worktime')}</span>
                          <br/>
                          <br/>
                          <span className="glyphicon glyphicon-phone phone-icon" aria-hidden="true"></span>
                          {district.phone}
                        </span>
                    );
                }else {
                    return I18n.t("footer.phone.not-serving-yet");
                }
            }
            else if(this.getDistricts().length == 0) {
                return I18n.t("footer.phone.not-serving-yet-city");
            }
            else {
                return null;
            }
        }
    };

    render() {
        return (
            <div className="district-selector-container">
              <div className="district-selector">
                <CitySelector cities={this.props.cities} select={this.onCitySelect}
                                              selected={this.state.city_id} hasError={false}/>
                <DistrictSelector districts={this.getDistricts()} select={this.onDistrictSelect}
                                                  selected={this.state.district_id} hasError={false}/>
              </div>
              <div className="phone-container">
                {this.phone()}
              </div>
            </div>
        );
    }

    getSelectedDistrict = () => {
        return _.find(this.getDistricts(), function(district){
            return this.state.district_id == district.id;
        }.bind(this));
    };

    getDistricts = () => {
        var selectedCity = this.getSelectedCity();

        if(selectedCity){
            return selectedCity.districts;
        }
        else {
            return null;
        }
    };

    getSelectedCity = () => {
        return _.find(this.props.cities, function(city){
            return this.state.city_id == city.id;
        }.bind(this));
    };

    onDistrictSelect = (selected) => {
        if(selected == null) {
            this.setState({district_id: null});
        }else{
            this.setState({district_id: selected.id});

        }
    };

    onCitySelect = (selected) => {
        if(selected == null) {
            this.setState({city_id: null});
        }else{
            this.setState({city_id: selected.id});

        }
    };
}

export default PhoneForDistrict;
