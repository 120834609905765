import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import PriceTag from '@components/common/price_tag';


class OrderPrice extends React.Component {
    static propTypes = {
        currency: PropTypes.string.isRequired,

        productAmount: PropTypes.string.isRequired,
        productDiscounts: PropTypes.arrayOf(PropTypes.shape({
            amount: PropTypes.string,
            description: PropTypes.string
        })),

        fees: PropTypes.arrayOf(PropTypes.shape({
            amount: PropTypes.string,
            description: PropTypes.string
        })),
        productAndFeeDiscounts: PropTypes.arrayOf(PropTypes.shape({
            amount: PropTypes.string,
            description: PropTypes.string
        })),

        amount: PropTypes.string.isRequired
    };

    isCompound = () => {
        return (this.props.productDiscounts &&
                this.props.productDiscounts.length > 0) ||

               (this.props.productAndFeeDiscounts &&
                this.props.productAndFeeDiscounts.length > 0) ||

               (this.props.fees &&
                 this.props.fees.length > 0);
    };

    priceComponents = () => {
        var fees = _.map(this.props.fees, function(fee) {
            return { amount: fee.amount,
                     description: fee.description,
                     fee: true };
        });

        return this.props.productDiscounts.concat(fees).concat(this.props.productAndFeeDiscounts);
    };

    render() {
        return (
            <div className="total-price">
              <div className="description">
                {i18n.t('funnel.pay.total_amount')}
              </div>

              {this.isCompound() &&
                  <div className="discounted-price">
                        <PriceTag amount={this.props.productAmount} currency={this.props.currency} className="discounted-price-tag" flat={true}/>

                        {_.map(this.priceComponents(),
                               function(component, i) {
                                   return (
                                       <div key={i}>
                                         <div className={component.fee ? "fee-tag" : "discount-tag"} >
                                           <span className="amount">{component.amount} </span>
                                           <span className="info">{component.description}</span>
                                         </div>
                                       </div>
                                   );
                               })
                        }
                  </div>
              }

              <PriceTag amount={this.props.amount} currency={this.props.currency} className="price-tag green-text"/>
            </div>
        );
    }
}

export default OrderPrice;
