import React from "react";
import SpinnerComponent from "@components/common/spinner";

function withLoader(Component) {
    return class extends React.Component {
        state = {
            loadersShown: 0
        };

        executeSlowOperation = (operation) => {
            this.setState(prevState => ({ loadersShown: prevState.loadersShown + 1}));
            var promise = operation();

            promise.done(function() {
                this.setState(prevState => ({ loadersShown: prevState.loadersShown - 1}));
            }.bind(this));

            return promise;
        };

        loading = () => {
            return this.state.loadersShown > 0;
        };

        showScreenLoader = () => {
            this.setState(prevState => { loadersShown: prevState.loadersShown + 1 });
        };

        render() {
            return (
                <div>
                    <SpinnerComponent loading={this.loading()} />
                    <Component {...this.props} executeSlowOperation={this.executeSlowOperation}
                               showScreenLoader={this.showScreenLoader}/>
                </div>
            );
        }
    };
}

export default withLoader;