import React from 'react';

import UnitsUtils from '@services/units_utils';
import PestControlUtils from '@services/pest_control_utils';

class FunnelBreadcrumbsPestControlProductInfo extends React.Component {
    renderDetails = () => {
        if(this.props.product) {
            return i18n.t("services.pest_control.products.names." + this.props.product.pest_type + "@" + this.props.product.property_type,
                          { amount: UnitsUtils.quantityWithUnit(this.props.product.pest_quantity, PestControlUtils.unitType(this.props.product.property_type, this.props.product.pest_type)) });
        }
        else {
            return i18n.t('funnel.breadcrumbs.pest-control.content');
        }
    };

    render() {
        return (
            <div>
              <div className="little-darkblue-title">
                {i18n.t('funnel.breadcrumbs.pest-control.title')}
              </div>
              <p>
                {this.renderDetails()}
              </p>
            </div>
        );
    }
};

export default FunnelBreadcrumbsPestControlProductInfo;
