import React from 'react';
import PropTypes from 'prop-types';

import AbstractFunnel from "@components/abstract_funnel";
import withLoader from '@components/common/with_loader_decorator';
import withHistory from '@components/common/with_history_decorator'
import withPhoneCollector from '@components/common/with_phone_collector_decorator'
import Authenticator from '@components/common/authenticator'

import FunnelStatePersistance from '@services/funnel_state_persistance'

class PestControlFunnel extends AbstractFunnel {
    static childContextTypes = {
        locationStrategy: PropTypes.string
    };

    componentDidMount() {
        if (this.props.initializeStateAndHistory) {
            this.props.initializeStateAndHistory({componentName: 'PestControlFunnel', currentState: this.state});
        }
        FunnelStatePersistance.loadPersistedStateIfNeeded(this);
    }

    serviceType = () => {
        return 'pest_control';
    };

    availabilityPathString = () => {
        return 'pest_control_availability_path';
    };

    companiesForDatePath = () => {
        return PathHelper('companies_for_date_pest_control_requests_path');
    };

    attributesPath = () => {
        return PathHelper('attributes_pest_control_requests_path');
    };

    extraServiceTypes = () => {
        return [];
    };

    selectedProductsPrice = () => {
        let selectedCompany = this.selectedCompany();
        if (selectedCompany) {
            let productPrice =  parseFloat(selectedCompany.product.visitPrice);

            if(selectedCompany.product.minVisitPrice && productPrice < parseFloat(selectedCompany.product.minVisitPrice)){
                productPrice = parseFloat(selectedCompany.product.minVisitPrice);
            }

            return productPrice;
        } else {
            return null;
        }
    }
}


PestControlFunnel = withLoader(PestControlFunnel);
PestControlFunnel = withPhoneCollector(PestControlFunnel);
PestControlFunnel = withHistory(PestControlFunnel);

class AuthenticatingPestControlFunnel extends React.Component {
    render() {
        return (
            <Authenticator status={this.props.componentProps.authenticationStatus}
                           omniauthError={this.props.componentProps.omniauthError}
                           component={PestControlFunnel}
                           {...this.props} />
        );
    }
}

export default AuthenticatingPestControlFunnel;
