import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import FeedbacksList from '@components/common/feedbacks_list';

class FeedbacksSection extends React.Component {
    static propTypes = {
        requestPath: PropTypes.string.isRequired,
        requestParams: PropTypes.object.isRequired,
        feedbacksPerPage: PropTypes.number.isRequired,
        companyName: PropTypes.string.isRequired,

        initialFeedbacks: PropTypes.shape({
            all: PropTypes.shape({
                allShowed: PropTypes.boolean,

                items: PropTypes.arrayOf(PropTypes.shape({
                    author: PropTypes.string.isRequired,
                    rating: PropTypes.number.isRequired,
                    content: PropTypes.string,
                    comment_reply: PropTypes.string,
                }))
            }),

            withComment: PropTypes.shape({
                allShowed: PropTypes.boolean,

                items: PropTypes.arrayOf(PropTypes.shape({
                    author: PropTypes.string.isRequired,
                    rating: PropTypes.number.isRequired,
                    content: PropTypes.string,
                    comment_reply: PropTypes.string,
                }))
            })
        })
    };

    constructor(props) {
        super(props);
        if (props.initialFeedbacks.withComment.items.length > 0){
            this.state = {
                tabOpen: 'withComment'
            };

            return;
        }
        else {
            this.state = {
                tabOpen: 'all'
            };

            return;
        }
    }

    hasFeedbacksWithComment = () => {
        return this.props.initialFeedbacks.withComment.items.length > 0;
    };

    hasAnyFeedbacks = () => {
        return this.props.initialFeedbacks.all.items.length > 0;
    };

    allTabStyles = () => {
        if(this.state.tabOpen == 'withComment') {
            return { display: 'none'};
        }
        else {
            return {};
        }
    };

    withCommentTabStyles = () => {
        if(this.state.tabOpen == 'all') {
            return { display: 'none'};
        }
        else {
            return {};
        }
    };

    showWithCommentTab = () => {
        this.setState({ tabOpen: 'withComment' });
    };

    showAllTab = () => {
        this.setState({ tabOpen: 'all' });
    };

    render() {
        if(this.hasFeedbacksWithComment()) {
            return (
                <div>
                  <div className="feedback-tabs">
                    <a onClick={this.showWithCommentTab}
                       className={classNames({ active: this.state.tabOpen == 'withComment' })}>
                      {i18n.t('company-profile.feedbacks-with-comment')}
                    </a>

                    <a onClick={this.showAllTab}
                       className={classNames({ active: this.state.tabOpen == 'all' })}>
                      {i18n.t('company-profile.all-feedbacks')}
                    </a>
                  </div>

                  <div style={this.allTabStyles()}>
                    <FeedbacksList requestPath={this.props.requestPath}
                                   recipientName={this.props.companyName}
                                   requestParams={_.extend({}, this.props.requestParams)}
                                   feedbacksPerPage={this.props.feedbacksPerPage}
                                   initialFeedbacks={this.props.initialFeedbacks.all.items}
                                   allFeedbacksShowed={this.props.initialFeedbacks.all.allShowed}/>
                  </div>

                  <div style={this.withCommentTabStyles()}>
                    <FeedbacksList requestPath={this.props.requestPath}
                                   recipientName={this.props.companyName}
                                   requestParams={_.extend({}, this.props.requestParams, { with_comment: true })}
                                   feedbacksPerPage={this.props.feedbacksPerPage}
                                   initialFeedbacks={this.props.initialFeedbacks.withComment.items}
                                   allFeedbacksShowed={this.props.initialFeedbacks.withComment.allShowed}/>
                  </div>
                </div>
            );
        }
        else if(this.hasAnyFeedbacks()) {
            return (
                <FeedbacksList requestPath={this.props.requestPath}
                               recipientName={this.props.companyName}
                               requestParams={_.extend({}, this.props.requestParams)}
                               feedbacksPerPage={this.props.feedbacksPerPage}
                               initialFeedbacks={this.props.initialFeedbacks.all.items}
                               allFeedbacksShowed={this.props.initialFeedbacks.all.allShowed}/>
            );
        }
        else {
            return null;
        }
    }
}

export default FeedbacksSection;
