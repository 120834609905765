import React from 'react';
import PropTypes from 'prop-types';
import withClientPreferencesCollector from '@components/common/with_client_preferences_collector_decorator'
import SubscriptionRenewalFormBreadcrumbs from '@components/subscription_renewal_form/breadcrumbs'
import OrderPayment from '@components/funnel/order_payment';

class SubscriptionRenewalForm extends React.Component {
    static propTypes = {
        categoryPrice: PropTypes.object,

        // default values
        product: PropTypes.object,
        address: PropTypes.object,
        cleaner: PropTypes.object,
        dateslots: PropTypes.arrayOf(PropTypes.object),

        // payment
        totalPrice: PropTypes.string,
        priceType: PropTypes.string
    };

    componentWillMount() {
        if(this.props.clientPreferences.shouldRequest) {
            this.props.showPreferencesModal();
        }
    }

    breadcrumbsProps = () => {
        return {
            step: 4,
            stepUpTo: -1,
            product: this.props.product,
            address: this.props.address.text_description,
            slotsInfo: SLOTS_INFO,
            onStepChange: null,
            onStepChangeMobile: null,
            categoryPrice: this.props.categoryPrice,
            cleaner: this.props.cleaner,
            dateslots: this.props.dateslots,
            totalPrice: this.props.totalPrice,
            priceType: this.props.priceType
        };
    };

    render() {
        return (
            <div>
              <SubscriptionRenewalFormBreadcrumbs {...this.breadcrumbsProps()} />
              <div className="funnel-body">
                <OrderPayment />
              </div>
            </div>
        );
    }
}

export default withClientPreferencesCollector(SubscriptionRenewalForm);
