import React from 'react';
import PropTypes from 'prop-types';
import MoneyUtils from '@services/money_utils'

import _ from 'underscore';

class ClubSaleStep extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        amountToPay: PropTypes.number,
    };

    shouldShowSkipButton = () => {
        return this.props.amountToPay > 0;
    }

    mainButtonText = () => {
        return this.props.amountToPay === 0 ? i18n.t('form.buttons.proceed') : i18n.t('form.buttons.add-to-cart');
    }

    headerText = () => {
        return this.props.amountToPay === 0 ? i18n.t("funnel.address-membership.step.header-no-choice") : i18n.t("funnel.address-membership.step.header-choice", {percent: ADDRESS_MEMBERSHIP_DISCOUNT_PERCENT});
    }

    subtitleText = () => {
        if (this.props.amountToPay === 0)
            return i18n.t("funnel.address-membership.step.subheader-no-choice.line1_html", {amount: MoneyUtils.price(10 * ADDRESS_MEMBERSHIP_PRICE)});
        else
            return i18n.t("funnel.address-membership.step.subheader-choice_html", {original_price: MoneyUtils.price(ADDRESS_MEMBERSHIP_PRICE), price: MoneyUtils.price(this.props.amountToPay)})
    }

    render() {
        return (
            <div className="club-sale-step-container">
                <div>
                    <div className="funnel-heading">
                        <div>
                            {this.headerText()}
                        </div>
                    </div>
                    <div className="funnel-subheading"
                         dangerouslySetInnerHTML={{__html: this.subtitleText()}}>
                    </div>
                </div>

                <div className="club-benefits-container">
                    <div className={'benefit'}>
                        <div className="image-container house">
                        </div>
                        <div className="header-container">
                            {i18n.t("funnel.address-membership.step.benefits.discount.header", {percent: ADDRESS_MEMBERSHIP_DISCOUNT_PERCENT})}
                        </div>
                        <div className="text-container">
                            {i18n.t("funnel.address-membership.step.benefits.discount.text")}
                        </div>
                    </div>
                    <div className={'benefit'}>
                        <div className="image-container housemaid">
                        </div>
                        <div className="header-container">{i18n.t("funnel.address-membership.step.benefits.housemaids.header")}</div>
                        <div className="text-container">
                            {i18n.t("funnel.address-membership.step.benefits.housemaids.text")}
                        </div>
                    </div>
                    <div className={'benefit'}>
                        <div className="image-container warranty">
                        </div>
                        <div className="header-container">{i18n.t("funnel.address-membership.step.benefits.warranty.header")}</div>
                        <div className="text-container">
                            {i18n.t("funnel.address-membership.step.benefits.warranty.text")}
                        </div>
                    </div>
                </div>

                <div className={'validity-container'}>
                    {i18n.t("funnel.address-membership.step.validity")}
                </div>


                <div className="form-footer extra-service-form-footer">
                    <button
                        className={"btn btn-primary next-step btn-submit"}
                        onClick={_.partial(this.props.onSubmit, true)}>
                        {this.mainButtonText()}
                    </button>

                    <br/>
                    {this.shouldShowSkipButton() &&
                    <button
                        className={"btn btn-default next-step btn-submit"}
                        onClick={_.partial(this.props.onSubmit, false)}>
                        {i18n.t("form.buttons.skip")}
                    </button>}
                </div>
            </div>
        );
    }
}

export default ClubSaleStep;
