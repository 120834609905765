import React from 'react';
import _ from 'underscore';
import $ from "jquery";
import PropTypes from 'prop-types';
import classNames from 'classnames';
class EarlyAdopterCollector extends React.Component {
    static prpoTypes = {
        cityId: PropTypes.number.isRequired,
        cityName: PropTypes.string.isRequired,
        districtId: PropTypes.number.isRequired,
        serviceType: PropTypes.number.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            name: null,
            email: null,
            collected: false,
            loading: false,
            mainError: null,
            errors: {}
        };

    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    signupClicked = () => {
        if(this.validateValues()){
            this.setState({loading: true}, function () {
                $.ajax({
                    type: "POST",
                    url: PathHelper('early_adopters_path'),
                    dataType: 'json',
                    contentType: 'application/json',
                    data : JSON.stringify({
                        name: this.state.name,
                        email: this.state.email,
                        district_id: this.props.districtId,
                        city_id: this.props.cityId
                    }),
                    statusCode: {
                        500: function() {
                            window.location.href = '/500';
                        }
                    }
                }).done(function(payload) {
                    if(payload['result'] == 'success'){
                        this.setState({collected: true, loading: false})
                    } else {
                        this.setState({collected: false, loading: false, mainError: payload['message']})
                    }

                }.bind(this));
            });
        }
    };

    validateValues = () => {
        var errors = {};
        if (!this.state.name) {
            errors.name = i18n.t('form.errors.required', {attribute: i18n.t("funnel.early-adopters-form.name")});
        }
        if (!this.state.email) {
            errors.email = i18n.t('form.errors.required', {attribute: i18n.t("funnel.early-adopters-form.email")});
        } else if (!this.state.email.match(/.+@.+\..+/)) {
            errors.email = i18n.t('form.errors.email-format');
        }
        this.setState({errors: errors});

        return _.isEmpty(errors);
    }

    btnClasses = () => {
        if (this.state.loading) {
            return " btn-loading-shared disabled";
        }
        return '';
    };

    renderMainError = () => {
        return (
            this.state.mainError &&
                <div className="early-adopter-collector-main-error text-danger">
                    {this.state.mainError}
                </div>
        )
    }

    renderSuccess = () => {
        return (
            <div className="early-adopter-collector-success">
                <div className="circle-check"></div>
                {i18n.t("funnel.early-adopters-form.success-explanation", {city: this.props.cityName})}
            </div>
        )
    }

    renderForm = () => {
        return (
            <div className="early-adopter-collector-form">
                {this.renderMainError()}

                <div className="early-adopter-collector-explanation"
                     dangerouslySetInnerHTML={{__html: i18n.t("funnel.early-adopters-form.start-in-country_html", {city: this.props.cityName})}}>
                </div>
                <div className={classNames({"form-group": true, "has-error": !_.isEmpty(this.state.errors['name']) })}>
                    <label htmlFor="name" className={'control-label'}>{i18n.t("funnel.early-adopters-form.name")}</label>
                    <input type="text" placeholder={i18n.t("funnel.early-adopters-form.name")} name={'name'} className={'form-control input-md'} onChange={this.handleChange}/>
                    {this.state.errors['name'] && <span className="help-block">{this.state.errors['name']}</span>}
                </div>
                <div className={classNames({ "form-group": true, "has-error": !_.isEmpty(this.state.errors['email']) })}>
                    <label htmlFor="name" className={'control-label'}>{i18n.t("funnel.early-adopters-form.email")}</label>
                    <input type="text" placeholder={i18n.t("funnel.early-adopters-form.email")} name={'email'} className={'form-control input-md'} onChange={this.handleChange}/>
                    {this.state.errors['email'] && <span className="help-block">{this.state.errors['email']}</span>}
                </div>
                <div className="early-adopter-collector__form__input">
                    <button onClick={this.signupClicked} className={"btn btn-primary cta-button-shared " + this.btnClasses()}>{i18n.t("funnel.early-adopters-form.cta")}</button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="early-adopter-collector-container">
                {!this.state.collected && this.renderForm()}
                {this.state.collected && this.renderSuccess()}
            </div>
        );
    }
}

export default EarlyAdopterCollector;