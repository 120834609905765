import React from 'react';

import DeepCleaningProductCustomizer from '@components/product_customizers/deep_cleaning';


class EndOfTenancyDeepCleaning extends DeepCleaningProductCustomizer {
    static selectorType = 'end_of_tenancy_cleaning';

    selectorType = () => {
        return EndOfTenancyDeepCleaning.selectorType;
    };

}


export default EndOfTenancyDeepCleaning;