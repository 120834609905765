import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'underscore';

class CheckListItem extends React.Component {
    static propTypes = {
        message: PropTypes.string,
        checked: PropTypes.bool
    };

    itemClasses = () => {
        return classNames({'checklist-item': true, checked: this.props.checked });
    };

    render() {
        return (
            <div className={this.itemClasses()}>
              <span className="glyphicon glyphicon-ok"></span>{this.props.message}
            </div>
        );
    }
}

class CheckList extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.object),
        responsive: PropTypes.bool
    };

    static defaultProps = {
        responsive: false
    };

    getClassNames = () => {
        return classNames({ checklist: true,
                            fixed: this.props.responsive });
    };

    render() {
        return (
            <div className={this.getClassNames()}>
              {_.map(this.props.items, function(item, index){
                  return (<CheckListItem {...item} key={index} />);
              })}
            </div>
        );
    }
}

export default CheckList;