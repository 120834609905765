import React from 'react';

import _ from 'underscore';
import LabelService from '@services/label_service';
import NumberField from '@components/common/number_field'
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import AnimateHeight from "react-animate-height";
import BookingRequest from '@services/booking_request'
import classNames from 'classnames';



class BathroomCleaning extends AbstractProductCustomizer {
    static selectorType = 'bathroom_cleaning';
    static zeroSelectedProducts = (new BookingRequest()).zeroSelectedProductsForService(BathroomCleaning.selectorType);

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = this.zeroSelectedProducts();
        this.initializeProductOptions(props);
    };

    selectorType = () => {
        return BathroomCleaning.selectorType;
    };

    zeroSelectedProducts = () => {
        let initialProducts = _.clone(BathroomCleaning.zeroSelectedProducts);
        initialProducts['after_renovation_mode'] = false;
        return initialProducts;
    };

    samplePriceOptions = () => {
        return ['regular_bathrooms', 'separate_wcs']
    };

    renderFields = () => {
        return (
                <div className={'service-customizer-input-fields-container'}>
                    <div className="row">
                        {this.props.showExplanation &&
                        <div className='secondary-service-explanation col-md-8 col-md-offset-2'>
                            {i18n.t('funnel.bathroom-cleaning.subtitle_html')}
                        </div>}

                        {_.map(['regular_bathrooms', 'large_bathrooms', 'separate_wcs'], function (option) {
                            return (
                                <div className="col-xs-12 col-sm-4" key={option}>
                                    <NumberField value={this.state.selectedProductOptions[option]}
                                                 label={LabelService.labelOption(option, this.state.selectedProductOptions[option])}
                                                 onChange={_.partial(this.updateOption, option)}
                                                 disabled={!this.fieldSupported(option)}
                                                 price={this.extraInformation(option)}
                                                 min={0}
                                    />
                                </div>
                            );
                        }.bind(this))}
                    </div>
                    {this.renderRenovationModeCheckbox()}
                </div>
        )
    }

    render() {
        return (
            <div>
                {this.props.isOptional &&
                <div className="add-service-checkbox">
                    <div className="headline-container">
                        <div className={'add-service-label'} onClick={this.toggleCheckbox}>
                            {i18n.t('services.bathroom_cleaning.title')}
                            <br/>
                            {this.priceHookText()}
                        </div>
                        {this.showExpandable() &&
                        <div className="arrow-headline-container">
                            <div className={classNames({'arrow': true, 'down': !this.state.checkbox, 'up': this.state.checkbox})} onClick={this.toggleCheckbox}>
                            </div>
                        </div>}
                    </div>
                    {this.showExpandable() &&
                    <AnimateHeight id={'bathroom-cleaning-expanded'}
                                   duration={500}
                                   height={(this.showInputFields()) ? 'auto' : 0}>
                        <hr className="customization-separator"/>
                        {this.renderFields()}
                    </AnimateHeight> }
                </div>}

                {!this.props.isOptional && this.renderFields()}
            </div>
        );
    }
}


export default BathroomCleaning;