import React from 'react';

import EndOfTenancyDeepCleaning from '@components/product_customizers/end_of_tenancy_deep_cleaning';
import CarpetAndUpholsteryCustomizer from '@components/product_customizers/carpet_and_upholstery_cleaning';


class EndOfTenancyCleaning extends React.Component {
    render() {
        return (
            <div className={'end-of-tenancy-customizer'}>
                <EndOfTenancyDeepCleaning {...{...this.props, ...{alternativeDesign: true}} } />
                <hr className="customization-separator"/>
                <CarpetAndUpholsteryCustomizer {...{...this.props, ...{alwaysExpanded: true}} } />
            </div>
        );
    }

}


export default EndOfTenancyCleaning;