import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import FunnelBookingSchedule from '@components/funnel/booking_schedule';
import BreakPoints from '@services/breakpoints';
import Select from "react-select";

class FunnelBookingDateSlotSelector extends React.Component {
    static propTypes = {
        availabilityPathString: PropTypes.string.isRequired,
        serviceType: PropTypes.string.isRequired,
        // Slot Selection
        initialWeek: PropTypes.number,

        scheduleOptions: PropTypes.shape({
            districtId: PropTypes.number.isRequired,
            cat: PropTypes.bool,
            dog: PropTypes.bool,
            weeksAheadLimit: PropTypes.number
        }).isRequired,

        selectedDateslots: PropTypes.arrayOf(PropTypes.object).isRequired,

        // Action
        onSlotsSelect: PropTypes.func.isRequired,
        executeSlowOperation: PropTypes.func.isRequired,

        //
        companies: PropTypes.arrayOf(PropTypes.object),
        companyIdFilter: PropTypes.number,
        selectCompany: PropTypes.func,
        highlightedCompanyId: PropTypes.number,
        listCompanies: PropTypes.bool,

        //
        onSubmit: PropTypes.func,

        filterDate: PropTypes.instanceOf(Date),
        extraAvailabilityParams: PropTypes.object,

        cleanerId: PropTypes.number,

        handleBackToList: PropTypes.func,

        showDuration: PropTypes.bool,
        selectedDuration: PropTypes.string,
        durationOptions: PropTypes.array,
        onDurationChange: PropTypes.func,
        amplitudeEvent: PropTypes.string,
        amplitudeProperties: PropTypes.object,
    };

    static defaultProps = {
        initialWeek: 0,
        showDuration: false
    };

    state = {
        week: this.props.initialWeek
    };

    // WEEK ==========

    incrementWeek = () => {
        this.setState({week: this.state.week + 1});
    };

    decrementWeek = () => {
        this.setState({week: this.state.week - 1});
    };

    handleDateslotsChange = (dateslotsUpdate) => {
        this.props.onSlotsSelect(dateslotsUpdate.newDateslots);
    };

    renderSchedule = () => {
        return <FunnelBookingSchedule availabilityPathString={this.props.availabilityPathString}
                                      week={this.state.week}
                                      districtId={this.props.scheduleOptions.districtId}
                                      cat={this.props.scheduleOptions.cat}
                                      dog={this.props.scheduleOptions.dog}
                                      selectedDateslots={this.props.selectedDateslots}
                                      executeSlowOperation={this.props.executeSlowOperation}
                                      onNextWeek={this.getNextWeekHandler()}
                                      onPrevWeek={this.getPrevWeekHandler()}
                                      onDateslotsChange={this.handleDateslotsChange}
                                      companyId={this.props.companyIdFilter}
                                      renderDescription={this.renderFilter}
                                      filterDate={this.props.filterDate}
                                      hideArrows={!!this.props.filterDate}
                                      extraAvailabilityParams={this.props.extraAvailabilityParams}
                                      cleanerId={this.props.cleanerId}
                                      selectedDuration={this.props.selectedDuration}
                                      amplitudeEvent={this.props.amplitudeEvent}
                                      amplitudeProperties={this.props.amplitudeProperties}
        />;
    };

    renderSlotExplanation = () => {
        if (this.props.selectedDateslots.length > 0) {
            var dateslot = this.props.selectedDateslots[0];
            return (
                <div className={'slot-selection-in-funnel-explanation'}>
                    {i18n.t("funnel.time-form.slot-summary-above-cta", {
                        starts_at: dateslot.starts_at,
                        ends_at: dateslot.ends_at
                    })}
                </div>
            )
        }
    }

    getNextWeekHandler = () => {
        if (this.state.week < this.props.scheduleOptions.weeksAheadLimit && !this.props.filterDate) {
            return this.incrementWeek;
        } else {
            return null;
        }
    };

    getPrevWeekHandler = () => {
        if (this.state.week > 0 && !this.props.filterDate) {
            return this.decrementWeek;
        } else {
            return null;
        }
    };

    canSubmit = () => {
        return this.props.selectedDateslots.length > 0 && !this.props.selectedDateslots[0].unavailable;
    };

    handleSubmit = () => {
        this.props.onSubmit();
        Analytics.sendSchedule(this.props.serviceType);
    };

    selectedCompany = () => {
        return _.find(this.props.companies, function (company) {
            return company.id === this.props.companyIdFilter;
        }.bind(this));

    }

    companySelect = () => {
        var companySelect,
            company = this.selectedCompany();

        if (company) {
            companySelect = (<strong>{company.name}</strong>);
        } else {
            companySelect = '';
        }

        return (
            <span>
              {" "} {i18n.t('funnel.time-form.schedule-for')} {companySelect}
            </span>
        );
    };

    renderFilter = (from, to) => {
        var daysRange = moment(from).format("DD.MM") + ' - ' +
            moment(to).format("DD.MM");
        return (
            <div className="schedule-description">
                {i18n.t("funnel.time-form.schedule-period-description", {"days_range": daysRange})}
                {this.props.listCompanies && this.companySelect()}
            </div>
        );
    };

    selectedDurationValue = () => {
        return _.find(this.props.durationOptions, function (durationOption) {
            return durationOption.value === this.props.selectedDuration;
        }.bind(this));
    };

    render() {
        var buttonDisabled = !this.canSubmit();

        return (
            <div className="cleaner-and-slot-selector">
                <div>
                    <MediaQuery minWidth={BreakPoints.xsMax}>
                        <div className="funnel-heading">
                            <div>
                                {i18n.t('funnel.time-form.hints.single-event')}
                            </div>
                        </div>
                    </MediaQuery>

                    {this.props.showDuration &&
                        <div className="duration-selector-container">
                            <Select value={this.selectedDurationValue()}
                                    onChange={this.props.onDurationChange}
                                    options={this.props.durationOptions}
                                    autoBlur={true}
                                    scrollMenuIntoView={false}
                            />
                        </div>}
                </div>

                {this.renderSchedule()}

                <div className="form-footer">
                    {this.renderSlotExplanation()}
                    <button className={"btn btn-primary next-step btn-submit " + (buttonDisabled ? 'disabled' : '')}
                            disabled={buttonDisabled} onClick={this.handleSubmit}>
                        {i18n.t('form.buttons.proceed')}
                    </button>
                    {this.props.handleBackToList &&
                    <div className={'back-to-list-btn-container'}>
                        <a href="#" onClick={this.props.handleBackToList}>{i18n.t('funnel.time-form.back-to-list')}</a>
                    </div>}
                </div>
            </div>
        );
    }
}

export default FunnelBookingDateSlotSelector;
