import React from 'react';
import PropTypes from 'prop-types';
import LocationSelector from '@components/common/location_selector';
import $ from 'jquery';
import withLoader from '@components/common/with_loader_decorator';

class LocationToFunnelForm extends React.Component {
    static propTypes = {
        locationStrategy: PropTypes.string.isRequired,
        funnelPath: PropTypes.string.isRequired,
        buttonText: PropTypes.string,
        headerText: PropTypes.string,
        serviceType: PropTypes.string,
        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            available: PropTypes.bool.isRequired,

            label: PropTypes.string.isRequired,
            alternative_label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,

            districts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                available: PropTypes.bool.isRequired,

                label: PropTypes.string.isRequired,
                alternative_label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired
            }))
        })),
        location: PropTypes.shape({ city: PropTypes.object,
            district: PropTypes.object }),
    };

    static childContextTypes = {
        locationStrategy: PropTypes.string
    };

    getChildContext() {
        return { locationStrategy: this.props.locationStrategy };
    }

    onDistrictSelect = (city, district) => {
        Analytics.sendDataLayerSignal('Services', 'See prices');
        Analytics.sendAddPartialAddress(this.props.serviceType);
        var params = $.param({district_id: district.id});
        window.location = this.props.funnelPath + '?' + params;
    };

    onPostCodeSelect = (postCode, city, district) => {
        this.props.executeSlowOperation(function() {
            Analytics.sendDataLayerSignal('Services', 'See prices');
            Analytics.sendAddPartialAddress(this.props.serviceType);
            var params = $.param({postcode: postCode, district_id: district.id});
            window.location = this.props.funnelPath + '?' + params;
        }.bind(this));
    };

    render() {
        return <LocationSelector {...this.props} onDistrictSelect={this.onDistrictSelect} onPostCodeSelect={this.onPostCodeSelect} sendAnalyticsPageview={false}/>;
    }
}

LocationToFunnelForm = withLoader(LocationToFunnelForm);

export default LocationToFunnelForm;
