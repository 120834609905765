import React from 'react';
import PropTypes from 'prop-types';

import AbstractFunnel from "@components/abstract_funnel";
import withLoader from '@components/common/with_loader_decorator';
import withHistory from '@components/common/with_history_decorator'
import withPhoneCollector from '@components/common/with_phone_collector_decorator'
import Authenticator from '@components/common/authenticator'

import FunnelStatePersistance from '@services/funnel_state_persistance'

class OvenCleaningFunnel extends AbstractFunnel {
    static childContextTypes = {
        locationStrategy: PropTypes.string
    };

    componentDidMount() {
        if(this.props.initializeStateAndHistory){
            this.props.initializeStateAndHistory({ componentName: 'OvenCleaningFunnel', currentState: this.state });
        }

        FunnelStatePersistance.loadPersistedStateIfNeeded(this);
    }

    serviceType = () => {
        return 'oven_cleaning';
    };

    availabilityPathString = () => {
        return 'oven_cleaning_availability_path';
    };

    companiesForDatePath = () => {
        return PathHelper('companies_for_date_oven_cleaning_requests_path');
    };

    attributesPath = () => {
        return PathHelper('attributes_oven_cleaning_requests_path');
    };

    extraServiceTypes = () => {
        return ['carpet_and_upholstery_cleaning', 'bathroom_cleaning']
    };
}


OvenCleaningFunnel = withLoader(OvenCleaningFunnel);
OvenCleaningFunnel = withPhoneCollector(OvenCleaningFunnel);
OvenCleaningFunnel = withHistory(OvenCleaningFunnel);

class AuthenticatingOvenCleaningFunnel extends React.Component {
    render() {
        return (
            <Authenticator status={this.props.componentProps.authenticationStatus}
                           omniauthError={this.props.componentProps.omniauthError}
                           component={OvenCleaningFunnel}
                           {...this.props} />
        );
    }
}

export default AuthenticatingOvenCleaningFunnel;
