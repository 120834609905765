import React from 'react';

import _ from 'underscore';
import LabelService from '@services/label_service';
import NumberField from '@components/common/number_field'
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import MoneyUtils from '@services/money_utils'
import BookingRequest from '@services/booking_request'
import classNames from "classnames";
import AnimateHeight from "react-animate-height";


class OvenCleaning extends AbstractProductCustomizer {
    static selectorType = 'oven_cleaning';
    static zeroSelectedProducts = (new BookingRequest()).zeroSelectedProductsForService(OvenCleaning.selectorType);

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = this.zeroSelectedProducts();
        this.initializeProductOptions(props);
    };

    selectorType = () => {
        return OvenCleaning.selectorType;
    };

    zeroSelectedProducts = () => {
        return _.clone(OvenCleaning.zeroSelectedProducts);
    };

    samplePriceOptions = () => {
        return ['ovens', 'kitchen_hoods']
    };

    renderFields = () => {
        return (
            <div className={'service-customizer-input-fields-container'}>
                <div className="row">
                    {this.props.showExplanation &&
                    <div className='secondary-service-explanation col-md-8 col-md-offset-2'>
                        {i18n.t('funnel.oven-cleaning.subtitle_html')}
                    </div>}
                    {_.map(['ovens', 'kitchen_hoods', 'microwave_ovens'], function (option) {
                        return (
                            <div className="col-xs-12 col-sm-4" key={option}>
                                <NumberField value={this.state.selectedProductOptions[option]}
                                             label={LabelService.labelOption(option, this.state.selectedProductOptions[option])}
                                             onChange={_.partial(this.updateOption, option)}
                                             disabled={!this.fieldSupported(option)}
                                             price={this.extraInformation(option)}
                                             min={0}
                                />
                            </div>
                        );
                    }.bind(this))}

                    <div className="col-xs-12 col-sm-4 col-sm-offset-4" key={'fridges'}>
                        <NumberField value={this.state.selectedProductOptions['fridges']}
                                     label={LabelService.labelOption('fridges', this.state.selectedProductOptions['fridges'])}
                                     onChange={_.partial(this.updateOption, 'fridges')}
                                     disabled={!this.fieldSupported('fridges')}
                                     price={this.extraInformation('fridges')}
                                     min={0}
                        />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.props.isOptional &&
                <div className="add-service-checkbox">
                    <div className="headline-container">
                        <div className={'add-service-label'} onClick={this.toggleCheckbox}>
                            {i18n.t('services.oven_cleaning.title')}
                            <br/>
                            {this.priceHookText()}
                        </div>
                        {this.showExpandable() &&
                        <div className="arrow-headline-container">
                            <div className={classNames({'arrow': true, 'down': !this.state.checkbox, 'up': this.state.checkbox})} onClick={this.toggleCheckbox}>
                            </div>
                        </div>}
                    </div>
                    {this.showExpandable() &&
                    <AnimateHeight id={'bathroom-cleaning-expanded'}
                                   duration={500}
                                   height={(this.showInputFields()) ? 'auto' : 0}>
                        <hr className="customization-separator"/>
                        {this.renderFields()}
                    </AnimateHeight>}
                </div>}

                {!this.props.isOptional && this.renderFields()}
            </div>
        );
    }
}


export default OvenCleaning;