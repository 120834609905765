import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';


class PhoneForTopic extends React.Component {
    static propTypes = {
        housemaidsPhoneNumber: PropTypes.string,
        bookingsPhoneNumber: PropTypes.string,
        workHoursStart: PropTypes.string,
        workHoursEnd: PropTypes.string,
        careersPath: PropTypes.string,
        becomeAPartnerPath: PropTypes.string,
        services: PropTypes.arrayOf(PropTypes.shape({
            service_type: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            landing_page_path: PropTypes.string.isRequired,
        }))
    };

    serviceExplanation = (title, path) => {
        return (
            <div>
                {i18n.t('shared.phone-widget.read-more')} <a href={path}>{title}</a>
            </div>
        )
    };

    phoneForQuestion = (questionType) => {
        if(questionType === 'housemaid' || questionType === 'careers'){
            return this.props.housemaidsPhoneNumber;
        } else{
            return this.props.bookingsPhoneNumber;
        }
    }

    generateQuestions = () => {
        var serviceQuestions = _.map(this.props.services, function (service) {
            return {
                name: service.title,
                questionType: service.service_type,
                explanation: this.serviceExplanation(service.title, service.landing_page_path)
            }
        }.bind(this));

        return serviceQuestions;
    };

    state = {
        selectedQuestion: null,
        questions: this.generateQuestions()
    };

    onSelect = (questionOption) => {
        this.setState({selectedQuestion: this.findQuestion(questionOption.value)});
    };

    findQuestion = (questionType) => {
        return _.find(this.state.questions, function (question) {
            return question.questionType === questionType;
        });
    };

    selectOptions = () => {
        var questions = _.map(this.state.questions, function (question) {
            return {label: question.name, value: question.questionType}
        });
        return questions;
    };

    selectedOption = () => {
        if (this.state.selectedQuestion === null) {
            return null;
        } else {
            return _.find(this.selectOptions(), function (option) {
                return option['value'] === this.state.selectedQuestion.questionType
            }.bind(this));
        }
    };

    onNumberClick = () => {
        Analytics.sendEvent('Phone', 'Phone Widget Call Click', window.location.href);
        return true;
    };


    render() {
        return (
            <div className='phone-for-topic-component-container'>
                <div className='upper-text'>{i18n.t('shared.phone-widget.call-us')} <br/>
                    <b>{i18n.t('shared.phone-widget.business-days', {hours_start: this.props.workHoursStart, hours_end: this.props.workHoursEnd})}</b>.
                </div>

                <div className='info-container'>
                    <div className='phone-link'><a onClick={this.onNumberClick} href={'tel:' + this.props.bookingsPhoneNumber}
                            id="phone-widget-call-click">{this.props.bookingsPhoneNumber}</a>
                    </div>
                </div>

            </div>
        );
    };
}

export default PhoneForTopic;