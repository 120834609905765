import _ from 'underscore';

var ProductUtils = function() {
    var types = {
        0: 'single_visit',
        1: 'subscription',
        2: 'package',
        4: 'at_work',
        7: 'membership'},

        afterAuthenticateProductMap = {
            'membership': 'single_visit'
        },

        isSubscription = function(product) {
            return types[product.productType] === 'subscription';
        },

        isWeekly = function(product) {
            return isSubscription(product);
        },

        extractParams = function(product) {
            return {
                product_type: product.productType,
                visits_weekly: product.visitsWeekly,
                visit_duration: product.visitDuration
            };
        },

        selectOptionsByType = function(productOptions, type) {
            return _.filter(productOptions, function(product) {
                return types[product.productType] === type;
            });
        },

        serviceType = function(productType) {
            return  'housemaid';
        },

        typeToString = function(productType) {
            return types[productType];
        },

        stringToType = function(productString) {
            return _.invert(types)[productString];
        },

        isSingleVisitType = function(productType) {
            return typeToString(productType) == 'single_visit';
        },

        findByParams = function(products, params) {
            return _.find(products, function(product) {
                var productParams = extractParams(product);
                return _.isEqual(productParams, params);
            });
        },

        findByParamsSubset = function(products, params) {
            return _.find(products, function(product) {
                var productParams = extractParams(product);
                return _.isMatch(productParams, params);
            });
        },

        selectAppropriateProduct = function(products, product) {
            var params = extractParams(product),
                resultProduct = findByParams(products, params);

            if(resultProduct) {
                return resultProduct;
            }
            else {
                mappedType = afterAuthenticateProductMap[typeToString(product.productType)]

                if(mappedType) {
                    params.product_type = parseInt(stringToType(mappedType))
                }
                resultProduct = findByParams(products, params);

                if(resultProduct) {
                    return resultProduct;
                }
                else {
                    return null;
                }
            }

        };

    return {
        isSingleVisitType: isSingleVisitType,
        isSubscription: isSubscription,
        isWeekly: isWeekly,
        extractParams: extractParams,
        selectOptionsByType: selectOptionsByType,
        serviceType: serviceType,
        typeToString: typeToString,
        stringToType: stringToType,
        findByParams: findByParams,
        findByParamsSubset: findByParamsSubset,
        selectAppropriateProduct: selectAppropriateProduct
    };
}();

export default ProductUtils;
