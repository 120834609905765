import React from 'react';

import _ from 'underscore';
import UnitsUtils from '@services/units_utils';
import classNames from "classnames";
import PropTypes from "prop-types";
import $ from "jquery";


class FacadeCleaning extends React.Component {
    static propTypes = {
        selectedAddressId: PropTypes.number,
        selectedDistrictId: PropTypes.number,
        clientId: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {name: '', email: '', phone: ''};
        this.state.area = 0;
        this.state.frequency = 'one_off';
        this.state.viewState = 'initial';
    };

    zeroSelectedProducts = () => {
        return _.clone(FacadeCleaning.zeroSelectedProducts);
    };

    updateArea = (event) => {
        var area = parseInt(event.target.value);
        if (isNaN(area)) {
            alert("Моля въведете валидна площ");
            return
        }
        this.setState({area: area})

    };

    updateName = (event) => {
        this.setState({name: event.target.value})
    };

    updateEmail = (event) => {
        this.setState({email: event.target.value})
    };

    updatePhone = (event) => {
        this.setState({phone: event.target.value})
    };

    hasInput = () => {
        var area = this.state.area;
        return !!area && area !== '' && area > 0;
    }

    showUserDetailsFields = () => {
        return !this.props.clientId;
    }

    frequencyPreferences = () => {
        return [["Еднократно", 'one_off'], ["Абонамент", 'subscription']];
    }

    handleSubmit = () => {
        if (!this.props.selectedDistrictId && !this.props.selectedAddressId) {
            alert("Моля изберете град и квартал за да получите оферта.");
            return;
        }

        if (this.state.area === '' || this.state.area <= 0) {
            alert("Моля въведете валидна площ.");
            return;
        }

        if (!this.props.clientId && (_.isEmpty(this.state.name.trim()) || _.isEmpty(this.state.phone.trim()) || _.isEmpty(this.state.email.trim()))) {
            alert("Моля въведете всички данни за контакт.");
            return;
        }

        // send to server

        this.setState({viewState: 'submitting'}, function () {
            let requestData = {
                area: this.state.area, frequency: this.state.frequency, client_id: this.props.clientId,
                name: this.state.name, phone: this.state.phone, email: this.state.email
            };

            $.ajax({
                type: "POST",
                url: PathHelper('facade_cleaning_quotes_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(requestData),
            }).done(function (data) {
                if (data['success'] === true) {
                    this.setState({viewState: 'submitted'});
                } else {
                    this.setState({viewState: 'initial'});
                    alert("Имаше грешка, моля натиснете Refresh и опитайте отново.")
                }
            }.bind(this));
        }.bind(this));//end set state
    }

    onFrequencyChange = (event) => {
        this.setState({frequency: event.currentTarget.value});
    };

    isOnInitialState = () => {
        return this.state.viewState === 'initial';
    }

    isOnSubmitting = () => {
        return this.state.viewState === 'submitting';
    }

    isOnSubmitted = () => {
        return this.state.viewState === 'submitted';
    }


    render() {
        var area = this.state.area;

        if (isNaN(area) || area === 0) {
            area = '';
        }
        return (
            <div>
                <div className={classNames({hidden: !this.isOnInitialState()})}>
                    <div className="service-customizer-input-fields-container">
                        <div className="row">
                            <div className="form-group col-md-6 col-md-offset-3 free-input-field-container">
                                <div className="radio-container">
                                    {_.map(this.frequencyPreferences(), function (preference) {
                                        var preferenceLabel = preference[0],
                                            preferenceValue = preference[1];

                                        return (
                                            <label className="radio-inline" key={preferenceValue}>
                                                <input type="radio"
                                                       name="frequency_preference"
                                                       value={preferenceValue}
                                                       checked={this.state.frequency === preferenceValue}
                                                       onChange={this.onFrequencyChange}/>
                                                {preferenceLabel}
                                            </label>
                                        );
                                    }.bind(this))}
                                </div>

                                <div className={classNames({"input-group": this.hasInput()})}>
                                    <input type="number" name="area"
                                           value={area}
                                           onChange={this.updateArea}
                                           placeholder={I18n.t('components.customizers.enter-area-placeholder', {metric: UnitsUtils.areaUnit()})}
                                           className={classNames({
                                               "without-right-border": this.hasInput(),
                                               "form-control": true
                                           })}/>
                                    {this.hasInput() &&
                                    <div className="input-group-addon">{UnitsUtils.areaUnit()}</div>}
                                </div>

                                {this.showUserDetailsFields() &&
                                <div className="input-group row-input-group">
                                    <input type="tel" className="form-control"
                                           value={this.state.name}
                                           onChange={this.updateName}
                                           placeholder="Име"/>
                                    <div className="input-group-addon">
                                        <span className="glyphicon glyphicon-user" aria-hidden="true"></span>
                                    </div>
                                </div>}

                                {this.showUserDetailsFields() &&
                                <div className="input-group row-input-group">
                                    <input type="tel" className="form-control"
                                           value={this.state.phone}
                                           onChange={this.updatePhone}
                                           placeholder="Телефон"/>
                                    <div className="input-group-addon">
                                        <span className="glyphicon glyphicon-phone-alt" aria-hidden="true"></span>
                                    </div>
                                </div>}

                                {this.showUserDetailsFields() &&
                                <div className="input-group row-input-group">
                                    <input type="tel" className="form-control"
                                           value={this.state.email}
                                           onChange={this.updateEmail}
                                           placeholder="Имейл"/>
                                    <div className="input-group-addon">
                                        <span className="glyphicon glyphicon-envelope" aria-hidden="true"></span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="mobile-service-actions">
                        <button
                            className={'btn btn-primary continue-cta polyxena-submit-button'}
                            onClick={this.handleSubmit}>
                            Вземи оферта
                        </button>
                    </div>
                </div>

                <div className={classNames({hidden: !this.isOnSubmitting()}) + " facade-progress-container"}>
                    <div className="css-loader">
                    </div>
                    Данните се обработват...
                </div>

                <div className={classNames({hidden: !this.isOnSubmitted()}) + " facade-progress-container"}>
                    <div className="circle-check">
                    </div>
                    Заявката е приета, ще получите обаждане най-късно до следващия работен ден.
                </div>
            </div>
        );
    }
}


export default FacadeCleaning;