import React from 'react';
import PropTypes from "prop-types";

import classNames from 'classnames';

import LoginForm from '@components/common/authenticator/login_form';
import ForgottenPasswordForm from '@components/common/authenticator/forgotten_password_form';
import RegistrationForm from '@components/common/authenticator/registration_form';

class Options extends React.Component {
    static propTypes = {
        onLoginSubmit: PropTypes.func,
        loginErrors: PropTypes.object,

        onResetPasswordSubmit: PropTypes.func,
        resetPasswordErrors: PropTypes.object,
        resetPasswordSent: PropTypes.bool,

        onRegistrationSubmit: PropTypes.func,
        onRegisterClick: PropTypes.func,
        registrationErrors: PropTypes.object,

        omniauthRedirectPath: PropTypes.string,
        omniauthError: PropTypes.bool
    };

    constructor(props) {
        super(props);
        let initialTabOpen;
        if (this.props.omniauthError == "register") {
             initialTabOpen = "registration";
        } else {
             initialTabOpen = "login";
        }
        this.state = {
            tabOpen: initialTabOpen
        };
    };


    componentDidMount() {
        Analytics.sendAuthenticationPageview();
        Analytics.sendStartLogin('funnel_login');
    }

    onForgottenPasswordClick = () => {
        this.setState({tabOpen: 'forgotten_password'});
    }

    showLogin = () => {
        this.setState({tabOpen: "login"});
        Analytics.sendStartLogin('funnel_login');
    };

    showRegistration = () => {
        this.setState({tabOpen: "registration"});
        Analytics.sendStartRegistration('funnel_registration');
    };

    loginFormClasses = () => {
        return this.tabIsActive("login") ? "" : "hidden";
    };

    registrationFormClasses = () => {
        return this.tabIsActive("registration") ? "" : "hidden";
    };

    forgottenPasswordFormClasses = () => {
        return this.tabIsActive("forgotten_password") ? "" : "hidden";
    };

    tabIsActive = (tabName) => {
        return this.state.tabOpen == tabName;
    };


    render() {
        return (
            <div>
                <div className="modal-tabs tabs-type-1">
                    <div className={classNames({tab: true, active: this.tabIsActive("login")})}
                         onClick={this.showLogin}>
                        {i18n.t('funnel.user-form.login.tab')}
                    </div>

                    <div className={classNames({tab: true, active: this.tabIsActive("registration")})}
                         onClick={this.showRegistration}>
                        {i18n.t('funnel.user-form.registration.tab')}
                    </div>
                </div>

                <div>
                    <div className={this.loginFormClasses()}>
                        {this.props.omniauthError == "login" &&
                        <div className="error-message error-omniauth">
                            {i18n.t('login.invalid-omniauth')}
                        </div>}

                        <div className="row social-login-buttons">
                            <div className="col-sm-12">
                                <a href={this.props.googleAuthUrl + "?login=true&locale=" + i18n.locale + "&origin=" + encodeURIComponent(this.props.omniauthRedirectPath)}
                                   className="btn btn-omniauth btn-google">
                                    {i18n.t('login.with-google')}
                                </a>
                            </div>
                            {FACEBOOK_LOGIN &&
                            <div className="col-sm-12">
                                <a href={this.props.facebookAuthUrl + "?login=true&locale=" + i18n.locale + "&origin=" + encodeURIComponent(this.props.omniauthRedirectPath)}
                                   className="btn btn-omniauth btn-facebook">
                                    {i18n.t('login.with-facebook')}
                                </a>
                            </div>}
                        </div>

                        <div className="text-center horizontal-separator">
                            <span className="tag">{i18n.t('support.or')}</span>
                            <hr/>
                        </div>

                        <LoginForm onSubmit={this.props.onLoginSubmit} errors={this.props.loginErrors} onForgottenPasswordClick={this.onForgottenPasswordClick}/>
                    </div>

                    <div className={this.forgottenPasswordFormClasses()}>
                        <ForgottenPasswordForm onSubmit={this.props.onResetPasswordSubmit} backToLoginClicked={this.showLogin} errors={this.props.resetPasswordErrors} resetPasswordSent={this.props.resetPasswordSent} />
                    </div>

                    <div className={this.registrationFormClasses()}>
                        {this.props.omniauthError == "register" &&
                        <div className="error-message error-omniauth">
                            {i18n.t('signup.invalid-omniauth')}
                        </div>}

                        <div className="row social-login-buttons">
                            <div className="col-sm-12">
                                <a href={this.props.googleAuthUrl + "?register=true&locale=" + i18n.locale + "&origin=" + encodeURIComponent(this.props.omniauthRedirectPath)}
                                   className="btn btn-omniauth btn-google" onClick={this.props.onRegisterClick}>
                                    {i18n.t('signup.with-google')}
                                </a>
                            </div>
                        </div>

                        <div className="text-center horizontal-separator">
                            <span className="tag">{i18n.t('support.or')}</span>
                            <hr/>
                        </div>

                        <RegistrationForm onSubmit={this.props.onRegistrationSubmit}
                                          errors={this.props.registrationErrors}
                                          onSubmitClick={this.props.onRegisterClick}/>
                    </div>
                </div>
            </div>
        );
    }
};

export default Options;
