import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import AddressSelectorForm from '@components/common/address_selector/form';
import AddressSelectorResponsiveOptions from '@components/common/address_selector/responsive_options';
import MediaQuery from 'react-responsive';
import BreakPoints from '@services/breakpoints';
import Modal from '@components/vendor/modal';
import $ from 'jquery';

class AddressSelector extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        cities: PropTypes.arrayOf(PropTypes.object),
        addresses: PropTypes.arrayOf(PropTypes.object),

        highlightedCompanyName: PropTypes.string,

        onSelect: PropTypes.func,
        onDestroy: PropTypes.func,
        collectPhone: PropTypes.bool,

        selectedDistrict: PropTypes.object,
        selectedPostCode: PropTypes.string,
        selectedAddress: PropTypes.object,

        executeSlowOperation: PropTypes.func,
        displaySpecialBadge: PropTypes.bool,
    };

    state = { newAddressDialogIsOpen: false };

    componentDidMount() {
        Analytics.sendAddressSelectionPageview();

        var screenIsSmall = window.innerWidth < BreakPoints.xsMax;

        if(screenIsSmall) {
            $(function() {
                window.scrollTo(0,0);
            });
        }
    }

    addressesForLocation = () => {
        if(this.props.location.district) {
            return _.filter(this.props.addresses, function(address) {
                return address.district_id == this.props.location.district.id;
            }.bind(this));
        }
        else {
            return this.props.addresses || [];
        }
    };

    openAddressDialog = () => {
        this.setState({newAddressDialogIsOpen: true});
    };

    closeAddressDialog = () => {
        this.setState({newAddressDialogIsOpen: false});
    };

    recordPhoneIfGiven = (address) => {
        if(address.phone) {
            $.ajax({
                type: "PATCH",
                url: PathHelper('client_update_phone_path'),
                dataType: 'json',
                contentType: 'application/json',
                data : JSON.stringify({
                    phone: address.phone
                }),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });
        }
    };

    selectedCompanyName = () => {
        return this.props.highlightedCompanyName;
    };

    handleSelect = (address) => {
        if(address.unavailable) {
            if (typeof window !== 'undefined') {
                window.scrollTo(0,0);
            }
            if(this.props.highlightedCompanyName != null){
                this.setState({ error:
                        i18n.t('funnel.address-form.unavailable-address-error-for-company', {company_name: this.selectedCompanyName()})});
            } else {
                this.setState({ error: i18n.t('funnel.address-form.unavailable-address-error')});
            }

        }
        else {
            this.recordPhoneIfGiven(address);
            this.props.onSelect(address);
            this.setState({ error: null });
        }
    };

    handleCreate = (address) => {
        this.recordPhoneIfGiven(address);

        return this.props.executeSlowOperation(function() {
            var promise = $.ajax({
                type: "POST",
                url: PathHelper('addresses_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(address),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });

            promise.done(function(response) {
                if(response.success) {
                    this.props.onSelect(response.address);
                }
            }.bind(this));

            return promise;
        }.bind(this), this.props.withDistrictStep);
    };

    handleDestroy = (address) => {
        if(address.has_upcoming_event) {
            alert(i18n.t('funnel.address-form.remove-error'));
            return;
        }

        if(confirm(i18n.t('funnel.address-form.remove-confirmation'))) {
            $.ajax({
                type: "DELETE",
                url: PathHelper('address_path', { id: address.id }),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });

            this.props.onDestroy(address);
        }
    };

    refreshWithoutParams = () => {
        window.location = window.location.pathname;
    };

    renderForm = () => {
        return (
            <AddressSelectorForm cities={this.props.cities}
                                  selectedCity={this.props.selectedCity}
                                  cityReadOnly={this.cityReadOnly()}
                                  selectedDistrict={this.props.selectedDistrict}
                                  districtReadOnly={this.districtReadOnly()}
                                  postCode={this.props.selectedPostCode}
                                  postCodeReadOnly={this.postCodeReadOnly()}
                                  onSubmit={this.handleCreate}
                                  collectPhone={this.props.collectPhone} />
        );
    };

    renderFormStep = () => {
        return (
            <div>
              <MediaQuery minWidth={BreakPoints.xsMax}>
                <div className="funnel-heading">
                  {i18n.t('funnel.address-form.enter-address')}
                </div>
                <div className="funnel-subtitle">
                </div>
              </MediaQuery>

              {this.renderForm()}
            </div>
        );
    };

    renderAddressOptions = () => {
        return (
            <div>
              <MediaQuery minWidth={BreakPoints.xsMax}>
                <div className="funnel-heading">
                  {i18n.t('funnel.address-form.select-address')}
                </div>
              </MediaQuery>

              {this.state.error &&
                  <div className="col-xs-12 error-message" style={{'textAlign': 'center', 'marginBottom': '12px'}}>
                      {this.state.error}
                      {this.props.highlightedCompanyName != null &&
                      <b><a href='#'
                            onClick={this.refreshWithoutParams}> {i18n.t('funnel.address-form.expand-search')}</a></b>
                      }
                      <br/>
                  </div>}

              <AddressSelectorResponsiveOptions
                 addresses={this.addressesForLocation()}
                 selectedAddress={this.props.selectedAddress}
                 onDestroy={this.handleDestroy}
                 onSelect={this.handleSelect}
                 onAdd={this.openAddressDialog}
                 displaySpecialBadge={this.props.displaySpecialBadge}
              />

              {this.renderModal()}
            </div>
        );
    };

    districtReadOnly = () => {
        return !!this.props.location.district;
    };

    cityReadOnly = () => {
        return !!this.props.location.city;
    };

    postCodeReadOnly = () => {
        return !!this.props.location.postCode;
    };

    renderModal = () => {
        return (
            <Modal size="large" isOpen={this.state.newAddressDialogIsOpen} onHide={this.closeAddressDialog}>
              <div className="custom-modal-header">
                <div className="close-button" onClick={this.closeAddressDialog}></div>
                <h1>{i18n.t('funnel.address-form.enter-address')}</h1>
              </div>
              <div className="custom-modal-content">
                {this.renderForm()}
              </div>
            </Modal>
        );
    };

    render() {
        if(this.addressesForLocation().length == 0) {
            return this.renderFormStep();
        }
        else {
            return this.renderAddressOptions();
        }
    }
}

export default AddressSelector;
