

var CleanerUtils = {
    humanCategory: function(category, deaf) {
        var textPath = "funnel.cleaners-options.all-cleaners.";

        if(category) {
            textPath += category + "-";
        }

        if(deaf) {
            textPath += "deaf-";
        }

        textPath += "title";

        return i18n.t(textPath);
    },

    categoryClasses: function(category, deaf) {
        var result = "";

        if(category) {
            result += category;
        }
        else if(deaf) {
            result += " deaf";
        }

        return result;
    }
};

export default CleanerUtils;
