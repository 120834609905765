import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class CountdownTimer extends React.Component {
    static propTypes = {
        seconds: PropTypes.number
    };

    state = { secondsLeft: this.props.seconds,
             timeStarted: moment() };

    componentDidMount() {
        var intervalId = setInterval(this.syncSecondsLeft, 1000);
        this.setState({intervalId: intervalId});
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    syncSecondsLeft = () => {
        var timeCurrent = moment(),
            secondsElapsed = moment.duration(timeCurrent.diff(this.state.timeStarted)).asSeconds(),
            secondsLeft = this.props.seconds - secondsElapsed;

        if(secondsLeft < 0) {
            clearInterval(this.state.intervalId);
        }
        else {
            this.setState({ secondsLeft: secondsLeft });
        }
    };

    renderTimeLeft = () => {
        var minutes = Math.floor(this.state.secondsLeft / 60),
            seconds = Math.floor(this.state.secondsLeft % 60);

        return this.numberWithPadding(minutes) + ':' + this.numberWithPadding(seconds);
    };

    numberWithPadding = (number) => {
        if(number / 10 < 1) {
            return '0' + number.toString();
        }
        else {
            return number.toString();
        }
    };

    render() {
        return (
            <span className="timer">
              {this.renderTimeLeft()}
            </span>
        );
    }
}


export default  CountdownTimer;