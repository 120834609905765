import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import withLoader from '@components/common/with_loader_decorator';
import AddressSelector from '@components/common/address_selector'
import SpinnerComponent from "@components/common/spinner";
import SubscriptionEditFormBreadcrumbs from "@components/subscription_edit_form/breadcrumbs";
import CleanerAndSlotSelector from "@components/common/cleaner_and_slot_selector";
import $ from 'jquery';
import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';

class SubscriptionEditForm extends React.Component {
    static propTypes = {
        submitPath: PropTypes.string.isRequired,

        subscriptionId: PropTypes.number.isRequired,
        product: PropTypes.object.isRequired,

        addresses: PropTypes.arrayOf(PropTypes.object),
        cities: PropTypes.arrayOf(PropTypes.object),
        cleaners: PropTypes.arrayOf(PropTypes.object),

        // default values
        initialAddress: PropTypes.object.isRequired,
        initialWeek: PropTypes.number.isRequired,
        initialCleaner: PropTypes.object.isRequired,
        initialDateslots: PropTypes.arrayOf(PropTypes.object),

        // languages
        nativeLanguage: PropTypes.string.isRequired,
        cleanerLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,

        listCompanies: PropTypes.bool,
        companies: PropTypes.arrayOf(PropTypes.object),
        companyIdFilter: PropTypes.number,

        locationStrategy: PropTypes.string
    };

    static childContextTypes = {
        locationStrategy: PropTypes.string
    };

    state = {
        step: 'slotsSelection', // || 'addressSelection'
        addresses: this.props.addresses,
        address: this.props.initialAddress || {},

        cleaners: this.props.cleaners,
        cleaner: this.props.initialCleaner,
        dateslots: this.props.initialDateslots || [],
        invalidSlots: false
    };

    getChildContext() {
        return { locationStrategy: this.props.locationStrategy };
    }

    submit = () => {
        if(confirm(i18n.t('dashboard.subscription.edit-confirmation', { date: i18n.l("date.formats.default", this.state.dateslots[0].date) }))){
            var params = {
                address_data: this.state.address,
                cleaner_id: this.state.cleaner.id,
                dateslots_data: _.map(this.state.dateslots, function(dateslot) {
                    return ScheduleUtils.sanitizeDateslot(dateslot);
                })
            };

            this.props.executeSlowOperation(function() {
                $.ajax({
                    type: "POST",
                    url: this.props.submitPath,
                    dataType: 'json',
                    contentType: 'application/json',
                    data : JSON.stringify({subscription_edit: params}),
                    statusCode: {
                        500: function() {
                            window.location.href = '/500';
                        }
                    }
                }).done(function(result) {
                    if(result.success) {
                        window.location.pathname = result.redirect_path;
                    }
                });

                return $.Deferred().promise();
            }.bind(this));
        }
    };

    // Address Logic --------------------------------

    selectAddress = (address) => {
        var addressIsNew = !_.some(this.state.addresses, function(existingAddress){
               return existingAddress.id == address.id;
            }),

            newState = { address: address,
                         step: 'slotsSelection',
                         dateslots: []};

        if(addressIsNew) {
            newState.addresses = this.state.addresses.concat(address);
        }

        this.setState(newState);
    };

    removeAddress = (address) => {
        var addresses = _.reject(this.state.addresses, function(existingAddress) {
            return existingAddress.id === address.id;
        });

        this.setState({ addresses: addresses });
    };

    // ==============================================

    // Slot logic -----------------------------------

    scheduleOptions = () => {
        return {
            productType: this.props.product.productType,
            districtId: this.state.address.district_id,
            cat: this.state.address.cat,
            dog: this.state.address.dog,
            hotel: this.state.address.hotel,
            visitDuration: this.props.product.visitDuration,
            visitsWeekly: this.props.product.visitsWeekly,
            subscriptionId: this.props.subscriptionId
        };
    };

    updateSlots = (slots) => {
        this.setState({ dateslots: slots, invalidSlots: false });
    };

    updateCleaner = (cleaner, callback) => {
        this.setState({ cleaner: cleaner }, callback);
    };

    addCleaner = (cleaner) => {
        this.setState({
            cleaners: [cleaner].concat(this.state.cleaners),
            cleaner: cleaner
        });
    };

    // ==============================================

    showAddressSelector = () => {
        this.setState({ step: 'addressSelection'});
    };

    showSlotsSelector = () => {
        this.setState({ step: 'slotsSelection'});
    };

    cleanerId = () => {
        var cleaner;

        if(this.state.cleaner && this.state.cleaner.id != this.props.initialCleaner.id) {
            cleaner = null;
        }
        else {
            cleaner = this.props.initialCleaner;
        }

        if(cleaner && !cleaner.presale_fake) {
            return cleaner.id;
        }
        else {
            return null;
        }
    };

    render() {
        var heading = i18n.t('dashboard.subscription.edit-hint-date-of-change'),
            body;

        if(this.state.step === 'slotsSelection') {
            body = (
                <CleanerAndSlotSelector scheduleOptions={this.scheduleOptions()}
                                        categoryPrice={this.props.categoryPrice}
                                        selectedDateslots={this.state.dateslots}
                                        initialWeek={this.props.initialWeek}
                                        cleaners={this.state.cleaners}
                                        cleaner={this.state.cleaner}
                                        initialCleanerIdFilter={this.cleanerId()}
                                        onCleanerSelect={this.updateCleaner}
                                        onAddCleaner={this.addCleaner}
                                        onSlotsSelect={this.updateSlots}
                                        invalidSlots={this.state.invalidSlots}
                                        executeSlowOperation={this.props.executeSlowOperation}
                                        onSubmit={this.submit}
                                        editing={true}
                                        heading={heading}
                                        cancelable={true}
                                        cancelButtonText={i18n.t('form.buttons.cancel-changes')}
                                        cancelButtonUrl={PathHelper('dashboard_path')}
                                        submitButtonText={i18n.t('form.buttons.confirm')}
                                        cleanerLanguages={this.props.cleanerLanguages}
                                        companyIdFilter={this.props.companyIdFilter}
                                        companies={this.props.companies}
                                        listCompanies={this.props.listCompanies}
                                        />
            );
        }
        else {
            body = (
                <AddressSelector
                   location={{}}
                   cities={this.props.cities}
                   addresses={this.state.addresses}

                   selectedAddress={this.state.address}

                   onSelect={this.selectAddress}
                   onDestroy={this.removeAddress}

                   executeSlowOperation={this.props.executeSlowOperation}
                   />
            );
        }

        return (
            <div>
              <SubscriptionEditFormBreadcrumbs step={this.state.step}
                                                product={this.props.product}
                                                dateslots={this.state.dateslots}
                                                address={this.state.address.text_description}
                                                cleaner={this.state.cleaner}
                                                showAddressSelector={this.showAddressSelector}
                                                showSlotsSelector={this.showSlotsSelector}
                                                cleanerCategoriesEnabled={this.props.cleanerCategoriesEnabled}
                                                priceChangeInfo={this.props.cleanerCategoriesEnabled} />
              <SpinnerComponent loading={this.state.loading} />
              {body}
            </div>
        );
    }
}


export default withLoader(SubscriptionEditForm);
