import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import $ from "jquery";
import storage from 'local-storage-fallback'

import BraintreeFormService from '@services/braintree_form_service'
import BraintreeForm from "@components/braintree/braintree_form";
import InvoiceModalTrigger from '@components/common/invoice_modal_trigger'
import PriceTag from '@components/common/price_tag'
import MoneyUtils from '@services/money_utils'
import CodeDiscountInput from '@components/funnel/code_discount_input'
import _ from "underscore";


class ClubPaymentForm extends React.Component {
    static propTypes = {
        clientToken: PropTypes.string.isRequired,
        verifyCardAmount: PropTypes.string.isRequired,
        braintreeCards: PropTypes.array,
        activePromoCodeExists: PropTypes.bool,
        initialOrderPrice: PropTypes.shape({
            currency: PropTypes.string.isRequired,

            productAmount: PropTypes.string.isRequired,
            productDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
            productAndFeeDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
        }),
    };


    BUYING_STATES = ['initial', 'processing_payment', 'bought']

    constructor(props) {
        super(props);
        this.state = {
            orderPrice: this.props.initialOrderPrice,
            buyingState: this.BUYING_STATES[0],
            selectedCardId: null,
            buttonDisabled: false,
            errorMessage: null,
            redirecting: false,
        };

        if (props.braintreeCards.length > 0)
            this.state.selectedCardId = _.last(props.braintreeCards).id;
    };

    onInstanceCreated = (clientInstance, hostedFieldsInstance, threeDSecureInstance) => {
        this.setState({
            clientInstance: clientInstance,
            hostedFieldsInstance: hostedFieldsInstance,
            threeDSecureInstance: threeDSecureInstance
        })
    };

    redirectBackToHousemaidFunnel = () => {
        let prev_state = storage.getItem('housemaid_product_selection_before_club');
        if(prev_state){
            this.setState({redirecting: true}, function(){
                prev_state = JSON.parse(prev_state);
                storage.removeItem('housemaid_product_selection_before_club');

                window.location.href = '/housemaid_requests/new?' + this.encodeQueryData(prev_state);
            })
        }
    }

    encodeQueryData = (data) => {
        const ret = [];
        for (let d in data)
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
    }

    submitPayment = (nonce) => {
        this.setState({buyingState: this.BUYING_STATES[1]}, function(){
            let requestData;
            if (this.state.selectedCardId) {
                requestData = {
                    saved_card_3d_nonce: nonce,
                    card_id: this.state.selectedCardId
                }
            } else {
                requestData = {
                    payment_method_nonce: nonce
                }
            }

            $.ajax({
                type: "POST",
                url: PathHelper('address_membership_payments_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(requestData),
            }).done(function (data) {
                if(data['success']) {
                    this.setState({buyingState: this.BUYING_STATES[2]}, function(){
                        this.redirectBackToHousemaidFunnel()
                    });
                    Analytics.sendClubSaleThankYou();
                    Analytics.sendPurchase(this.state.orderPrice, CURRENCY_CODE, 'club', [{id: 'club', item_price: this.state.orderPrice, quantity: 1}]);

                    if(typeof gtag !== 'undefined')
                        gtag('event', 'purchase',
                            { transaction_id: data['code'],
                              value: this.state.orderPrice,
                              currency: CURRENCY_CODE,
                              items: [{name: 'club', id: 'club', category: 'club', price: this.state.orderPrice, quantity: 1}]
                        });

                } else {
                    this.setState({buyingState: this.BUYING_STATES[0], errorMessage: data['error'], buttonDisabled: false});
                }
            }.bind(this));
        }.bind(this));//end set state

    }

    handleSubmit = (event) => {
        this.setState({buttonDisabled: true, errorMessage: null}, () => {
            if (this.hasSavedCardSelected()) {
                BraintreeFormService.savedCardSubmit(this.state.selectedCardId, this.state.threeDSecureInstance, this.props.verifyCardAmount)
                    .then((nonce) => {
                        this.submitPayment(nonce);
                    }).catch((error) => {
                    this.setState({buttonDisabled: false, errorMessage: error.message});
                });
            } else {
                if (!BraintreeFormService.isFormValid(this.state.hostedFieldsInstance)) {
                    this.setState({buttonDisabled: false, errorMessage: i18n.t('funnel.pay.braintree.card-invalid')});
                    return false;
                }
                BraintreeFormService.newCardSubmit(this.state.hostedFieldsInstance, this.state.threeDSecureInstance, this.props.verifyCardAmount)
                    .then((nonce) => {
                        this.submitPayment(nonce);
                    }).catch((error) => {
                        this.setState({buttonDisabled: false, errorMessage: error.message})
                    });
            }
        });
        event.preventDefault();
        return false;
    };

    btnClasses = () => {
        if (this.state.buttonDisabled) {
            return " loading disabled";
        }
        return '';
    };

    hasSavedCardSelected = () => {
        return this.state.selectedCardId !== null
    };

    amount = () => {
        return parseFloat(this.props.verifyCardAmount);
    }

    updatePrice = (newPrice) => {
        this.setState({orderPrice: newPrice});
    }

    membershipPrice = () => {
        var amount = parseFloat(this.props.verifyCardAmount);
        if (amount.toFixed(2) % 1 === 0) {
            return amount.toFixed(0).toString();
        } else {
            return amount.toFixed(2).toString();
        }
    }

    allDiscounts = () => {
        return _.flatten([this.state.orderPrice.productDiscounts, this.state.orderPrice.productAndFeeDiscounts]);
    }

    onCardSelect = (cardId) => {
        this.setState({selectedCardId: cardId})
    }

    isOnInitialState = () => {
        return this.state.buyingState === this.BUYING_STATES[0];
    }

    isOnProcessingState = () => {
        return this.state.buyingState === this.BUYING_STATES[1];
    }

    isOnBoughtState = () => {
        return this.state.buyingState === this.BUYING_STATES[2];
    }

    renderCodeInput = () => {
        if(this.props.activePromoCodeExists)
            return <CodeDiscountInput orderCode={this.props.orderCode} onCodeAccepted={this.updatePrice} />;
        else
            return <div/>;
    };


    render() {
        return (
            <div className='buy-membership-box-container'>
                <div className={classNames({hidden: !this.isOnBoughtState()}) + " progress-container payment-success"}>
                    <div className="circle-check">
                    </div>
                    <div className="header-text">
                        <h4>{i18n.t('buy-club.welcome')}</h4>
                        {!this.state.redirecting && <div>
                            <a href="/">{i18n.t('buy-club.book-service')}</a>
                        </div>}

                        {this.state.redirecting && <div>
                            {i18n.t('buy-club.redirecting')}
                        </div>}

                    </div>
                </div>


                <div className={classNames({hidden: !this.isOnProcessingState()}) + " progress-container"}>
                    <div className="css-loader">
                    </div>
                    {i18n.t('buy-club.processing-payment')}
                </div>

                <div className={classNames({hidden: !this.isOnInitialState()})}>
                    <div className='box-content-container'>
                        <div id="payment">
                            <div className={classNames({'order-summary-container': true, 'with-ddc-price': true})}>
                                <div className="payment-section-header">
                                    {i18n.t('funnel.cash-payment.order')}
                                </div>
                                <div className="line-items-container">
                                    <div className={'service-row'} key={'service-row-club'}>
                                        <div className="main-service-row">
                                            <div className={'service-name'}> {i18n.t('buy-club.activation-fee')} </div>
                                            <div className={'line-item-price'}>
                                                {MoneyUtils.price(this.state.orderPrice.productAmount, 2, false)}
                                            </div>
                                        </div>
                                        <div className={'service-line-items-container'}>
                                            <div className={'line-item-row'}>{i18n.t('buy-club.membership-clarification', {membership_fee: MoneyUtils.price(FUTURE_MEMBERSHIP_PRICE, 2, false)})}</div>
                                        </div>
                                    </div>
                                    {_.map(this.allDiscounts(), function (discount) {
                                        return (
                                            <div className={'service-row'} key={'service-row-domestina-club'}>
                                                <div className="main-service-row">
                                                    <div className={'service-name'}> {discount.description} </div>
                                                    <div className={'line-item-price'}>
                                                        -{MoneyUtils.price(discount.amount, 2, false)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }.bind(this))}

                                    <div className="service-row final-price-line-item">
                                        <div className={'payment-method-container'}>
                                        </div>
                                        <div className="funnel-payment-total-price-container">
                                            <div className={'text-container'}>{i18n.t('funnel.pay.total_amount')}</div>
                                            <PriceTag amount={this.state.orderPrice.amount} className="final-price-tag"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'funnel-card-form-section'}>
                                <div className="payment-section-header">
                                    {i18n.t('funnel.cash-payment.payment')}
                                </div>
                                <div className="card-list-container">
                                    <BraintreeForm clientToken={this.props.clientToken}
                                                   braintreeCards={this.props.braintreeCards}

                                                   selectedCardId={this.state.selectedCardId}
                                                   onCardSelect={this.onCardSelect}

                                                   onInstanceCreated={this.onInstanceCreated}
                                    />
                                </div>
                            </div>

                            <div className="funnel-payment-invoice-container">
                                <InvoiceModalTrigger orderCode={this.props.orderCode}
                                                     invoiceRequested={this.props.invoiceRequested}/>
                            </div>
                        </div>
                    </div>

                    {this.state.errorMessage &&
                    <div className={"errors-container "}>
                        {this.state.errorMessage}
                    </div>}

                    <div className="price-and-cta-container">
                        {this.renderCodeInput()}
                        <div className="cta-container">
                            <button className={"btn btn-primary cta-button " + this.btnClasses()} onClick={this.handleSubmit}>
                                {i18n.t('buy-club.cta')}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default ClubPaymentForm;
