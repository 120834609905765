import React from "react";
import PropTypes from "prop-types";
import PhoneCollector from "@components/common/phone_collector";

function withPhoneCollector(Component) {
    return class extends React.Component {
        static propTypes = {
            collectPhone: PropTypes.bool
        };

        state = {
            phoneNumber: null
        };

        collectPhone = () => {
            return this.props.collectPhone && this.state.phoneNumber == null;
        };

        updatePhoneNumber = (phoneNumber) => {
            this.setState({ phoneNumber: phoneNumber });
        };

        render() {
            return (
                <div>
                    {this.collectPhone() && <PhoneCollector updatePhoneNumber={this.updatePhoneNumber}/>}
                    <Component {...this.props}/>
                </div>
            );
        }
    };
}

export default withPhoneCollector;