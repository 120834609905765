import React from 'react';
import PropTypes from 'prop-types';

import FeedbacksSection from '@components/common/feedbacks_section'

class CompanyServiceProfile extends React.Component {
    static propTypes = {
        companyData: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            logo_url: PropTypes.string,
            rating: PropTypes.string,
            description_with_locale: PropTypes.object,
            events_count: PropTypes.number,
            initialFeedbacks: PropTypes.object,
            feedbacksPerPage: PropTypes.number,
            allFeedbacksShowed: PropTypes.bool,
        }),
        ctaButtonText: PropTypes.string,
        onCtaButtonClick: PropTypes.func
    };

    render() {
        return (
            <div className="company-profile">
                <div className="logo-and-stats">
                    <div className="company-logo-wrapper">
                        <img src={this.props.companyData.logo_url}/>
                    </div>

                    <div className="info">
                        {this.props.companyData.rating &&
                        <div className="stats">
                            <div className="stats-item">
                                <div className="value">
                                    {this.props.companyData.rating} / 10
                                </div>
                                <div className="name">
                                    {i18n.t('company-profile.rating')}
                                </div>
                            </div>

                            <div className="stats-item">

                                <div className="value">
                                    {this.props.companyData.events_count}
                                </div>

                                <div className="name">
                                    <a href="#company-feedbacks" className="with-down-arrow">
                                        {i18n.t('company-profile.reviews')}
                                    </a>
                                </div>

                            </div>
                        </div>}

                        {!this.props.companyData.rating &&
                        <div className="new-provider-info">
                            {i18n.t('company-profile.new-provider-info')}
                        </div>}
                    </div>
                </div>

                <h2 className='company-name'>{this.props.companyData.name}</h2>

                <h3>{this.props.companyData.serviceName}</h3>

                {this.props.companyData.description_with_locale && <div dangerouslySetInnerHTML={{__html: this.props.companyData.description_with_locale[i18n.locale]}}/> }

                {this.props.ctaButtonText && this.props.onCtaButtonClick &&
                    <div className='cta-container'>
                        <button className={'btn btn-primary cta-button'} onClick={this.props.onCtaButtonClick}>{this.props.ctaButtonText}</button>
                    </div>
                }

                {this.props.companyData.initialFeedbacks.all.items.length > 0 &&
                <div className="company-feedbacks">
                    <h4 id="company-feedbacks">{i18n.t('company-profile.feedbacks')}</h4>
                    <FeedbacksSection requestPath={'feedbacks_api_company_path'}
                                      requestParams={{id: this.props.companyData.id}}
                                      companyName={this.props.companyData.name}
                                      feedbacksPerPage={this.props.companyData.feedbacksPerPage}
                                      initialFeedbacks={this.props.companyData.initialFeedbacks}/>
                </div>}

                {this.props.ctaButtonText && this.props.onCtaButtonClick && this.props.companyData.initialFeedbacks.all.items.length > 0 &&
                <div className='cta-container'>
                    <button className={'btn btn-primary cta-button'} onClick={this.props.onCtaButtonClick}>{this.props.ctaButtonText}</button>
                </div>
                }
            </div>
        );
    }
}

export default CompanyServiceProfile;
