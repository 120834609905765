import storage from 'local-storage-fallback'

var FunnelStatePersistance = function() {
    var omittedStateParams = ['addresses']; // These params are updated after the client logs in so we don't want to override the updated values.

    var shouldLoadState = function() {
        if(typeof(URLSearchParams) !== 'undefined') {
            var urlParams = new URLSearchParams(location.search);
            var afterAuth = urlParams.get('after_auth');

            return afterAuth && afterAuth == 'true';
        }
        else {
            return false;
        }
    };

    var loadPersistedStateIfNeeded = function(component) {
        if(shouldLoadState()) {
            var funnelBeforeAuthStateJSON = storage.getItem('funnel_before_auth_state'),
                funnelBeforeAuthState = null;

            storage.removeItem('funnel_before_auth_state');

            if(funnelBeforeAuthStateJSON) {
                try {
                    funnelBeforeAuthState = JSON.parse(funnelBeforeAuthStateJSON);
                } catch(e) { //Remove invalid JSON and send error to sentry.io
                    if(typeof(Raven) !== 'undefined'){
                        Raven.captureException(e);
                    }
                }

                if(funnelBeforeAuthState) {
                    component.setState(funnelBeforeAuthState);
                }
            }
        }
    };

    var persistState = function(state) {
        storage.setItem('funnel_before_auth_state', JSON.stringify(_.omit(state, omittedStateParams)));
    }

    return {
        loadPersistedStateIfNeeded: loadPersistedStateIfNeeded,
        persistState: persistState
    };
}();

export default FunnelStatePersistance;
