import React from 'react';
import PropTypes from 'prop-types';

import CleanerNameWithCategory from '@components/common/cleaner_name_with_category';
import ProductUtils from '@services/product_utils';
import DateService from '@services/date_service';
import DateSlotService from '@services/date_slot_service';

class FunnelBreadcrumbsDetailsInfo extends React.Component {
    static propTypes = {
        product: PropTypes.object,

        dateslots: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            slot: PropTypes.number
        })),

        cleaner: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string
        }),

        slotsInfo: PropTypes.object,
        weekdaysDescription: PropTypes.string,
        service: PropTypes.string
    };

    render() {
        return (
            <div>
              <div className="little-darkblue-title">
                {this.title()}
              </div>
              <div>
                {this.content()}
              </div>
            </div>
        );
    }

    title = () => {
        var productType = this.props.product && this.props.product.productType,
            visitsWeekly = this.props.product && this.props.product.visitsWeekly;

        if(productType == 1 && this.props.dateslots.length > 0) { // Subscription
            return this.subscriptionDescription();
        }
        else if(productType == 4) { // AtWork
            if(visitsWeekly <= 2) {
                return this.subscriptionDescription();
            }
            else {
                return this.props.product.visitsWeekdaysDescription;
            }
        }
        else {
            return i18n.t('funnel.breadcrumbs.details.title');
        }
    };

    subscriptionDescription = () => {
        return DateSlotService.formatSubscriptionDateslots(this.props.dateslots, this.props.slotsInfo);
    };

    content = () => {
        if(this.props.dateslots.length > 0 && ProductUtils.isWeekly(this.props.product)) {
            return this.startsOn();
        }

        if(this.props.dateslots.length > 0) {
            return this.firstEventDescription(_.first(this.props.dateslots));
        }

        if(this.props.service == 'housemaid') {
            return i18n.t('funnel.breadcrumbs.details.content-housemaid');
        }

        return i18n.t('funnel.breadcrumbs.details.content');
    };

    startsOn = () => {
        var dateslotDescription = null,
            dateslot = _.first(this.props.dateslots);

        if(dateslot) {
            var date;

            if(this.props.dateslots.length > 1) {
                date = DateSlotService.formatDateSlot(dateslot, this.props.slotsInfo);
            }
            else {
                date = DateService.formatDate(dateslot.date);
            }

            dateslotDescription = (
                <div>
                  {i18n.t('funnel.breadcrumbs.details.starts-on', { date: date })}
                </div>
            );
        }

        return (
            <div>
              <div className="cleaner-and-event">
                {dateslotDescription}
                {this.props.cleaner && this.props.cleaner.id && <CleanerNameWithCategory {...this.props.cleaner}/>}
              </div>
            </div>
        );
    };

    firstEventDescription = (dateslot) => {
        var timeStart = dateslot.starts_at,
            timeEnd = dateslot.ends_at,
            dateslotDescription = null, calendarDate = null;

        if(dateslot) {
            dateslotDescription = (
                <div>
                  {DateService.formatDateAndDay(dateslot.date) + ', ' + timeStart + '-' + timeEnd}
                </div>
            );
            calendarDate = (
                <div className="calendar-sheet visible-lg-inline-block">
                  <div>
                    {_.last(dateslot.date.split('-'))}
                  </div>
                </div>
            );
        }

        return (
            <div>
              <div className="cleaner-and-event">
                {dateslotDescription}
                {this.props.cleaner && this.props.cleaner.id && <CleanerNameWithCategory {...this.props.cleaner}/>}
              </div>
            </div>
        );
    };
};


export default FunnelBreadcrumbsDetailsInfo;
