import React from 'react';
import PropTypes from "prop-types";

import classNames from 'classnames';

class ForgottenPasswordForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        backToLoginClicked: PropTypes.func,
        errors: PropTypes.object,
        resetPasswordSent: PropTypes.bool

    };

    handleSubmit = (event) => {
        event.preventDefault();

        this.props.onSubmit(this.refs.email.value);
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="pretty-form-heading">
                    {i18n.t('forgotten-password.header')}
                </div>
                {!this.props.resetPasswordSent &&
                    <div>
                        <div className="pretty-form-description">
                            {i18n.t('forgotten-password.description')}
                        </div>

                        <div>
                            <div className={classNames({"help-block": true, "error-text": this.props.errors })}>
                                { this.props.errors }
                            </div>

                            <div className="pretty-input">
                                <input name="email" ref="email" type="email"
                                       placeholder={i18n.t('helpers.label.funnel_form.login_email')}
                                       className="form-control input-lg"/>
                            </div>
                        </div>
                        <div className="forgotten-password-cta-container">
                            <div className="forgotten-pass-col">
                                <span className={'oh-hover-underline cursor-pointer link_color'} onClick={this.props.backToLoginClicked}> {i18n.t("forgotten-password.back")} </span>
                            </div>
                            <div className="forgotten-pass-col" style={{'textAlign': 'right'}}>
                                <button type="submit" className="pretty-submit">{i18n.t('forgotten-password.send')}</button>
                            </div>
                        </div>
                    </div>}
                {this.props.resetPasswordSent &&
                    <div>
                        <div className="pretty-form-description text-success">
                            {i18n.t('recovery-email-sent.subtitle')}
                        </div>
                        <div className="forgotten-pass-col text-center">
                            <button type="button" onClick={this.props.backToLoginClicked} className="pretty-submit">{i18n.t("forgotten-password.back")}</button>
                        </div>
                    </div>
                }

            </form>
        );
    }
};

export default ForgottenPasswordForm;
