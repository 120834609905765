import React from 'react';
import PropTypes from 'prop-types';

import PriceTag from '@components/common/price_tag';

class FunnelBreadcrumbsPaymentInfo extends React.Component {
    static propTypes = {
        product: PropTypes.object,
        totalPrice: PropTypes.string,
        priceType: PropTypes.string,
        prepaidEventsCount: PropTypes.number,
        defaultText: PropTypes.string,
        defaultTitle: PropTypes.string,
    };

    state = {
        latestPrice: this.props.totalPrice
    };

    updatePrice = (newPrice) => {
        this.setState({latestPrice: newPrice.amount});
    };

    componentWillMount() {
        OrderPriceStore.onChange(this.updatePrice);
    }

    totalVisits = () => {
        return 1;// this.props.product.visitsWeekly
    };

    price = () => {
        return this.props.totalPrice + " " + MoneyUtils.currency();
    };

    content = () => {
        if(this.props.totalPrice) {
            return (
                <div>
                  <PriceTag amount={this.state.latestPrice} className="price-tag green-text"/>

                  <div className="visits">
                    ({i18n.t('visits', {count: this.totalVisits()})})
                  </div>

                  {this.props.priceType &&
                    <div className="vat-note">
                      {this.props.priceType}
                    </div>}
                </div>
            );
        }
        else {
            return this.props.defaultText || i18n.t('funnel.breadcrumbs.payment.content');
        }
    };

    title = () => {
        return this.props.defaultTitle || i18n.t('funnel.breadcrumbs.payment.title');
    };

    render() {
        return (
            <div className="payment-info">
              <div className="little-darkblue-title">
                {this.title()}
              </div>
              <div>
                {this.content()}
              </div>
            </div>
        );
    }
};

export default FunnelBreadcrumbsPaymentInfo;
