import $ from "jquery";

var BraintreeFormService = {
    isFormValid: function (hostedFieldsInstance) {
        var fieldsState = hostedFieldsInstance.getState();
        return Object.keys(fieldsState.fields).every(function (key) {
            return fieldsState.fields[key].isValid;
        });
    },

    newCardSubmit: function (hostedFieldsInstance, threeDSecureInstance, verifyAmount) {
        let realVerifyAmount = parseFloat(verifyAmount) > 0 ? verifyAmount : 1;
        return new Promise(function (resolve, reject) {
            hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
                if (tokenizeErr) {
                    reject(Error(i18n.t('funnel.pay.braintree.card-tokenization-error')));
                }
                var my3DSContainer = document.createElement('div');

                //TODO Add email address after bin
                threeDSecureInstance.verifyCard({
                    amount: realVerifyAmount,
                    challengeRequested: true,
                    nonce: payload.nonce,
                    bin: payload.details.bin,
                    onLookupComplete: function (data, next) {
                        next();
                    }
                }, function (err, response) {
                    if (err) {
                        reject(Error(i18n.t('funnel.pay.braintree.card-3d-general-error')));
                    }
                    resolve(response.nonce);
                });
            });
        });

    },

    savedCardSubmit: function (selectedCardId, threeDSecureInstance, verifyAmount) {
        let realVerifyAmount = parseFloat(verifyAmount) > 0 ? verifyAmount : 1;
        return new Promise(function (resolve, reject) {
            $.get(PathHelper('clients_dashboard_braintree_card_nonce_path', {id: selectedCardId}), function (data) {
                var saved_card_nonce = data.nonce;
                var saved_card_bin = data.bin;
                var my3DSContainer = document.createElement('div');
                threeDSecureInstance.verifyCard({
                    amount: realVerifyAmount,
                    challengeRequested: true,
                    nonce: saved_card_nonce,
                    bin: saved_card_bin,
                    onLookupComplete: function (data, next) {
                        next();
                    }
                }, function (err, response) {
                    if (err) {
                        reject(Error(i18n.t('funnel.pay.braintree.card-3d-general-error')));
                    }
                    resolve(response.nonce);
                });
            });
        });
    },
}

export default BraintreeFormService;
