import React from 'react';


class FunnelProgressListExtrasInfo extends React.Component {
    render() {
        return (
            <div>
              <div className="little-darkblue-title">
                {i18n.t("funnel.breadcrumbs.extras-selection.title")}
              </div>
            </div>
        );
    }
};

export default FunnelProgressListExtrasInfo;
