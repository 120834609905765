import UnitsUtils from '@services/units_utils';

var LabelService = function() {
    var labelOption = function (fieldName, value) {
        var translationMap = {
            sofa_seats: "services.carpet_and_upholstery_cleaning.sofa_seat",
            sofa_pillows: "services.carpet_and_upholstery_cleaning.sofa_pillow",
            chairs: 'services.carpet_and_upholstery_cleaning.chair',
            armchairs: 'services.carpet_and_upholstery_cleaning.armchair',
            stools: 'services.carpet_and_upholstery_cleaning.stool',
            small_carpets: 'services.carpet_and_upholstery_cleaning.small_carpet',
            large_carpets: 'services.carpet_and_upholstery_cleaning.large_carpet',
            single_mattresses: 'services.carpet_and_upholstery_cleaning.single_mattress',
            double_mattresses: 'services.carpet_and_upholstery_cleaning.double_mattress',
            car_seats: 'services.car_upholstery_cleaning.car_seat',
            car_floors: 'services.car_upholstery_cleaning.car_floor',
            car_trunks: 'services.car_upholstery_cleaning.car_trunk',
            ovens: 'services.oven_cleaning.ovens',
            kitchen_hoods: 'services.oven_cleaning.kitchen_hoods',
            microwave_ovens: 'services.oven_cleaning.microwave_ovens',
            fridges: 'services.oven_cleaning.fridges',
            regular_bathrooms: 'services.bathroom_cleaning.regular_bathrooms',
            large_bathrooms: 'services.bathroom_cleaning.large_bathrooms',
            separate_wcs: 'services.bathroom_cleaning.separate_wcs',
            disinfection_area: 'services.disinfection.disinfection_price',
            light_cleaning_area: 'services.light_cleaning.light_cleaning_price',
        };

        return i18n.t(translationMap[fieldName], {count: value,
            count_times_4: value * 4,
            units: UnitsUtils.areaUnit(),
            bathroom_square_limit: BATHROOM_SQUARE_LIMIT});
    };

    return {
        labelOption: labelOption
    }
}();

export default LabelService;
