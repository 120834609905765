import React from 'react';

import _ from 'underscore';
import NumberField from '@components/common/number_field'
import UnitsUtils from '@services/units_utils';
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import BookingRequest from '@services/booking_request'
import classNames from "classnames";


class WindowCleaning extends AbstractProductCustomizer {
    static selectorType = 'window_cleaning';
    static zeroSelectedProducts = (new BookingRequest()).zeroSelectedProductsForService(WindowCleaning.selectorType);

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = {...this.zeroSelectedProducts(), ...props.initiallySelectedProductOptions};
        this.initializeProductOptions(props);
        if(_.any(this.areaFields(), function(area_field){
            return this.state.selectedProductOptions[area_field] && this.state.selectedProductOptions[area_field] > 0
        }.bind(this))) {
            this.state.activeTab = 'area';
        } else{
            this.state.activeTab = 'type';
        }
    };

    selectorType = () => {
        return WindowCleaning.selectorType;
    };

    zeroSelectedProducts = () => {
        let initialProducts = _.clone(WindowCleaning.zeroSelectedProducts);
        initialProducts['after_renovation_mode'] = false;
        return initialProducts;
    };

    samplePriceOptions = () => {
        return ['single_windows', 'glass_doors']
    };

    typeFields = () => {
        return ['glass_doors',
            'single_windows',
            'large_windows',
            'single_french_windows',
            'large_french_windows',
            'glass_door_blinds',
            'single_window_blinds',
            'large_window_blinds',
            'single_french_window_blinds',
            'large_french_window_blinds'];
    };

    typeFieldsGrouped = () => {
        return [
            ['single_windows', 'single_window_blinds'],
            ['large_windows', 'large_window_blinds'],
            ['glass_doors', 'glass_door_blinds'],
            ['single_french_windows', 'single_french_window_blinds'],
            ['large_french_windows', 'large_french_window_blinds'],
        ];
    };

    nullActiveTabFields = () => {
        var data = _.clone(this.state.selectedProductOptions);
        var keys = [];
        if (this.activeTabIsType()) {
            keys = this.typeFields();
        } else {
            keys = this.areaFields();
        }

        for (var index in keys) {
            data[keys[index]] = 0;
        }
        return data;
    };

    areaFields = () => {
        return ['windows_area',
            'blinds_area'];
    };

    activeTabIsType = () => {
        return this.state.activeTab === 'type';
    };

    activeTabIsArea = () => {
        return this.state.activeTab === 'area';
    };

    setTab = (tabName, e) => {
        e.preventDefault();
        if(tabName === this.state.activeTab)
            return;

        this.setState({selectedProductOptions: this.nullActiveTabFields(), activeTab: tabName}, function () {
            this.sendSelection(this.state.selectedProductOptions);
        }.bind(this));
    };

    updateArea = (fieldName, event) => {
        var area = parseInt(event.target.value),
            selectedProductOptions = this.state.selectedProductOptions;

        selectedProductOptions[fieldName] = (isNaN(area) || area < 2000) ? area : 2000;

        this.setState({selectedProductOptions: selectedProductOptions}, function () {
            this.sendSelection(this.state.selectedProductOptions);
        });
    };

    fieldPlaceholder = (fieldType) => {
        if (fieldType === 'windows_area') {
            return i18n.t('services.window_cleaning.windows', {count: 0}) + " " + UnitsUtils.areaUnit();
        } else if (fieldType === 'blinds_area') {
            return i18n.t('services.window_cleaning.blinds', {count: 0}) + " " + UnitsUtils.areaUnit();
        }
    }

    hasInput = (fieldType) => {
        var field = this.state.selectedProductOptions[fieldType]
        return !!field && field !== '' && field > 0;
    }

    render() {
        return (
            <div>
                <div className="window-cleaning-tab-container">
                    <div className={"left-part tab-container"}>
                        <div
                            className={classNames({"window-customization-tab": true, selected: this.activeTabIsType()})}
                            onClick={(e) => this.setTab('type', e)}>
                            {i18n.t('services.window_cleaning.customizer-tabs.type')}
                        </div>
                    </div>
                    <div className={"right-part tab-container"}>
                        <div
                            className={classNames({"window-customization-tab": true, selected: this.activeTabIsArea()})}
                            onClick={(e) => this.setTab('area', e)}>
                            {i18n.t('services.window_cleaning.customizer-tabs.area')}
                        </div>
                    </div>
                    <div className="bottom-border">
                    </div>
                </div>

                <div className="window-cleaning-customization service-customizer-input-fields-container">
                    {this.activeTabIsArea() && _.map(['windows_area', 'blinds_area'], function (option) {
                        return (
                            <div className="row" key={option}>
                                <div className="form-group col-md-6 col-md-offset-3 free-input-field-container">
                                    <div className={classNames({"input-group": this.hasInput(option)})}>
                                        <input type="number" name="windows_area"
                                               value={this.hasInput(option) ? this.state.selectedProductOptions[option] : ''}
                                               onChange={_.partial(this.updateArea, option)}
                                               onKeyPress={this.areaInputFieldKeyPressed}
                                               placeholder={this.fieldPlaceholder(option)}
                                               className={classNames({
                                                   "without-right-border": this.hasInput(option),
                                                   "form-control": true
                                               })}/>
                                        {this.hasInput(option) &&
                                        <div className="input-group-addon">{UnitsUtils.areaUnit()}</div>}
                                    </div>
                                </div>
                            </div>
                        );
                    }.bind(this))}

                    {this.activeTabIsType() && _.map(this.typeFieldsGrouped(), function (option) {
                        return (
                            <div className="window-customization-row" key={option[0]}>
                                <div className="row ">
                                    <div className="col-md-6 col-xs-12">
                                        <div className="customizer-explanation-container">
                                            <div className="window-name">
                                                {i18n.t('services.window_cleaning.customizer-sections.' + option[0])}
                                            </div>
                                            <div className='icon-container visible-md visible-lg'>
                                                <img src={this.props.icons[option[0]]}/>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6 col-xs-12" key={option[0]}>
                                        <div className="window-customizer">
                                            <div className='icon-container visible-xs visible-sm'>
                                                <img src={this.props.icons[option[0]]}/>
                                            </div>

                                            <div className="inputs-container">
                                                <NumberField value={this.state.selectedProductOptions[option[0]]}
                                                             label={i18n.t('services.window_cleaning.windows', {count: this.state.selectedProductOptions[option[0]]})}
                                                             onChange={_.partial(this.updateOption, option[0])}
                                                             disabled={!this.fieldSupported(option)}
                                                             price={this.extraInformation(option)}
                                                             min={0}
                                                />
                                                <NumberField value={this.state.selectedProductOptions[option[1]]}
                                                             label={i18n.t('services.window_cleaning.blinds', {count: this.state.selectedProductOptions[option[1]]})}
                                                             onChange={_.partial(this.updateOption, option[1])}
                                                             disabled={!this.fieldSupported(option)}
                                                             price={this.extraInformation(option)}
                                                             min={0}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }.bind(this))}

                    {this.renderRenovationModeCheckbox()}
                </div>
            </div>
        );
    }
}


export default WindowCleaning;