import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';

import BookingRequest from '@services/booking_request'
import Pricelist from '@services/pricelist'
import $ from "jquery";
import moment from 'moment';
import MoneyUtils from '@services/money_utils'

import ExtraServicesSelector from '@components/funnel/extra_services_selector'
import FunnelBookingAddressSelector from '@components/funnel/booking_address_selector'
import FunnelBookingPayment from '@components/funnel/booking_payment'
import FunnelBookingDateSlotSelector from '@components/funnel/booking_date_slot_selector'
import CommentInputStep from '@components/funnel/comment_input_step'
import ClubSaleStep from '@components/funnel/club_sale_step'
import CompanySelector from '@components/common/company_selector'
import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';
import ResponsiveBreadcrumbs from '@components/responsive_breadcrumbs'
import EarlyAdopterCollector from '@components/funnel/early_adopter_collector'


import FunnelStatePersistance from '@services/funnel_state_persistance'
import FunnelLocationProductCustomizer from '@components/funnel/location_product_customizer'


class AbstractFunnel extends React.Component {
    static propTypes = {
        serviceType: PropTypes.string,
        serviceTitle: PropTypes.string,

        submitPath: PropTypes.string,
        httpVerb: PropTypes.string,

        initialStep: PropTypes.string,

        // Authentication
        authenticationPrompt: PropTypes.func,
        authenticationStatus: PropTypes.string,

        // Location
        locationStrategy: PropTypes.string,
        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            districts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                alternative_name: PropTypes.string.isRequired,
            })),
            supports_service: PropTypes.bool,
        })),
        initialCityId: PropTypes.number,
        initialDistrictId: PropTypes.number,

        initialAddress: PropTypes.object,
        initialAddresses: PropTypes.array,
        initialPostCode: PropTypes.string,
        withDistrictStep: PropTypes.bool,

        // Product
        initiallySelectedProductOptions: PropTypes.object,

        // Companies
        listCompanies: PropTypes.bool,
        initialCompanies: PropTypes.arrayOf(PropTypes.object),
        highlightedCompanyId: PropTypes.number,
        highlightedCompanyName: PropTypes.string,
        highlightedCompanyDistrictIds: PropTypes.arrayOf(PropTypes.number),
        subdomainCompanyId: PropTypes.number,

        // Schedule
        initialWeek: PropTypes.number,
        initialDateslots: PropTypes.arrayOf(PropTypes.object),
        weeksAheadLimit: PropTypes.number,

        // Payment
        activePromoCodeExists: PropTypes.bool,

        lineItems: PropTypes.arrayOf(PropTypes.object),
        totalPrice: PropTypes.string,
        orderCode: PropTypes.string,
        invoiceRequested: PropTypes.bool,
        orderPrice: PropTypes.shape({
            currency: PropTypes.string.isRequired,

            productAmount: PropTypes.string.isRequired,
            productDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
            productAndFeeDiscounts: PropTypes.arrayOf(PropTypes.shape({
                amount: PropTypes.string,
                description: PropTypes.string
            })),
        }),

        // Icons
        icons: PropTypes.shape({
            glass_doors: PropTypes.string,
            single_windows: PropTypes.string,
            large_windows: PropTypes.string,
            single_french_windows: PropTypes.string,
            large_french_windows: PropTypes.string,
        }),

        // Filter
        initialFilter: PropTypes.string,
        initialFilterDate: PropTypes.string,

        // only pest control
        supportedProductOptions: PropTypes.arrayOf(PropTypes.object),

        collectPhone: PropTypes.bool,
        filterDateCache: PropTypes.object,

        promotionSeed: PropTypes.number,

        isMember: PropTypes.bool,
        membershipCurrentlyValid: PropTypes.bool,
        initialBuyMembership: PropTypes.bool,
        membershipPercentDiscount: PropTypes.number,
        afterRenovationModeServices: PropTypes.arrayOf(PropTypes.string)
    };

    constructor(props) {
        super(props);
        this.state = {
            location: {
                city: this.findCity(props.initialCityId),
                district: null,
            },

            address: this.props.initialAddress,
            addresses: this.props.initialAddresses,
            hasDog: false,
            hasCat: false,

            selectedProductOptions: this.props.initiallySelectedProductOptions || {},

            companies: this.props.initialCompanies,
            companyIdFilter: null,
            availabilitiesForSelectedUnavailableDateslot: null,

            bookingRequest: new BookingRequest(),

            selectedDateslots: this.props.initialDateslots || [],
            week: this.props.initialWeek || 0,
            weeksAheadLimit: this.props.weeksAheadLimit,

            commentStepSeen: false,
            clubStepSeen: false,

            step: this.props.initialStep || this.steps()[0],
            stepUpTo: this.props.initialStep || this.steps()[0],

            extraServicePrice: null,

            companyListSelectionData: {
                sortingCriteria: 0,
                selectedRank: null,
                possibleChoices: 0,
                promoted: false
            },

            filter: this.props.initialFilter || 'all',
            filterDate: null,
            filterDateCache: this.props.filterDateCache || {},
            buyAddressMembership: this.props.initialBuyMembership || false,
        }

        if (this.props.isMember) {
            this.state.buyAddressMembership = false;
        }

        if (this.state.filter === 'date') {
            if (Date.parse(this.props.initialFilterDate)) {
                this.state.filterDate = new Date(this.props.initialFilterDate);
            } else {
                this.state.filter = 'all';
            }

        }

        if (this.state.location.city) {
            this.state.location.district = this.findCity(props.initialCityId).districts.find(district => district.id === props.initialDistrictId);
        }

        if (this.props.initialCompanyIdFilter) {
            if (this.props.initialStep === 'dateslotSelection')
                this.state = {...this.state, ...this.newStateAfterCompanyChange(this.props.initialCompanyIdFilter, null, null, false)}
            else
                this.state['companyIdFilter'] = this.props.initialCompanyIdFilter
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.updatingFromHistory) {
            let newState = _.clone(props.stateFromHistory);
            props.updateFromHistoryFinished();
            return newState;
        } else
            return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var stepChanged = (this.state.step !== prevState.step ||
            this.state.extraServicesStepSeen !== prevState.extraServicesStepSeen);

        if (stepChanged) {
            window.scrollTo(0, 0);

            if (!this.props.updatingFromHistory) {
                this.props.captureHistoryState(this.state);
            }
        }
    }

    getChildContext() {
        return {locationStrategy: this.props.locationStrategy};
    }

    availabilityPathString = () => {
        throw "Implement availabilityPathString";
    };

    serviceType = () => {
        throw "Implement serviceType";
    };

    companiesForDatePath = () => {
        throw "Implement companiesForDatePath";
    };

    attributesPath = () => {
        throw "Implement attributesPath";
    };

    extraServiceTypes = () => {
        return []
    };

    dateslotSelectionStepName = () => {
        return 'dateslotSelection';
    };

    isAfterRenovationMode = () => {
        return !!this.state.selectedProductOptions['after_renovation_mode'];
    }

    selectedProductsPrice = () => {
        let selectedCompany = this.selectedCompany();
        if (selectedCompany) {
            let pricelist = new Pricelist(selectedCompany.pricelistData);
            let productPrice =  pricelist.totalPrice(this.state.selectedProductOptions);

            if(selectedCompany.product.minVisitPrice && productPrice < parseFloat(selectedCompany.product.minVisitPrice)){
                productPrice = parseFloat(selectedCompany.product.minVisitPrice);
            }

            return productPrice;
        } else {
            return null;
        }
    }

    highlightedCompanyError = () => {
        if(this.props.highlightedCompanyId === null)
            return null;
        else if(!_.contains(this.props.highlightedCompanyDistrictIds, this.activeDistrictId()))
            return i18n.t('funnel.companies.highlight-errors.not-support-district', {company_name: this.props.highlightedCompanyName})
        else if(!_.contains(this.companyIds(), this.props.highlightedCompanyId))
            return i18n.t('funnel.companies.highlight-errors.not-available', {company_name: this.props.highlightedCompanyName})
        else
            return null;
    }

    membershipAdditionalPrice = () => {
        let clubDiscount = MoneyUtils.round(this.selectedProductsPrice() * (this.props.membershipPercentDiscount) / 100, 2);
        if(clubDiscount >= ADDRESS_MEMBERSHIP_PRICE) {
            return 0;
        } else {
            return ADDRESS_MEMBERSHIP_PRICE - clubDiscount;
        }
    }

    totalPrice = () => {
        let productPrice = this.selectedProductsPrice();
        if (productPrice) {
            if(this.activeDiscountPercent()){
                productPrice =  productPrice - MoneyUtils.round(productPrice * (this.activeDiscountPercent()) / 100, 2);
            }
            if(this.state.buyAddressMembership && this.membershipAdditionalPrice() > 0)
                productPrice += this.membershipAdditionalPrice();

            return productPrice;
        } else {
            return null;
        }
    };

    // Stepping logic
    steps = () => {
        return ['locationProductCustomization', 'companySelection', 'dateslotSelection', 'extraInfoCustomization',
            'bookingConfirmation']
    };

    stepBody = () => {
        switch (this.state.step) {
            case 'locationProductCustomization':
                return this.locationProductCustomizer()
            case 'companySelection':
                if(OPERATIONAL) {
                    Analytics.sendCompanySelectionPageview();
                    return (
                        <CompanySelector highlightedCompanyId={this.props.highlightedCompanyId}
                                         highlightedCompanyError={this.highlightedCompanyError()}
                                         companies={this.filteredCompanies()}
                                         onSelect={this.selectCompany}
                                         allowNull={false}
                                         selectedFilter={this.state.filter}
                                         handleFilterSelect={this.handleFilterSelect}
                                         selectedFilterDate={this.state.filterDate}
                                         promotionSeed={this.props.promotionSeed}
                                         buyAddressMembership={this.state.buyAddressMembership}
                                         membershipPercentDiscount={this.props.membershipPercentDiscount}
                                         isCurrentAddressMember={this.isCurrentAddressAMember()}
                                         toggleBuyMembership={this.toggleBuyMembership}
                        >
                            <div className="funnel-heading hidden-xs hidden-sm">{i18n.t('funnel.companies.title')}</div>
                            <div className="funnel-subtitle"></div>
                        </CompanySelector>);
                } else {
                    return(
                        <div>
                            <div className="funnel-heading hidden-xs hidden-sm">{i18n.t('funnel.companies.title')}</div>
                            <EarlyAdopterCollector
                                cityName={this.selectedCity() ? this.selectedCity().name : '' }
                                cityId={this.selectedCityId()}
                                districtId={this.selectedDistrictId()}
                                serviceType={this.serviceType()}
                            />
                        </div>
                    );
                }

            case 'dateslotSelection':
                Analytics.sendDateslotSelectionPageview();
                return this.dateSlotSelector();
            case 'extraInfoCustomization':
                if (!this.state.extraServicesStepSeen && this.supportsAnyExtraServices()) {
                    Analytics.sendExtraServicesSelectionPageview();
                    return (
                        <ExtraServicesSelector onSubmit={this.extraProductsSubmit}
                                               pricelistData={this.selectedCompany().pricelistData}
                                               optionalServices={this.extraServiceTypes()}
                                               initiallySelectedProductOptions={this.state.selectedProductOptions}
                                               serviceName={this.props.serviceTitle}
                                               onExtraServicesUpdate={this.onExtraServicesUpdate}
                                               discountPercent={this.activeDiscountPercent()}
                                               areExtrasOptional={this.areExtrasOptional()}
                                               subheadingText={this.extraServicesSubheading()}
                                               headingText={this.extraServicesHeading()}
                        />
                    );
                } else if(!this.isCurrentAddressAMember() && !this.state.clubStepSeen){
                    Analytics.sendClubSalePageview();
                    return (<ClubSaleStep onSubmit={this.onClubSubmit}
                                              amountToPay={this.membershipAdditionalPrice()}
                        />
                    );
                }else if (!this.state.commentStepSeen) {
                    Analytics.sendCommentPageview();
                    return (<CommentInputStep onSubmit={this.onCommentSubmit}
                                              initiallySelectedProductOptions={this.state.selectedProductOptions}
                        />
                    );
                } else if (!this.hasSelectedAddress()) {
                    if (this.props.authenticationStatus === 'unauthenticated')
                        return (<div
                            className={'creating-order-funnel-step'}>{i18n.t("funnel.step-placeholders.authenticating")}</div>);
                    else
                        return this.addressSelector();
                } else {
                    return (<div
                        className={'creating-order-funnel-step'}>{i18n.t("funnel.step-placeholders.creating-order")}</div>);
                }
            case 'bookingConfirmation':
                return (
                    <FunnelBookingPayment serviceType={this.props.serviceType}
                                          lineItems={this.props.lineItems}
                                          totalPrice={this.totalPrice()}
                                          selectedProductsPrice={this.selectedProductsPrice()}
                                          orderCode={this.props.orderCode}
                                          invoiceRequested={this.props.invoiceRequested}
                                          orderPrice={this.props.orderPrice}
                                          activePromoCodeExists={this.props.activePromoCodeExists}
                                          buyAddressMembership={this.state.buyAddressMembership}
                                          membershipPercentDiscount={this.props.membershipPercentDiscount}
                                          membershipCurrentlyValid={this.props.membershipCurrentlyValid}
                                          addressMembershipAdditionalPrice={this.membershipAdditionalPrice()}
                                          showAddressMembershipOption={this.showAddressMembershipOption()}
                                          isCurrentAddressMember={this.isCurrentAddressAMember()}
                                          toggleBuyMembership={this.toggleBuyMembership}

                                          clientToken={this.props.clientToken}
                                          verifyCardAmount={this.props.verifyCardAmount}
                                          braintreeCards={this.props.braintreeCards}
                                          isAfterRenovationMode={this.isAfterRenovationMode()}
                                          afterRenovationModeServices={this.props.afterRenovationModeServices}

                    />
                )
            default:
                return '';
        }
    };

    clearStep = (step, state) => {
        switch (step) {
            case 'locationProductCustomization':
                break;
            case 'companySelection':
                break;
            case 'dateslotSelection':
                break;
            case 'extraInfoCustomization':
                state.extraServicesStepSeen = false;
                state.commentStepSeen = false;
                state.clubStepSeen = false;
                break;
            case 'bookingConfirmation':
                state.totalPrice = null;
                break;
            default:
                return '';
        }
    };

    dateSlotSelector = () => {

        var scheduleOptions = {
            districtId: this.activeDistrictId(),
            cat: this.state.hasCat,
            dog: this.state.hasDog,
            weeksAheadLimit: this.state.weeksAheadLimit
        };

        return (
            <FunnelBookingDateSlotSelector serviceType={this.props.serviceType}
                                           availabilityPathString={this.availabilityPathString()}
                                           initialWeek={this.state.week}
                                           scheduleOptions={scheduleOptions}
                                           selectedDateslots={this.state.selectedDateslots}
                                           onSlotsSelect={this.updateDateslots}
                                           companies={this.state.companies}
                                           companyIdFilter={this.state.companyIdFilter}
                                           selectCompany={this.onCompanyInScheduleSelect}
                                           highlightedCompanyId={this.props.highlightedCompanyId}
                                           listCompanies={this.props.listCompanies}
                                           executeSlowOperation={this.props.executeSlowOperation}
                                           onSubmit={this.handleDateSlotSelectorSubmit}
                                           filterDate={this.state.filterDate}
                                           handleBackToList={this.handleBackToList}
            />
        );
    };


    // --------------- STEPS LOGIC --------------------------

    changeStepAndClear = (step, cb) => {
        var newState = this.clearStepAndStepsAfter(step);
        newState.step = step;

        this.setState(newState, cb);
    };

    nextStepState = (step) => {
        var nextStep = this.nextStep(step),
            state = this.clearStepAndStepsAfter(nextStep);

        state.step = nextStep;
        state.stepUpTo = nextStep;

        return state;
    };

    nextStep = (step) => {
        var steps = this.steps(),
            givenStepIndex = steps.indexOf(step);

        return steps[givenStepIndex + 1];
    };

    clearStepAndStepsAfter = (step) => {
        var state = {},
            steps = this.steps(),
            stepIndex = steps.indexOf(step),
            lastStepIndex = steps.length - 1;

        while (stepIndex <= lastStepIndex) {
            this.clearStep(steps[stepIndex], state);
            stepIndex += 1;
        }

        return state;
    };

    isSubmissionStep = (step) => {
        return step === 'extraInfoCustomization' && this.hasSelectedAddress();
    };

    hasSelectedAddress = () => {
        return !_.isNull(this.state.address) && !_.isUndefined(this.state.address)
    }

    handleBackToList = (e) => {
        e.preventDefault();
        this.changeStep('companySelection');
    }

    changeStep = (step) => {
        var newState = {step: step};
        if (step === this.dateslotSelectionStepName()) {
            newState['extraServicesStepSeen'] = false;
            this.clearStep(step, newState);
        }
        if (step === 'locationProductCustomization' || step === 'companySelection') {
            newState['extraServicesStepSeen'] = false;
            this.clearStep(step, newState);
        }
        if (step === 'extraInfoCustomization') {
            newState['extraServicesStepSeen'] = false;
            this.clearStep(step, newState);
        }
        this.setState(newState);
    };

    // --------------- LOCATION LOGIC --------------------------

    locationProductCustomizer = () => {
        return (
            <FunnelLocationProductCustomizer service={this.props.serviceType}
                                             onSubmit={this.setLocationAndProduct}
                                             initiallySelectedCityId={this.selectedCityId()}
                                             initiallySelectedDistrictId={this.selectedDistrictId()}
                                             initiallySelectedAddressId={this.state.address ? this.state.address.id : null}
                                             initiallySelectedProductOptions={this.state.selectedProductOptions}
                                             addresses={this.state.addresses}
                                             cities={this.props.cities}
                                             districts={this.props.districts}
                                             icons={this.props.icons}
                                             supportedProductOptions={this.props.supportedProductOptions}
                                             isMember={this.props.isMember}
            />
        );
    }

    findCity = (cityId) => {
        return this.props.cities.find(city => city.id === cityId);
    };

    findDistrictInCity = (cityId, districtId) => {
        return this.findCity(cityId).districts.find(district => district.id === districtId);
    };

    selectPostCode = (postCode, city, district) => {
    };

    selectCityAndDistrict = (city, district) => {
        var nextState = this.nextStepState(this.state.step);
        nextState.location = {city: city, district: district};
        this.setState(nextState);

        Cookies.set('district_id', district.id);
        Analytics.sendAddPartialAddress(this.serviceType());
    };

    selectedCity = () => {
        return this.state.location.city;
    };

    selectedCityId = () => {
        if (this.selectedCity()) {
            return this.state.location.city.id;
        } else {
            return null;
        }
    };

    activeDistrict = () => {
        if (this.state.address && this.state.address.district_id) {
            return this.findDistrictInCity(this.state.address.city_id, this.state.address.district_id);
        } else {
            return this.state.location.district;
        }

    };

    activeDistrictId = () => {
        if (this.activeDistrict()) {
            return this.activeDistrict().id;
        } else {
            return null;
        }
    };


    selectedDistrict = () => {
        return this.state.location.district;
    };

    selectedDistrictId = () => {
        if (this.selectedDistrict())
            return this.selectedDistrict().id
        else
            return null;

    };

    selectedAddressId = () => {
        if (this.hasSelectedAddress())
            return this.state.address.id;
        else
            return null;
    }

    // --------------- PRODUCT LOGIC --------------------------

    setLocationAndProduct = (selected) => {
        var nextState = this.nextStepState(this.state.step);
        var selectedDistrictId = null;
        var selectedAddressId = null;

        if (selected['address']) {
            nextState['address'] = selected['address'];
            nextState.location = {city: null, district: null};
            selectedDistrictId = selected['address'].district_id;
            selectedAddressId = selected['address'].id;
        } else if (selected['district'] && selected['city']) {
            nextState.address = null;
            nextState.location = {city: selected['city'], district: selected['district']};
            selectedDistrictId = selected['district'].id
        } else {
            alert("No Location Selected!")
            return;
        }

        // I want to keep selection from extra services, but I want to get prices just for the main service(
        nextState['selectedProductOptions'] = {...this.state.selectedProductOptions, ...selected['productOptions']};

        this.props.executeSlowOperation(function () {
            return this.fetchAttributes(nextState['selectedProductOptions'], selectedAddressId, selectedDistrictId)
                .done(function (attributes) {
                    nextState.companies = attributes.companies;
                    nextState.weeksAheadLimit = attributes.weeksAheadLimit;
                    nextState.extraServicesStepSeen = false;
                    nextState.companyIdFilter = null;
                    nextState.companyListSelectionData = this.state.companyListSelectionData;
                    nextState.companyListSelectionData.sortingCriteria = 0;
                    nextState.companyListSelectionData.selectedRank = null;
                    nextState.companyListSelectionData.promoted = false;

                    this.setState(nextState);
                }.bind(this));
        }.bind(this));
    };

    extraProductsSubmit = () => {
        this.setState({
            extraServicesStepSeen: true
        });
    };

    onExtraServicesUpdate = (selectedExtraProductOptions, extraServicePrice) => {
        this.setState({
            selectedProductOptions: {...this.state.selectedProductOptions, ...selectedExtraProductOptions},
            extraServicePrice: extraServicePrice
        })
    }

    fetchAttributes = (product, addressId, districtId) => {
        var params = {};
        _.extend(params, {
            product_data: product,
            loyalty_discount_reference_code: this.props.loyaltyDiscountReferenceCode
        });

        if (districtId) {
            params.address_data = {district_id: districtId, id: addressId};
        }

        return $.ajax({
            type: "GET",
            url: this.attributesPath(),
            dataType: 'json',
            contentType: 'application/json',
            data: params,
            statusCode: {
                500: function () {
                    window.location.href = '/500';
                }
            }
        });
    };

    // ----------------------- COMPANIES LOGIC ---------------------------

    toggleBuyMembership = () => {
        this.setState({buyAddressMembership: !this.state.buyAddressMembership});
    };

    isCurrentAddressAMember = () => {
        return this.props.isMember;
    }

    showAddressMembershipOption = () => {
        return !this.isCurrentAddressAMember();
    }

    activeDiscountPercent = () => {
        if (this.props.isMember) {
            return this.props.membershipPercentDiscount;
        } else if (this.selectedCompany()) {
            return this.selectedCompany().ranking.discount_percent;
        } else {
            return 0;
        }
    }

    areExtrasOptional = () => {
        return true;
    }

    extraServicesSubheading = () => {
        return i18n.t('funnel.most-often-booked', {service_name: this.props.serviceTitle});
    }

    extraServicesHeading = () => {
        return i18n.t('funnel.extra-services');
    }

    selectCompany = (companyId, rank, sortingCriteria, showedPromoted) => {
        this.setState({...this.nextStepState(this.state.step), ...this.newStateAfterCompanyChange(companyId, rank, sortingCriteria, showedPromoted), ...{selectedDateslots: []}});
        window.scrollTo(0, 0);
    };

    handleFilterSelect = (newFilter, filterDate) => {
        this.setState({filter: newFilter, filterDate: filterDate}, function () {
            var filterDateString = this.filterDateString();
            if (newFilter === 'date' && !this.state.filterDateCache[filterDateString]) {
                this.props.executeSlowOperation(function () {
                    return this.fetchCompaniesForDate(this.state.selectedProductOptions, this.activeDistrictId(), filterDateString)
                        .done(function (companyIds) {
                            let toMerge = {};
                            toMerge[filterDateString] = companyIds;
                            this.setState({filterDateCache: {...this.state.filterDateCache, ...toMerge}});
                        }.bind(this));
                }.bind(this));
            }
        }.bind(this));
    }

    filteredCompanies = () => {
        var filteredIds = this.filteredCompanyIds();
        if (filteredIds) {
            return _.filter(this.state.companies, function (company) {
                return _.contains(filteredIds, company.id);
            });
        } else {
            return this.state.companies;
        }
    };

    companyIds = () => {
        return _.pluck(this.state.companies, 'id');
    };

    filteredCompanyIds = () => {
        if (this.state.filter && this.state.filter !== 'all') {
            if (this.state.filter === 'recent') {
                return this.companyIdsAvailableSoon();
            } else if (this.state.filter === 'date') {
                return this.state.filterDateCache[this.filterDateString()];
            }
        } else {
            return null;
        }
    };

    filterDateString = () => {
        return this.state.filterDate ? moment(this.state.filterDate).format('YYYY-MM-DD') : null;
    };


    fetchCompaniesForDate = (product, districtId, date) => {
        var params = {};
        _.extend(params, {
            product_data: product,
            dateslot_data: {date: date, starts_at: '00:00', ends_at: '23:59'}
        });

        if (districtId) {
            params.address_data = {district_id: districtId};
        }

        return $.ajax({
            type: "GET",
            url: this.companiesForDatePath(),
            dataType: 'json',
            contentType: 'application/json',
            data: params,
            statusCode: {
                500: function () {
                    window.location.href = '/500';
                }
            }
        });
    };


    companyIdsAvailableSoon = () => {
        return _.map(_.filter(this.state.companies, function (company) {
            return company.available_soon;
        }), function (company) {
            return company.id
        });
    }

    newStateAfterCompanyChange = (companyId, rank, sortingCriteria, showedPromoted) => {
        //filter out all selected product options with no price
        var company = this.findCompany(companyId);
        var pricelist = new Pricelist(company.pricelistData);
        var validItems = pricelist.rejectItemsWithNoPrice(this.state.selectedProductOptions, this.extraServiceTypes());
        var newState = {
            companyIdFilter: companyId,
            selectedProductOptions: validItems,
            extraServicesStepSeen: false,
            companyListSelectionData: {
                selectedRank: rank,
                sortingCriteria: sortingCriteria,
                possibleChoices: this.state.companies.length,
                promoted: showedPromoted && company.promoted
            },
        };
        return newState;
    };


    // ----------------------- ADDRESS LOGIC ---------------------------

    addressSelector = () => {
        return (
            <FunnelBookingAddressSelector
                location={this.state.location}
                cities={this.props.cities}
                districts={this.props.districts}
                addresses={this.state.addresses}

                selectedCompanyName={this.selectedCompany().name}

                selectedDistrict={this.selectedDistrict()}
                selectedCity={this.selectedCity()}

                onSelect={this.selectAddress}
                onDestroy={this.removeAddress}

                executeSlowOperation={this.props.executeSlowOperation}/>
        );
    };

    selectAddress = (address) => {
        var newAddress = !_.some(this.state.addresses, function (existingAddress) {
                return existingAddress.id === address.id;
            }),
            newState = {
                address: address,
                hasCat: address.cat,
                hasDog: address.dog
            };

        if (newAddress) {
            newState.addresses = this.state.addresses.concat(address);
        }

        this.setState(newState, function () {
            if (this.isSubmissionStep(this.state.step)) {
                this.submit();
            }
        }.bind(this));
    };

    removeAddress = (address) => {
        var newState = {};

        if (address.id === this.state.address.id) {
            newState = this.clearStepAndStepsAfter(this.state.step);
        }

        newState.addresses = _.reject(this.state.addresses, function (existingAddress) {
            return existingAddress.id === address.id;
        });

        this.setState(newState);
    };

    // ----------------------- DATESLOT LOGIC ---------------------------

    updateDateslots = (dateslots) => {
        var newState = this.clearStepAndStepsAfter(this.nextStep(this.state.step));
        newState.stepUpTo = this.state.step;
        newState.selectedDateslots = dateslots;
        this.setState(newState);
    };

    selectedUnavailableDateslot = () => {
        return this.state.selectedDateslots[0] !== undefined && this.state.selectedDateslots[0].unavailable;
    };

    handleDateSlotSelectorSubmit = () => {
        this.setState(this.nextStepState(this.state.step));
    };

    afterAuthFunnelPath = () => {
        var params = {after_auth: true};

        if (this.props.highlightedCompanyId) {
            params.highlighted_company_id = this.props.highlightedCompanyId;
        }

        return this.props.afterAuthenticationUrl + "?" + $.param(params);
    };

    updateFunnelAfterAuth = () => {
        return this.props.executeSlowOperation(function () {
            return this.fetchAttributes(this.props.selectedProductOptions, this.selectedAddressId(), this.activeDistrictId())
                .done(function (attributes) {
                    this.setState({addresses: attributes.addresses});
                }.bind(this));
        }.bind(this));
    };

    // ----------------------- SUBMISSION LOGIC ---------------------------

    funnelData = () => {
        return {
            product_data: this.state.selectedProductOptions,
            address_data: this.state.address,
            schedule_data: {
                cleaner_id: this.state.selectedDateslots[0].cleaners[0].id,

                dateslots: _.map(this.state.selectedDateslots, function (dateslot) {
                    return ScheduleUtils.sanitizeDateslot(dateslot);
                })
            },
            company_list_selection_data: {
                sorting_criteria: this.state.companyListSelectionData.sortingCriteria,
                selected_rank: this.state.companyListSelectionData.selectedRank,
                possible_choices: this.state.companyListSelectionData.possibleChoices,
                promoted: this.state.companyListSelectionData.promoted,
            },
            loyalty_discount_reference_code: this.props.loyaltyDiscountReferenceCode,
            filter: this.state.filter,
            filter_date: this.filterDateString(),
            buy_address_membership: this.state.buyAddressMembership,
        };
    };

    submit = () => {
        this.props.executeSlowOperation(function () {
            var promise = $.ajax({
                type: this.props.httpVerb,
                url: this.props.submitPath,
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(this.funnelData()),
                statusCode: {
                    500: function () {
                        window.location.href = '/500';
                    }
                }
            });

            var deferred = $.Deferred();
            deferred.promise();

            promise.done(function (result) {
                this.props.showScreenLoader();
                if (result.success) {
                    if (window.location.pathname === result.redirect_path) {
                        // Reloading preserves the scroll and we want
                        // the payment page to be always scrolled to
                        // the top so we scroll it before the reload.
                        window.scrollTo(0, 0);
                        window.location.reload();
                    } else {
                        window.location.assign(result.redirect_path);
                    }
                } else {
                    alert(result.error);
                    this.changeStepAndClear(this.dateslotSelectionStepName(), function () {
                        this.setState({cleanerAndSlotSelectorVersion: this.state.cleanerAndSlotSelectorVersion + 1});
                    }.bind(this));
                    deferred.resolve();
                }
            }.bind(this)).fail(function () {
                alert(i18n.t('funnel.time-form.errors.invalid-date-slot'));
                this.changeStepAndClear(this.dateslotSelectionStepName());
                deferred.resolve();
            }.bind(this));

            return deferred;
        }.bind(this));
    };


    // ----------------------- MISC ---------------------------

    ensureAuthentication = () => {
        if (this.props.authenticationStatus === 'unauthenticated') {
            FunnelStatePersistance.persistState(_.extend({}, this.state));

            this.props.authenticationPrompt(function () {
                this.updateFunnelAfterAuth();
            }.bind(this), this.afterAuthFunnelPath());
        } else {
            if (this.isSubmissionStep(this.state.step)) {
                this.submit();
            }
        }
    };

    supportsAnyExtraServices = () => {
        var pricelist = new Pricelist(this.selectedCompany().pricelistData);
        return _.some(this.extraServiceTypes(), function (serviceType) {
            return pricelist.doesSupportService(serviceType);
        }.bind(this));
    };


    cityAndDistrictDescription = () => {
        var district = this.selectedDistrict();
        var city = this.selectedCity();

        if (city && district)
            return city.name + ", " + district.name;
        else
            return null;
    };

    selectedCompany = () => {
        return this.findCompany(this.state.companyIdFilter);
    };

    findCompany = (companyId) => {
        return _.find(this.state.companies, function (company) {
            return company.id === companyId;
        }.bind(this));
    };

    availableCompanyIdsForUnavailableDateslot = () => {
        if (this.state.availabilitiesForSelectedUnavailableDateslot !== null) {
            return _.map(this.state.availabilitiesForSelectedUnavailableDateslot, function (availability) {
                return availability.company_id;
            });
        } else {
            return null;
        }
    };

    cleanerIdForCompanyId = (companyId) => {
        if (this.state.availabilitiesForSelectedUnavailableDateslot !== null) {
            var availability = _.find(this.state.availabilitiesForSelectedUnavailableDateslot, function (availability) {
                return availability.company_id === companyId;
            });
            return availability === undefined ? null : availability.cleaner_id;
        } else {
            return null;
        }
    };

    onCompanyForDateslotSelect = (companyId, rank, sortingCriteria, showedPromoted) => {
        var cleanerId = this.cleanerIdForCompanyId(companyId);
        var dateslot = this.state.selectedDateslots[0];
        dateslot.cleaners = [{id: cleanerId}];
        dateslot.unavailable = false;
        var newState = this.newStateAfterCompanyChange(companyId, rank, sortingCriteria, showedPromoted);

        newState['selectedDateslots'] = [dateslot];

        this.setState(newState, function () {
            this.handleDateSlotSelectorSubmit();
        }.bind(this));
    };

    onCompanyInScheduleSelect = (companyId, rank, sortingCriteria, showedPromoted) => {
        this.setState(this.newStateAfterCompanyChange(companyId, rank, sortingCriteria, showedPromoted))
    };

    onCompanyForDateslotModalClosed = () => {
        this.setState({availabilitiesForSelectedUnavailableDateslot: null});
        this.updateDateslots([]);
    };

    onCommentSubmit = (comment) => {
        var updatedOptions = this.state.selectedProductOptions;
        updatedOptions['comment'] = comment;
        this.setState({selectedProductOptions: updatedOptions, commentStepSeen: true}, function () {
            this.ensureAuthentication();
        }.bind(this));
    };

    onClubSubmit = (wouldBuyMembership) => {
        this.setState({buyAddressMembership: wouldBuyMembership, clubStepSeen: true});
    };

    breadcrumbsProps = () => {
        return {
            step: this.state.step,
            stepUpTo: this.state.stepUpTo,
            product: this.state.selectedProductOptions,
            cityAndDistrict: this.cityAndDistrictDescription(),
            address: this.state.address,
            // slotsInfo: SLOTS_INFO,
            onStepChange: this.changeStep,
            onStepChangeMobile: this.changeStepAndClear,
            dateslots: this.state.selectedDateslots,
            totalPrice: this.totalPrice(),
            priceType: this.props.priceType,
            prepaidEventsCount: this.props.prepaidEventsCount,
            steps: this.steps(),
            company: this.selectedCompany(),
            serviceTitle: this.props.serviceTitle,
            extraServicePrice: this.state.extraServicePrice,
            membershipPercentDiscount: this.props.membershipPercentDiscount,
            buyAddressMembership: this.state.buyAddressMembership
        };
    };

    render() {
        return (
            <div>
                <ResponsiveBreadcrumbs {...this.breadcrumbsProps()} />
                <div className="funnel-body">
                    {this.stepBody()}
                </div>
            </div>
        );
    }

}

export default AbstractFunnel;
