import React from 'react';
import PropTypes from "prop-types";
import ReactSelect from 'react-select';
import _ from 'underscore'

function Select(props) {
    var selectedValue = _.find(props.options, (option) => {
            return option.value === props.value;
        }) || null,

        filterOption = (option, filterString) => {
            return props.filterOption(_.extend({}, option, option.data), filterString);
        };

    return (
        <ReactSelect
           name={props.name}
           instanceId={props.name}
           options={props.options || []}
           value={selectedValue}
           onChange={props.onChange}
           filterOption={filterOption}
           placeholder={props.placeholder}
           className={props.className}
           noOptionsMessage={() => {return props.noResultsText; }}
           menuShouldScrollIntoView={props.scrollMenuIntoView}
           blurInputOnSelect={props.autoBlur}
           isDisabled={props.disabled}
           captureMenuScroll={false}
           />
    );
}

Select.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func,
    filterOption: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    noResultsText: PropTypes.string,
    scrollMenuIntoView: PropTypes.bool,
    autoBlur: PropTypes.bool,
    disabled: PropTypes.bool
};

export default Select;
