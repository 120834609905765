import React from 'react';


class FunnelProgressListMaidInfo extends React.Component {
    render() {
        return (
            <div>
              <div>
                {i18n.t("funnel.cleaners-options.select-cleaner-cta")}
              </div>
            </div>
        );
    }
};


export default FunnelProgressListMaidInfo;
