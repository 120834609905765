class BookingRequest {
    constructor() {
    }

    fieldsForService(serviceType) {
        if (serviceType === 'deep_cleaning' || serviceType === 'end_of_tenancy_cleaning') {
            return ['deep_cleaning_area'];
        } else if (serviceType === 'carpet_and_upholstery_cleaning') {
            return ['sofa_seats', 'armchairs', 'sofa_pillows', 'chairs', 'stools', 'small_carpets', 'large_carpets', 'single_mattresses', 'double_mattresses'];
        }else if (serviceType === 'after_renovation_cleaning') {
            return ['after_renovation_area'];
        }else if (serviceType === 'bathroom_cleaning') {
            return ['regular_bathrooms', 'large_bathrooms', 'separate_wcs'];
        }else if (serviceType === 'oven_cleaning') {
            return ['ovens', 'microwave_ovens', 'kitchen_hoods', 'fridges'];
        } else if (serviceType === 'car_upholstery_cleaning') {
            return ['car_seats', 'car_floors', 'car_trunks'];
        }else if (serviceType === 'window_cleaning') {
            return ['windows_area', 'blinds_area', 'glass_doors', 'single_windows', 'large_windows', 'single_french_windows',
                    'large_french_windows', 'glass_door_blinds', 'single_window_blinds', 'large_window_blinds',
                    'single_french_window_blinds', 'large_french_window_blinds'];
        }else if (serviceType === 'light_cleaning') {
            return ['light_cleaning_area'];
        } else {
            return [];
        }
    }

    zeroSelectedProductsForService(serviceType) {
        return _.object(_.map(this.fieldsForService(serviceType), function (productOption) {
            return [productOption, 0]
        }));
    }

}

export default BookingRequest;