import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from "underscore";


class TestimonialLoader extends React.Component {
    static propTypes = {
        testimonialsPath: PropTypes.string
    }


    state = {
        testimonials: [],
        hasMore: true,
        isSubmitted: false,
        loading: false,
        nextPage: 1
    };


    moreButtonClicked = () => {
        this.setState({loading: true}, function () {
            $.ajax({
                type: "GET",
                url: this.props.testimonialsPath + '?page=' + this.state.nextPage,
                dataType: 'json',
                contentType: 'application/json',
                statusCode: {
                    500: function () {
                        alert("There was a problem, please refresh and try again.")
                    }
                }
            }).done(function (attributes) {
                var hasMore = (attributes.length === 12);
                this.setState({
                    testimonials: [].concat(this.state.testimonials, attributes),
                    loading: false,
                    hasMore: hasMore,
                    nextPage: this.state.nextPage + 1
                })
            }.bind(this));
        }.bind(this));
    }

    showButton = () => {
        return !this.state.loading && this.state.hasMore;
    }

    renderTestimonial = (testimonial) => {
        return (
            <div className="testimonial-container" key={testimonial.id}>
                <div className="content-container">
                    <div className="info-container">
                        <span className="glyphicon glyphicon-star" aria-hidden="true"> </span> {i18n.t("home.testimonials.rating")}:
                        <span className="rating">{testimonial['rating']}/10</span> | {testimonial['submitted_at']}
                    </div>
                    <div className="comment-container">
                        {testimonial['comment']}
                    </div>
                    <div className="client-contractor-container">
                        {testimonial['client_name']} &nbsp; <span className="for-container">{i18n.t("home.testimonials.for")}</span> &nbsp;
                        {testimonial['path'] && <a href={testimonial['path']}>{testimonial['provider']}</a>}
                        {!testimonial['path'] && testimonial['provider']}
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <div>
                {_.map(this.state.testimonials,
                    function (testimonial, i) {
                        return this.renderTestimonial(testimonial);
                    }.bind(this))
                }
                <div className={'testimonials-load-more-button-container'} onClick={this.moreButtonClicked}>
                    {this.showButton() &&
                    <button className="btn btn-default btn-secondary more-testimonials-btn">{i18n.t("home.testimonials.more-testimonials")}</button>}
                    {this.state.loading && <div className={'css-loader'}></div>}
                </div>
            </div>

        );
    }
}

export default TestimonialLoader;
