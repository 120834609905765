import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';

class CommentInputStep extends React.Component {
    static propTypes = {
        initiallySelectedProductOptions: PropTypes.object,
        onSubmit: PropTypes.func,
        amplitudeContext: PropTypes.string,
    };

    state = {
        comment: this.props.initiallySelectedProductOptions.comment || ''
    };

    componentDidMount() {
        if(this.props.amplitudeContext)
            amplitude.track('see page - ' + this.props.amplitudeContext +  ' comment');
    }

    handleSubmit = () => {
        this.props.onSubmit(this.refs.comment.value);
    };

    commentChanged = () => {
        this.setState({comment: this.refs.comment.value});
    };

    buttonName = () => {
      if(_.isEmpty(this.state.comment)){
          return i18n.t('form.buttons.skip')
      } else {
          return i18n.t('form.buttons.proceed');
      }
    };


    render() {
        return (
            <div className="deep-cleaning-customization">
                <div>
                    <div className="funnel-heading">
                        <div>
                            {i18n.t('funnel.comment-input.heading')}
                        </div>
                    </div>
                    <div className="funnel-subheading">
                        {i18n.t('funnel.comment-input.subheading')}
                    </div>
                </div>

                <div className="row" style={{marginTop: '24px'}}>
                    <div className="form-group col-md-6 col-md-offset-3">
                            <textarea name="comment" ref="comment"
                                      value={this.state.comment}
                                      onChange={this.commentChanged}
                                      className="form-control input-lg"
                                      placeholder={i18n.t('funnel.deep-cleaning.comment')}/>
                    </div>
                </div>


                <div className="form-footer extra-service-form-footer">
                    <button
                        className={"btn btn-primary next-step btn-submit "}
                        onClick={this.handleSubmit}>
                        {this.buttonName()}
                    </button>
                </div>
            </div>
        );
    }
}

export default CommentInputStep;
