import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import withHistory from '@components/common/with_history_decorator'
import PreferredCleanerModal from '@components/funnel/preferred_cleaner_modal'

class CleanerDropdownFilter extends React.Component {
    static propTypes = {
        cleaners: PropTypes.arrayOf(PropTypes.object),
        onSelect: PropTypes.func,
        cleanerIdFilter: PropTypes.number,
        availableLanguages: PropTypes.array,
        selectedLanguage: PropTypes.string,
        languageFilterActive: PropTypes.bool
    };

    state = {
        modalIsOpen: false
    };

    // History management
    componentDidMount() {
        if(this.props.initializeStateAndHistory){
            this.props.initializeStateAndHistory({ componentName: 'CleanerDropdownFilter', currentState: this.state });
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.updatingFromHistory) {
            this.setState(nextProps.stateFromHistory);
            nextProps.updateFromHistoryFinished();
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if(this.state.modalIsOpen != nextState.modalIsOpen && !nextProps.updatingFromHistory) {
            nextProps.captureHistoryState(nextState);
        }
    }

    // ---

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    selectFromModal = (filterParams) => {
        this.setState({ modalIsOpen: false});
        this.props.onSelect(filterParams);
    };

    getCleaner = () => {
        if(this.props.cleanerIdFilter) {
            return this.findCleanerById(this.props.cleanerIdFilter);
        }
        else {
            return null;
        }
    };

    // TODO: EXTRACT REPETITION
    findCleanerById = (cleanerId) => {
        return _.find(this.props.cleaners, function(cleaner) {
            return cleaner.id === cleanerId;
        });
    };

    selectedCleanerName = () => {
        var cleaner = this.getCleaner();

        if(cleaner) {
            return cleaner.name;
        }
        else {
            return i18n.t('funnel.cleaners-options.company-and-maid-selector.all-maids');
        }
    };

    renderModal = () => {
        return (
            <div className="cleaner-list">
              <PreferredCleanerModal isOpen={this.state.modalIsOpen}
                                     onSelect={this.selectFromModal}
                                     cleaners={this.props.cleaners}
                                     languageFilterActive={false}/>
            </div>
        );
    };

    render() {
        return (
            <div onClick={this.openModal} className="schedule-dropdown-filter">
              {this.renderModal()}
              {this.selectedCleanerName()}
            </div>
        );
    }
}

export default withHistory(CleanerDropdownFilter);
