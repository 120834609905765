import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import AddressSelectorResponsiveOptions from '@components/common/address_selector/responsive_options';
import FunnelBookingAddressForm from '@components/funnel/booking_address_form'
import MediaQuery from 'react-responsive';
import BreakPoints from '@services/breakpoints';
import Modal from '@components/vendor/modal';
import $ from 'jquery';

class FunnelBookingAddressSelector extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        cities: PropTypes.arrayOf(PropTypes.object),
        districts: PropTypes.arrayOf(PropTypes.object),
        addresses: PropTypes.arrayOf(PropTypes.object),

        selectedCompanyName: PropTypes.string,

        selectedCity: PropTypes.object,
        selectedDistrict: PropTypes.object,

        onSelect: PropTypes.func,
        onDestroy: PropTypes.func,

        executeSlowOperation: PropTypes.func,
        amplitudeContext: PropTypes.string,

    };

    state = { newAddressDialogIsOpen: false };

    componentDidMount() {
        Analytics.sendAddressSelectionPageview();

        if(this.props.amplitudeContext)
            amplitude.track('see page - ' + this.props.amplitudeContext +  ' address selection');

        var screenIsSmall = window.innerWidth < BreakPoints.xsMax;

        if(screenIsSmall) {
            $(function() {
                window.scrollTo(0,0);
            });
        }
    }

    addressesForLocation = () => {
        return _.filter(this.props.addresses, function(address) {
            return address.district_id === this.props.location.district.id;
        }.bind(this));
    };

    openAddressDialog = () => {
        this.setState({newAddressDialogIsOpen: true});
    };

    closeAddressDialog = () => {
        this.setState({newAddressDialogIsOpen: false});
    };

    handleSelect = (address) => {
        if(!address.supports_service) {
            if (typeof window !== 'undefined') {
                window.scrollTo(0,0);
            }
            if(this.props.selectedCompany){
                this.setState({ error:
                        i18n.t('funnel.address-form.unavailable-address-error-for-company', {company_name: this.props.selectedCompanyName})});
            } else {
                this.setState({ error: i18n.t('funnel.address-form.unavailable-address-error')});
            }

        }
        else {
            this.props.onSelect(address);
            this.setState({ error: null });
        }
    };

    handleCreate = (address) => {
        return this.props.executeSlowOperation(function() {
            var promise = $.ajax({
                type: "POST",
                url: PathHelper('addresses_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(address),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });

            promise.done(function(response) {
                if(response.success) {
                    this.props.onSelect(response.address);
                }
            }.bind(this));

            return promise;
        }.bind(this), this.props.withDistrictStep);
    };

    handleDestroy = (address) => {
        if(address.has_upcoming_event) {
            alert(i18n.t('funnel.address-form.remove-error'));
            return;
        }

        if(confirm(i18n.t('funnel.address-form.remove-confirmation'))) {
            $.ajax({
                type: "DELETE",
                url: PathHelper('address_path', { id: address.id }),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });

            this.props.onDestroy(address);
        }
    };

    refreshWithoutParams = () => {
        window.location = window.location.pathname;
    };

    renderForm = () => {
        return (
            <FunnelBookingAddressForm selectedCity={this.props.selectedCity}
                                      selectedDistrict={this.props.selectedDistrict}
                                      onSubmit={this.handleCreate} />
        );
    };

    renderFormStep = () => {
        return (
            <div>
              <MediaQuery minWidth={BreakPoints.xsMax}>
                <div className="funnel-heading">
                  {i18n.t('funnel.address-form.enter-address')}
                </div>
                <div className="funnel-subtitle">
                </div>
              </MediaQuery>

              {this.renderForm()}
            </div>
        );
    };

    renderAddressOptions = () => {
        return (
            <div>
              <MediaQuery minWidth={BreakPoints.xsMax}>
                <div className="funnel-heading">
                  {i18n.t('funnel.address-form.select-address')}
                </div>
              </MediaQuery>

              {this.state.error &&
                  <div className="col-xs-12 error-message" style={{'textAlign': 'center', 'marginBottom': '12px'}}>
                      {this.state.error}
                      {this.props.highlightedCompanyName != null &&
                      <b><a href='#'
                            onClick={this.refreshWithoutParams}> {i18n.t('funnel.address-form.expand-search')}</a></b>
                      }
                      <br/>
                  </div>}

              <AddressSelectorResponsiveOptions
                 addresses={this.addressesForLocation()}
                 selectedAddress={this.props.selectedAddress}
                 onDestroy={this.handleDestroy}
                 onSelect={this.handleSelect}
                 onAdd={this.openAddressDialog} />

              {this.renderModal()}
            </div>
        );
    };

    districtReadOnly = () => {
        return !!this.props.location.district;
    };

    cityReadOnly = () => {
        return !!this.props.location.city;
    };

    postCodeReadOnly = () => {
        return !!this.props.location.postCode;
    };

    renderModal = () => {
        return (
            <Modal size="large" isOpen={this.state.newAddressDialogIsOpen} onHide={this.closeAddressDialog}>
              <div className="custom-modal-header">
                <div className="close-button" onClick={this.closeAddressDialog}/>
                <h1>{i18n.t('funnel.address-form.enter-address')}</h1>
              </div>
              <div className="custom-modal-content">
                {this.renderForm()}
              </div>
            </Modal>
        );
    };

    render() {
        if(this.addressesForLocation().length === 0) {
            return this.renderFormStep();
        }
        else {
            return this.renderAddressOptions();
        }
    }
}

export default FunnelBookingAddressSelector;
