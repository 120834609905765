import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import $ from 'jquery';

class ProgressList extends React.Component {
    componentDidMount() {
        $(window).on('scroll', this.adjustPosition);
        this.adjustFiller();
    }

    componentWillUnmount() {
        $(window).off('scroll', this.adjustPosition);
    }

    componentDidUpdate() {
        this.adjustFiller();
    }

    render() {
        return (
            <div>
              <div className="progress-list" ref="self">
                {this.props.children}
              </div>

              <div ref="filler"></div>
            </div>
        );
    }

    adjustPosition = () => {
        var selfNode = this.refs.self;

        if ($(window).scrollTop() > 50 ) {
            $(selfNode).css({'position': 'fixed', 'top': 0});
        } else {
            $(selfNode).css({'position': 'absolute', 'top': 'auto'});
        }
    };

    adjustFiller = () => {
        var selfNode = $(this.refs.self),
            fillerNode = $(this.refs.filler);

        fillerNode.css({ 'height': selfNode.height() });
    };
}

class ProgressListItem extends React.Component {
    static propTypes = {
        active: PropTypes.bool,
        disabled: PropTypes.bool,
        onClick: PropTypes.func
    };

    classes = () => {
        return classNames({
            'progress-list-item': true,
            active: this.props.active,
            disabled: this.props.disabled
        });
    };

    render() {
        return (
            <div className={this.classes()} onClick={this.props.onClick}>
              {this.props.children}
            </div>
        );
    }
};

export  {ProgressList, ProgressListItem};
