import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class InputWithErrors extends React.Component {
    static propTypes = {
        errors: PropTypes.string
    };

    renderErrorMessages = () => {
        if(this.props.errors) {
            return (
                <div className="messages help-block">
                  {this.props.errors}
                </div>
            );
        }

        return null;
    };

    containerClasses = () => {
        return classNames({ "has-error": this.props.errors,
                            "pretty-input": true });
    };

    render() {
        return (
            <div className={this.containerClasses()}>
              {this.props.children}
              {this.renderErrorMessages()}
            </div>
        );
    }
}

export default InputWithErrors;