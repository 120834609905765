import React from 'react';

import _ from 'underscore';
import UnitsUtils from '@services/units_utils';
import AbstractProductCustomizer from '@components/product_customizers/abstract'
import classNames from "classnames";


class AfterRenovationCleaning extends AbstractProductCustomizer {
    static zeroSelectedProducts = {
        after_renovation_area: 0,
    };
    static selectorType = 'after_renovation_cleaning';

    constructor(props) {
        super(props);
        this.state.selectedProductOptions = _.clone(AfterRenovationCleaning.zeroSelectedProducts);
        this.initializeProductOptions(props);
    };

    selectorType = () => {
        return AfterRenovationCleaning.selectorType;
    };

    zeroSelectedProducts = () => {
        return _.clone(AfterRenovationCleaning.zeroSelectedProducts);
    };

    updateArea = (event) => {
        var area = parseInt(event.target.value),
            selectedProductOptions = this.state.selectedProductOptions;

        selectedProductOptions['after_renovation_area'] = (isNaN(area) || area < 2000) ? area : 2000;

        this.setState({selectedProductOptions: selectedProductOptions}, function(){
            this.sendSelection(this.state.selectedProductOptions)
        }.bind(this));
    };

    hasInput = () => {
        var area = this.state.selectedProductOptions['after_renovation_area'];
        return !!area && area !== '' && area > 0;
    }


    render() {
        var area = this.state.selectedProductOptions['after_renovation_area'];

        if (isNaN(area) || area === 0) {
            area = '';
        }
        return (
            <div className="service-customizer-input-fields-container">
                <div className="row">
                    <div className="form-group col-md-6 col-md-offset-3 free-input-field-container">
                        <div className={classNames({"input-group": this.hasInput()})}>
                            <input type="number" name="area"
                                   value={area}
                                   onChange={this.updateArea}
                                   onKeyPress={this.areaInputFieldKeyPressed}
                                   placeholder={i18n.t('components.customizers.enter-area-placeholder', {metric: UnitsUtils.areaUnit()})}
                                   className={classNames({"without-right-border": this.hasInput(), "form-control": true})}/>
                            {this.hasInput() &&
                            <div className="input-group-addon">{UnitsUtils.areaUnit()}</div> }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default AfterRenovationCleaning;