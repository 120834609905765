import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import moment from 'moment';
import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';
import ProductUtils from '@services/product_utils';
import $ from 'jquery';

class WaitingListRequest extends React.Component {
    static propTypes = {
        product: PropTypes.object,
        addressId: PropTypes.number,
        selectedDateslots: PropTypes.arrayOf(PropTypes.object),
        selectedCleaner: PropTypes.object,
        executeSlowOperation: PropTypes.func,
        user: PropTypes.object
    };

    state = {
        mandatoryCleaner: false,
        dateslotPreference: null,
        notificationFrequency: null,
        notifyUpToDate: false,
        notifyFromDate: false,

        waitingListRecordCreated: false,
        showViberSubscribeButton: false
    };

    componentWillReceiveProps(nextProps) {
        if(!_.isEqual(this.props, nextProps)) {
            this.setState({
                mandatoryCleaner: false,
                dateslotPreference: null,
                notificationFrequency: null,
                notifyUpToDate: false,
                notifyFromDate: false,

                waitingListRecordCreated: false
            });
        }
    }

    componentDidMount() {
        if(this.props.user &&
           this.props.user.subscribed_in_viber != undefined &&
           this.props.user.subscribed_in_viber === false &&
           $("#qr-code-modal").length > 0) {
            $("#viber-subscription-qr-code").html(this.props.user.viber_subscription_qr_code);
            this.setState({ showViberSubscribeButton: true });
        }
    }

    requestParams = () => {
        var result = {
            // product
            product_type: this.props.product.productType,
            visit_duration: this.props.product.visitDuration,
            visits_weekly: this.props.product.visitsWeekly,

            // address
            address_id: this.props.addressId,
            district_id: this.props.districtId,
            postcode: this.props.postcode,

            // slots
            dateslots_data: _.map(this.props.selectedDateslots, function(dateslot) {
                return ScheduleUtils.sanitizeDateslot(dateslot);
            }),

            // selected preferences
            notify_regardless_of_selected_maid: !this.state.mandatoryCleaner,
            dateslot_preference: this.state.dateslotPreference,
            notification_frequency: this.state.notificationFrequency,
            convenient_up_to_date: this.state.notifyUpToDate ? this.props.selectedDateslots[0].date : null,
            convenient_from_date: this.state.notifyFromDate ? this.props.selectedDateslots[0].date : null
        };

        if(this.props.selectedCleaner) {
            result.cleaner_id = this.props.selectedCleaner.id;
        }

        return result;
    };

    checkedButton = () => {
        return (
            <button type="submit" className="btn btn-primary btn-success btn-with-check" disabled>
              <span className="glyphicon glyphicon-ok"></span>
              {i18n.t('funnel.time-form.request-phonecall.success')}
            </button>
        );
    };

    normalButton = () => {
        var buttonDisabled = !this.state.dateslotPreference || !this.state.notificationFrequency;

        return (
            <button type="submit" className="btn btn-primary" disabled={buttonDisabled}>
              {i18n.t('waiting-list.user-form.sign-me-up')}
            </button>
        );
    };

    makeRequest = (event) => {
        event.preventDefault();

        this.props.executeSlowOperation(function() {
            var promise = $.ajax({
                type: "POST",
                url: PathHelper('waiting_list_records_path'),
                dataType: 'json',
                contentType: 'application/json',
                data : JSON.stringify({ waiting_list_record: this.requestParams()}),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });

            promise.done(function(result) {
                this.setState({ waitingListRecordCreated: true });
            }.bind(this));

            return promise;
        }.bind(this));
    };

    onDateslotPreferenceOptionChange = (event) => {
        if(this.state.waitingListRecordCreated) { return null; }
        var newState = { dateslotPreference: event.currentTarget.value };

        if(!this.notifyUpToDateIsRelevant(event.currentTarget.value)) {
            newState.notifyUpToDate = false;
        }

        if(!this.notifyFromDateIsRelevant(event.currentTarget.value)) {
            newState.notifyFromDate = false;
        }

        this.setState(newState);
    };

    onNotificationFrequencyOptionChange = (event) => {
        if(this.state.waitingListRecordCreated) { return null; }
        this.setState({ notificationFrequency: event.currentTarget.value });
    };

    toggleNotifyUpToDate = () => {
        if(this.state.waitingListRecordCreated) { return null; }

        this.setState(function(prevState){
            return { notifyUpToDate: !prevState.notifyUpToDate };
        });
    };

    toggleNotifyFromDate = () => {
        if(this.state.waitingListRecordCreated) { return null; }

        this.setState(function(prevState){
            return { notifyFromDate: !prevState.notifyFromDate };
        });
    };

    toggleMandatoryCleaner = () => {
        if(this.state.waitingListRecordCreated) { return null; }

        this.setState(function(prevState){
            return { mandatoryCleaner: !prevState.mandatoryCleaner };
        });
    };

    dateslotPreferenceOptions = () => {
        var date = moment(this.props.selectedDateslots[0].date),
            dateIsWeekend = (date.day() == 0) || (date.day() == 6),
            allDatesAreWorkDays = _.every(this.props.selectedDateslots, function(dateslot){
                return moment(dateslot.date).day() != 0 && moment(dateslot.date).day() != 6;
            }),
            options = null,
            single_visit_during_workday_preference_options = null,
            single_visit_during_weekend_preference_options = null,

            subscription_visit_during_workday_preference_options = null,
            subscription_visit_during_weekend_preference_options = null,

            multiple_subscription_during_weekdays_preference_options = null,
            multiple_subscription_visits_preference_options = null;

        if(this.noDifferentHoursOptions()) {
            single_visit_during_workday_preference_options = ['exact_dateslot', 'any_slot_during_workday', 'anytime'],
            single_visit_during_weekend_preference_options = ['exact_dateslot', 'any_slot_during_weekend', 'anytime'],

            subscription_visit_during_workday_preference_options = ['exact_wday', 'any_slot_during_workday', 'anytime'],
            subscription_visit_during_weekend_preference_options = ['exact_wday', 'any_slot_during_weekend', 'anytime'],

            multiple_subscription_during_weekdays_preference_options = ['exact_wday', 'any_slot_during_workday', 'anytime'],
            multiple_subscription_visits_preference_options = ['exact_wday', 'anytime'];
        }
        else {
            single_visit_during_workday_preference_options = ['exact_dateslot', 'exact_date', 'matching_slot_during_workday', 'anytime'],
            single_visit_during_weekend_preference_options = ['exact_dateslot', 'exact_date', 'any_slot_during_weekend', 'anytime'],

            subscription_visit_during_workday_preference_options = ['exact_wdayslot', 'exact_wday', 'matching_slot_during_workday', 'anytime'],
            subscription_visit_during_weekend_preference_options = ['exact_wdayslot', 'exact_wday', 'any_slot_during_weekend', 'anytime'],

            multiple_subscription_during_weekdays_preference_options = ['exact_wdayslot', 'exact_wday', 'any_slot_during_workday', 'anytime'],
            multiple_subscription_visits_preference_options = ['exact_wdayslot', 'exact_wday', 'anytime'];
        }


        if(ProductUtils.isSubscription(this.props.product)) {
            if(this.props.selectedDateslots.length > 1) {
                if(allDatesAreWorkDays) {
                    options = multiple_subscription_during_weekdays_preference_options;
                }
                else {
                    options = multiple_subscription_visits_preference_options;
                }
            }
            else {
                if(dateIsWeekend) {
                    options = subscription_visit_during_weekend_preference_options;
                }
                else {
                    options = subscription_visit_during_workday_preference_options;
                }
            }
        }
        else {
            if(dateIsWeekend) {
                options = single_visit_during_weekend_preference_options;
            }
            else {
                options = single_visit_during_workday_preference_options;
            }
        }

        return _.map(options, function(option) {
            return [this.getOptionLabel(option), option];
        }.bind(this));
    };

    getOptionLabel = (optionType) => {
        var dateslot = this.props.selectedDateslots[0],
            options = ['exact_dateslot', 'exact_wday', 'any_slot_during_workday', 'any_slot_during_weekend', 'anytime'];

        if(this.noDifferentHoursOptions() && _.contains(options, optionType)) {
            optionType = optionType + "_without_hours";
        }

        return this.capitalizeSentence(
            i18n.t('waiting-list.user-form.' + optionType, { date: i18n.l('date.formats.month_short', dateslot.date),
                                                             week_day: i18n.t('date.day_names')[moment(dateslot.date).day()],
                                                             starts_at: dateslot.starts_at,
                                                             count: this.props.selectedDateslots.length })
        );
    };

    capitalizeSentence = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    noDifferentHoursOptions = () => {
        return this.props.selectedDateslots[0] &&
            this.props.selectedDateslots[0].noDifferentHoursOptions;
    };

    notificationFrequencyOptions = () => {
        return _.map(['daily', 'once_a_week'], function(frequencyType) {
            return [i18n.t('waiting-list.user-form.frequency_type_' + frequencyType), frequencyType];
        }.bind(this));
    };

    notifyUpToDateIsRelevant = (dateslotPreference) => {
        return this.state.dateslotPreference && !_.contains(['exact_dateslot', 'exact_date'], dateslotPreference || this.state.dateslotPreference);
    };

    notifyFromDateIsRelevant = (dateslotPreference) => {
        return this.state.dateslotPreference && !_.contains(['exact_dateslot', 'exact_date'], dateslotPreference || this.state.dateslotPreference);
    };

    renderForm = () => {
        var dateslotPreferenceText = null;

        if(this.props.selectedCleaner && this.state.mandatoryCleaner) {
            dateslotPreferenceText = i18n.t('waiting-list.user-form.dateslot-preference-with-cleaner', { name: this.props.selectedCleaner.name });
        }
        else {
            dateslotPreferenceText = i18n.t('waiting-list.user-form.dateslot-preference');
        }

        return (
            <div className="waiting-list-request-box">
              <form onSubmit={this.makeRequest}>
                <div className="title">
                  {i18n.t('waiting-list.user-form.heading')}
                </div>

                <div className="content">
                  <p dangerouslySetInnerHTML={{__html: i18n.t('waiting-list.user-form.description_html', { count: this.props.selectedDateslots.length })}}/>
                  {this.props.selectedCleaner &&
                      <div>
                            <label><input type="checkbox" name="mandatoryCleaner" onChange={this.toggleMandatoryCleaner} checked={this.state.mandatoryCleaner} value={this.state.mandatoryCleaner}/> {i18n.t('waiting-list.user-form.mandatory-cleaner', { name: this.props.selectedCleaner.name })}</label>
                      </div>}

                  <hr />

                  <div>
                    {dateslotPreferenceText}
                  </div>

                  {_.map(this.dateslotPreferenceOptions(), function (preference) {
                      var preferenceLabel = preference[0],
                          preferenceValue = preference[1];

                      return (
                          <div key={preferenceValue}>

                            <label>
                              <input type="radio"
                                     name="dateslot_preference"
                                     value={preferenceValue}
                                     checked={this.state.dateslotPreference === preferenceValue}
                                     onChange={this.onDateslotPreferenceOptionChange} />
                              {preferenceLabel}
                            </label>
                          </div>
                      );
                  }.bind(this))}

                  <div>
                      <label className={classNames({ disabled: !this.notifyFromDateIsRelevant() })} style={{ fontWeight: 'normal' }}>
                          <input type="checkbox" onChange={this.toggleNotifyFromDate} checked={this.state.notifyFromDate} value={this.state.notifyFromDate} disabled={!this.notifyFromDateIsRelevant()}/>
                              {i18n.t('waiting-list.user-form.do-not-notify-before', { date: i18n.l('date.formats.month_short', this.props.selectedDateslots[0].date)})}
                      </label>
                  </div>

                  {!ProductUtils.isSubscription(this.props.product) &&
                       <div>
                           <label className={classNames({ disabled: !this.notifyUpToDateIsRelevant() })} style={{ fontWeight: 'normal' }}>
                               <input type="checkbox" onChange={this.toggleNotifyUpToDate} checked={this.state.notifyUpToDate} value={this.state.notifyUpToDate} disabled={!this.notifyUpToDateIsRelevant()}/>
                               {i18n.t('waiting-list.user-form.do-not-notify-after', { date: i18n.l('date.formats.month_short', this.props.selectedDateslots[0].date)})}
                           </label>
                   </div>}

                  <hr />

                  <div>
                    {i18n.t('waiting-list.user-form.notification-frequency')}
                  </div>

                  {_.map(this.notificationFrequencyOptions(), function (preference) {
                      var preferenceLabel = preference[0],
                          preferenceValue = preference[1];

                      return (
                          <div key={preferenceValue}>

                            <label>
                              <input type="radio"
                                     name="notification_frequency"
                                     value={preferenceValue}
                                     checked={this.state.notificationFrequency === preferenceValue}
                                     onChange={this.onNotificationFrequencyOptionChange} />
                              {preferenceLabel}
                            </label>
                          </div>
                      );
                  }.bind(this))}

                  <div className="btn-wrapper">
                    { this.state.waitingListRecordCreated ? this.checkedButton(): this.normalButton() }
                  </div>
                </div>
              </form>
            </div>
        );
    };

    renderThankYou = () => {
        return (
            <div className="waiting-list-request-box">
              <h4>
                {i18n.t('waiting-list.thank-you.heading')}
              </h4>
              {this.state.showViberSubscribeButton &&
                i18n.t('waiting-list.thank-you.description')}

              {!this.state.showViberSubscribeButton &&
                i18n.t('waiting-list.thank-you.description-with-viber')}

              {this.state.showViberSubscribeButton &&
                  <div className="btn-wrapper" style={{ marginTop: "35px", marginBottom: "20px" }}>
                      <div className=" hidden-xs hidden-sm">
                          <button className="btn viber-button" data-toggle="modal" data-target="#qr-code-modal">
                              <span className="viber-icon"></span>
                              <span>{i18n.t('waiting-list.thank-you.viber-button')}</span>
                          </button>
                      </div>

                      <div className="hidden-md hidden-lg hidden-xl">
                          <a href={this.props.user.viber_deep_link} className="btn viber-button">
                              <span className="viber-icon"></span>
                              <span>{i18n.t('waiting-list.thank-you.viber-button')}</span>
                          </a>
                      </div>

                  </div>}
            </div>
        );
    };

    render() {
        if(this.state.waitingListRecordCreated) {
            return this.renderThankYou();
        }
        else {
            return this.renderForm();
        }
    }
}


export default WaitingListRequest;
