import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/vendor/modal';

import ReactBootstrapModal from 'react-bootstrap/lib/Modal'
import $ from "jquery";

class MicroWaitingListModal extends React.Component {
    static propTypes = {
        isLoggedin: PropTypes.bool,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        executeSlowOperation: PropTypes.func,
        selectedAddressId: PropTypes.number,
        selectedDistrictId: PropTypes.number,
        selectedProduct: PropTypes.string
    };

    state = {
        emailAddress: '',
        isSuccess: false,
    };

    onWaitingListNotifyClick = () => {
        if (!this.props.selectedDistrictId && !this.props.selectedAddressId) {
            alert("No Location Selected!")
            return;
        }

        let waitingListParams = {
            email_address: this.state.emailAddress,
            address_id: this.props.selectedAddressId,
            district_id: this.props.selectedDistrictId,
            product_type: this.props.selectedProduct
        }

        this.props.executeSlowOperation(function () {
            return $.ajax({
                type: "POST",
                url: PathHelper('waiting_list_records_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify({waiting_list_record: waitingListParams}),
                statusCode: {
                    500: function () {
                        window.location.href = '/500';
                    }
                }
            }).done(function (result) {
                this.setState({isSuccess: true});
                setTimeout(function(){ this.props.onClose();}.bind(this), 3500);
            }.bind(this));
        }.bind(this));
    }

    validateEmail = (email) => {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    updateEmail = (event) => {
        this.setState({emailAddress: event.target.value })
    }

    notifyClick = () => {
        if (!this.props.isLoggedin && !this.validateEmail(this.state.emailAddress)) {
            alert(i18n.t("funnel.micro-waiting-list.modal.email-error"));
        } else {
            this.onWaitingListNotifyClick();
        }
    }

    isInforming = () => {
        return !this.state.isSuccess;
    }

    render() {
        return (
            <Modal size="lg" isOpen={this.props.isOpen} onHide={this.props.onClose} title={i18n.t("funnel.micro-waiting-list.modal.header")}>
                <ReactBootstrapModal.Body>
                    <div className="micro-waiting-list-body-container">
                        {this.isInforming() &&
                        <div>
                            <p>{i18n.t("funnel.micro-waiting-list.modal.description")}</p>
                            {!this.props.isLoggedin &&
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">{i18n.t("funnel.micro-waiting-list.modal.email-label")}</label>
                                <input type="email"
                                       className="form-control"
                                       value={this.state.emailAddress}
                                       onChange={this.updateEmail}
                                       style={{maxWidth: '500px'}}
                                />
                            </div>
                            }
                        </div>
                        }
                        {this.state.isSuccess &&
                        <div className={'success-container'} >
                            <div className="circle-check" />
                            {i18n.t("funnel.micro-waiting-list.modal.success")}
                        </div>
                        }
                    </div>
                </ReactBootstrapModal.Body>

                <ReactBootstrapModal.Footer>
                    <button className={"btn btn-primary"} onClick={this.notifyClick} disabled={this.state.isLoading || this.state.isSuccess}>
                        {i18n.t("funnel.micro-waiting-list.notify-me-cta")}
                    </button>
                </ReactBootstrapModal.Footer>
                <div>

                </div>
            </Modal>
        );
    }
}


export default MicroWaitingListModal;
