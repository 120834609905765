import React from 'react'
import PropTypes from "prop-types";
import classNames from 'classnames';
import _ from "underscore";
import BraintreeHostedFields from '@components/braintree/braintree_hosted_fields'

class BraintreeForm extends React.Component {
    static propTypes = {
        clientToken: PropTypes.string.isRequired,
        braintreeCards: PropTypes.array.isRequired,

        selectedCardId: PropTypes.number,
        onCardSelect: PropTypes.func.isRequired,

        onInstanceCreated: PropTypes.func.isRequired,
    }

    renderCard = (card) => {
        return (
            <div className="braintree-form-credit-card-row" key={'card-container-' + card.id}>
                <div className="braintree-form-radio-container">
                    <input type="radio" value={card.id} name="selected-card" id={'radio-card-' + card.id}
                           onChange={_.partial(this.props.onCardSelect, card.id)} checked={this.props.selectedCardId === card.id}/>
                </div>

                <div className="braintree-form-credit-icon-container">
                    <img src={card.image} alt=""/>
                </div>

                <div className="braintree-form-card-details-container">
                    <div className="bold card-name">
                        <label htmlFor={'radio-card-' + card.id}>{card.name}</label>
                    </div>
                    <div className="grey-text card-details">
                        {i18n.t("funnel.pay.braintree.ends-with")} <strong>{card.last4}</strong>, &nbsp;
                        {i18n.t("funnel.pay.braintree.expires-on")} <strong>{card.expire_month}/{card.expire_year}</strong>
                        {card.is_expired &&
                        <strong className="text-danger">({i18n.t("funnel.pay.braintree.expired")})</strong>}
                    </div>
                </div>
            </div>
        )
    }

    renderOtherCard = () =>{
        return (
            <div className="braintree-form-credit-card-row" key={'other-card-container'}>
                <div className="braintree-form-radio-container">
                    <input type="radio" value={'other'} name="selected-card" id={'radio-card-other'}
                           checked={this.props.selectedCardId === null} onChange={_.partial(this.props.onCardSelect, null)}/>
                </div>

                <div className="braintree-form-credit-icon-container">
                    <div className="default-card-icon"></div>
                </div>

                <div className="braintree-form-card-details-container">
                    <div className="bold card-name">
                        <label htmlFor={'radio-card-other'}>{i18n.t("funnel.pay.braintree.other-card")}</label>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div id='braintree' className={'braintree-form-container'}>
                {this.props.braintreeCards.length > 0 &&
                <div className="braintree-form-card-list-container">
                    {_.map(this.props.braintreeCards, function(card) {return this.renderCard(card)}.bind(this))}
                    {this.renderOtherCard()}
                </div>}

                <div className={classNames({'hidden': !!this.props.selectedCardId}) + " braintree-form-new-card-container"} >
                    <BraintreeHostedFields
                        className=""
                        clientToken={this.props.clientToken}
                        onInstance={this.props.onInstanceCreated}
                    >
                    </BraintreeHostedFields>
                </div>
            </div>
        )
    }
}

export default BraintreeForm;