import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import storage from 'local-storage-fallback'


import _ from 'underscore';
import AddressSelectorCitySelector from '@components/common/address_selector/city_selector';
import AddressSelectorDistrictSelector from '@components/common/address_selector/district_selector';


class CityDistrictSelector extends React.Component {
    static propTypes = {
        buttonText: PropTypes.string,
        headerText: PropTypes.string,
        highlightedCompanyName: PropTypes.string,
        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            available: PropTypes.bool.isRequired,

            label: PropTypes.string.isRequired,
            alternative_label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,

            districts: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                available: PropTypes.bool.isRequired,

                label: PropTypes.string.isRequired,
                alternative_label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired
            }))
        })),

        location: PropTypes.shape({ city: PropTypes.object,
                                          district: PropTypes.object }),

        onDistrictSelect: PropTypes.func.isRequired,
    };

    state = {
        city: this.props.location.city || {},
        district: this.props.location.district || {},
        errors: {}
    };

    constructor(props) {
        super(props);
        var previouslySelectedCityId = parseInt(storage.getItem('previouslySelectedCityId'));
        var previouslySelectedDistrictId = parseInt(storage.getItem('previouslySelectedDistrictId'));

        if(_.isEmpty(this.state.city) && previouslySelectedCityId && _.isEmpty(this.getCityIdSelectionError(previouslySelectedCityId))){
            this.state.city = this.findCity(previouslySelectedCityId);

            if(previouslySelectedDistrictId && _.isEmpty(this.getDistrictIdSelectionError(previouslySelectedDistrictId))){
                this.state.district = this.findDistrict(previouslySelectedDistrictId);
            }
        }
    }

    componentDidMount() {
        if(this.state.city)
            this.validateCity(this.state.city.id);

        if(this.state.district)
            this.validateDistrict(this.state.district.id);
    };

    selectCity = (city) => {
        this.setState({ city: city || {}, district: {} }, function() {
            if(this.validateCity(this.state.city.id)) {
                storage.setItem('previouslySelectedCityId', city.id)
            }
        }.bind(this));
    };

    findCity = (cityId) => {
        return _.find(this.props.cities, function(city) {
            return city.id === cityId;
        });
    };

    getCityIdSelectionError = (cityId) => {
        var cityError = null,
            city = this.findCity(cityId);

        if(cityId === null || cityId === undefined) {
            cityError = i18n.t('funnel.address-form.missing-city-error');
        }
        else if(this.cityNotAvailableForCompany(city)) {
            cityError = i18n.t('funnel.address-form.unavailable-city-error-for-company', {company_name: this.selectedCompanyName()});
        }else if(!city.available){
            cityError = i18n.t('funnel.address-form.unavailable-city-error');
        }
        return cityError;
    };

    validateCity = (cityId) => {
        var cityError = this.getCityIdSelectionError(cityId);

        if(cityError) {
            this.setState({ errors: _.extend({}, this.state.errors, { city: cityError})});
            return false;
        }
        else {
            this.setState({ errors: _.omit(this.state.errors, 'city') });
            return true;
        }
    };

    selectedCompanyName = () => {
        return this.props.highlightedCompanyName;
    };

    cityNotAvailableForCompany = (city) => {
       return !city.available && this.props.highlightedCompanyName != null ;
    };

    refreshWithoutParams = () => {
        window.location = window.location.pathname;
    };

    selectDistrict = (district) => {
        this.setState({ district: district || {} }, function() {
            if(this.validateDistrict(this.state.district.id)){
                storage.setItem('previouslySelectedDistrictId', district.id)
            }
        }.bind(this));
    };

    findDistrict = (districtId) => {
        return _.find(this.state.city.districts || [], function(district) {
            return district.id === districtId;
        }.bind(this))
    };

    getDistrictIdSelectionError = (districtId) => {
        var districtError = null;

        if(districtId === null || districtId === undefined) {
            districtError = i18n.t('funnel.address-form.missing-district-error');
        } else{
            var district = this.findDistrict(districtId);

            if(!district || !district.available) {
                districtError = i18n.t('funnel.address-form.unavailable-disctrict-error');
            }
        }

        return districtError;
    };

    validateDistrict = (districtId) => {
        var districtError = this.getDistrictIdSelectionError(districtId);

        if(districtError) {
            this.setState({ errors: _.extend({}, this.state.errors, { district: districtError})});
            return false;
        }
        else {
            this.setState({ errors: _.omit(this.state.errors, 'district') });
            return true;
        }
    };

    handleSubmit = () => {
        if(this.validateCity(this.state.city.id) && this.validateDistrict(this.state.district.id)) {
            this.props.onDistrictSelect(this.state.city, this.state.district);
        }
    };

    hasError = () => {
        return !_.isEmpty(this.state.errors);
    };

    render() {
        return (
            <div>
              <div>
                <div className="funnel-heading">{ this.props.headerText || i18n.t('funnel.breadcrumbs.district.content')}</div>
                <div className="funnel-subtitle"></div>
              </div>

              <div className="address-fields">
                {this.hasError() &&
                  <div className="row">
                      <div className="col-xs-12 error-message">
                          {this.state.errors.city}{'\u00A0'}
                          {this.state.errors.city && this.cityNotAvailableForCompany(this.state.city) &&
                          <b><a href='#'
                                onClick={this.refreshWithoutParams}> {i18n.t('funnel.address-form.expand-search')}</a></b>
                          }
                          <br/>
                          {this.state.errors.district}
                      </div>
                  </div>}

                <div className="row">
                  <div className={classNames({ "form-group": true, "col-sm-6": true, "col-sm-offset-3": true })}>
                    <label>{i18n.t('helpers.label.funnel_form.city_id')}</label>
                    <AddressSelectorCitySelector readOnly={this.props.cityReadOnly}
                                                  cities={this.props.cities}
                                                  select={this.selectCity}
                                                  selected={this.state.city.id}
                                                  hasError={!!this.state.errors.city} />
                  </div>
                </div>

                <div className="row">
                  <div className={classNames({ "form-group": true, "col-sm-6": true, "col-sm-offset-3": true })}>
                    <label>{i18n.t('helpers.label.funnel_form.district_id')}</label>
                    <AddressSelectorDistrictSelector readOnly={this.props.districtReadOnly}
                                                      districts={this.state.city.districts}
                                                      select={this.selectDistrict}
                                                      selected={this.state.district.id}
                                                      hasError={!!this.state.errors.district} />
                  </div>
                </div>

                <button type="submit"
                        onClick={this.handleSubmit}
                        className={classNames({ btn: true, 'btn-primary': true, disabled: this.hasError() })}>
                  {this.props.buttonText || i18n.t('form.buttons.proceed')}
                </button>
              </div>
            </div>
        );
    }
}

export default CityDistrictSelector;
