import React from 'react';
import PropTypes from 'prop-types';

import withLoader from '@components/common/with_loader_decorator';
import $ from 'jquery';

class CodeDiscountInput extends React.Component {
    static propTypes = {
        orderCode: PropTypes.string,
        onCodeAccepted: PropTypes.func,
    };

    state = {
        message: '',
        discountCode: '',
    };

    submit = (event) => {
        event.preventDefault();
        var params = {
            order_code: this.props.orderCode,
            referral_code: this.state.discountCode
        };

        this.props.executeSlowOperation(function() {
            var promise = $.ajax({
                type: "POST",
                url: PathHelper('funnel_create_reference_path'),
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(params),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });

            var defer = $.Deferred();
            defer.promise();

            promise.done(function(result) {
                if(result.error) {
                    this.invalidCode(result.message);
                    defer.resolve();
                    return;
                }

                if(parseFloat(result.newPrice.amount) == 0) {
                    location.reload();
                }
                else {
                    this.acceptCode(result.newPrice);
                    defer.resolve();
                }
            }.bind(this));

            return defer;
        }.bind(this));
    };

    acceptCode = (newPrice) => {
        this.props.onCodeAccepted(newPrice);
        this.setState({ message: 'success', discountCode: ''});
    };

    invalidCode = (message) => {
        this.setState({ message: message });
    };


    updateCode = (event) => {
        this.setState({discountCode: event.target.value})
    }

    renderForm = () => {
        return (
            <div>
              <input name="discountCode" className="form-control" type="text" onChange={this.updateCode}/>
              <button className="btn light-button" onClick={this.submit}> {i18n.t('funnel.pay.apply-discount-code')} </button>
            </div>
        );
    };

    renderMessage = () => {
        if(this.state.message == 'success') {
            return (
                <div className="success-message">{i18n.t('funnel.referral.code-accepted')}</div>
            );
        }
        else if(this.state.message != '') {
            return (
                <div className="fail-message">{this.state.message}</div>
            );
        }
        else {
            return null;
        }
    };

    render() {
        return (
            <div className="code-discount-input">
              <p>
                <span className="form-label">
                  {i18n.t('funnel.pay.code-discount')}
                </span>
              </p>

              {this.renderForm()}

              {this.renderMessage()}
            </div>
        );
    }
}

export default withLoader(CodeDiscountInput);
