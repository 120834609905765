import React from 'react';
import PropTypes from "prop-types";

import FormWithDistrict from '@components/common/address_selector/form_with_district';
import FormWithPostcode from '@components/common/address_selector/form_with_postcode';

class Form extends React.Component {
    static contextTypes = {
        locationStrategy: PropTypes.string
    };

    render() {
        if(this.context.locationStrategy == 'district') {
            return <FormWithDistrict {...this.props}/>;
        }
        else {
            return <FormWithPostcode {...this.props}/>;
        }
    }
};

export default Form;
