import React from 'react';

import _ from 'underscore';
import PropTypes from "prop-types";
import classNames from 'classnames';

import MoneyUtils from '@services/money_utils'


class Housemaid extends React.Component {
    static propTypes = {
        initiallySelectedProductOptions: PropTypes.object,
        onItemSelectionChange: PropTypes.func.isRequired,
        onSubmissionRequest: PropTypes.func,
        isMember: PropTypes.bool,
    };

    static zeroSelectedProducts = {
        product_type: 'single_visit',
        visits_weekly: 0,
    };

    zeroSelectedProducts = () => {
        return _.clone(Housemaid.zeroSelectedProducts);
    };

    static selectorType = 'housemaid';

    selectorType = () => {
        return Housemaid.selectorType;
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.state.selectedProductOptions = {...this.zeroSelectedProducts(), ...props.initiallySelectedProductOptions};
        this.sendSelection(this.state.selectedProductOptions);
    };

    updateProduct = (visitsWeekly) => {
        let productType = visitsWeekly === 0 ? 'single_visit' : "subscription"
        this.setState({
            selectedProductOptions: {
                ...this.state.selectedProductOptions,
                ...{product_type: productType, visits_weekly: visitsWeekly}
            }
        }, function () {
            this.sendSelection(this.state.selectedProductOptions)
        }.bind(this));
    };

    sendSelection = (productHash) => {
        this.props.onItemSelectionChange(this.selectorType(),
            productHash,
            true,
            null
        );
    };



    productOptions = () => {
        return [
            {label: i18n.t('services.housemaid.customizer.single_visit'), value: 0},
            {label: i18n.t('services.housemaid.customizer.once_a_week'), value: 1}
        ]
    }

    isSingleSelected = () => {
        return this.state.selectedProductOptions.product_type === 'single_visit';
    }

    isSubscriptionSelected = () => {
        return this.state.selectedProductOptions.product_type === 'subscription';
    }

    isFlexAvailable = () => {
        return this.props.isMember;
    }

    renderClubLink = () => {
        return "<a href='/club'>Domestina Club</a>";
    }


    render() {
        return (
            <div className="service-customizer-input-fields-container housemaid-customizer-container">
                <div className="warning-container">
                    {!this.isFlexAvailable() && <div className="exclamation-container">
                        !
                    </div> }
                    {!this.isFlexAvailable() && <div dangerouslySetInnerHTML={{__html: i18n.t('public.club.for-housemaids.paragraph1_html')}}>
                    </div>}
                    {!this.isFlexAvailable() && <div dangerouslySetInnerHTML={{__html: i18n.t('public.club.for-housemaids.paragraph2_html', {activation_fee: MoneyUtils.price(MEMBERSHIP_ACTIVATION_FEE), price: MoneyUtils.price(FUTURE_MEMBERSHIP_PRICE, 2, true), club_link: this.renderClubLink()})}}>
                    </div>}

                    {this.isFlexAvailable() && i18n.t('services.housemaid.customizer.flex-member-description')}
                </div>

                <div className="product-options-container">
                    <div className={classNames({"product-option": true, "selected-product-option": this.isSingleSelected()})}  onClick={_.partial(this.updateProduct, 0)}>
                        <div className="header">
                            FLEX
                        </div>
                        <div className="subheader">
                            {i18n.t('services.housemaid.customizer.flex-subheader')}
                        </div>
                    </div>

                    <div className={classNames({"product-option": true, "selected-product-option": this.isSubscriptionSelected()})} onClick={_.partial(this.updateProduct, 1)}>
                        <div className="header">
                            FIX
                        </div>
                        <div className="subheader">
                            {i18n.t('services.housemaid.customizer.fix-subheader')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Housemaid;