import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/vendor/modal'
import CompanyServiceProfile from '@components/common/company_service_profile';

class CompanyServiceProfileLink extends React.Component {
    static propTypes = {
        linkText: PropTypes.string.isRequired,
        ctaButtonText: PropTypes.string,
        onCtaButtonClick: PropTypes.func,
        companyData: PropTypes.object
    };

    state = {
        isOpen: false
    };

    openModal = () => {
        this.setState({isOpen: true});
    };

    closeModal = () => {
        this.setState({isOpen: false});
    };

    handleCtaClick = () => {
        this.closeModal();
        if(this.props.onCtaButtonClick) {
            this.props.onCtaButtonClick();
        }
    };

    render() {
        return (
            <span>
                <a href="#" onClick={this.openModal}>{this.props.linkText}</a>

                <Modal size="large" isOpen={this.state.isOpen} onHide={this.closeModal} dialogClassName="company-profile-modal">
                    <div className="custom-modal-content">
                      <CompanyServiceProfile companyData={this.props.companyData} ctaButtonText={this.props.ctaButtonText}
                                             onCtaButtonClick={this.handleCtaClick}/>
                    </div>

                    <div className="close-button" onClick={this.closeModal}></div>
                </Modal>
            </span>
        );
    }
}

export default CompanyServiceProfileLink;