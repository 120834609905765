import React from 'react';
import PropTypes from 'prop-types';


class InvoiceModalTrigger extends React.Component {
    static propTypes = {
        orderCode: PropTypes.string,
        with_icon: PropTypes.bool,
        invoiceRequested: PropTypes.bool
    };

    static defaultProps = {
        with_icon: false
    };

    shouldComponentUpdate() {
        return false;
    }

    checkboxId = () => {
        return "show-invoice-form-" + this.props.orderCode;
    };

    render() {
        return (
            <div className={"invoice-checkbox"} data-modal-id={this.props.orderCode}>
              {this.props.with_icon && <span className={"glyphicon glyphicon glyphicon-file"} aria-hidden="true"></span>}
              <input type="checkbox"
                     disabled={true}
                     name="show-invoice-form"
                     className="show-invoice-form"
                     id={this.checkboxId()}
                     defaultChecked={this.props.invoiceRequested}/>
              {' '}
              <label htmlFor={this.checkboxId()} className="show-invoice-form-label checkbox-label">
                {i18n.t('funnel.pay.invoice.issue')}
              </label>
              {' '}
              <span className={"selected-company"}></span>
              {' '}
              <span className={"edit-invoice-btn"}><span className={"glyphicon glyphicon-pencil"} aria-hidden="true"></span></span>
            </div>
        );
    }
}

export default InvoiceModalTrigger;
