import React from 'react';


class TermsOfUse extends React.Component {
    render() {
        var link = ('<a href="/terms_of_use" target="_blank">' + i18n.t("signup.terms") + '</a>'),
            content = i18n.t("signup.conditions_html", { href: link });

        return (
            <div className="signup-terms-and-conditions" dangerouslySetInnerHTML={{__html: content}}/>
        );
    }
}

export default TermsOfUse;
