import React from 'react';
import PropTypes from "prop-types";

import _ from 'underscore';
import MediaQuery from 'react-responsive';
import AddressSelectorBoxRow from '@components/common/address_selector/box_row';
import BreakPoints from '@services/breakpoints';

class ResponsiveOptions extends React.Component {
    static propTypes = {
        addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
        selectedAddress: PropTypes.object,
        onSelect: PropTypes.func,
        onDestroy: PropTypes.func.isRequired,
        onAdd: PropTypes.func.isRequired,
        displaySpecialBadge: PropTypes.bool,
    };

    chunk = (array, chunkSize) => {
        var result = [], i;

        if(chunkSize <= 0) {
            return result;
        }

        for (i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }

        return result;
    };

    renderOptions = (rowSize) => {
        var addresses = this.props.addresses.concat([{ blank: true }]),
            addressesChunks = this.chunk(addresses, rowSize);

        return (
            <div>
              {_.map(addressesChunks, function(addresses, index) {
                  return (
                      <AddressSelectorBoxRow
                         rowSize={rowSize}
                         addresses={addresses}
                         selectedAddress={this.props.selectedAddress}
                         onAdd={this.props.onAdd}
                         onSelect={this.props.onSelect}
                         onDestroy={this.props.onDestroy}
                         displaySpecialBadge={this.props.displaySpecialBadge}
                         key={index} />
                  );
              }.bind(this))}
            </div>
        );
    };

    render() {
        return (
            <div className="address-options">
              <MediaQuery maxWidth={BreakPoints.addressXsMax}>
                {this.renderOptions(1)}
              </MediaQuery>

              <MediaQuery minWidth={BreakPoints.addressXsMax} maxWidth={BreakPoints.smMax}>
                {this.renderOptions(2)}
              </MediaQuery>

              <MediaQuery minWidth={BreakPoints.smMax}>
                {this.renderOptions(3)}
              </MediaQuery>
            </div>
        );
    }
};

export default ResponsiveOptions;
