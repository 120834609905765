import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Modal from '@components/vendor/modal';

class ModalSelect extends React.Component {
    static propTypes = {
        modalTitle: PropTypes.string,
        label: PropTypes.string,
        modalContentClasses: PropTypes.string
    };

    state = {
        modalIsOpen: false
    };

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    closeModal = (e) => {
        if(typeof(e) !== 'undefined' ) {
            e.stopPropagation();
        }

        this.setState({ modalIsOpen: false });
    };

    closeModalIfOptionIsSelected = (event) => {
        event.stopPropagation();
        if(_.some(event.target.className.split(' '), function(name) { return name === "modal-select-option"; })) {
            this.closeModal();
        }
    };

    render() {
        return (
            <div onClick={this.openModal} className="modal-dropdown-filter">
              {this.props.label}

              <Modal isOpen={this.state.modalIsOpen} onHide={this.closeModal}>
                <div className="custom-modal-header">
                  <div className="close-button" onClick={this.closeModal}></div>
                  <h1>{this.props.modalTitle}</h1>
                </div>

                <div className={"custom-modal-content" + " " + this.props.modalContentClasses} onClick={this.closeModalIfOptionIsSelected}>
                  {this.props.children}
                </div>
              </Modal>
            </div>
        );
    }
}

export default ModalSelect;
