/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import Rails from "@rails/ujs"
// import "@babel/polyfill";
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)


// Rails.start()

// import React from "react"
// import PropTypes from "prop-types"
// import Select from '../components/vendor/select.js'
// import ReactDOM from 'react-dom';
// import classNames from 'classnames'
// import MediaQuery from 'react-responsive'
// import { OverlayTrigger, Tooltip, Collapse, Modal } from 'react-bootstrap'

// window.React = React
// window.PropTypes = PropTypes
// window.Select = Select
// window.ReactDOM = ReactDOM
// window.classNames = classNames
// window.MediaQuery = MediaQuery
// window.Tooltip = Tooltip
// window.OverlayTrigger = OverlayTrigger
// window.Collapse = Collapse
// window.ReactBootstrapModal = Modal
