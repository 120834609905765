import React from 'react';

class PrettyLittleContainer extends React.Component {
    render() {
        return (
            <div className={"pretty-little-select " + this.props.className}>
              <div className="pretty-little-select-option">
                {this.props.children}
              </div>
            </div>
        );
    }
}

export default PrettyLittleContainer;