import React from 'react';
import PropTypes from 'prop-types';


class FunnelProgressListPaymentInfo extends React.Component {
    static propTypes = {
        product: PropTypes.object,
        prepaidEventsCount: PropTypes.number,
        totalPrice: PropTypes.string
    };

    payFromCredit = () => {
        return this.props.totalPrice === 0;
    };

    content = () => {
        if(this.props.defaultText) {
            return this.props.defaultText;
        }
        else if(this.payFromCredit()) {
            return i18n.t('funnel.breadcrumbs.payment.confirm-order');
        }
        else {
            return i18n.t('funnel.breadcrumbs.payment.content');
        }
    };

    render() {
        return (
            <div>
              <div>
                {this.content()}
              </div>
            </div>
        );
    }
};

export default FunnelProgressListPaymentInfo;
