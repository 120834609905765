import React from "react";
import PropTypes from "prop-types";

import CarpetAndUpholsteryProductCustomizer from '@components/product_customizers/carpet_and_upholstery_cleaning'
import DeepCleaningProductCustomizer from '@components/product_customizers/deep_cleaning';
import AfterRenovationProductCustomizer from '@components/product_customizers/after_renovation_cleaning';
import BathroomCleaningProductCustomizer from '@components/product_customizers/bathroom_cleaning';
import OvenCleaningProductCustomizer from '@components/product_customizers/oven_cleaning';
import CarUpholsteryProductCustomizer from '@components/product_customizers/car_upholstery_cleaning';
import WindowCleaningProductCustomizer from '@components/product_customizers/window_cleaning';
import DisinfectionProductCustomizer from '@components/product_customizers/disinfection';
import PestControlProductCustomizer from '@components/product_customizers/pest_control';
import LightCleaningProductCustomizer from '@components/product_customizers/light_cleaning';
import EndOfTenancyCleaningProductCustomizer from '@components/product_customizers/end_of_tenancy_cleaning';
import HousemaidProductCustomizer from '@components/product_customizers/housemaid';
import FacadeCleaning from "./facade_cleaning";

class ProductCustomizer extends React.Component {
    static propTypes = {
        serviceType: PropTypes.string,
        selectedProductOptions: PropTypes.object,
        pricelist: PropTypes.object,
        onItemSelectionChange: PropTypes.func.isRequired,
        onSubmissionRequest: PropTypes.func,
        showExplanation: PropTypes.bool,
        isOptional: PropTypes.bool,
        isMember: PropTypes.bool,
        icons: PropTypes.shape({
            glass_doors: PropTypes.string,
            single_windows: PropTypes.string,
            large_windows: PropTypes.string,
            single_french_windows: PropTypes.string,
            large_french_windows: PropTypes.string,
        }),

        selectedAddressId: PropTypes.number,
        selectedDistrictId: PropTypes.number,
        clientId: PropTypes.number,

        // only pest control
        supportedProductOptions: PropTypes.arrayOf(PropTypes.object),
    };

    renderCustomizer = () => {
        if (this.props.serviceType === 'carpet_and_upholstery_cleaning') {
            return (
                <CarpetAndUpholsteryProductCustomizer isOptional={this.props.isOptional}
                                                      isMember={this.props.isMember}
                                                      pricelist={this.props.pricelist}
                                                      initiallySelectedProductOptions={this.props.selectedProductOptions}
                                                      onItemSelectionChange={this.props.onItemSelectionChange}
                                                      showExplanation={this.props.showExplanation}
                                                      key={this.props.serviceType}
                />
            )
        } else if (this.props.serviceType === 'deep_cleaning') {
            return (
                <DeepCleaningProductCustomizer isOptional={this.props.isOptional}
                                               isMember={this.props.isMember}
                                               pricelist={this.props.pricelist}
                                               initiallySelectedProductOptions={this.props.selectedProductOptions}
                                               onItemSelectionChange={this.props.onItemSelectionChange}
                                               showExplanation={this.props.showExplanation}
                                               onSubmissionRequest={this.props.onSubmissionRequest}
                                               key={this.props.serviceType}
                />
            )
        } else if (this.props.serviceType === 'after_renovation_cleaning') {
            return (
                <AfterRenovationProductCustomizer isOptional={this.props.isOptional}
                                                  isMember={this.props.isMember}
                                                  pricelist={this.props.pricelist}
                                                  initiallySelectedProductOptions={this.props.selectedProductOptions}
                                                  onItemSelectionChange={this.props.onItemSelectionChange}
                                                  showExplanation={this.props.showExplanation}
                                                  onSubmissionRequest={this.props.onSubmissionRequest}
                                                  key={this.props.serviceType}
                />
            )
        } else if (this.props.serviceType === 'bathroom_cleaning') {
            return (
                <BathroomCleaningProductCustomizer isOptional={this.props.isOptional}
                                                   isMember={this.props.isMember}
                                                   pricelist={this.props.pricelist}
                                                   initiallySelectedProductOptions={this.props.selectedProductOptions}
                                                   onItemSelectionChange={this.props.onItemSelectionChange}
                                                   showExplanation={this.props.showExplanation}
                                                   key={this.props.serviceType}
                />
            )
        } else if (this.props.serviceType === 'oven_cleaning') {
            return (
                <OvenCleaningProductCustomizer isOptional={this.props.isOptional}
                                               isMember={this.props.isMember}
                                               pricelist={this.props.pricelist}
                                               initiallySelectedProductOptions={this.props.selectedProductOptions}
                                               onItemSelectionChange={this.props.onItemSelectionChange}
                                               showExplanation={this.props.showExplanation}
                                               key={this.props.serviceType}
                />
            )
        } else if (this.props.serviceType === 'car_upholstery_cleaning') {
            return (
                <CarUpholsteryProductCustomizer isOptional={this.props.isOptional}
                                                isMember={this.props.isMember}
                                                pricelist={this.props.pricelist}
                                                initiallySelectedProductOptions={this.props.selectedProductOptions}
                                                onItemSelectionChange={this.props.onItemSelectionChange}
                                                showExplanation={true}
                                                key={this.props.serviceType}
                />
            )
        } else if (this.props.serviceType === 'window_cleaning') {
            return (
                <WindowCleaningProductCustomizer isOptional={this.props.isOptional}
                                                 isMember={this.props.isMember}
                                                 pricelist={this.props.pricelist}
                                                 initiallySelectedProductOptions={this.props.selectedProductOptions}
                                                 onItemSelectionChange={this.props.onItemSelectionChange}
                                                 showExplanation={this.props.showExplanation}
                                                 key={this.props.serviceType}
                                                 icons={this.props.icons}
                />
            )
        } else if (this.props.serviceType === 'disinfection') {
            return (
                <DisinfectionProductCustomizer isOptional={this.props.isOptional}
                                               isMember={this.props.isMember}
                                               pricelist={this.props.pricelist}
                                               initiallySelectedProductOptions={this.props.selectedProductOptions}
                                               onItemSelectionChange={this.props.onItemSelectionChange}
                                               showExplanation={this.props.showExplanation}
                                               onSubmissionRequest={this.props.onSubmissionRequest}
                                               key={this.props.serviceType}
                />
            )
        }else if (this.props.serviceType === 'pest_control') {
            return (
                <PestControlProductCustomizer  isOptional={this.props.isOptional}
                                               isMember={this.props.isMember}
                                               pricelist={this.props.pricelist}
                                               initiallySelectedProductOptions={this.props.selectedProductOptions}
                                               onItemSelectionChange={this.props.onItemSelectionChange}
                                               showExplanation={this.props.showExplanation}
                                               onSubmissionRequest={this.props.onSubmissionRequest}
                                               key={this.props.serviceType}
                                               supportedProductOptions={this.props.supportedProductOptions}
                />
            )
        }else if (this.props.serviceType === 'light_cleaning') {
            return (
                <LightCleaningProductCustomizer  isOptional={this.props.isOptional}
                                                 isMember={this.props.isMember}
                                                 pricelist={this.props.pricelist}
                                                 initiallySelectedProductOptions={this.props.selectedProductOptions}
                                                 onItemSelectionChange={this.props.onItemSelectionChange}
                                                 showExplanation={this.props.showExplanation}
                                                 onSubmissionRequest={this.props.onSubmissionRequest}
                                                 key={this.props.serviceType}
                />
            )
        }else if (this.props.serviceType === 'end_of_tenancy_cleaning') {
            return (
                <EndOfTenancyCleaningProductCustomizer  isOptional={this.props.isOptional}
                                                        isMember={this.props.isMember}
                                                        pricelist={this.props.pricelist}
                                                        initiallySelectedProductOptions={this.props.selectedProductOptions}
                                                        onItemSelectionChange={this.props.onItemSelectionChange}
                                                        showExplanation={this.props.showExplanation}
                                                        onSubmissionRequest={this.props.onSubmissionRequest}
                                                        key={this.props.serviceType}
                />
            )
        }else if (this.props.serviceType === 'housemaid') {
            return (
                <HousemaidProductCustomizer initiallySelectedProductOptions={this.props.selectedProductOptions}
                                            isMember={this.props.isMember}
                                            onItemSelectionChange={this.props.onItemSelectionChange}
                                            onSubmissionRequest={this.props.onSubmissionRequest}
                                            key={this.props.serviceType}
                />
            )
        } else if (this.props.serviceType === 'facade_cleaning') {
            return (
                <FacadeCleaning selectedAddressId={this.props.selectedAddressId}
                                selectedDistrictId={this.props.selectedDistrictId}
                                clientId={this.props.clientId}
                />
            )
        } else {
            return (
                <div></div>
            )
        }

    };

    render() {
        return (
            <div>
                {this.renderCustomizer()}
            </div>
        );
    }
}
export default ProductCustomizer;
