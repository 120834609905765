import PropTypes from "prop-types";
import React from 'react';

import Modal from '@components/vendor/modal'
import ClientPreferencesCollector from './client_preferences_collector.js'

function withClientPreferencesCollector(Component) {
    return class extends React.Component {
        static propTypes = {
            clientPreferences: PropTypes.object
        };

        state = {
            modalIsOpen: false,
            preferencesCollected: !this.props.clientPreferences.shouldRequest
        };

        showModal = () => {
            this.setState({ modalIsOpen: true });
        };

        closeModal = () => {
            this.setState({ modalIsOpen: false });
        };

        render() {
            return (
                <div>
                    <Modal size="lg" isOpen={this.state.modalIsOpen}>
                        <div className="custom-modal-header">
                            <div className="close-button"></div>
                            <h1>{i18n.t('funnel.preferences.almost-done')}</h1>
                            <p className="subhead">
                                {i18n.t('funnel.preferences.before-proceeding')}
                            </p>
                        </div>

                        <div className="custom-modal-content">
                            <ClientPreferencesCollector afterCollect={this.closeModal}
                                                        submitButtonName={i18n.t("funnel.preferences.continue-to-pay")}
                                                        initialWantsSubstitute={this.props.clientPreferences.wantsSubstitute}
                                                        initialDeclinesTrainings={this.props.clientPreferences.declinesTrainings} />
                        </div>
                    </Modal>

                    <Component {...this.props} showPreferencesModal={this.showModal}/>
                </div>
            );
        }
    };
}

export default withClientPreferencesCollector;
