import React from 'react';
import PropTypes from 'prop-types';

import withLoader from '@components/common/with_loader_decorator';
import DomestinaClubModal from '@components/common/domestina_club_modal'
import $ from 'jquery';

class FunnelPaymentPageDomestinaClubModal extends React.Component {
    static propTypes = {
        membershipVisitPrice: PropTypes.string,
        regularVisitPrice: PropTypes.string,
        orderCode: PropTypes.string
    };

    state = { modalIsOpen: false };

    hideModal = () => {
        this.setState({ modalIsOpen: false });
    };

    showModal = (e) => {
        e.preventDefault();
        this.setState({ modalIsOpen: true });
    };

    addMembershipToOrder = () => {
        this.props.executeSlowOperation(function(){
            var promise = $.ajax({
                type: "POST",
                url: PathHelper('funnel_add_membership_fee_path'),
                dataType: 'json',
                contentType: 'application/json',
                data : JSON.stringify({
                    order_code: this.props.orderCode
                }),
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            });

            promise.done(function(result) {
                window.location.reload();
            });

            // Return new promise to prevent hiding of the loader
            return jQuery.Deferred();
        }.bind(this));
    };

    render() {
        return (
            <div className="membership-modal-trigger">
              <a href="#" onClick={this.showModal}>{i18n.t('funnel.pay.membership-teaser')}</a>
              <DomestinaClubModal
                 isOpen={this.state.modalIsOpen}
                 onHide={this.hideModal}
                 addToOrder={this.addMembershipToOrder}
                 {...this.props} />
            </div>
        );
    }
};


export default withLoader(FunnelPaymentPageDomestinaClubModal);
