import React from 'react';
import PropTypes from 'prop-types';

import AbstractFunnel from "@components/abstract_funnel";
import withLoader from '@components/common/with_loader_decorator';
import withHistory from '@components/common/with_history_decorator'
import withPhoneCollector from '@components/common/with_phone_collector_decorator'
import Authenticator from '@components/common/authenticator'

import FunnelStatePersistance from '@services/funnel_state_persistance'

class DisinfectionFunnel extends AbstractFunnel {
    static childContextTypes = {
        locationStrategy: PropTypes.string
    };

    componentDidMount() {
        if(this.props.initializeStateAndHistory){
            this.props.initializeStateAndHistory({ componentName: 'DisinfectionFunnel', currentState: this.state });
        }

        FunnelStatePersistance.loadPersistedStateIfNeeded(this);
    }

    serviceType = () => {
        return 'disinfection';
    };

    availabilityPathString = () => {
        return 'disinfection_availability_path';
    };


    companiesForDatePath = () => {
        return PathHelper('companies_for_date_disinfection_requests_path');
    };

    attributesPath = () => {
        return PathHelper('attributes_disinfection_requests_path');
    };

    extraServiceTypes = () => {
        return ['carpet_and_upholstery_cleaning', 'oven_cleaning', 'bathroom_cleaning']
    };
}


DisinfectionFunnel = withLoader(DisinfectionFunnel);
DisinfectionFunnel = withPhoneCollector(DisinfectionFunnel);
DisinfectionFunnel = withHistory(DisinfectionFunnel);

class AuthenticatingDisinfectionFunnel extends React.Component {
    render() {
        return (
            <Authenticator status={this.props.componentProps.authenticationStatus}
                           omniauthError={this.props.componentProps.omniauthError}
                           component={DisinfectionFunnel}
                           {...this.props} />
        );
    }
}

export default AuthenticatingDisinfectionFunnel;
