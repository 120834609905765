import React from 'react';
import MediaQuery from 'react-responsive';
import CleanerCarouselMobile from '@components/common/cleaner_and_slot_selector/cleaner_carousel_mobile'
import {CleanerCarousel} from '@components/common/cleaner_and_slot_selector/cleaner_carousel'
import BreakPoints from '@services/breakpoints';

class ResponsiveCleanerFilter extends React.Component {
  render() {
      return (
          <div>
            <MediaQuery maxWidth={BreakPoints.xsMax}>
              <CleanerCarouselMobile {...this.props} />
            </MediaQuery>

            <MediaQuery minWidth={BreakPoints.xsMax}>
              <CleanerCarousel {...this.props}/>
            </MediaQuery>
          </div>
      );
  }
}

export default ResponsiveCleanerFilter;
