import React from 'react';
import PropTypes from 'prop-types';
import CityDistrictSelector from '@components/common/city_district_selector';
import PostCodeResolver from '@components/common/post_code_resolver';

class LocationSelector extends React.Component {
    static contextTypes = {
        locationStrategy: PropTypes.string,
        sendAnalyticsPageview: PropTypes.bool
    };

    static defaultProps = {
        sendAnalyticsPageview: true
    };

    componentDidMount() {
        if(this.props.sendAnalyticsPageview) {
            Analytics.sendPartialAddressSelectionPageview();
        }
    }

    render() {
        if(this.context.locationStrategy == 'district') {
            return (
                <CityDistrictSelector {...this.props} />
            );
        }
        else if(this.context.locationStrategy == 'postcode') {
            return (
                <PostCodeResolver {...this.props} />
            );
        }
        else {
            return null;
        }
    }
}

export default LocationSelector;