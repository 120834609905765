import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import MediaQuery from 'react-responsive';
import BreakPoints from '@services/breakpoints';
import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';
import DateService from '@services/date_service';

class ScheduleSlotButton extends React.Component {
    static propTypes = {
        date: PropTypes.string,
        starts_at: PropTypes.string,
        ends_at: PropTypes.string,
        active: PropTypes.bool,
        available: PropTypes.bool,
        newbie: PropTypes.bool,
        expert: PropTypes.bool,
        regular: PropTypes.bool,
        cleaners: PropTypes.arrayOf(PropTypes.object),
        onToggle: PropTypes.func,
        categoryPrice: PropTypes.object,
        allowUnavailable: PropTypes.bool
    };

    buttonClasses = () => {
        return classNames({
            btn: true,
            available: this.props.available,
            unavailable: this.props.allowUnavailable && !this.props.available,
            regular: this.props.regular,
            expert: this.props.expert,
            newbie: this.props.newbie,
            active: this.props.active
        });
    };

    handleClick = () => {
        if (this.props.available || this.props.allowUnavailable) {
            var dateslot = {
                date: this.props.date,
                starts_at: this.props.starts_at,
                ends_at: this.props.ends_at,
                cleaners: this.props.cleaners,
                unavailable: !this.props.available,
                noDifferentHoursOptions: this.props.noDifferentHoursOptions
            };

            this.props.onToggle(dateslot);
        }
    };

    renderDurationWithABreak = () => {
        if (this.props.break_starts_at) {
            return (
                <div>
                    <div>
                        {this.props.starts_at} - {this.props.break_starts_at}
                    </div>

                    <div>
                        {this.props.break_ends_at} - {this.props.ends_at}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        {this.props.starts_at} - {this.props.ends_at}
                    </div>
                </div>
            );
        }
    };

    renderDurationDescription = () => {
        return (
            <div>
                <MediaQuery maxWidth={BreakPoints.xsMax}>
                    <div>
                        <div>{this.props.starts_at}</div>
                        <div className="dash">-</div>
                        <div>{this.props.ends_at}</div>
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={BreakPoints.xsMax}>
                    {this.renderDurationWithABreak()}
                </MediaQuery>
            </div>
        );
    };

    render() {
        return (
            <button type="button" className={this.buttonClasses()} onClick={this.handleClick}>
                {this.props.categoryPrice && this.props.categoryPrice.newbie &&
                <div className="newbie-icon">
                    <div className="content">
                        {i18n.t('funnel.cleaners-options.all-cleaners.newbie-corner-text', {amount: this.props.categoryPrice.newbie})}
                    </div>
                </div>
                }
                <div className="regular-icon"></div>
                <div className="expert-icon"></div>
                {this.renderDurationDescription()}
            </button>
        );
    }
}

class ScheduleDate extends React.Component {
    static propTypes = {
        date: PropTypes.string,
        highlight: PropTypes.bool
    };

    dateToWeekdayLabel = (date) => {
        if (DateService.isTomorrow(this.props.date)) {
            return i18n.t('date.tomorrow');
        } else {
            return DateService.weekdayString(date) + ' ' + DateService.formatDate(date);
        }
    };

    render() {
        return (
            <div className={classNames({"weekday-slots": true, 'highlighted-weekday-slot': this.props.highlight} )}>
                <div className="weekday-label">
                    <MediaQuery maxWidth={BreakPoints.xsMax}>
                        <div>{DateService.weekdayString(this.props.date)}</div>
                        <div>{DateService.formatDate(this.props.date)}</div>
                    </MediaQuery>

                    <MediaQuery minWidth={BreakPoints.xsMax}>
                        {this.dateToWeekdayLabel(this.props.date)}
                    </MediaQuery>
                </div>
                {this.props.children}
            </div>
        );
    }
}

class MembershipOverlay extends React.Component {
    static propTypes = {
        onMoreInfo: PropTypes.func
    };

    triggerMoreInfo = (e) => {
        e.preventDefault();
        this.props.onMoreInfo();
    };

    render() {
        return (
            <div className="schedule-overlay">
                <div>
                    <div
                        dangerouslySetInnerHTML={{__html: i18n.t('funnel.order-type-form.membership.overlay.content_html')}}/>
                    <a href="#"
                       onClick={this.triggerMoreInfo}>{i18n.t('funnel.order-type-form.membership.overlay.learn-more')}</a>
                </div>
            </div>
        );
    }
}

class DateSlotSelectorLegend extends React.Component {
    static propTypes = {
        legendType: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.state.availableText = i18n.t('funnel.time-form.legend.available-timeslot');
        this.state.unavailableText = i18n.t('funnel.time-form.legend.available-timeslot-other');
    }

    render() {
        return (
            <div>
                {this.props.legendType && <div className="dateslot-selector-legend-container">
                    <div className="legend-item-container">
                        <div className="legend-color-ball available"/>
                        <div className="legend-text">{this.state.availableText}</div>
                        <div className="clearer"/>
                    </div>

                    <div className="legend-item-container">
                        <div className={classNames({"legend-color-ball": true, unavailable: true})}/>
                        <div className="legend-text">{this.state.unavailableText}</div>
                        <div className="clearer"/>
                    </div>

                    <div className="clearer"/>
                </div>}
            </div>
        );
    };
}

class SlotSelector extends React.Component {
    static propTypes = {
        schedule: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            slots: PropTypes.arrayOf(PropTypes.shape({
                slot: PropTypes.number,
                available: PropTypes.bool,
                newbie: PropTypes.bool,
                expert: PropTypes.bool,
                // active: PropTypes.bool,
                duration_description: PropTypes.arrayOf(PropTypes.object),
                cleaners: PropTypes.arrayOf(PropTypes.object)
            }))
        })),

        hideArrows: PropTypes.bool,

        onButtonToggle: PropTypes.func,

        // TODO merge this data to schedule field
        selectedDateslots: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            slot: PropTypes.number
        })),

        onPrevWeek: PropTypes.any,
        onNextWeek: PropTypes.any,

        categoryPrice: PropTypes.object,
        allowUnavailable: PropTypes.bool,
        promoteMembershipModal: PropTypes.func,
        legendType: PropTypes.string,
        highlightedDateString: PropTypes.string

    };

    static defaultProps = {
        hideArrows: false,
    };

    noDifferentHoursOptions = () => {
        return this.props.schedule && this.props.schedule[0].slots.length == 1;
    };

    slotIsActive = (date, starts_at, ends_at) => {
        var result = _.find(this.props.selectedDateslots, function (dateslot) {
            return ScheduleUtils.dateslotsEqual(dateslot, {date: date, starts_at: starts_at, ends_at: ends_at});
        });

        return result != undefined;
    };

    arrowLeftClasses = () => {
        return classNames({'arrow-left': true, disabled: !this.props.onPrevWeek});
    };

    arrowRightClasses = () => {
        return classNames({'arrow-right': true, disabled: !this.props.onNextWeek});
    };


    render() {
        return (
            <div>
                <div className="slots-schedule">
                    {!this.props.hideArrows &&
                    <div className={this.arrowLeftClasses()}
                         onClick={this.props.onPrevWeek ? this.props.onPrevWeek : undefined}/>}
                    {!this.props.hideArrows &&
                    <div className={this.arrowRightClasses()}
                         onClick={this.props.onNextWeek ? this.props.onNextWeek : undefined}/>}

                    <div className="slots-wrapper clearfix">
                        {_.map(this.props.schedule, function (dateData) {
                            return (
                                <ScheduleDate date={dateData.date} key={dateData.date} highlight={ dateData.date === this.props.highlightedDateString}>
                                    {_.map(dateData.slots, function (slotData) {
                                        return (
                                            <ScheduleSlotButton {...slotData} date={dateData.date}
                                                                onToggle={this.props.onButtonToggle}
                                                                active={this.slotIsActive(dateData.date, slotData.starts_at, slotData.ends_at)}
                                                                key={ScheduleUtils.dateslotToUniqString({
                                                                    date: dateData.date,
                                                                    starts_at: slotData.starts_at,
                                                                    ends_at: slotData.ends_at
                                                                })}
                                                                categoryPrice={this.props.categoryPrice}
                                                                allowUnavailable={this.props.allowUnavailable}
                                                                noDifferentHoursOptions={this.noDifferentHoursOptions()}
                                            />
                                        );
                                    }.bind(this))}
                                </ScheduleDate>
                            );
                        }.bind(this))}

                        {this.props.promoteMembershipModal &&
                        <MembershipOverlay onMoreInfo={this.props.promoteMembershipModal}/>}
                    </div>
                </div>
                <DateSlotSelectorLegend legendType={this.props.legendType}/>
            </div>
        );
    }
}

export default SlotSelector;
