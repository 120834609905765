import React from 'react';
import PropTypes from 'prop-types';


class FunnelProgressListConfirmationInfo extends React.Component {
    render() {
        return (
            <div>
              <div>
                {i18n.t('funnel.breadcrumbs.confirmation.content')}
              </div>
            </div>
        );
    }
};

export default FunnelProgressListConfirmationInfo;
