import React from 'react';
import PropTypes from 'prop-types';


class FunnelBreadcrumbsLocationProductInfo extends React.Component {
    static contextTypes = {
        locationStrategy: PropTypes.string
    };

    static propTypes = {
        cityAndDistrict: PropTypes.string,
        address: PropTypes.object,
        serviceTitle: PropTypes.string,
    };

    content = () => {
        if (this.props.address)
            return this.props.address.text_description;
        else if (this.props.cityAndDistrict)
            return this.props.cityAndDistrict;
        else
            return i18n.t('funnel.breadcrumbs.district.content');
    };

    render() {
        return (
            <div>
                <div className="little-darkblue-title">
                    {this.props.serviceTitle}
                </div>
                <p>
                    {this.content()}
                </p>
            </div>
        );
    }
};

export default FunnelBreadcrumbsLocationProductInfo;
