import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';


class AvailabilitySchedule extends React.Component {
    static propTypes = {
        schedule: PropTypes.arrayOf(PropTypes.object),
        onSlotSelect: PropTypes.func,

        selectedDateSlot: PropTypes.shape({
            date: PropTypes.string,
            slot: PropTypes.number
        })
    };

    render() {
        var wdays = [1, 2, 3, 4, 5, 6, 0];

        return (
            <table className="availability_schedule with_selectable_slots">

              <thead>
                <tr>
                {_.map(wdays, function(wday) {
                    return (
                        <th key={wday} className={this.wdayLegendClasses(wday)}>
                          {i18n.t("date.abbr_day_names")[wday]}
                        </th>
                    );
                }.bind(this))}
                </tr>
              </thead>

              <tbody>
                {_.map(this.sliceBy(7, this.props.schedule), function(row, index) {
                    return this.renderCalendarRow(row, index);
                }.bind(this))}
              </tbody>

            </table>
        );
    }

    sliceBy = (sizeOfSlice, collection) => {
        return _.values(_.groupBy(collection, function(day, index) {
            return Math.floor(index / sizeOfSlice);
        }));
    };

    renderCalendarRow = (calendarRow, index) => {
        return (
            <tr key={index}>
              {_.map(calendarRow, function(day) {
                  return this.renderCalendarDay(day);
              }.bind(this))}
            </tr>
        );
    };

    renderCalendarDay = (day) => {
        var formattedDate = moment(day.date).format("DD.MM");

        return (
            <td key={day.date}>
              <div className="date">
                {formattedDate}
              </div>

              <div>
                {_.map(day.slots, function(slot) {
                    var selectedDateSlot = this.props.selectedDateSlot,
                        isSelected = selectedDateSlot && day.date == selectedDateSlot.date && slot.starts_at == selectedDateSlot.starts_at && slot.ends_at == selectedDateSlot.ends_at,
                        isAvailable = slot.available,
                        classes = "slot",
                        handler = function() {};

                    if(isSelected) {
                        classes += " selected";
                    }
                    else if(isAvailable) {
                        classes += " available";
                        handler = _.partial(this.props.onSlotSelect, _.extend({ date: day.date }, slot));
                    }

                    return (
                        <div key={slot.starts_at + slot.ends_at} onClick={handler} className={classes}></div>
                    );
                }.bind(this))}
              </div>
            </td>
        );
    };

    wdayLegendClasses = (wday) => {
        var weekend = [6, 0];

        if(_.contains(weekend, wday)) {
            return "red lighten-2";
        }
        else {
            return "green lighten-2";
        }
    };
};

export default AvailabilitySchedule;
