import React from 'react';
import _ from 'underscore';
import OrderPrice from '@components/common/order_price';

class FunnelOrderPriceContainer extends React.Component {
    state = { price: OrderPriceStore.getPrice() };

    componentWillMount() {
        OrderPriceStore.onChange(function(newPrice) {
            this.setState({price: newPrice});
        }.bind(this));
    }

    render() {
        if(!_.isEmpty(this.state.price)) {
            return (
                <OrderPrice {...this.state.price} />
            );
        }
        else {
            return null;
        }
    }
};

export default FunnelOrderPriceContainer;
