import React from 'react';


class AtHomeDescription extends React.Component {
  paragraphHtml = () => {
      return i18n.t('home.order-section.paragraph-2_html', { detergents_link: "/detergents" });
  };

  render() {
      return (
          <div className="row housemaid-product-description">
            <div className="col-sm-9 work-description">
              <h3>
                {i18n.t('home.order-section.how-we-work')}
              </h3>
              <p className="spacy" dangerouslySetInnerHTML={{__html: this.paragraphHtml()}}>
              </p>
            </div>

            <div className="col-sm-3 working-hours">
              <h4>{i18n.t('funnel.gomama-info.working-time')}</h4>
              <div className="working-days">{i18n.t('funnel.gomama-info.working-days')}</div>
              <div className="time-interval">{SLOT_INTERVAL.morning.begin} - {SLOT_INTERVAL.morning.end}</div>
              <div className="time-interval">{SLOT_INTERVAL.afternoon.begin} - {SLOT_INTERVAL.afternoon.end}</div>
            </div>
          </div>
      );
  }
}

export default AtHomeDescription;
