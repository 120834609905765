import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Dropdown from '@components/common/dropdown';

class PrettyLittleSelect extends React.Component {
    static propTypes = {
        className: PropTypes.string
    };

    dropdownParams = () => {
        return _.omit(this.props, 'className');
    };

    selectedOption = () => {
        return _.find(this.props.options, function(option) {
            return option.value === this.props.value;
        }.bind(this));
    };

    render() {
        return (
            <div className={"pretty-little-select" + " " + this.props.className}>
              <Dropdown {...this.dropdownParams()}/>

              <div className="pretty-little-select-option">
                {typeof(this.selectedOption()) != 'undefined' ? this.selectedOption().label : ''}
                <span className="pretty-little-select-arrows"></span>
              </div>
            </div>
        );
    }
}

export default PrettyLittleSelect;