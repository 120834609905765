import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

class ScheduleDescription extends React.Component {
    static propTypes = {
        firstDate: PropTypes.string.isRequired,
        lastDate: PropTypes.string.isRequired,
        cleanerName: PropTypes.string,
        showAllCleaners: PropTypes.func.isRequired,
        language: PropTypes.string
    };

    handleShowAllCleaners = (event) => {
        event.preventDefault();
        this.props.showAllCleaners();
    };

    daysRange = () => {
        return moment(this.props.firstDate).format("DD.MM") + ' - ' +
               moment(this.props.lastDate).format("DD.MM");
    };

    filterApplied = () => {
        return this.props.language || this.props.cleanerName;
    };

    filterName = () => {
        var cleanerName = this.props.cleanerName;

        var language = '';
        if (this.props.language) {
            language = i18n.t('funnel.time-form.maids_who_speak', {
                language: i18n.t("languages." + this.props.language)
            });
        }

        return cleanerName || language || i18n.t("funnel.time-form.all-maids");
    };

    render() {
        return (
            <div className="schedule-description">
              {i18n.t("funnel.time-form.schedule-description", { "days_range": this.daysRange(), maids: this.filterName()})}
              {" "}
              {this.filterApplied() && this.props.showAllCleaners &&
                  <a onClick={this.handleShowAllCleaners} href="">
                        {i18n.t("funnel.time-form.see-all-maids-schedule")}
                  </a>}
            </div>
        );
    }
}

export default ScheduleDescription;
