import React from 'react';
import PropTypes from 'prop-types';
import DateService from '@services/date_service';


class FunnelBreadcrumbsDateslotInfo extends React.Component {

    static propTypes = {
        dateslots: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            slot: PropTypes.number
        })),
    };

    title = () => {
        if (this.props.dateslots.length > 0)
            return this.firstEventDateAndDay(_.first(this.props.dateslots));
        else
            return i18n.t("funnel.breadcrumbs.dateslot-selection.title");
    }

    content = () => {
        if (this.props.dateslots.length > 0)
            return this.firstEventTimeslot(_.first(this.props.dateslots));
        else
            return i18n.t("funnel.breadcrumbs.dateslot-selection.content-default");
    };


    firstEventDateAndDay = (dateslot) => {
        return (
            <span>
                {DateService.formatDateAndDay(dateslot.date)}
            </span>
        );
    }

    firstEventTimeslot = (dateslot) => {
        return (
            <span>
                {i18n.t("funnel.breadcrumbs.dateslot-selection.visit-starts", {time: dateslot.starts_at})}
                <br/>
                {i18n.t("funnel.breadcrumbs.dateslot-selection.visit-ends", {time: dateslot.ends_at})}
            </span>
        );
    }

    render() {
        return (
            <div>
                <div className="little-darkblue-title">
                    {this.title()}
                </div>
                <p>
                    {this.content()}
                </p>
            </div>
        );
    }
};

export default FunnelBreadcrumbsDateslotInfo;
