import React from 'react';
import PropTypes from 'prop-types';


class FunnelBreadcrumbsAddressInfo extends React.Component {
    static propTypes = {
        address: PropTypes.string
    };

    content = () => {
        return this.props.address || i18n.t('funnel.breadcrumbs.address.content');
    };

    render() {
        return (
            <div>
              <div className="little-darkblue-title">
                {i18n.t('funnel.breadcrumbs.address.title')}
              </div>
              <p>
                {this.content()}
              </p>
            </div>
        );
    }
};

export default FunnelBreadcrumbsAddressInfo;
