import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import $ from 'jquery';
import CountdownTimer from '@components/common/countdown_timer';

class PromoCodeBanner extends React.Component {
    static propTypes = {
        errorText: PropTypes.string,
        timeLeft: PropTypes.number,
        discountText: PropTypes.string,
        infoText: PropTypes.string,
        code: PropTypes.string
    };

    state = {
        errorText: this.props.errorText,
        timeLeft: this.props.timeLeft,
        discountText: this.props.discountText,
        infoText: this.props.infoText,
        code: this.props.code
    };

    componentDidMount() {
        $(document).on("promoCodeBannerUpdate", function(event, params) {
            $.ajax({
                type: "GET",
                url: PathHelper('funnel_promo_code_banner_path'),
                data: _.extend({}, params, { code: this.state.code }),
                dataType: 'json',
                contentType: 'application/json',
                statusCode: {
                    500: function() {
                        window.location.href = '/500';
                    }
                }
            }).done(function(result) {
                this.setState(result);
            }.bind(this));
        }.bind(this));
    }

    hasError = () => {
        return this.state.errorText != null && this.state.errorText != "";
    };

    renderErrors = () => {
        return (
            <div className="promo-code-info">
              <div className="heading clearfix">
                <div className="errors">
                  {this.state.errorText}
                </div>

              </div>

              {this.state.infoText &&
              <div className="additional-info">{this.state.infoText}</div>}
            </div>
        );
    };

    renderAppliedCode = () => {
        return (
            <div className="promo-code-info">
              <div className="heading clearfix">
                <div className={classNames({ 'discount': true, 'full-width': !this.state.timeLeft })}>
                  { this.state.discountText}
                </div>

                {this.state.timeLeft &&
                  <div className="separator hidden-xs">|</div>}

                {this.state.timeLeft &&
                  <div className="time-left hidden-xs">
                        {i18n.t('promo-code.time-left')}
                        <CountdownTimer seconds={this.state.timeLeft} />
                  </div>}
              </div>

              {this.state.infoText &&
                <div className="additional-info">{this.state.infoText}</div>}
            </div>
        );
    };

    render() {
        if(this.hasError()) {
            return this.renderErrors();
        }
        else {
            return this.renderAppliedCode();
        }
    }
}

export default PromoCodeBanner;
