import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import $ from 'jquery';

class PostCodeResolver extends React.Component {
    static propTypes = {
        onPostCodeSelect: PropTypes.func.isRequired
    };

    state = {};

    resolvePostCode = () => {
        var postCode = this.refs.postcode.value;

        if(postCode == "") {
            this.setState({ error: "Invalid postcode" });
        }
        else {
            this.props.executeSlowOperation(function(){
                var deferred = $.Deferred();
                deferred.promise();

                $.ajax({
                    type: "GET",
                    url: PathHelper('api_post_code_path', { id: postCode }),
                    dataType: 'json',
                    contentType: 'application/json',
                    statusCode: {
                        500: function() {
                            window.location.href = '/500';
                        },
                        404: function() {
                            this.setState({ error: I18n.t('funnel.address-form.invalid-postcode') });
                            deferred.resolve();
                        }.bind(this)
                    }
                }).done(function(data) {
                    if(data.error) {
                        this.setState({ error: data.error });
                    }
                    else {
                        this.props.onPostCodeSelect(postCode, data.city, data.district);
                    }

                    deferred.resolve();
                }.bind(this));

                return deferred;
            }.bind(this));
        }
    };

    render() {
        return (
            <div className="postcode-resolver">
              <div className="funnel-heading">{ this.props.headerText || I18n.t('funnel.breadcrumbs.district.content-post')}</div>
              <div className="funnel-subtitle"></div>


              <div className="field-with-button">
                <input name="postcode" defaultValue={this.props.selectedPostCode} ref="postcode" type="text" placeholder={"Postcode"} className="field" />
                <input type="submit" value={"continue"} className={classNames({ btn: true, 'btn-primary': true })} onClick={this.resolvePostCode}/>
              </div>

              <div className="error-message">
                {this.state.error}
              </div>
            </div>
        );
    }
}


export default PostCodeResolver;
