import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import PreferredCleanerModal from '@components/funnel/preferred_cleaner_modal'
import {CleanerCarouselBox, LanguageFilterCarouselBox, AllCleanersCarouselBox} from '@components/common/cleaner_and_slot_selector/cleaner_carousel'

class CleanerCarouselMobile extends React.Component {
    static propTypes = {
        cleaners: PropTypes.arrayOf(PropTypes.object),
        onSelect: PropTypes.func,
        cleanerIdFilter: PropTypes.number,
        availableLanguages: PropTypes.array.isRequired,
        selectedLanguage: PropTypes.string,
        languageFilterActive: PropTypes.bool
    };

    state = {
        modalIsOpen: false
    };

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    selectFromModal = (filterParams) => {
        this.setState({ modalIsOpen: false});
        this.props.onSelect(filterParams);
    };

    getCleaner = () => {
        if(this.props.cleanerIdFilter) {
            return this.findCleanerById(this.props.cleanerIdFilter);
        }
        else {
            return null;
        }
    };

    // TODO: EXTRACT REPETITION
    findCleanerById = (cleanerId) => {
        return _.find(this.props.cleaners, function(cleaner) {
            return cleaner.id === cleanerId;
        });
    };

    render() {
        var cleaner = this.getCleaner(),
            currentSelection;

        if(cleaner) {
            currentSelection = <CleanerCarouselBox {...cleaner} onSelect={this.openModal} editable={true} />;
        } else if (this.props.languageFilterActive) {
            currentSelection = <LanguageFilterCarouselBox onSelect={this.openModal}
                                                          editable={true}
                                                          availableLanguages={this.props.availableLanguages}
                                                          selectedLanguage={this.props.selectedLanguage}
                                                          languageFilterActive={this.props.languageFilterActive}
                                                          mobile={true}/>;
        }
        else {
            currentSelection = <AllCleanersCarouselBox onSelect={this.openModal} editable={true} />;
        }

        return (
            <div className="cleaner-list">
              {currentSelection}
              <PreferredCleanerModal isOpen={this.state.modalIsOpen}
                                     onSelect={this.selectFromModal}
                                     cleaners={this.props.cleaners}
                                     availableLanguages={this.props.availableLanguages}
                                     selectedLanguage={this.props.selectedLanguage}
                                     languageFilterActive={this.props.languageFilterActive}/>
            </div>
        );
    }
}

export default CleanerCarouselMobile;