import React from 'react';
import PropTypes from 'prop-types';

import ProductUtils from '@services/product_utils';
import BreakPoints from '@services/breakpoints';

class FunnelProgressListProductInfo extends React.Component {
    static propTypes = {
        product: PropTypes.shape({
            typeName: PropTypes.string,
            attributesDescription: PropTypes.string
        })
    };

    renderProductInfo = () => {
        var content = [this.props.product.typeName,
                       this.props.product.productName,
                       this.props.product.attributesDescription].join(' ');

        if(ProductUtils.typeToString(this.props.product.productType) === "membership") {
            content = [this.props.product.typeName,
                       this.props.product.attributesDescription].join(' ');
        }

        return (
            <div>
              <div className="little-darkblue-title" dangerouslySetInnerHTML={{__html: content}}>
              </div>
            </div>
        );
    };

    renderRequiredAction = () => {
        return (
            <div>
              <div>
                {i18n.t('funnel.breadcrumbs.product.content')}
              </div>
            </div>
        );
    };

    hasProduct = () => {
        return (this.props.product.typeName || this.props.product.productName);
    };

    render() {
        if(this.hasProduct()) {
            return this.renderProductInfo();
        }
        else {
            return this.renderRequiredAction();
        }
    }
};

export default FunnelProgressListProductInfo;
