import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import moment from 'moment';
import PreferredCleanerModal from '@components/funnel/preferred_cleaner_modal'
import ResponsiveCleanerFilter from '@components/common/cleaner_and_slot_selector/responsive_cleaner_filter'
import CleanerSelector from '@components/common/cleaner_and_slot_selector/cleaner_selector'
import WaitingListRequest from '@components/common/cleaner_and_slot_selector/waiting_list_request'
import CleanerProfileModal from '@components/cleaner_profile_modal'
import ProductUtils from '@services/product_utils';
import BreakPoints from '@services/breakpoints';
import MediaQuery from 'react-responsive';
import SingleEventSchedule from '@components/common/cleaner_and_slot_selector/single_event_schedule';
import SubscriptionSchedule from '@components/common/cleaner_and_slot_selector/subscription_schedule';
import ScheduleDescription from '@components/common/cleaner_and_slot_selector/schedule_description';

class CleanerAndSlotSelector extends React.Component {
    static propTypes = {
        // Slot Selection
        scheduleOptions: PropTypes.shape({
            productType: PropTypes.number.isRequired,
            districtId: PropTypes.number.isRequired,
            cat: PropTypes.bool,
            dog: PropTypes.bool,
            visitDuration: PropTypes.number.isRequired,
            visitsWeekly: PropTypes.number,
            eventId: PropTypes.number,
            subscriptionId: PropTypes.number,
            weeksAheadLimit: PropTypes.number,
            scheduleModal: PropTypes.text
        }).isRequired,

        selectedDateslots: PropTypes.arrayOf(PropTypes.object).isRequired,

        // Cleaner Selection
        preferredCleanerModalSeen: PropTypes.bool,
        onPrefferedCleanerModalOpen: PropTypes.func,
        cleaners: PropTypes.arrayOf(PropTypes.object).isRequired,
        cleaner: PropTypes.object,
        categoryPrice: PropTypes.object,
        initialWeek: PropTypes.number,

        // Action
        onCleanerSelect: PropTypes.func.isRequired,
        onSlotsSelect: PropTypes.func.isRequired,
        executeSlowOperation: PropTypes.func.isRequired,
        invalidSlots: PropTypes.bool.isRequired,
        onAddCleaner: PropTypes.func.isRequired,

        initialCleanerIdFilter: PropTypes.number,

        //
        heading: PropTypes.string,
        headingSubtitle: PropTypes.string,
        editing: PropTypes.bool,

        noHints: PropTypes.bool,

        cleanerLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,

        onSingleSlotSelect: PropTypes.func,

        waitingList: PropTypes.bool,
        promoteMembershipModal: PropTypes.func,
        version: PropTypes.number, // if this integer changes we have to refresh the schedule
        companies: PropTypes.arrayOf(PropTypes.object),
        companyIdFilter: PropTypes.number,
        selectCompany: PropTypes.func,
        selectedProduct: PropTypes.object,
        listCompanies: PropTypes.bool,

        addressId: PropTypes.number,
        districtId: PropTypes.number,
        postcode: PropTypes.string
    };

    static defaultProps = {
        initialWeek: 0,
        enableCleanerCategories: true
    };

    componentWillMount() {
        var openModal = window.innerWidth < BreakPoints.xsMax &&
                        !this.props.preferredCleanerModalSeen &&
                        this.cleaners().length > 0 &&
                        this.props.cleaner.id === undefined;

        if(openModal) {
            this.setState({preferredCleanerModalIsOpen: true});
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.selectedDateslots && nextProps.selectedDateslots.length === 0) {
            this.setState({ dateslotsSelectionDone: false });
        }
    }

    renderStepDescription = () => {
        return (
            <div>
              <MediaQuery minWidth={BreakPoints.xsMax}>
                <div className="funnel-heading">
                  {this.props.heading}
                </div>
                {!this.props.listCompanies && <div className="funnel-subtitle">
                  {this.props.headingSubtitle}
                </div>}
              </MediaQuery>
            </div>
        );
    };

    // WEEK ==========

    incrementWeek = () => {
        this.setState({ week: this.state.week + 1 });
    };

    decrementWeek = () => {
        this.setState({ week: this.state.week - 1 });
    };

    // ===============

    // Cleaners ======

    noPreferredCleaner = () => {
        return this.state.cleanerIdFilter == null;
    };

    updateCleanerFilter = (filterOptions = {}) => {
        this.updateCleanerIdFilter(filterOptions.cleanerId);
        this.updateCleanerLanguageFilter(filterOptions);
    };

    updateCleanerIdFilter = (cleanerId) => {
        this.setState({cleanerIdFilter: cleanerId, dateslotsSelectionDone: false });
        this.props.onSlotsSelect([]);

        var cleaner = this.findCleanerById(cleanerId);
        this.props.onCleanerSelect(cleaner || {});

        if(cleaner && this.state.companyIdFilter == null) {
            this.updateCompanyIdFilter(cleaner.company_id);
        }
    };

    updateCompanyIdFilter = (companyId) => {
        if(this.props.selectCompany) {
            this.props.selectCompany(companyId);
        }

        this.props.onSlotsSelect([]);
        this.props.onCleanerSelect({});
        if(companyId == null) {
            this.setState({ cleanerIdFilter: null });
        }
    };

    updateCleanerLanguageFilter = (filterOptions) => {
        var newState = {dateslotsSelectionDone: false};

        if (typeof(filterOptions.selectedLanguage) != 'undefined') {
            newState.selectedLanguage = filterOptions.selectedLanguage;
        }

        if (typeof(filterOptions.languageFilterActive) != 'undefined') {
            newState.languageFilterActive = filterOptions.languageFilterActive;
        }

        this.setState(newState);
        this.props.onSlotsSelect([]);
    };

    findCleanerById = (cleanerId) => {
        return _.find(this.cleaners(), function(cleaner) {
            return cleaner.id === cleanerId;
        });
    };

    selectCleanerAndSubmit = (cleaner) => {
        this.props.onCleanerSelect(cleaner, function() {
            this.props.onSubmit();
        }.bind(this));
    };

    // ===============

    // Languages =====

    getDefaultLanguage = () => {
        if (_.contains(this.getAvailableLanguages(), i18n.locale)) {
            return(i18n.locale);
        } else {
            return(this.getAvailableLanguages()[0]);
        }
    };

    getAvailableLanguages = () => {
        return this.props.cleanerLanguages;
    };

    getLanguageFilterValue = () => {
        return this.state.languageFilterActive === true ? this.state.selectedLanguage : null;
    };

    // ===============

    invalidSlotsMessage = () => {
        return null;
            // <div>
            //   Slots are taken, choose another
            // </div>

    };

    getStatusAndCleanerOptions = (dateslotsUpdate) => {
        var nextState = {};
        nextState.dateslotsSelectionDone = dateslotsUpdate.dateslotsSelectionDone;

        if(dateslotsUpdate.dateslotsSelectionDone && !this.state.cleanerIdFilter) {
            nextState.cleanerOptions = _.last(dateslotsUpdate.newDateslots).cleaners;
        }

        return nextState;
    };

    updateStatusAndCleanerOptions = (dateslotsUpdate) => {
        var nextState = this.getStatusAndCleanerOptions(dateslotsUpdate);
        this.setState(nextState);
    };

    handleDateslotsChange = (dateslotsUpdate) => {
        this.props.onSlotsSelect(dateslotsUpdate.newDateslots);
        var nextState = this.getStatusAndCleanerOptions(dateslotsUpdate);

        // we need to wait the state to update so we can use emitCleaner
        this.setState(nextState, function() {
            if(this.state.dateslotsSelectionDone) {
                this.emitCleaner();
            }
        }.bind(this));
    };

    cleanerOptions = () => {
        return _.last(this.props.selectedDateslots).cleaners;
    };

    emitCleaner = () => {
        var cleaner = {};

        if(this.state.cleanerIdFilter) {
            cleaner = _.find(this.cleaners(), function(cleaner) {
                return cleaner.id === this.state.cleanerIdFilter;
            }.bind(this));
        }
        else if(this.state.cleanerOptions.length == 1) {
            cleaner = this.state.cleanerOptions[0];
        }

        this.props.onCleanerSelect(cleaner);
    };

    removeCleanerFilter = () => {
        this.updateCleanerFilter({cleanerId: null, languageFilterActive: false});
    };

    scheduleProps = () => {
        var cleaner = this.findCleanerById(this.state.cleanerIdFilter),
            renderDescription = function(from, to) {
                if(this.props.listCompanies) {
                    return this.renderFilter(from, to);
                }
                else {
                    return (
                        <ScheduleDescription
                           firstDate={from}
                           lastDate={to}
                           cleanerName={cleaner ? cleaner.name : null}
                           showAllCleaners={this.removeCleanerFilter}
                           language={this.getLanguageFilterValue()} />
                    );
                }
            }.bind(this);

        return {
            week: this.state.week,
            districtId: this.props.scheduleOptions.districtId,
            cat: this.props.scheduleOptions.cat,
            dog: this.props.scheduleOptions.dog,
            hotel: this.props.scheduleOptions.hotel,
            language: this.getLanguageFilterValue(),
            visitDuration: this.props.scheduleOptions.visitDuration,
            visitsWeekly: this.props.scheduleOptions.visitsWeekly,
            addMembership: this.props.scheduleOptions.addMembership,
            subscriptionId: this.props.scheduleOptions.subscriptionId,
            eventId: this.props.scheduleOptions.eventId,
            prepaidEventsCount: this.props.scheduleOptions.prepaidEventsCount,
            cleanerId: this.state.cleanerIdFilter && this.state.cleanerIdFilter.toString(),
            cleanerName: cleaner ? cleaner.name : null,
            showAllCleaners: this.removeCleanerFilter,
            selectedDateslots: this.props.selectedDateslots,
            onDateslotsChange: this.handleDateslotsChange,
            onDateslotsLoad: this.updateStatusAndCleanerOptions,
            executeSlowOperation: this.props.executeSlowOperation,
            onPrevWeek: (this.state.week >= 1 || null) && this.decrementWeek,
            onNextWeek: this.incrementWeek,
            categoryPrice: this.props.categoryPrice,
            editing: this.props.editing,
            noHints: this.props.noHints,
            onSingleSlotSelect: this.props.onSingleSlotSelect,
            weeksAheadLimit: this.props.scheduleOptions.weeksAheadLimit,
            scheduleModal: this.props.scheduleOptions.scheduleModal,
            allowUnavailable: this.props.waitingList,
            promoteMembershipModal: this.props.promoteMembershipModal,
            version: this.props.version,
            companyId: this.props.companyIdFilter,
            renderDescription: renderDescription,
            productType: this.props.scheduleOptions.productType
        };
    };

    productTypeString = () => {
        return ProductUtils.typeToString(this.productType());
    };

    productType = () => {
        var productTypeString = ProductUtils.typeToString(this.props.scheduleOptions.productType);

        if(productTypeString === 'membership') {
            return ProductUtils.stringToType('single_visit');
        }
        else {
            return this.props.scheduleOptions.productType;
        }
    };

    renderSchedule = () => {
        var schedules = { single_visit: SingleEventSchedule,
                          subscription: SubscriptionSchedule
            },
            productTypeString = this.productTypeString(),
            // We need capitalized variable, because that is how jsx works
            ChosenSchedule = schedules[productTypeString];

        return (
            <ChosenSchedule {...this.scheduleProps()}/>
        );
    };

    handleAddCleaner = (cleaner) => {
        this.setState({cleanerIdFilter: cleaner.id});
        this.props.onAddCleaner(cleaner);
    };

    cleanerSelectorHint = () => {
        var type = this.productTypeString();

        if(type === "single_visit") {
            return i18n.t('funnel.time-form.hints.select-single-event-cleaner');
        }
        else if(type === "subscription" || type === "at_work") {
            return i18n.t('funnel.time-form.hints.select-subscription-cleaner');
        }

        return null;
    };

    updateCleanerFilterAndCloseModal = (filterParameters) => {
        this.updateCleanerFilter(filterParameters);
        this.setState({ preferredCleanerModalIsOpen: false});
    };

    cleanerSelectorVisible = () => {
        var fakeCleanerSelected = this.props.cleaner && this.props.cleaner.presale_fake;
        return !fakeCleanerSelected && this.state.dateslotsSelectionDone && this.noPreferredCleaner() && this.allSelectedSlotsAreAvailable();
    };

    continueButtonHidden = () => {
        return this.cleanerSelectorVisible() && this.state.cleanerOptions.length > 1;
    };

    continueButtonVisible = () => {
        return !this.continueButtonHidden();
    };

    visibleCleaners = () => {
        return _.filter(this.cleaners(), function(cleaner) {
            return !cleaner.presale_fake &&
                _.contains(cleaner.service_types, ProductUtils.serviceType(this.productType()));
        }.bind(this));
    };

    cleaners = () => {
        if(this.props.companyIdFilter) {
            return _.filter(this.props.cleaners, function(cleaner) {
                return cleaner.company_id == this.props.companyIdFilter;
            }.bind(this));
        }
        else {
            var companyIds = _.map(this.props.companies, function(company) { return company.id; });

            if(companyIds.length > 0) {
                return _.filter(this.props.cleaners, function(cleaner) {
                    return _.contains(companyIds, cleaner.company_id);
                }.bind(this));
            }
            else {
                return this.props.cleaners;
            }
        }
    };

    cleanerFilterProps = () => {
        return {
            cleaners: this.visibleCleaners(),
            cleanerIdFilter: this.state.cleanerIdFilter,
            onSelect: this.updateCleanerFilter,
            cleanerCategoriesEnabled: this.props.cleanerCategoriesEnabled,
            availableLanguages: this.getAvailableLanguages(),
            selectedLanguage: this.state.selectedLanguage,
            languageFilterActive: this.state.languageFilterActive
        };
    };

    cleanerFilterVisible = () => {
        return this.visibleCleaners().length > 0 || (this.getAvailableLanguages().length > 0 && i18n.locale != NATIVE_LOCALE);
    };

    waitingListRequestVisible = () => {
        return this.state.dateslotsSelectionDone && !this.allSelectedSlotsAreAvailable();
    };

    allSelectedSlotsAreAvailable = () => {
        return !_.some(this.props.selectedDateslots, function(dateslot) {
            return dateslot.unavailable;
        });
    };

    canSubmit = () => {
        return this.props.cleaner.id && this.state.dateslotsSelectionDone && this.allSelectedSlotsAreAvailable();
    };

    waitingListRequestKey = () => {
        return _.map(this.props.selectedDateslots, function(dateslot) {
            return (dateslot.date + dateslot.slot);
        }).join() + this.props.cleaner.id;
    };

    updateCleanerProfileCode = (code) => {
        this.setState({cleanerProfileCode: code});
    };

    getFilterCleaner = () => {
        if(this.state.cleanerIdFilter) {
            return _.find(this.cleaners(), function(cleaner) {
                return cleaner.id == this.state.cleanerIdFilter;
            }.bind(this));
        }
        else {
            return null;
        }
    };

    renderFilter = (from, to) => {
        var daysRange = moment(from).format("DD.MM") + ' - ' +
                        moment(to).format("DD.MM");

        return (
            <div className="schedule-description">
              {i18n.t("funnel.time-form.schedule-period-description", { "days_range": daysRange })}
            </div>
        );
    };

    state = {
        week: this.props.initialWeek,
        cleanerIdFilter: this.props.initialCleanerIdFilter || null,
        cleanerOptions: [],
        dateslotsSelectionDone: false,
        preferredCleanerModalIsOpen: false,
        selectedLanguage: this.getDefaultLanguage(),
        languageFilterActive: false,
        cleanerProfileCode: ''
    };

    render() {
        var buttonDisabled = !this.canSubmit();

        if(this.state.preferredCleanerModalIsOpen && !this.props.preferredCleanerModalSeen) {
            return (
                <PreferredCleanerModal
                  isOpen={this.state.preferredCleanerModalIsOpen}
                  onOpen={this.props.onPrefferedCleanerModalOpen}
                  onSelect={this.updateCleanerFilterAndCloseModal}
                  cleaners={this.cleaners()}
                  cleanerCategoriesEnabled={this.props.cleanerCategoriesEnabled}
                  availableLanguages={this.getAvailableLanguages()}
                  selectedLanguage={this.state.selectedLanguage}
                  languageFilterActive={this.state.languageFilterActive}/>
            );
        }

        return (
                <div className="cleaner-and-slot-selector">
                  {this.props.invalidSlots &&
                      this.invalidSlotsMessage()
                  }

                  {this.renderStepDescription()}

                  {!this.props.listCompanies && this.cleanerFilterVisible() &&
                    <ResponsiveCleanerFilter {...this.cleanerFilterProps()}/>}

                  {this.renderSchedule()}

                  {this.cleanerSelectorVisible() &&
                      <CleanerSelector
                             categoryPrice={this.props.categoryPrice}
                             cleaners={this.state.cleanerOptions}
                             cleaner={this.props.cleaner}
                             onSelect={this.selectCleanerAndSubmit}
                             hintText={this.cleanerSelectorHint()}
                             cleanerCategoriesEnabled={this.props.cleanerCategoriesEnabled}
                             priceChangeInfo={this.props.cleanerCategoriesEnabled}
                             onMoreInfoAboutCleaner={this.updateCleanerProfileCode}
                             listCompanies={this.props.listCompanies}/> }

                  {this.waitingListRequestVisible() &&
                          <WaitingListRequest product={this.props.selectedProduct}
                                              addressId={this.props.addressId}
                                              districtId={this.props.districtId}
                                              postcode={this.props.postcode}
                                              selectedDateslots={this.props.selectedDateslots}
                                              selectedCleaner={this.getFilterCleaner()}
                                              executeSlowOperation={this.props.executeSlowOperation}
                                              user={this.props.user} />}

                  <div className="form-footer">
                    {this.continueButtonVisible() && !this.waitingListRequestVisible() &&
                        <button className={"btn btn-primary btn-submit " + (buttonDisabled ? 'disabled' : '')}
                                    disabled={buttonDisabled}
                                    onClick={this.props.onSubmit}>
                              {this.props.submitButtonText}
                        </button>}

                    {this.props.cancelable &&
                        <a className="btn-cancel" href={this.props.cancelButtonUrl}>
                              {this.props.cancelButtonText}
                        </a>}
                  </div>

                  <PreferredCleanerModal
                    isOpen={this.state.preferredCleanerModalIsOpen}
                    onSelect={this.updateCleanerFilterAndCloseModal}
                    cleaners={this.cleaners()}
                    cleanerCategoriesEnabled={this.props.cleanerCategoriesEnabled}
                    availableLanguages={this.getAvailableLanguages()}
                    selectedLanguage={this.state.selectedLanguage}
                    languageFilterActive={this.state.languageFilterActive}/>

                  <CleanerProfileModal cleanerCode={this.state.cleanerProfileCode} onClose={_.partial(this.updateCleanerProfileCode, null)}/>
                </div>
        );
    }
}

export default CleanerAndSlotSelector;
