import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import FeedbacksList from '@components/common/feedbacks_list';
import CleanerPhoto from '@components/common/cleaner_photo';
import CleanerUtils from '@services/cleaner_utils';

class CleanerInterview extends React.Component {
    static propTypes = {
        htmlContent: PropTypes.string
    };

    state = {
        open: false
    };

    toggleFullContent = (event) => {
        event.preventDefault();
        this.setState({open: !this.state.open});
    };

    interviewStyle = () => {
        if (this.state.open) {
            return {maxHeight: this.refs.interviewNode.offsetHeight + 'px'};
        } else {
            return {};
        }
    };

    renderShowMoreButton = () => {
        var buttonClass = "glyphicon ",
            buttonText;

        if (this.state.open) {
            buttonClass += "glyphicon-chevron-up";
            buttonText = i18n.t('cleaner-profile.hide-interview');
        }
        else {
            buttonClass += "glyphicon-chevron-down";
            buttonText = i18n.t('cleaner-profile.read-full-interview');
        }

        return (
            <a href="" onClick={this.toggleFullContent} className="show-more">
                {buttonText}
                <span className={buttonClass}/>
            </a>

        );
    };

    render() {
        return (
            <div>
                <div className={classNames({"cleaner-interview": true, open: this.state.open})}
                     style={this.interviewStyle()}>
                    <div dangerouslySetInnerHTML={{__html: this.props.htmlContent}} ref='interviewNode'/>
                </div>

                {this.renderShowMoreButton()}
            </div>
        );
    }
}

class CleanerProfile extends React.Component {
    static propTypes = {
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        service_types: PropTypes.arrayOf(PropTypes.string),
        photoUrl: PropTypes.string.isRequired,
        deaf: PropTypes.bool.isRequired,
        category: PropTypes.string.isRequired,

        rating: PropTypes.shape({
            value: PropTypes.string.isRequired,
            max: PropTypes.string.isRequired
        }).isRequired,

        visitsDone: PropTypes.number.isRequired,

        subscriptionsTaken: PropTypes.shape({
            value: PropTypes.number.isRequired,
            max: PropTypes.number.isRequired
        }).isRequired,

        districts: PropTypes.arrayOf(PropTypes.string),
        description: PropTypes.string.isRequired,

        interview: PropTypes.string,

        initialFeedbacks: PropTypes.arrayOf(PropTypes.shape({
            author: PropTypes.string.isRequired,
            rating: PropTypes.number.isRequired,
            content: PropTypes.string
        })),

        allFeedbacksShowed: PropTypes.bool.isRequired,
        default_rating: PropTypes.bool.isRequired,

        additionalDistrict: PropTypes.string
    };

    districts = () => {
        return _.chain(this.props.districts.concat([this.props.additionalDistrict]))
            .compact()
            .uniq()
            .value()
            .sort()
            .join(', ');
    };

    showSubscriptions = () => {
        return _.contains(this.props.service_types, 'housemaid') && this.props.subscriptionsTaken.max > 0;
    };

    showDistricts = () => {
        return _.contains(this.props.service_types, 'housemaid');
    };

    showCategory = () => {
        return _.contains(this.props.service_types, 'housemaid');
    };

    showVisitsDone = () => {
        return this.props.visitsDone > 9;
    };

    render() {
        return (
            <div className="cleaner-profile">
                <div className="base-container">
                    <CleanerPhoto image_path={this.props.photoUrl} deaf={this.props.deaf}/>

                    <div className="cleaner-name-and-category offset-by-photo hidden-xs">
                        <div className="cleaner-name">{this.props.name}</div>
                        { this.showCategory() &&
                            <div className={"cleaner-category " + CleanerUtils.categoryClasses(this.props.category, this.props.deaf)}>{CleanerUtils.humanCategory(this.props.category, this.props.deaf)}</div>
                        }
                    </div>
                </div>

                <div className="overlay-container">
                    <div className="cleaner-name-and-category visible-xs-block">
                        <div className="cleaner-name">{this.props.name}</div>
                        { this.showCategory() &&
                        <div
                            className={"cleaner-category " + CleanerUtils.categoryClasses(this.props.category, this.props.deaf)}>{CleanerUtils.humanCategory(this.props.category, this.props.deaf)}</div>}
                    </div>

                    <div className="cleaner-stats offset-by-photo">
                        { !this.props.default_rating &&
                        <div className="cleaner-stats-item">
                            <div className="value">
                                {this.props.rating.value}/{this.props.rating.max}
                            </div>
                            <div className="name">
                                {i18n.t('cleaner-profile.rating')}
                            </div>
                        </div>}
                        { this.showVisitsDone() &&
                        <div className="cleaner-stats-item">
                            <div className="value">
                                {this.props.visitsDone}
                            </div>
                            <div className="name">
                                {i18n.t('cleaner-profile.visits')}
                            </div>
                        </div>}
                        {this.showSubscriptions() &&
                        <div className="cleaner-stats-item">
                            <div className="value">
                                {this.props.subscriptionsTaken.value}/{this.props.subscriptionsTaken.max}
                            </div>
                            <div className="name">
                                {i18n.t('cleaner-profile.subscriptions')}
                            </div>
                        </div>}
                    </div>

                    {this.props.phone &&
                    <div className="phone offset-by-photo">
                        <strong>{i18n.t('cleaner-profile.phone')}:</strong> {this.props.phone}
                    </div>}

                    <hr className="separator"/>

                    <p className="cleaner-description">
                        {this.props.description}
                    </p>

                    {this.props.interview && <h4 className="section-heading">
                        {i18n.t('cleaner-profile.interview')}
                    </h4>}

                    {this.props.interview && <CleanerInterview htmlContent={this.props.interview}/>}

                    {(this.props.initialFeedbacks.length > 0) &&
                    <h4 className="section-heading">
                        {i18n.t('cleaner-profile.feedbacks')}
                    </h4>}

                    <FeedbacksList initialFeedbacks={this.props.initialFeedbacks}
                                   recipientName={this.props.name}
                                   allFeedbacksShowed={this.props.allFeedbacksShowed}
                                   requestPath={'public_feedbacks_cleaners_path'}
                                   requestParams={{ code: this.props.code }} />

                    {this.props.orderNow &&
                    <div className="order-now">
                        <a href={PathHelper('new_housemaid_request_path', {cleaner_code: this.props.code})}
                           className="btn btn-primary">
                            {i18n.t('cleaner-profile.order-now', {name: this.props.name.split(' ')[0]})}
                        </a>
                    </div>}
                </div>
            </div>
        );
    }
}

export default CleanerProfile;
