import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import SlotSelector from '@components/common/cleaner_and_slot_selector/slot_selector'
import MediaQuery from 'react-responsive';
import BreakPoints from '@services/breakpoints';

import Modal from '@components/vendor/modal';
import CheckList from '@components/common/cleaner_and_slot_selector/check_list';
import ScheduleUtils from '@components/common/cleaner_and_slot_selector/utils';
import ProductUtils from '@services/product_utils'
import $ from 'jquery';

class SingleEventSchedule extends React.Component {
    static propTypes = {
        week: PropTypes.number,
        visitDuration: PropTypes.number,
        addMembership: PropTypes.bool,
        districtId: PropTypes.number,
        cat: PropTypes.bool,
        dog: PropTypes.bool,
        hotel: PropTypes.bool,
        language: PropTypes.string,
        cleanerId: PropTypes.string,
        selectedDateslots: PropTypes.arrayOf(PropTypes.object),

        onDateslotsChange: PropTypes.func,
        onDateslotsLoad: PropTypes.func,
        executeSlowOperation: PropTypes.func,

        onNextWeek: PropTypes.func,
        onPrevWeek: PropTypes.func,
        categoryPrice: PropTypes.object,
        showAllCleaners: PropTypes.func,
        eventId: PropTypes.number,
        noHints: PropTypes.bool,
        weeksAheadLimit: PropTypes.number,
        onSingleSlotSelect: PropTypes.func,
        scheduleModal: PropTypes.string,
        allowUnavailable: PropTypes.bool,
        promoteMembershipModal: PropTypes.func,
        companyId: PropTypes.number,
        renderDescription: PropTypes.func
    };

    state = {
        schedule: [],
        modalIsSeen: Cookies.get('single-visit-schedule-modal') == 'seen'
    };

    componentDidMount() {
        this.updateSchedule()
            .done(function() {
                this.props.onDateslotsLoad({
                    newDateslots: ScheduleUtils.getDateslotsFromSchedule(this.state.schedule, this.props.selectedDateslots),
                    dateslotsSelectionDone: this.dateslotsSelectionDone(this.props.selectedDateslots)
                });
            }.bind(this));
    }

    componentDidUpdate(prevProps, _) {
        var weekChanged = this.props.week !== prevProps.week,
            districtIdChanged = this.props.districtId !== prevProps.districtId,
            cleanerIdChanged = this.props.cleanerId !== prevProps.cleanerId,
            languageChanged = this.props.language != prevProps.language,
            versionChanged = this.props.version != prevProps.version,
            companyIdChanged = this.props.companyId != prevProps.companyId,
            productIsChanged = this.props.productType != prevProps.productType;

        if(weekChanged || districtIdChanged || cleanerIdChanged || languageChanged || versionChanged || companyIdChanged || productIsChanged) {
            this.updateSchedule();
        }
    }

    weeksAheadLimit = () => {
        var result;
        if(this.props.weeksAheadLimit != undefined) {
            result = this.props.weeksAheadLimit;
        }
        else {
            result = 1;
        }

        if(this.props.promoteMembershipModal) {
            result = 1;
        }

        return result;
    };

    updateSchedule = () => {
        return this.props.executeSlowOperation(function() {
            var promise = this.fetchSchedule();

            promise.done(function(schedule) {
                this.setState({ schedule: schedule });
            }.bind(this));

            return promise;
        }.bind(this));
    };

    queryUrl = () => {
        var path = "single_availability";

        if(this.props.eventId) {
            path = "/edit_" + path;
        }
        else {
            path = "/" + path;
        }

        return PathWithLocale.build(path);
    };

    fetchSchedule = () => {
        var queryParams = { week: this.props.week, district_id: this.props.districtId, visit_duration: this.props.visitDuration,
                            cat: this.props.cat, dog: this.props.dog, language: this.props.language, hotel: this.props.hotel,
                            add_membership: this.props.addMembership,
                            company_id: this.props.companyId };

        if(this.promoteMembershipModal()) {
            queryParams.product_type = ProductUtils.stringToType('membership');
        }
        else {
            queryParams.product_type = this.props.productType;
        }

        if(this.props.cleanerId) {
            queryParams.cleaner_id = this.props.cleanerId;
        }

        if(this.props.eventId) {
            queryParams.event_id = this.props.eventId;
        }

        return $.get(this.queryUrl() + "?" + $.param(queryParams));
    };

    dateslotsSelectionDone = (dateslots) => {
        return this.selectedDateslotsAreEnough(dateslots) && this.selectedDateslotsAreVisible(dateslots);
    };

    selectedDateslotsAreEnough = (dateslots) => {
        return dateslots.length > 0;
    };

    handleDateSlotToggle = (dateslot) => {
        if(this.props.onSingleSlotSelect) this.props.onSingleSlotSelect();
        var newDateslots = this.dateSlotIsSelected(dateslot) ? [] : [dateslot];

        this.props.onDateslotsChange({
            newDateslots: newDateslots,
            dateslotsSelectionDone: this.dateslotsSelectionDone(newDateslots)
        });
    };

    hints = () => {
        return [{
            message: i18n.t('funnel.time-form.hints.single-event'),
            checked: this.props.selectedDateslots.length == 1
        }];
    };

    // shared =========

    dateSlotIsSelected = (dateslot) => {
        var dateslotFound = _.find(this.props.selectedDateslots, function(ds) {
            return ScheduleUtils.dateslotsEqual(dateslot, ds);
        });

        return dateslotFound != undefined;
    };

    selectedDateslotsAreVisible = (dateslots) => {
        return _.find(this.state.schedule, function(dateData) {
            return _.some(dateslots, function(dateslotData) {
                return dateData.date == dateslotData.date;
            });
        }.bind(this)) != undefined;
    };

    closeModal = () => {
        Cookies.set('single-visit-schedule-modal', 'seen', { expires: 30 });
        this.setState({ modalIsSeen: true });
    };

    renderModal = () => {
        if(this.props.scheduleModal) {
            return (
                <Modal isOpen={!this.state.modalIsSeen}>
                  <div className="custom-modal-content">
                    <div dangerouslySetInnerHTML={{__html: this.props.scheduleModal}}></div>
                    <div className="text-center">
                      <button className="btn btn-primary uppercase" onClick={this.closeModal}>{i18n.t("funnel.order-type-form.schedule-modal-agree-button")}</button>
                    </div>
                  </div>
                </Modal>
            );
        }
        else {
            return null;
        }
    };

    promoteMembershipModal = () => {
        if(this.props.week === this.weeksAheadLimit() && this.props.promoteMembershipModal) {
            return this.props.promoteMembershipModal;
        }
        else {
            return null;
        }
    };

    // ================

    render() {
        if(this.state.schedule.length === 0) return null;

        return (
            <div className="clearfix">
              {this.renderModal()}
              {this.props.renderDescription(_.first(this.state.schedule).date, _.last(this.state.schedule).date)}

              <SlotSelector
                 schedule={this.state.schedule}
                 selectedDateslots={this.props.selectedDateslots}
                 onButtonToggle={this.handleDateSlotToggle}
                 onPrevWeek={this.props.onPrevWeek}
                 onNextWeek={this.props.week < this.weeksAheadLimit() && this.props.onNextWeek}
                 categoryPrice={this.props.categoryPrice}
                 allowUnavailable={this.props.allowUnavailable}
                 promoteMembershipModal={this.promoteMembershipModal()}
                 legendType={'housemaid'}
              />

              {this.props.children}

                {!this.props.noHints &&
                    <MediaQuery minWidth={BreakPoints.xsMax}>
                          <CheckList items={this.hints()} />
                    </MediaQuery>}
            </div>
        );
    }
}

export default SingleEventSchedule;
