import React from 'react';
import PropTypes from 'prop-types';

class Dropdown extends React.Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string
        })),
        label: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        name: PropTypes.string,
        className: PropTypes.string,
        containerClassName: PropTypes.string
    };

    selectedOption = () => {
        return _.find(this.props.options, function(option) {
            return option.value === this.props.value;
        }.bind(this));
    };

    renderOptions = () => {
        return _.map(this.props.options, function(option, i) {
            return <option key={i} value={option.value}>{option.label}</option>;
        });
    };

    render() {
        return (
            <div className={this.props.containerClassName}>
              {<label>
                    {this.props.label}
              </label>}
              <select name={this.props.name}
                      value={this.props.value}
                      onClick={this.props.onClick}
                      onChange={this.props.onChange}
                      className={this.props.className}>
                {this.renderOptions()}
              </select>
            </div>
        );
    }
}


export default Dropdown;