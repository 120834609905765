import {Spinner} from 'spin.js';
import 'spin.js/spin.css';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import $ from 'jquery';

// React component wrapper around spinjs
class SpinnerComponent extends React.Component {
    static propTypes = {
        options: PropTypes.object,
        loading: PropTypes.bool
    };

    options = () => {
        if(this.props.options) {
            return this.props.options;
        }
        else {
            return {
                lines: 9, // The number of lines to draw
                length: 0, // The length of each line
                width: 17, // The line thickness
                radius: 33, // The radius of the inner circle
                scale: 1, // Scales overall size of the spinner
                corners: 1, // Corner roundness (0..1)
                // color: '#379feb', // blue
                // color: '#6faf1d', // green
                // color: '#404040',
                color: '#FFFFFF',

                opacity: 0.25, // Opacity of the lines
                rotate: 0, // The rotation offset
                direction: 1, // 1: clockwise, -1: counterclockwise
                speed: 1, // Rounds per second
                trail: 50, // Afterglow percentage
                fps: 20, // Frames per second when using setTimeout() as a fallback for CSS
                zIndex: 2e9, // The z-index (defaults to 2000000000)
                className: 'spinner', // The CSS class to assign to the spinner
                top: '50%', // Top position relative to parent
                left: '50%', // Left position relative to parent
                shadow: false, // Whether to render a shadow
                hwaccel: false, // Whether to use hardware acceleration
                position: 'absolute' // Element positioning
            };
        }
    };

    state = {
        spinner: new Spinner(this.options()),
        spinnerId: _.uniqueId("spinner-overlay-")
    };

    componentDidMount() {
        this.updateDom();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.loading !== nextProps.loading;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateDom(this.props.loading);
    }

    updateDom = (loading) => {
        var target;

        if(loading) {
            // this.state.spinner.spin(this.refs.spinner);
            target = document.createElement("div");
            target.classList.add("spinner-overlay");
            target.id = this.state.spinnerId;
            this.state.spinner.spin(target);
            $(target).hide().appendTo(document.body).fadeIn();
        }
        else {
            $("#" + this.state.spinnerId).fadeOut('fast', function() {
                $("#" + this.state.spinnerId).remove();
                this.state.spinner.stop();
            }.bind(this));
        }
    };




    render() {
        return null;
    }
}

export default SpinnerComponent;
