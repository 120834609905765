import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Breadcrumbs extends React.Component {
    render() {
        return (
            <div className="breadcrumbs">
              {this.props.children}
            </div>
        );
    }
}

class BreadcrumbsItem extends React.Component {
    static propTypes = {
        active: PropTypes.bool,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        style: PropTypes.object
    };

    classes = () => {
        return classNames({
            'breadcrumbs-item-wrapper': true,
            active: this.props.active,
            disabled: this.props.disabled
        });
    };

    render() {
        return (
            <div style={this.props.style} className={this.classes()} onClick={this.props.onClick}>
              <div className="breadcrumbs-item">
                {this.props.children}
              </div>
            </div>
        );
    }
};

export {BreadcrumbsItem, Breadcrumbs};
