import React from 'react';
import PropTypes from "prop-types";

import classNames from 'classnames';
import _ from 'underscore';

class FormWithPostcode extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        productType: PropTypes.number
    };

    state = { errors: {} };

    handleSubmit = (event) => {
        event.preventDefault();

        var address = { postcode: this.refs.postcode.value,
                        description: this.refs.description.value,
                        cat: this.getCatCheckboxState(),
                        dog: this.getDogCheckboxState() };

        this.props.onSubmit(address)
            .done(function(result) {
                if(!result.success) {
                    this.setState({ errors: _.extend({}, result.errors )});
                }
            }.bind(this));
    };

    getCatCheckboxState = () => {
        return this.refs.cat ? this.refs.cat.checked : false;
    };

    getDogCheckboxState = () => {
        return this.refs.dog ? this.refs.dog.checked : false;
    };

    renderSubmitButton = (handler) => {
        var classes = classNames({ btn: true, 'btn-primary': true});

        return (
            <button onClick={this.handleSubmit} type="submit" className={classes}>{i18n.t('funnel.address-form.save-address')}</button>
        );
    };

    hasError = () => {
        return !_.isEmpty(this.allErrors());
    };

    allErrors = () => {
        return _.flatten(_.values(this.state.errors));
    };

    render() {
        return (
            <div className="address-fields">
              {this.hasError() &&
                <div className="row">
                  <div className="col-xs-12 error-message">
                        {this.allErrors().join(';')}
                  </div>
                </div>}

              <div className="row">
                <div className={classNames({ "form-group": true, "col-sm-12": true, "has-error": this.state.errors.postcode })}>
                  <label>Postcode</label>
                  <input name="postcode" defaultValue={this.props.postCode} ref="postcode" type="text" className="form-control input-lg" disabled={this.props.postCodeReadOnly}/>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-12">
                  <label>{i18n.t('helpers.label.funnel_form.description')}</label>
                  <textarea name="description" ref="description" className="form-control input-lg">

                  </textarea>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-2 form-group">
                  <label>
                    <input type="checkbox" ref="cat" name="cat"/> {i18n.t('helpers.label.funnel_form.i_have_a_cat')}
                  </label>
                </div>
                <div className="col-sm-2 form-group">
                  <label>
                    <input type="checkbox" ref="dog" name="dog"/> {i18n.t('helpers.label.funnel_form.i_have_a_dog')}
                  </label>
                </div>
              </div>

              {this.renderSubmitButton()}
            </div>
        );
    }
};

export default FormWithPostcode;
