import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import PriceTag from '@components/common/price_tag'
import PrettyLittleContainer from '@components/common/pretty_little_container'


class MembershipOptionSelector extends React.Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            price: PropTypes.string,
            description: PropTypes.string,

            // Data
            productType: PropTypes.number,
            visitDuration: PropTypes.number,
            visitsWeeky: PropTypes.number
        })),

        priceClass: PropTypes.string,
        onSelect: PropTypes.func,

        selectedOption: PropTypes.object
    };

    constructor(props, context) {
        super(props, context);
        var selectedOption = this.fetchSelectedOption(props.selectedOption.visitDuration,
                                                      props.selectedOption.visitsWeekly);

        if(selectedOption) {
            this.state = { visitDuration: selectedOption.visitDuration,
                     visitsWeekly: selectedOption.visitsWeekly,
                     selected: true };

            return;
        }
        else {
            var defaultVisitDuration = (_.contains(_.map(props.options, function(option) { return option.visitDuration; }), 4) && 4) ||
                                       props.options[0].visitDuration,
                defaultVisitsWeekly = (_.contains(_.map(props.options, function(option) { return option.visitsWeekly; }), 1) && 1) ||
                                       props.options[0].visitWeekly;

            this.state = { visitDuration: defaultVisitDuration,
                     visitsWeekly: defaultVisitsWeekly,
                     selected: false };

            return;
        }
    }

    handleSelect = () => {
        this.props.onSelect(this.selectedOption());
    };

    selectedOption = () => {
        return this.fetchSelectedOption(this.state.visitDuration, this.state.visitsWeekly);
    };

    fetchSelectedOption = (visitDuration, visitsWeekly) => {
        return _.find(this.props.options, function(option) {
            return visitDuration === option.visitDuration &&
                visitsWeekly === option.visitsWeekly;
        });
    };

    renderButton = () => {
        var classes = 'btn btn-primary', text, handler;

        if(this.state.selected) {
            classes += ' active';
            text = i18n.t('form.buttons.selected');
        }
        else {
            text = i18n.t('form.buttons.choose');
        }

        return (
            <button className={classes} onClick={this.handleSelect}>
              {text}
            </button>
        );
    };

    renderDescription = () => {
        var selectedOption = this.selectedOption();
        var tooltip, overlayTrigger;

        if(selectedOption.tooltipMessage) {
            tooltip = (
                <Tooltip id={selectedOption.productType}>
                  <div dangerouslySetInnerHTML={{__html: selectedOption.tooltipMessage}}></div>
                </Tooltip>
            );

            overlayTrigger = (
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <span className="glyphicon glyphicon-info-sign" aria-hidden="true"></span>
                </OverlayTrigger>
            );
        }

        return (
            <ul>
              {_.map(selectedOption.description.split('\n'), function(line, i) {
                  if(line === "") {
                      return null;
                  }
                  else {
                      return (
                          <li key={i}>
                            <span dangerouslySetInnerHTML={{__html: line}}></span>
                            {i == 0 && overlayTrigger}
                          </li>
                      );
                  }
              }.bind(this))}
            </ul>
        );
    };

    render() {
        var selectedOption = this.selectedOption();

        return (
            <div className={classNames({'product-selector': true, highlighted: this.props.highlighted})}>
              <div className="box-label">
                <div className="product-type">{this.props.typeName}</div>
                <div className="product-name">{this.props.productName}</div>
              </div>

              <div className="box-content">
                <PrettyLittleContainer className="membership">
                  {i18n.t('funnel.order-type-form.membership.for-who')}
                </PrettyLittleContainer>

                <PriceTag amount={selectedOption.price}
                          className={"price-tag " + this.props.priceClass}
                          prefix={selectedOption.pricePrefix}
                          postfix={"/" + i18n.t('funnel.order-type-form.membership.month_abbrv')}/>

                {this.renderButton()}
                {this.renderDescription()}
              </div>
            </div>
        );
    }
}


export default MembershipOptionSelector;
